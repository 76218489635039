import {
    FETCH_NONROUTINES, FETCH_NONROUTINE_DETAILS, UPDATE_NONROUTINE, NONROUTINE_OPEN_FILTERS, SET_SEARCH_CARDS_TEXT_NR, CREATE_NONROUTINE, FETCH_NONROUTINES_REF_DATA,
    RESET_CREATE_NONROUTINE_FLAGS, SET_NONROUTINE_ACTIONS_VARIABLE, RESET_NONROUTINE_ACTIONS_VARIABLE, ADD_MXNOTES_NONROUTINES, TOGGLE_ADD_SUBTASK_MODAL, FETCH_NONROUTNE_HISTORY, NONROUTINE_HISTORY_FILTERS,
    RESET_NONROUTINE_REGISTER_DATA, FETCH_NONROUTINE_LOGPAGES, TOGGLE_SUBTASK_SIGNOFF_MODAL, FETCH_NONROUTINE_IMAGES, UPDATE_SUBTASK_SORT_ORDER, RESET_VIEW_NR_ERROR_MESSAGE, SUBTASK_SIGNOFF, HANDLE_NONROUTINE_ACTIONS
    , IS_APPLY_FILTERS_CLICK_ACTION, RESET_NR_FILTERS, GET_ATTACHED_TASKCARD, RESET_ATTACHED_TASKCARD, GET_GROUPS_FOR_NONROUTINE, GET_LINKED_GROUP_FOR_LINKED_NONROUTINE,GET_ATA_CODES, ADD_ROTABLE_PARTS, RESET_ADD_ROTABLE_PARTS,
    IS_OPENE_LINK_CLICK_ACTION, FETCH_ACTIONTYPES_FOR_NOTES, GET_DASH1_NOTES_FOR_NR, RESET_DASH1_NOTES_FOR_NR, SELF_ASSOCIATE_WL, SET_ASSIGNED_ROUTINES, GET_ASSIGNED_CREW, UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY, ADD_PDFIMAGE_NONROUTINES, GET_PDFIMAGE_NRS, DELETE_NR_PDF
} from '../actions/actionTypes';

const initialState = {
    isLoadingNonRoutines: true,
    nonRoutines: [],
    isLoadingNonRoutinesHistory: true,
    nonRoutinesHistory: null,
    nonRoutinesLoadingError: "",
    isLoadingNonRoutineDetails: true,
    nonRoutineDetails: null,
    selectedNonRoutine: null,
    cumulativeRefData: null,
    isUpdatingNonRoutine: false,
    updateNonRoutineResponse: null,
    nonRoutinesOpenFilters: { ata: "", status: [], skills: [], zones: [], mostRecent: false, inDays: false, day:{ inDays: false, days:'', inHours: false, hours:'' } },
    nonRoutinesHistoryFilters: { ata: "", days: "all", status: [], skills: [], zones: [], mostRecent: false, day:{ inDays: false, days:'', inHours: false, hours:'' }},
    isCreateNRPending: false,
    isCreateNRSuccess: false,
    isCreateNRError: false,
    createNRErrorMessage: null,
    createPasswordFailureMessage: null,
    nonRoutinesRefData: null,
    nonRoutinesActionsVariable: null,
    mxNotes: [],
    nonRoutineLogPages: null,
    showSubTaskSignoffModal: false,
    nonRoutineImages: [],
    loadingNonRoutineImages: true,
    isLoadingNonRoutineLogPages: true,
    isUpdateSortOrderPending: false,
    isUpdateSortOrderSuccess: false,
    isUpdateSortOrderError: false,
    updateSortOrderErrorMessage: null,
    updateSortOrderPasswordErrorMessage: null,
    isSignoffPending: false,
    signoffPasswordFailureMessage: null,
    signoffFailureMessage: null,
    isSignoffSuccess: false,
    showAddSubTaskModal: false,
    isNonRoutineActionSuccess: false,
    nonRoutineActionErrorMessage: null,
    nonRoutineActionPwdErrorMessage: null,
    isApplyFiltersClick: false,
    nonRoutinesOpenCount: 0,
    nonRoutinesClosedCount: 0,
    attachedTaskCardsForNR: null,
    groupsForNR: null,
    isLoadingGroupsForNR: false,
    linkedGroupforLinkedNr:null,
    ataCodes:null,
    isAddingRotableParts:null,
    addRotablePasswordError:undefined,
    logPageNumber:null,
    addRotableErrorMessage:'',
    isOpenELinkClicked: false,
    nonRoutinesTotalCount: 0,
    dash1NotesForNR: null,
    assignedNonRoutines: null,
    isLoadingFetchNotesType:false,
    fetchNoteTypes: null,
    assignedCrewsForNR: null,
    isLoadingAssignedCrewForNr: false,
    isLoadingPdfImageNRs: false,
    pdfImageNrs: null,
    isLoadingPdfImageForNRs: false,
    getPdfImageForNrs: null
}

const nonRoutinesReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${FETCH_NONROUTINES}_PENDING`: {
            return {
                ...state,
                isLoadingNonRoutines: true,
                isLoadingNonRoutineDetails: true,
                nonRoutines: [],
                nonRoutineDetails: null
            }
        }
        case UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY:{
            const nonRoutine = [...state.nonRoutines].map(routine =>{
                if(routine.nonRoutineID === action.payload.nonroutineid){
                    routine.notesCount += 1;
                    routine['workStop'] = action.payload.workStop ?? false;
                    routine.lastNote = `${action.payload.notes}${(action.payload.abbreviatedActionType && action.payload.workbin?.workBinName) ? ` - Nonroutine has been added to workbin ${action.payload.workbin?.workBinName}` : ''}` ;
                    routine['notesActionTypeAbbreviated'] = action.payload?.abbreviatedActionType ?? '';
                    routine.lastNoteDetail = action.payload.lastNoteDetail;
                }
                return routine;
            })
            const _nonRoutinesHistory = !!state?.nonRoutinesHistory?.length ? [...state.nonRoutinesHistory]?.map(routine =>{
                if(routine.nonRoutineID === action.payload.nonroutineid){
                    routine.notesCount += 1;
                    routine['workStop'] = action.payload.workStop ?? false;
                    routine.lastNote = `${action.payload.notes}${(action.payload.abbreviatedActionType && action.payload.workbin?.workBinName) ? ` - Nonroutine has been added to workbin ${action.payload.workbin?.workBinName}` : ''}` ;
                    routine['notesActionTypeAbbreviated'] = action.payload?.abbreviatedActionType ?? '';
                    routine.lastNoteDetail = action.payload.lastNoteDetail;
                }
                return routine;
            }) : [];
            return{
                ...state,
                nonRoutines : nonRoutine,
                nonRoutinesHistory: _nonRoutinesHistory
            }
        }
        case `${FETCH_NONROUTINES}_FULFILLED`: {
            return {
                ...state,
                isLoadingNonRoutines: false,
                nonRoutines: action.payload.getAircraftNRs ? action.payload.getAircraftNRs.aircraftNonRoutines : [],
                nonRoutinesOpenCount: action.payload.getAircraftNRs ? action.payload.getAircraftNRs.nROpenCount : 0,
                nonRoutinesClosedCount: action.payload.getAircraftNRs ? action.payload.getAircraftNRs.nRClosedCount : 0,
                nonRoutinesTotalCount: action.payload.getAircraftNRs ? action.payload.getAircraftNRs.nRTotalCount : 0,
                cumulativeRefData: action.payload.getAircraftNRs ? action.payload.getAircraftNRs.cumulativeRefData : null
            }
        }
        case `${FETCH_NONROUTNE_HISTORY}_PENDING`: {
            return {
                ...state,
                isLoadingNonRoutinesHistory: true,
                nonRoutinesHistory: [],
            }
        }
        case `${FETCH_NONROUTNE_HISTORY}_FULFILLED`: {
            return {
                ...state,
                isLoadingNonRoutinesHistory: false,
                nonRoutinesHistory: action.payload?.getAcNRHistory.aircraftNonRoutines,                
            }
        }
        case `${FETCH_NONROUTINE_DETAILS}_PENDING`: {
            return {
                ...state,
                isLoadingNonRoutineDetails: true,
                isNonRoutineActionSuccess: false,
                nonRoutineDetails: null,
                mxNotes: []
            }
        }
        case `${FETCH_NONROUTINE_DETAILS}_FULFILLED`: {
            return {
                ...state,
                isLoadingNonRoutineDetails: false,
                nonRoutineDetails: action.payload.viewNonRoutines ? action.payload.viewNonRoutines : null,
                mxNotes: action.payload.viewNonRoutines?.mxNotes,
                cumulativeRefData: action.payload.viewNonRoutines?.cumulativeRefData,
                isNonRoutineActionSuccess: false,
                nonRoutineActionErrorMessage: null,
                nonRoutineActionPwdErrorMessage: null
            }
        }
        case `${UPDATE_NONROUTINE}_PENDING`: {
            return {
                ...state,
                isUpdatingNonRoutine: true,
                isNonRoutineActionSuccess: false,
                nonRoutineActionErrorMessage: null,
                nonRoutineActionPwdErrorMessage: null
            }
        }
        case `${UPDATE_NONROUTINE}_FULFILLED`: {
            const response = action.payload.updateOrClearNonRoutine;
            return {
                ...state,
                isUpdatingNonRoutine: false,
                isNonRoutineActionSuccess: response !== null && response.nonRoutineID !== 0,
                nonRoutineActionPwdErrorMessage: response && response.passwordErrorMessage,
                nonRoutineActionErrorMessage: response !== null ? response.errorMessage : "Error occured while performing the action on the non-routine"
            }
        }
        case `${NONROUTINE_OPEN_FILTERS}`: {
            return {
                ...state,
                isLoadingNonRoutines: false,
                nonRoutinesOpenFilters: action.payload
            }
        }
        case `${NONROUTINE_HISTORY_FILTERS}`: {
            return {
                ...state,
                isLoadingNonRoutines: false,
                nonRoutinesHistoryFilters: action.payload
            }
        }
        case `WORKLOAD_NR_ACTIVE_TAB`:
            return {
                ...state,
                wlNrActiveTab: action.payload
            }
        case `${RESET_NR_FILTERS}`: {
            return {
                ...state,
                isLoadingNonRoutines: false,
                nonRoutinesOpenFilters: { ata: "", status: [], skills: [], zones: [] },
                nonRoutinesHistoryFilters: { ata: "", days: "all", status: [], skills: [], zones: [] },
            }
        }
        case `${SET_SEARCH_CARDS_TEXT_NR}`: {
            return {
                ...state,
                searchCardsText: action.payload
            }
        }
        case `${CREATE_NONROUTINE}_PENDING`: {
            return {
                ...state,
                isCreateNRPending: true,
                isCreateNRError: false,
                isCreateNRSuccess: false,
                createNRErrorMessage: null,
                createPasswordFailureMessage: null
            }
        }
        case `${CREATE_NONROUTINE}_FULFILLED`: {
            return {
                ...state,
                isCreateNRPending: false,
                isCreateNRSuccess: action.payload.createNonRoutine && action.payload.createNonRoutine.isSuccess,
                isCreateNRError: (action.payload.createNonRoutine === null || (action.payload.createNonRoutine && action.payload.createNonRoutine.isSuccess === false)) ? true : false,
                createNRErrorMessage: action.payload.createNonRoutine === null ? ["An Error occurred while creating a Non-Routine. Please try again!"] : action.payload.createNonRoutine && action.payload.createNonRoutine.errorMessage,
                createPasswordFailureMessage: action.payload.createNonRoutine && action.payload.createNonRoutine.passwordErrorMessage
            }
        }
        case `${FETCH_NONROUTINES_REF_DATA}_PENDING`: {
            return {
                ...state,
                cumulativeRefData: null
            }
        }
        case `${FETCH_NONROUTINES_REF_DATA}_FULFILLED`: {
            return {
                ...state,
                cumulativeRefData: action.payload.getAircraftNRs ? action.payload.getAircraftNRs.cumulativeRefData : null
            }
        }
        case `${RESET_CREATE_NONROUTINE_FLAGS}`: {
            return {
                ...state,
                isCreateNRSuccess: false,
                isCreateNRError: false,
                createNRErrorMessage: null,
                linkedGroupforLinkedNr: null
            }
        }
        case `${SET_NONROUTINE_ACTIONS_VARIABLE}`: {
            return {
                ...state,
                nonRoutinesActionsVariable: action.payload,
            }
        }
        case `${RESET_NONROUTINE_ACTIONS_VARIABLE}`: {
            return {
                ...state,
                nonRoutinesActionsVariable: null,
            }
        }

        case `${ADD_MXNOTES_NONROUTINES}_PENDING`: {
            return {
                ...state,
                mxNotes: []

            }
        }
        case `${ADD_MXNOTES_NONROUTINES}_FULFILLED`: {
            return {
                ...state,
                mxNotes: action.payload.saveMxNotes?.mxNotes
            }
        }
        case `${RESET_NONROUTINE_REGISTER_DATA}`: {
            return {
                ...state,
                nonRoutines: [],
                nonRoutineLogPages: [],
                isLoadingNonRoutines: true
            }
        }
        case `${FETCH_NONROUTINE_LOGPAGES}_PENDING`: {
            return {
                ...state,
                isLoadingNonRoutineLogPages: true,
                nonRoutineLogPages: null
            }
        }
        case `${FETCH_NONROUTINE_LOGPAGES}_FULFILLED`: {
            return {
                ...state,
                isLoadingNonRoutineLogPages: false,
                nonRoutineLogPages: action.payload.getLinkedLogPages
            }
        }
        case `${TOGGLE_SUBTASK_SIGNOFF_MODAL}`: {
            return {
                ...state,
                showSubTaskSignoffModal: action.payload.showSubTaskSignoffModal,
                subTaskSignoffModalType: action.payload.subTaskSignoffModalType,
                index: action.payload.index,
                signoffPasswordFailureMessage: null
            }
        }
        case `${TOGGLE_ADD_SUBTASK_MODAL}`: {
            return {
                ...state,
                showAddSubTaskModal: action.payload,
                signoffPasswordFailureMessage: null
            }
        }
        case `${SUBTASK_SIGNOFF}_PENDING`: {
            return {
                ...state,
                isSignoffPending: true,
                signoffPasswordFailureMessage: null,
                signoffFailureMessage: null
            }
        }
        case `${SUBTASK_SIGNOFF}_FULFILLED`: {
            return {
                ...state,
                isSignoffPending: false,
                signoffPasswordFailureMessage: action.payload.nonRoutineOtherActions?.passwordErrorMessage !== null ? action.payload.nonRoutineOtherActions.passwordErrorMessage : null,
                showSubTaskSignoffModal: action.payload.nonRoutineOtherActions?.passwordErrorMessage !== null ? state.showSubTaskSignoffModal : false,
                showAddSubTaskModal: action.payload.nonRoutineOtherActions?.passwordErrorMessage !== null ? state.showAddSubTaskModal : false,
                isSignoffSuccess: action.payload.nonRoutineOtherActions?.status !== null ? true : false,
                signoffFailureMessage: action.payload.nonRoutineOtherActions?.errorMessage !== null ? action.payload.nonRoutineOtherActions.errorMessage : null,
                subTaskSignoffModalType: ""
            }
        }
        case `${FETCH_NONROUTINE_IMAGES}_PENDING`: {
            return {
                ...state,
                isLoadingNonRoutineImages: true,
                nonRoutineImages: []
            }
        }
        case `${FETCH_NONROUTINE_IMAGES}_FULFILLED`: {
            return {
                ...state,
                isLoadingNonRoutineImages: false,
                nonRoutineImages: action.payload.getNRImages
            }
        }
        case `${UPDATE_SUBTASK_SORT_ORDER}_PENDING`: {
            return {
                ...state,
                isUpdateSortOrderPending: true,
                isUpdateSortOrderSuccess: false,
                isUpdateSortOrderError: false,
                updateSortOrderErrorMessage: null,
                updateSortOrderPasswordErrorMessage: null
            }
        }
        case `${UPDATE_SUBTASK_SORT_ORDER}_FULFILLED`: {
            return {
                ...state,
                isUpdateSortOrderPending: false,
                isUpdateSortOrderSuccess: action.payload.updateSubtaskSortOrder && action.payload.updateSubtaskSortOrder.response && action.payload.updateSubtaskSortOrder.response.isSuccess,
                isUpdateSortOrderError: (action.payload.updateSubtaskSortOrder === null || (action.payload.updateSubtaskSortOrder && action.payload.updateSubtaskSortOrder.response && action.payload.updateSubtaskSortOrder.response.isSuccess === false)) ? true : false,
                updateSortOrderErrorMessage: action.payload.updateSubtaskSortOrder === null ? ["An Error occurred while saving re-ordered sub tasks. Please try again!"] : action.payload.updateSubtaskSortOrder && action.payload.updateSubtaskSortOrder.response && action.payload.updateSubtaskSortOrder.response.error,
                updateSortOrderPasswordErrorMessage: action.payload.updateSubtaskSortOrder === null ? ["An Error occurred while saving re-ordered sub tasks. Please try again!"] : action.payload.updateSubtaskSortOrder && action.payload.updateSubtaskSortOrder.passwordErrorMessage,

            }
        }
        case `${RESET_VIEW_NR_ERROR_MESSAGE}`: {
            return {
                ...state,
                updateSortOrderPasswordErrorMessage: null,
                updateSortOrderErrorMessage: null,
                isUpdateSortOrderSuccess: false,
                isUpdateSortOrderError: false,
                signoffFailureMessage: null,
                signoffPasswordFailureMessage: null
            }
        }
        case `${HANDLE_NONROUTINE_ACTIONS}_PENDING`: {
            return {
                ...state,
                isNonRoutineActionSuccess: false,
                nonRoutineActionErrorMessage: null,
                nonRoutineActionPwdErrorMessage: null
            }
        }
        case `${HANDLE_NONROUTINE_ACTIONS}_FULFILLED`: {
            const response = action.payload.nonRoutineOtherActions || action.payload.saveNonRoutineDetails;
            return {
                ...state,
                isNonRoutineActionSuccess: response && response.isSuccess,
                nonRoutineActionPwdErrorMessage: response && response.passwordErrorMessage,
                nonRoutineActionErrorMessage: response && response.errorMessage
            }
        }
        case `${IS_APPLY_FILTERS_CLICK_ACTION}`: {
            return {
                ...state,
                isApplyFiltersClick: action.payload
            }
        }
        case `${GET_ATTACHED_TASKCARD}_PENDING`: {
            return {
                ...state,
                attachedTaskCardsForNR: null,

            }
        }
        case `${GET_ATTACHED_TASKCARD}_FULFILLED`: {
            const response = action.payload.getAttachedNR.getAttachedLPsForViewLPs[0];
            return {
                ...state,
                attachedTaskCardsForNR: response,

            }
        }
        case `${RESET_ATTACHED_TASKCARD}`: {
            return {
                ...state,
                attachedTaskCardsForNR: null,

            }
        }
        case `${GET_GROUPS_FOR_NONROUTINE}_PENDING`: {
            return {
                ...state,
                isLoadingGroupsForNR:true,
                groupsForNR: null,

            }
        }
        case `${GET_GROUPS_FOR_NONROUTINE}_FULFILLED`: {
            //Removed below sorting functionality as per req
            //sort((a, b) => (a.groupName > b.groupName) ? 1 : -1)
            return {
                ...state,
                isLoadingGroupsForNR:false,
                groupsForNR: action.payload.getGroupsList.groups && [{mxGroupId:0,groupName:"Select a group"},...action.payload.getGroupsList.groups.filter(group => group.groupName !== "OTHERS")],

            }
        }
        case `${GET_LINKED_GROUP_FOR_LINKED_NONROUTINE}_PENDING`: {
            return {
                ...state,
                linkedGroupforLinkedNr:null,

            }
        }
        case `${GET_LINKED_GROUP_FOR_LINKED_NONROUTINE}_FULFILLED`: {
            return {
                ...state,
                linkedGroupforLinkedNr: action.payload.getGroupInfo,

            }
        }
        case `${GET_ATA_CODES}_PENDING`: {
            return {
                ...state,
                ataCodes: null,

            }
        }
        case `${GET_ATA_CODES}_FULFILLED`: {
            return {
                ...state,
                ataCodes: action.payload.getATACodes.aTACodes,

            }
        }

        case `${ADD_ROTABLE_PARTS}_PENDING`:
            return{
                ...state,
                isAddingRotableParts: false,
                addRotablePasswordError: undefined,
                logPageNumber:null,
                addRotableErrorMessage:''
            }
        case `${ADD_ROTABLE_PARTS}_FULFILLED`:
           const isValid = !action.payload.createNRLinkedLogPages.passwordErrorMessage && !(action.payload?.createNRLinkedLogPages?.logpageTransactionResp?.errorMessage?.length) ;
            return{
                ...state,
                isAddingRotableParts: isValid,
                addRotablePasswordError: action.payload?.createNRLinkedLogPages?.passwordErrorMessage,
                addRotableErrorMessage: isValid ? '' : action.payload?.createNRLinkedLogPages?.logpageTransactionResp?.errorMessage[0],
                logPageNumber: action.payload.createNRLinkedLogPages.logpageNumber
            }
        case `${RESET_ADD_ROTABLE_PARTS}`: 
        return{
            ...state,
            isAddingRotableParts: false,
            addRotablePasswordError: undefined,
            logPageNumber:null,
            addRotableErrorMessage:''
        }
        case `${IS_OPENE_LINK_CLICK_ACTION}`: {
            return {
                ...state,
                isOpenELinkClicked: action.payload
            }
        }
        case `${FETCH_ACTIONTYPES_FOR_NOTES}_PENDING`: {
            return {
                ...state,
                isLoadingFetchNotesType:true,
                fetchNoteTypes: null
            }
        }
        case `${FETCH_ACTIONTYPES_FOR_NOTES}_FULFILLED`: {
            return {
                ...state,
                isLoadingFetchNotesType:false,
                fetchNoteTypes: action.payload?.actionTypesForNotesLookUp.actionTypesForNotesList
            }
        }
        case `SELF_ASSOCIATE_WL_SUCCESS`:{
            const nonRoutineNumber =  action.payload.tailWorkItems[0].mxWorkItem;
            const {techAssigned, isAssign} = action.payload;
            const user = action.payload.technicianAssigned?.map(user => {
                return{
                    assigneeFullName: `${user.firstName} ${user.lastName}`,
                    assigneeName: user.empId?.toUpperCase(),
                    eTaskItemId: 0,
                    hasTagged: false,
                    assignmentCreatedBy: user.empId?.toUpperCase()
                }
            }) || [];
            const updatednonRoutines =  [...state.nonRoutines].map(nonRoutine =>{
                if(nonRoutine.nonRoutineNumber === nonRoutineNumber){
                    if(!isAssign){
                        nonRoutine.assignees = nonRoutine.assignees.filter(assignee => assignee.assigneeName!== techAssigned.empId)
                    }else{
                        nonRoutine.assignees = [...((nonRoutine?.assignees) ? nonRoutine.assignees : []), ...user]
                    }
                }
                return nonRoutine
            });
            return{
                ...state,
                nonRoutines:updatednonRoutines
            }
        }
        case `${SET_ASSIGNED_ROUTINES}`: {
            return {
                ...state,
                nonRoutines: action.payload || [],
                nonRoutinesOpenCount: action.payload?.length,
                nonRoutinesClosedCount: action.payload?.length,
                nonRoutinesTotalCount: action.payload?.length,
                nonRoutinesHistory: []
            }
            }
        case `${GET_DASH1_NOTES_FOR_NR}_PENDING`: {
            return {
                ...state,
                dash1NotesForNR: null
            }
        }
        case `${GET_DASH1_NOTES_FOR_NR}_FULFILLED`: {
            return {
                ...state,
                dash1NotesForNR: action.payload?.getDash1Notes
            }
        }
        case `${GET_ASSIGNED_CREW}_PENDING`: {
            return {
                ...state,
                isLoadingAssignedCrewForNr: true,
                assignedCrewsForNR: null,
            }
        }
        case `${GET_ASSIGNED_CREW}_FULFILLED`: {
            return {
                ...state,
                isLoadingAssignedCrewForNr: false,
                assignedCrewsForNR: action.payload?.assignedCrewForCurrentDay?.shiftCrews,
            }
        }
        case 'UPDATE_WL_NR_ASSIGNEES':{
            const {techsSelected, unAssignedUsers, workItems} = action.payload;
            const _nonRoutines = [...state.nonRoutines];
            const _nrs = _nonRoutines.map(nr => {
                if(nr.nonRoutineNumber === workItems.mxWorkItem) {
                    const assignees = techsSelected.map(tech =>({
                        ...tech,
                        technicianId: tech.empId
                    }));
                    const existingtechs = nr?.assignees ? nr?.assignees?.filter(assignee => !unAssignedUsers.includes(assignee.technicianId)) : []
                    nr.assignees=[...existingtechs, ...assignees].filter((item, idx, _array) => _array.findIndex(emp => emp.technicianId === item.technicianId ) === idx)
                }
                return nr
            })

            return{
                ...state,
                nonRoutines: _nrs
            }
        }

        case `${ADD_PDFIMAGE_NONROUTINES}_PENDING`: {
            return {
                ...state,
                isLoadingPdfImageNRs: true,
                pdfImageNrs: null,
            }
        }
        case `${ADD_PDFIMAGE_NONROUTINES}_FULFILLED`: {
            const nonNrs = state.nonRoutineDetails.nonroutine;
            nonNrs.pdfs = [...action.payload?.saveNrPdf?.nrPdfContents, ...(nonNrs?.pdfs || [])]
            return {
                ...state,
                isLoadingPdfImageNRs: false,
                nonRoutineDetails: {...state.nonRoutineDetails, nonroutine: nonNrs},
                pdfImageNrs: action.payload?.saveNrPdf?.nrPdfContents,
            }
        }

        case `${GET_PDFIMAGE_NRS}_PENDING`: {
            return {
                ...state,
                isLoadingPdfImageNRs: true,
                getPdfImageForNrs: null,
            }
        }
        case `${GET_PDFIMAGE_NRS}_FULFILLED`: {
            return {
                ...state,
                isLoadingPdfImageNRs: false,
                getPdfImageForNrs: action.payload,
            }
        }

        case `${DELETE_NR_PDF}_PENDING`: {
            return {
                ...state,
                isLoadingPdfImageNRs: true,
            }
        }
        case `${DELETE_NR_PDF}_FULFILLED`: {
            try {
                
                let nonRoutine = {...state.nonRoutineDetails.nonroutine}
                if(action.payload && typeof action.payload === 'string'){
                    nonRoutine = {...state.nonRoutineDetails.nonroutine, pdfs: state.nonRoutineDetails.nonroutine.pdfs.filter(item => item.contentId !== action.payload)};
                }
    
                return {
                    ...state,
                    isLoadingPdfImageNRs: false,
                    nonRoutineDetails: {...state.nonRoutineDetails, nonroutine:nonRoutine}
                }
            } catch (error) {
                console.log('delete error', error)
            }
        }
        default: return state;
    }
}

export default nonRoutinesReducer;