import { FETCH_FILTERS, UPDATE_APPLIED_FILTERS, RESET_ALL_FILTERS } from '../actions/actionTypes';

const initialState = {
    checkTypes: null,
    packageTypes: null,
    appliedFilters: [],

}

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${FETCH_FILTERS}_FULFILLED`: {
            const checkTypes = action.payload.packageLookUp;
            const orderedCheckTypes = [...checkTypes?.filter(f => !f.packageType.startsWith("Storage")), ...checkTypes?.filter(f => f.packageType.startsWith("Storage"))];
            return {
                ...state,
                checkTypes: orderedCheckTypes,
                packageTypes: orderedCheckTypes.map(ct => ({ checkType: ct.packageType }))
            }
        }
        case `${UPDATE_APPLIED_FILTERS}`: {
            if (action.payload !== undefined && action.payload !== null) {
                let appliedLocFilters = [...state.appliedFilters];
                let filters = appliedLocFilters.find(af => af.VisitType === action.payload.VisitType);
                if (filters !== undefined) {

                    let filtered = appliedLocFilters.filter(af => af.VisitType !== action.payload.VisitType);
                    appliedLocFilters = filtered;
                }
                appliedLocFilters.push(action.payload);
                return {
                    ...state,
                    appliedFilters: appliedLocFilters
                }
            }
            return state;
        }
        case `${RESET_ALL_FILTERS}`: {
            return {
                ...state,
                appliedFilters: []
            }
        }
        default: return state;
    }
}

export default filtersReducer;