export const fetchWorkBins = () => {
    return `query WorkBin($workBin: GetAllWorkBinsInputType, $userInfo: UserInformationInputType) {
            getAllWorkBins(workBin: $workBin, userInfo: $userInfo) {
              workBinsList {
              workBinId
                workBinName
                description
                workbinTypeId
                workbinType 
                isSystemDefined
              }
            }
          }
        `
}

export const createWorkBin = () => {
    return `mutation WorkBin($createWorkBin: CreateWorkBinInputType, $userInfo: UserInformationInputType) {
        createWorkBin(createWorkBin: $createWorkBin, userInfo: $userInfo) {
          isSuccess
          errors {
            code
            type
            description
          }
        }
      }
      `
}

export const deleteWorkBin = () => {
  return `mutation WorkBin($deleteWorkBin: DeleteWorkBinInputType, $userInfo: UserInformationInputType) {
    deleteWorkBin(deleteWorkBin: $deleteWorkBin, userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }
  `
}

export const updateWorkBin = () => {
  return `mutation WorkBin($updateWorkBin: UpdateWorkBinInputType, $userInfo: UserInformationInputType) {
    updateWorkBin(updateWorkBin: $updateWorkBin, userInfo: $userInfo) {
     isSuccess
      errors
      {
        code
        type
        description
      }
    }
  }
  `
}

export const moveWorkloadToWorkBin = () => {
  return `mutation WorkBin($moveWorkLoad: MoveWorkloadInputType, $userInfo: UserInformationInputType) {
    moveWorkLoadToWorkBin(moveWorkLoad: $moveWorkLoad, userInfo: $userInfo) {
     isSuccess
      errors 
      {
        code
        type
        description
      }
    }
  }
  `
}

export const moveMultipleWorkLoadToWorkBins = () => {
  return `mutation WorkBin($moveMultipleWorkLoad: MoveMultipleWorkLoadToWorkBinsType, $userInfo: UserInformationInputType) {
    moveMultipleWorkLoadToWorkBins(moveMultipleWorkLoad: $moveMultipleWorkLoad,userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }
  `
}

export const deleteWorkloadFromWorkBin = () => {
  return `mutation WorkBin($deleteWorkLoad: DeleteWorkloadInputType, $userInfo: UserInformationInputType) {
    deleteWorkLoadFromWorkBin(deleteWorkLoad: $deleteWorkLoad, userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }
  `
}

export const deleteMultipleWorkLoadFromWorkBins = () => {
  return `mutation WorkBin($deleteMultipleWorkLoad: DeleteMultipleWorkLoadFromWorkBinsType, $userInfo: UserInformationInputType) {
    deleteMultipleWorkLoadFromWorkBins(deleteMultipleWorkLoad: $deleteMultipleWorkLoad, userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }
  `
} 

export const addWorkLoadToWorkBin = () => {
  return `mutation WorkBin($addWorkLoad: AddWorkLoadToWorkBinInputType, $userInfo: UserInformationInputType) {
    addWorkLoadToWorkBin(addWorkLoad: $addWorkLoad, userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }  
  `
}

export const getWorkloadWorkbin = () => {
  return `query WorkBin($workloadWb: GetWorkloadWorkbinInputType, $userInfo: UserInformationInputType) {
    getWorkloadWorkbin(workloadWb: $workloadWb, userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
      aircraftNonRoutines {
        mxMasterId
        majorRepair
        sDR
        cat
        partsChange
        requireParts
        insp
        zoneType
        workArea
        workControlNumber
        nonRoutineSequence
        specNumber
        tailNumber
        logPageNumber
        originatingDocument
        overallDescription
        nonRoutineNumber
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
        }
        nonRoutineSkillId
        nonRoutineSkillDesc
        schedArrDate
        lBEntryRequired
        lBEntryCompleted
        rotableParts
        lastModifiedDate
        openDate
        uILastModifiedDate
        uIOpenDate
        isCurrVisit
        immInspReq
        sTTotalCount
        sTClearCount
        sTOpenCount
        sDBlocked
        nonRoutineID
        nonRoutineStatus
        nonRoutineStatusDescription
        discrepency
        description
        aTA
        isVisitScheduleEnabled
        nRSkillTypes
      }
      scheduledLogpages {
        mxMasterId
        schdDate
        logpageNumber
        workItemDescription
        logType
        timeRemaining
        manHrs
        isDIP
        isMEL
        forecastedDash8s
        isCompleted
        isDetachedD8
        openedDate
        openTime
        hasLock
        hasInspection
        hasImages
        rERType
       closedDateFormatted
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
        }
      }
      taskCards {
        mxMasterId
        masterId
        mxItem
        mxType
        mxItemDescription
        workArea
        priorityID
        taskcardTypeID
        workAreaID
        groupName
        shiftEndNumber
        dash8
        workControlNumber
        serialNumber
        positionCode
        rootId
        cardId
        cardDocType
        schedArrDate
        taskCardMasterID
        isMissingProperties
        dash1NotesCount
        lastNote
        isPaperCard: isPaper
        isInspActive
        inspRequired
        mechRequired
        primaryActorId
        secondaryActorId
        zoneCardId
        actionType
        zoneCardNumber
        zoneId
        sortOrder
        assignees {
          assigneeName
          assigneeFullName
          id
          hasTagged
          eTaskItemId
        }
        skills {
          skillId
          skillType
        }
        workStop
        workStopActionType
        workStopActionTypeAbbreviated
      }
    }
  }        
  `
}

export const getWorkBinsWorkLoadCount = () => {
  return `query WorkBin($workBinsWorkLoadCount: GetWorkBinsWorkLoadCountInputType, $userInfo: UserInformationInputType) {
    getWorkBinsWorkLoadCount(workBinsWorkLoadCount: $workBinsWorkLoadCount, userInfo: $userInfo) {
      workLoadCountList{
        workBinId
        workLoadCountDetailsList
        {
          workLoadCount
          workLoadType
        }
      }
    }
  }
  `
}

export const getTemplateWorkBinsWorkLoadCount = () => {
  return `query WorkBin($templateWorkBinsWorkLoadCount: WorkBinsWorkLoadCountInputType, $userInfo: UserInformationInputType) {
    getTemplateWorkBinsWorkLoadCount(templateWorkBinsWorkLoadCount: $templateWorkBinsWorkLoadCount, userInfo: $userInfo) {
      workLoadCountList{
        workBinId
        workLoadCountDetailsList
        {
          workLoadCount
          workLoadType
        }
      }
    }
  }`
}

export const getTemplateWorkbinWorkload = () => {
  return `query WorkBin($mxMasterDetails: GetWorkloadForTemplateWorkBinInputType, $userInfo: UserInformationInputType) {
    getMxMasterDetailsForTemplateWorkBins(mxMasterDetails: $mxMasterDetails, userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
      aircraftNonRoutines {
        mxMasterId
        majorRepair
        sDR
        cat
        partsChange
        requireParts
        insp
        zoneType
        workArea
        workControlNumber
        nonRoutineSequence
        specNumber
        tailNumber
        logPageNumber
        originatingDocument
        overallDescription
        nonRoutineNumber
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
        }
        nonRoutineSkillId
        nonRoutineSkillDesc
        schedArrDate
        lBEntryRequired
        lBEntryCompleted
        rotableParts
        lastModifiedDate
        openDate
        uILastModifiedDate
        uIOpenDate
        isCurrVisit
        immInspReq
        sTTotalCount
        sTClearCount
        sTOpenCount
        sDBlocked
        nonRoutineID
        nonRoutineStatus
        nonRoutineStatusDescription
        discrepency
        description
        aTA
        isVisitScheduleEnabled
      }
      scheduledLogpages {
        mxMasterId
        schdDate
        logpageNumber
        workItemDescription
        logType
        timeRemaining
        manHrs
        isDIP
        isMEL
        forecastedDash8s
        isCompleted
        isDetachedD8
        openedDate
        openTime
        hasLock
        hasInspection
        hasImages
        rERType
       closedDateFormatted
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
        }
      }
      taskCards {
        mxMasterId
        masterId
        mxItem
        mxType
        mxItemDescription
        workArea
        priorityID
        taskcardTypeID
        workAreaID
        groupName
        shiftEndNumber
        dash8
        workControlNumber
        serialNumber
        positionCode
        rootId
        cardId
        cardDocType
        schedArrDate
        taskCardMasterID
        isMissingProperties
        dash1NotesCount
        lastNote
        isPaperCard: isPaper
        isInspActive
        inspRequired
        mechRequired
        primaryActorId
        secondaryActorId
        zoneCardId
        actionType
        zoneCardNumber
        zoneId
        sortOrder
        assignees {
          assigneeName
          assigneeFullName
          id
          hasTagged
          eTaskItemId
        }
        skills {
          skillId
          skillType
        }
        workStop
        workStopActionType
        workStopActionTypeAbbreviated
      }
    }
  }   

`
}

export const deleteWorkloadFromTemplateWorbin = () => {
  return `mutation WorkBin($deleteWorkLoadFromTWorkBin: DeleteWorkloadInputType, $userInfo: UserInformationInputType) {
    deleteWorkLoadFromTemplateWorkBin(
      deleteWorkLoadFromTWorkBin: $deleteWorkLoadFromTWorkBin
      userInfo: $userInfo
    ) {
      isSuccess
      errors {
        code
        description
        type
      }
    }
  }
  `
}

export const deleteMultipleWorkLoadFromTemplateWorkbins = () => {
  return `mutation WorkBin($deleteMultipleWorkLoadInTemplate: DeleteMultipleWorkLoadFromWorkBinsType, $userInfo: UserInformationInputType) {
    deleteMultipleWorkLoadFromTemplateWorkbins(deleteMultipleWorkLoadInTemplate: $deleteMultipleWorkLoadInTemplate,userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }
  `
}


export const moveWorkloadToTemplateWorkbin = () => {
  return `mutation WorkBin($moveWorkLoad: MoveWorkloadInputType, $userInfo: UserInformationInputType) {
    moveWorkLoadToTemplateWorkBin(moveWorkLoad: $moveWorkLoad, userInfo: $userInfo) {
     isSuccess
      errors 
      {
        code
        type
        description
      }
    }
  }
`
}

export const moveMultipleWorkLoadToWorkBinsInTemplate = () => {
  return `mutation WorkBin($moveMultipleWorkLoadInTemplate: MoveMultipleWorkLoadToWorkBinsType, $userInfo: UserInformationInputType) {
    moveMultipleWorkLoadToWorkBinsInTemplate(moveMultipleWorkLoadInTemplate: $moveMultipleWorkLoadInTemplate,userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }
`
}

export const addWorkloadToTemplateWorkbin = () => {
  return `mutation WorkBin($addWorkLoadToTWorkBin: AddWorkLoadToWorkBinInputType, $userInfo: UserInformationInputType) {
    addWorkLoadToTemplateWorkBin(
      addWorkLoadToTWorkBin: $addWorkLoadToTWorkBin
      userInfo: $userInfo
    ) {
      isSuccess
      errors {
        code
        description
        type
      }
    }
  }
  `
}