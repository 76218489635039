import React, { useState } from 'react';
import {overlayComponent} from '../../HOC/Overlay/Overlay';
import { useSelector } from 'react-redux';
const GenericError = () => {
    const {genricError} = useSelector(state => state.profileStore);
    const [toggleError, setToggleError] = useState(false)
    const removeURLParameter = (url, parameter) => {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');   
        if (urlparts.length >= 2) {
    
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);
    
            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {    
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                    pars.splice(i, 1);
                }
            }
    
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }
    const handleRefresh = () => {
        window.location.href = removeURLParameter(window.location.href, "code");
        //window.location.reload(true);
    }
    return(
         <div className="generic-error-container">
            <div className="generic-error">
                <span style={{...(toggleError && {display:'block', marginTop:'50px'})}}>
                     A problem has occurred.  Your session may have expired requiring you to log in again.  Please <label className="link-to-refresh" onClick={handleRefresh.bind(this)}>refresh</label> the page to try again.  If you continue to experience issues, please open a ticket or send feedback.                    
                </span>
                <span className='toggleBtn' onClick={()=> setToggleError(!toggleError)}></span>
                {genricError && (
                    <>
                        <div className='error-details' style={{display:toggleError ? 'block':'none'}}>
                            {genricError.status}
                            <br />
                            {genricError.message}
                        </div>
                        
                    </>
                )}
            </div>
        </div>
    )
};


const  GenericErrorModal = overlayComponent(GenericError);

export default GenericErrorModal;
