

export const fetchRegisterData = (aircraftNumber, employeeID, station, jobRole, isLine, isHvyChk, isArchived, enableVS) => {
  return `query RoutineRegister($routinesWorkload: RoutineWorkloadInputType, $userInfo: UserInformationInputType) {
      routineRegister(station:"`+ station + `",jobRole:"` + jobRole + `",tailNumber:"` + aircraftNumber + `",employeeID:"` + employeeID + `",isFlifo:` + isLine + `,isHvyChkPackage:` + isHvyChk + `,isHistoricalVisit:` + (isArchived != undefined ? isArchived : false) + `,enableVS:` + (enableVS != undefined ? enableVS : false) + `,routinesWorkload: $routinesWorkload, userInfo: $userInfo) {  
        notYetEnRoute
        isTCMFeatureEnabled
        isOptimized
        dash8s
       { 
      timeRemaining
      isDash9
      dash8Number
      workControlNumber
      serialNumber
      dash8Description
      d8TotalCount
      d8TotalNumCount
      d8TotalDenCount
      isDash8Completed
      isEAComponent
      tailNumber
      dash8SignOffDate
      positionCode
      isInspActive
      inspRequired
      workItemStatus 
      workStop
      jobNumber
      inspectorOnly
      assignees {
        assigneeFullName
        assigneeName
        id
        eTaskItemId
        hasTagged
      }
      dash1s {
        zoneDash1
        notesCount
        dash1Number
        dash8Number
        workControlNumber
        serialNumber
        carddoctype
        cardTitle
        cardId
        instanceId
        masterId
        rootId
        positionCode
        scheduledDate
        status
        statusDetail
        isInspActive
        isPartialActive
        isDash1Completed
        inspRequired
        inspectorOnly
        mechRequired
        mvtRequired
        blocksCount
        blocksCompleted
        sortOrder
        actionType
        zoneCardId
        name
        workStop
        notesActionType
        notesActionTypeAbbreviated
        lastNote
        lastNoteDetail{
          note
          createdBy
          employeeName
          createdDate
          createdDateUTC
        }
        mxMasterId
        assignees {
          assigneeFullName
          assigneeName
          id
          eTaskItemId
          hasTagged
          assignmentCreatedBy
        }
        dash1Status
        uIDash1Status
        isDash1Duplicate
        dash1OptimizedStatusType
        optimizedDetails {
          optimizedReason
          optimizedVersion
          optimizedReferenceInfo {
            optimizedDash8s {
              forecastDescription
              ncaPartNumber
              ncaSerialNumber
            }
            optimizedEffectivity
          }
        }
        optimizedGroup
        isOptimized
        tcmDetails {
          isTCMEnabled
          specNumber
        }
        }
      }
      paperTaskCards {
        dash1s {
          dash1Number
          cardTitle
          dash8Number
          assignedDate
          workControlNumber
          serialNumber
          isDash1Completed
          paperTaskCardId
          mxMasterId
          assignees {
            assigneeFullName
			      assigneeName
            id
			      hasTagged
            assignmentCreatedBy
          }
          signedBy
          signedDate
          dash1Status
          uIDash1Status
          optimizedReason
          optimizedVersion
          optimizedGroup
          dash1OptimizedStatusType
          isOptimized
        }
        assignedDate
        isScepterSignOffDisabled
        tailNumber
        positionCode
        isDash8Completed
        dash8SignOffDate
        uIDash8SignOffDate
        dash8Number
        dash8Description
        workControlNumber
        serialNumber
        assignees {
          assigneeFullName
          id
        }
        jobNumber
        d8TotalNumCount
        d8TotalDenCount
      }  
      scheduledLogPages {
        mxMasterId
        schdDate
        logpageNumber
        workItemDescription
        logType
        timeRemaining
        manHrs
        isDIP
        isMEL
        forecastedDash8s
        isCompleted
        isDetachedD8
        openedDate
        openTime
        hasLock
        hasInspection
        hasImages
        rERType
        closedDateFormatted
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
          assignmentCreatedBy
        }
      }
      openLogpages {
        assignees {
          technicianId
          firstName
          lastName
          assignmentCreatedBy
        }
        logpageNumber
        logpageStatus
        workItemDescription
        flightNumber
        station
        openedDate
        openTime
        hasInspection
     } 
      flifo {
        tailNumber
        flightNumber
        nextFlightNumber
        departureTo
        arrivalFrom
        arrivalTime
        departureTime
        arrivalGate
        isStar
        isCancel
        isRon
        isEtops
        inspectionRequired
        flightStatus
        scheduledArrivalDate
        estimatedArrivalDate
        actualArrivalDate
        bestArrivalDate
        workCompleted
        workPending
        assignees {
          assigneeFullName
          assigneeName
        }  
      } 
      duplicateDash1s {        
        dash1Number
      }              
    }
  }
`
}

export const signoffPaperDash8 = () => {
  return `mutation SignOff($d8signOff: D8SignOffInputType, $userInfo: UserInformationInputType) {
    dash8SignOff(d8signOff: $d8signOff,  userInfo: $userInfo) 
      {
        errorCode
        errorMessage
        passwordErrorMessage
        informationMessages
        dash8SignOffs
        {
          aircraftNumber_SignedOff
          dateAccomplished
          signedoff
        }
    }
  }  
  `
}

export const markPaperDash1Status = () => {
  return `mutation SignOff($paperSignOff: PaperSignOffInputType, $userInfo: UserInformationInputType) {
    markPaperCardComplete(paperSignOff: $paperSignOff,  userInfo: $userInfo) 
      {
      isSuccess
      errorMessage
    }
  }
  `
}

export const fetchWorkloadAccountabilityData = () => {
  return `query Workload($workloadAccountability: WorkLoadDetailsInputType, $userInfo: UserInformationInputType) {
    getWorkloadAccountability(
      workloadAccountability: $workloadAccountability
      userInfo: $userInfo
    ) {
      dash8s {
        dash8Number
        workControlNumber
        serialNumber
        dash8Description
        tailNumber
        positionCode
        scheduledDate
        workItemType
        workItemStatus
        workUpdatedDateTime
        isDeferOrRemoved
        
        dash1s {
          dash1Number
          mxMasterId
          masterId
          inspRequired
          mechRequired
          cardTitle
          paperOrElectronic
          carddoctype
          isDeferOrRemoved
          optimizedGroup
          optimizedDetails {
            optimizedReason
            optimizedReferenceInfo {
              optimizedDash8s {
                ncaPartNumber
                ncaSerialNumber
                forecastDescription
                positionCode
                formattedDash8
              }
              optimizedEffectivity
            }
            optimizedVersion
          }
          dash1OptimizedStatusType
          isOptimized
          dash1Status
          uIDash1Status
          isDash1Duplicate
          isPartialActive
          stepsCount
          stepsCompleted
          tcmDetails {
            isTCMEnabled
            specNumber
          }
        }
      }
    }
  }
  `

}

export const getMRDStatus = () => {
  return `query Workload($getMrd: GetMrdInputType, $userInfo: UserInformationInputType) {
    getMRDStatus(getMrd: $getMrd, userInfo: $userInfo) {
      mrdInfo {
        tailNumber
        hasMrd
        isMFD
        mrdMfdStatus
      }
    }
  }
  
  `
}
export const checkMRD = () => {
  return `query Workload($checkMrd: CheckMrdInputType, $userInfo: UserInformationInputType) {
    checkMrd(checkMrd: $checkMrd, userInfo: $userInfo) {
      checkMrdInfo {
        isBrokenMrd
        aircraftMessage
        mrd {
          data
          informationMessages
        }
        errorMessage
      }
      toolsInfo {
        toolNumber
        toolDescription
        tailNumber
        userID
        status
        location
        checkOutTimeStamp
        checkInTimeStamp
        checkOutDateTimeStamp
        checkedOutByUser
      }
    }
  }
  
  `

}
export const issueMRD = () => {
  return `query Workload($issueMrd: IssueMrdInputType, $userInfo: UserInformationInputType) {
    issueMrd(issueMrd: $issueMrd, userInfo: $userInfo) {
      isSuccessful
      errorMessage
    }
  }
  `
}

export const getStationLocalTime = (station) => {
  return `query RoutineRegister($userInfo: UserInformationInputType) {
    getLocalTime(station: "`+ station + `", userInfo: $userInfo) {
      localDateTime
    }
  }
  `
}
export const viewMRD = () => {
  return `query Workload($viewMrd: ViewMrdInputType, $userInfo: UserInformationInputType) {
    viewMrd(viewMrd: $viewMrd, userInfo: $userInfo) {
      mrd {
        data
        informationMessages
      }
      errorMessage
    }
  }
  `
}
export const viewMFD = () => {
  return `query Workload($viewMfd: ViewMrdInputType, $userInfo: UserInformationInputType) {
    viewMfd(viewMfd: $viewMfd, userInfo: $userInfo) {
      mrd {
        data
        informationMessages
      }
      errorMessage
    }
  }`
}

export const GetDeferredAndCarriedLPs = (tailNumber) => {
  return `query RoutineRegister($userInfo: UserInformationInputType) {
    getDeferredAndCarriedLPs(tailNumber: "`+ tailNumber + `", userInfo: $userInfo) {
      logpageNumber
      workItemDescription
      logpageStatus
      logType
      openedDate
      flightNumber
      openTime
      hasInspection
      station
      fRM
    }
  }  
  `
}

export const GetInductedAircraftData = (station, aircraftNumber, scheduledArrivalDate) => {
  return `query Visit($userInfo: UserInformationInputType) {
    getInductedACData(station: "`+ station + `"aircraftNumber: "` + aircraftNumber + `"scheduledArrivalDate: "` + scheduledArrivalDate + `"userInfo: $userInfo) {
      aircraftControlId
      isInducted
      errorMessages
    }
  }
  `
}

export const GetDash8PartsAndToolsData = () => {
  return `query RoutineRegister($nCAPartNumber: NcaPartNumberInputType, $userInfo: UserInformationInputType) {
    getPartsAndTools(nCAPartNumber: $nCAPartNumber, userInfo: $userInfo) {
      partsToolsInfo {
        partToolNumber
        ncaPartNumber
        mfgPartNumber
        partDescription
        partType
        unit
        qtyRequired
      }
      isSuccess
      errors {
        type
        code
        description
      }
    }
  }  
  `
}

export const GetExtendedRouting = () => {
  return `query Aircraft($aircraft: AircraftInfoInputType!, $userInfo: UserInformationInputType) {
    getAircraftAndRoutingInfo(aircraft: $aircraft, userInfo: $userInfo) {
      aircraftandRoutingInfo {
        tailNumber
        flightNumber
        nextFlightNumber
        estimatedArrival
        estimatedArrivalDateTime
        estimatedDeparture
        actualArrival
        actualDeparture
        actualDepartureFormatted
        arrivalGate
        departureGate
        departureCity
        arrivalCity
        nextArrivalCity
        nextScheduledDate
        nextScheduledDateTime
        nextEstimatedDeparture
        nextEstimatedDeparturedateTime
        estimatedGroundTime
        actualGroundTime
        arrival
        arrivalFormatted
        groundTime
        airTime
        flightHours
        cycles
        fleet
        subFleet
        departsIn
        isCurrent
        aircraftStatus
        economy
        first
        business
        seatingConfig
        totalSeats
        aircraftRegistrationNumber
        aircraftRoutes {
          flightStatus
          nextFlightStatus
          aircraftKey
          tailNumber
          flightNumber
          nextFlightNumber
          estimatedArrival
          estimatedDeparture
          actualArrival
          actualDeparture
          actualDepartureFormatted
          arrivalGate
          departureGate
          departureCity
          arrivalCity
          nextArrivalCity
          nextScheduledDate
          estimatedGroundTime
          actualGroundTime
          arrival
          arrivalFormatted
          groundTime
          isCurrent
          nextToCurrent
          isStar
          isEtops
          isRon
          hasMrd
          hasDMG
          inspectionRequired
          inspectionOverride
          maintenanceGate
          flightStatus
          nextFlightStatus
          isTailMonitored
          isTailMonitoredbyAC
          currentGate
          outOfService
          isReturnToGate
          scheduledArrivalDate
          scheduledArrivalFormatted
          scheduledDepartureDate
          scheduledDepartureDateFormatted
          estimatedArrivalFormatted
          isWideBody
          nextEstimatedDeparture
          nextActualDeparture
          arrivalCityLocalTime
          aircraftStatus
          cCOTime
          showCCOTime
          cCOExpiringwithMRD
          isParkedAC
          isBaseHold
        }
        engineInfo {
          nCAPartNumber
          nCASerialNumber
          engineDescription
          positionCode
          timeSinceInstalled
          cyclesSinceInstalled
        }
        fleetInfo {
          fleetCode
          subfleetCode
          fullFleetCode
          equipmentCode
          wifi
          entertainment
          marketingCode
          engineModel
          firstCapacity
          businessCapacity
          economyCapacity
          seating
          active
        }
        errorMessages
        currentGate
        cCOTime
        isWideBody
        isEtops
      }
    }
  }
  `
}

export const fetchAdvanceWorkloadSearch = (aircraftNumber, employeeID, station, jobRole, isLine, isHvyChk, enableVS) => {
  return `query RoutineRegister($routinesWorkload: RoutineWorkloadInputType, $advsearchWorkload: AdvdWorkloadSearchMultiSelectRequestInputType, $userInfo: UserInformationInputType) {
    advWorkloadSearchMultiSelect(station:"`+ station + `",jobRole:"` + jobRole + `",tailNumber:"` + aircraftNumber + `",employeeID:"` + employeeID + `",isFlifo:` + isLine + `,isHvyChkPackage:` + isHvyChk + `,enableVS:` + enableVS + `,routinesWorkload: $routinesWorkload, advsearchWorkload: $advsearchWorkload, userInfo: $userInfo) {
     aircraftNonRoutines {
      majorRepair
      sDR
      cat
      partsChange
      requireParts
      insp
      zoneType
      workArea
      workControlNumber
      nonRoutineSequence
      specNumber
      tailNumber
      logPageNumber
      originatingDocument
      overallDescription
      nonRoutineNumber
      assignees {
        technicianId
        firstName
        lastName
        uIFormattedTechName
      }
      nonRoutineSkillId
      nonRoutineSkillDesc
      schedArrDate
      lBEntryRequired
      lBEntryCompleted
      rotableParts
      lastModifiedDate
      openDate
      uILastModifiedDate
      uIOpenDate
      isCurrVisit
      immInspReq
      sTTotalCount
      sTClearCount
      sTOpenCount
      sDBlocked
      nonRoutineID
      nonRoutineStatus
      nonRoutineStatusDescription
      discrepency
      aTA
      isVisitScheduleEnabled
      inspectorOnly
      notesCount
      nRSkillTypes
      workStop
      notesActionType
      notesActionTypeAbbreviated
      lastNote
      lastNoteDetail{
        note
        createdBy
        employeeName
        createdDate
        createdDateUTC
      }
    }
    dash8s {
      timeRemaining
      isDash9
      dash8Number
      workControlNumber
      serialNumber
      dash8Description
      d8TotalCount
      d8TotalNumCount
      d8TotalDenCount
      isDash8Completed
      isEAComponent
      tailNumber
      dash8SignOffDate
      positionCode
      isInspActive
      inspRequired
      workItemStatus
      workStop
      jobNumber
      inspectorOnly
      assignees {
        assigneeFullName
        assigneeName
        id
        eTaskItemId
        hasTagged
      }
      dash1s {
        zoneDash1
        notesCount
        dash1Number
        dash8Number
        workControlNumber
        serialNumber
        carddoctype
        cardTitle
        cardId
        instanceId
        masterId
        rootId
        positionCode
        scheduledDate
        status
        statusDetail
        isInspActive
        isPartialActive
        isDash1Completed
        inspRequired
        inspectorOnly
        mechRequired
        mvtRequired
        blocksCount
        blocksCompleted
        sortOrder
        actionType
        zoneCardId
        name
        workStop
        notesActionType
        notesActionTypeAbbreviated
        mxMasterId
        isADCard
        lastNote
        lastNoteDetail{
          note
          createdBy
          employeeName
          createdDate
          createdDateUTC
        }
        assignees {
          assigneeFullName
          assigneeName
          id
          eTaskItemId
          hasTagged
        }
        dash1Status
        uIDash1Status
        isDash1Duplicate
        dash1OptimizedStatusType
        optimizedDetails {
          optimizedReason
          optimizedVersion
          optimizedReferenceInfo {
            optimizedDash8s {
              forecastDescription
              ncaPartNumber
              ncaSerialNumber
            }
            optimizedEffectivity
          }
        }
        optimizedGroup
        isOptimized
        tcmDetails {
          isTCMEnabled
          specNumber
        }
      }
    }
    paperTaskCards {
      dash1s {
        dash1Number
        cardTitle
        dash8Number
        assignedDate
        workControlNumber
        serialNumber
        isDash1Completed
        paperTaskCardId
        dash1Status
        uIDash1Status
        isOptimized
        dash1OptimizedStatusType
        mxMasterId
        assignees {
          assigneeFullName
          id
        }
        signedBy
        signedDate
      }
      assignedDate
      isScepterSignOffDisabled
      tailNumber
      positionCode
      isDash8Completed
      dash8SignOffDate
      uIDash8SignOffDate
      dash8Number
      dash8Description
      workControlNumber
      serialNumber
      assignees {
        assigneeFullName
        id
      }
      d8TotalNumCount
      d8TotalDenCount
    }
    scheduledLogPages {
      schdDate
      logpageNumber
      workItemDescription
      logType
      timeRemaining
      manHrs
      isDIP
      isMEL
      forecastedDash8s
      isCompleted
      isDetachedD8
      openedDate
      openTime
      hasLock
      hasInspection
      hasImages
      rERType
      closedDateFormatted
      assignees {
        technicianId
        firstName
        lastName
        uIFormattedTechName
      }
    }
    openLogpages {
      assignees {
        technicianId
        firstName
        lastName
      }
      logpageNumber
      logpageStatus
      workItemDescription
      flightNumber
      station
      openedDate
      openTime
    }
  }
}`
}


export const selfAssociateWorkload = () => {
  return `query CrewUpgrade($workload: SelfAssociateReqInputType, $userInfo: UserInformationInputType) {
    selfAssociateWorkload(workload: $workload, userInfo: $userInfo) {
         isAssign
      tailWorkItems {
        tailNumber
        station
        scheduledArrival
        scheduledDeparture
        mxWorkItem
        mxWorkItemType
        mxSubWorkItem
        mxSubWorkItemType
        serialNumber
        isInductedAC
        dash1Status
        workControlNumber
        optimizedGroup
      }
      technicianAssigned {
        empId
        firstName
        lastName
        jobRole
       
      }
      isSuccess
      errors {
        code
        description
        type
      }
      errorMessage
    }
  }  
  `
}

export const fetchAssignedWorkload = () => {
  return `query Workload($harmonizedAssignedWork: HarmonizedWorkloadAssignedInputType, $userInfo: UserInformationInputType) {
    getHarmonizedWorkloadAssignedWork(
      harmonizedAssignedWork: $harmonizedAssignedWork
      userInfo: $userInfo
    ) {
      notYetEnRoute
      isTCMFeatureEnabled
      isOptimized
      dash8s {
        timeRemaining
        isDash9
        dash8Number
        workControlNumber
        serialNumber
        dash8Description
        d8TotalCount
        d8TotalNumCount
        d8TotalDenCount
        isDash8Completed
        isEAComponent
        tailNumber
        dash8SignOffDate
        positionCode
        isInspActive
        inspRequired
        workItemStatus
        workStop
        jobNumber
        inspectorOnly
        assignees {
          assigneeFullName
          assigneeName
          id
          eTaskItemId
          hasTagged
          assignmentCreatedBy
        }
        dash1s {
          zoneDash1
          notesCount
          dash1Number
          dash8Number
          workControlNumber
          serialNumber
          carddoctype
          cardTitle
          cardId
          instanceId
          masterId
          rootId
          positionCode
          scheduledDate
          status
          statusDetail
          isInspActive
          isPartialActive          
          isDash1Completed
          inspRequired
          inspectorOnly
          lastNote
          lastNoteDetail {
            note
            createdBy
            employeeName
            createdDate
            createdDateUTC
            }    
          mechRequired
          mvtRequired
          blocksCount
          blocksCompleted
          sortOrder
          actionType
          zoneCardId
          name
          workStop
          notesActionType
          notesActionTypeAbbreviated
          mxMasterId
          assignees {
            assigneeFullName
            assigneeName
            id
            eTaskItemId
            hasTagged
            assignmentCreatedBy
          }
          dash1Status
          uIDash1Status
          isDash1Duplicate
          dash1OptimizedStatusType
          optimizedDetails {
            optimizedReason
            optimizedVersion
            optimizedReferenceInfo {
              optimizedDash8s {
                forecastDescription
                ncaPartNumber
                ncaSerialNumber
              }
              optimizedEffectivity
            }
          }
          optimizedGroup
          isOptimized
          tcmDetails {
            isTCMEnabled
            specNumber
          }
        }
      }
      paperTaskCards {
        dash1s {
          dash1Number
          cardTitle
          dash8Number
          assignedDate
          workControlNumber
          serialNumber
          isDash1Completed
          paperTaskCardId
          mxMasterId
          assignees {
            assigneeFullName
            id
          }
          signedBy
          signedDate
          dash1Status
          uIDash1Status
          optimizedReason
          optimizedVersion
          optimizedGroup
          dash1OptimizedStatusType
          isOptimized
        }
        assignedDate
        isScepterSignOffDisabled
        tailNumber
        positionCode
        isDash8Completed
        dash8SignOffDate
        uIDash8SignOffDate
        dash8Number
        dash8Description
        workControlNumber
        serialNumber
        assignees {
          assigneeFullName
          id
        }
        jobNumber
        d8TotalNumCount
        d8TotalDenCount
      }  
      scheduledLogPages {
        schdDate
        logpageNumber
        workItemDescription
        logType
        timeRemaining
        manHrs
        isDIP
        isMEL
        forecastedDash8s
        isCompleted
        isDetachedD8
        openedDate
        openTime
        hasLock
        hasInspection
        hasImages
        rERType
        closedDateFormatted
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
          assignmentCreatedBy
        }
      }
      openLogpages {
        assignees {
          technicianId
          firstName
          lastName
          assignmentCreatedBy
        }
        logpageNumber
        logpageStatus
        workItemDescription
        flightNumber
        station
        openedDate
        openTime
        hasInspection
      }
      duplicateDash1s {
        dash1Number
      }
      nonRoutines {
        shiftStartNumber
        shiftEndNumber
        mxMasterId
        nRSkillTypes
        nRStatusDescription
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
          assignmentCreatedBy
        }
        majorRepair
        sDR
        cat
        partsChange
        requireParts
        insp
        zoneType
        workArea
        workControlNumber
        nonRoutineSequence
        specNumber
        originatingDocument
        logPageNumber
        tailNumber
        overallDescription
        nonRoutineNumber
        nonRoutineSkillId
        nonRoutineSkillDesc
        schedArrDate
        lastNote
        lastNoteDetail {
          note
          createdBy
          employeeName
          createdDate
          createdDateUTC
          }  
        lBEntryRequired
        lBEntryCompleted
        rotableParts
        lastModifiedDate
        openDate
        uILastModifiedDate
        uIOpenDate
        isCurrVisit
        immInspReq
        sTTotalCount
        sTClearCount
        sTOpenCount
        sDBlocked
        inspectorOnly
        notesCount
        nonRoutineID
        nonRoutineStatus
        nonRoutineStatusDescription
        discrepency
        aTA
        nonRoutineNo
        isVisitScheduleEnabled
        workStop
        notesActionType
        notesActionTypeAbbreviated
      }
    }
  }
  `
}

export const fetchFlifo = (aircraftNumber, employeeID, station, jobRole, isLine, isHvyChk, isArchived, enableVS) => {
  return `query RoutineRegister($routinesWorkload: RoutineWorkloadInputType, $userInfo: UserInformationInputType) {
      routineRegister(station:"`+ station + `",jobRole:"` + jobRole + `",tailNumber:"` + aircraftNumber + `",employeeID:"` + employeeID + `",isFlifo:` + isLine + `,isHvyChkPackage:` + isHvyChk + `,isHistoricalVisit:` + (isArchived != undefined ? isArchived : false) + `,enableVS:` + (enableVS != undefined ? enableVS : false) + `,routinesWorkload: $routinesWorkload, userInfo: $userInfo) {  
        notYetEnRoute
      flifo {
        tailNumber
        flightNumber
        nextFlightNumber
        departureTo
        arrivalFrom
        arrivalTime
        departureTime
        arrivalGate
        isStar
        isCancel
        isRon
        isEtops
        inspectionRequired
        flightStatus
        scheduledArrivalDate
        estimatedArrivalDate
        actualArrivalDate
        bestArrivalDate
        workCompleted
        workPending
        assignees {
          assigneeFullName
          assigneeName
        }  
      }         
    }
  }
`
}
