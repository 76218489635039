export const searchWorkload = () => {
  return `query TemplateManagement($searchWorkload: SearchWorkloadRequestInputType, $userInfo: UserInformationInputType) {
    searchWorkload(searchWorkload: $searchWorkload, userInfo: $userInfo) {
      mxItems {
        mxMasterId
        cardNumber
        mxType
        description
        shiftEndNumber
        shiftStartNumber
        workArea
        zoneTypeID
        zoneType
        mxGroupId
        mileStone
        notesCount
        lastNote
        isPaper
        dash8
        workControlNumber
        serialNumber
        actionType
        sortOrder
        zoneCardId
        zoneCardNumber
        inspRequired
        mechRequired
        inspectorOnly
        isPlanningWorkbin
        isInspActive   
        skills {
          skillId
          skillType
          skillTypeCode
        }
        zoneId 
        positionCode
        masterId
        rootId
        cardDocType
        cardId
        taskCardTypeId
        workStop
        workStopActionType
        workStopActionTypeAbbreviated
        isMissingProperties  
        missingProperties {
          workArea
          taskCardType
          mileStone
          skills
        }
        assignedCrews {
          crewConfigId
          crewName
        }
        workBins
        {
          workBinId
          workBinName
        }
        lastSignOff {
          lastSignOffUserName
          lastSignOffComment
          lastSignOffUserId
          lastSignOffDate
        }
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
          assignmentCreatedBy
        }          
      }
      scheduledDate
    }
  }
  `
}

export const searchWorkloadWithMultiSelect = () => {
  return `query TemplateManagement($searchWorkload: SearchWorkloadWithMultiSelectRequestInputType, $userInfo: UserInformationInputType) {
    searchWorkloadWithMultiSelect(
      searchWorkload: $searchWorkload
      userInfo: $userInfo
    ) {
      mxItems {
        stepsCount
        stepsCompleted
        completedCount
        sTTotalCount
        sTClearCount
        sTOpenCount
        logType
        logpageStatus
        forcastDescription
        isADCard  
        mxMasterId
        cardNumber
        mxType
        description
        nRStatusDescription
        shiftEndNumber
        shiftStartNumber
        workArea
        zoneTypeID
        zoneType
        mxGroupId
        mileStone
        notesCount
        lastNote
        lastNoteDetail {
          createdDate
          createdBy
          employeeName
          note
        }
        isPaper
        dash8
        workControlNumber
        serialNumber
        actionType
        sortOrder
        zoneCardId
        zoneCardNumber
        inspRequired
        mechRequired
        inspectorOnly
        isPlanningWorkbin
        isInspActive
        skills {
          skillId
          skillType
          skillTypeCode
        }
        zoneId
        positionCode
        masterId
        rootId
        cardDocType
        cardId
        taskCardTypeId
        workStop
        tcmDetails {
          isTCMEnabled
          specNumber
        }
        workStopActionType
        workStopActionTypeAbbreviated
        isMissingProperties
        missingProperties {
          workArea
          zoneType
          taskCardType
          mileStone
          skills
        }
        assignedCrews {
          crewConfigId
          crewName
        }
        workBins {
          workBinId
          workBinName
        }
        lastSignOff {
          lastSignOffUserName
          lastSignOffComment
          lastSignOffUserId
          lastSignOffDate
          lastSignOffBlockId
          lastSignOffPanelName
          lastSignOffZuluDate
          lastSignOffType
        }
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
          assignmentCreatedBy
        }
        optimizedGroup
        optimizedDetails {
          optimizedReason
          optimizedReferenceInfo {
            optimizedDash8s {
              ncaPartNumber
              ncaSerialNumber
              forecastDescription
              positionCode
              formattedDash8
            }
            optimizedEffectivity
          }
          optimizedVersion
        }
        dash1OptimizedStatusType
        isOptimized
        dash1Status
        uIDash1Status
        isDash1Duplicate
        isPartialActive
      }
      scheduledDate
    }
  }  
  `
}

export const searchWorkloadWithMultiSelectEnhanced = () => {
  return `query TemplateManagement($searchWorkload: SearchWorkloadWithMultiSelectRequestEnhancedInputType, $userInfo: UserInformationInputType) {
    searchWorkloadWithMultiSelect_Enhanced(
      searchWorkload: $searchWorkload
      userInfo: $userInfo
    ) {
      mxItems {
        stepsCount
        stepsCompleted
        completedCount
        sTTotalCount
        sTClearCount
        sTOpenCount
        logType
        logpageStatus
        forcastDescription
        isADCard  
        mxMasterId
        cardNumber
        mxType
        description
        nRStatusDescription
        shiftEndNumber
        shiftStartNumber
        workArea
        zoneTypeID
        zoneType
        mxGroupId
        mileStone
        notesCount
        lastNote
        lastNoteDetail {
          createdDate
          createdBy
          employeeName
          note
        }
        isPaper
        dash8
        workControlNumber
        serialNumber
        actionType
        sortOrder
        zoneCardId
        zoneCardNumber
        inspRequired
        mechRequired
        inspectorOnly
        isPlanningWorkbin
        isInspActive
        skills {
          skillId
          skillType
          skillTypeCode
        }
        zoneId
        positionCode
        masterId
        rootId
        cardDocType
        cardId
        taskCardTypeId
        workStop
        tcmDetails {
          isTCMEnabled
          specNumber
        }
        workStopActionType
        workStopActionTypeAbbreviated
        isMissingProperties
        missingProperties {
          workArea
          zoneType
          taskCardType
          mileStone
          skills
        }
        assignedCrews {
          crewConfigId
          crewName
        }
        workBins {
          workBinId
          workBinName
        }
        lastSignOff {
          lastSignOffUserName
          lastSignOffComment
          lastSignOffUserId
          lastSignOffDate
          lastSignOffBlockId
          lastSignOffPanelName
          lastSignOffZuluDate
          lastSignOffType
        }
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
          assignmentCreatedBy
        }
        optimizedGroup
        optimizedDetails {
          optimizedReason
          optimizedReferenceInfo {
            optimizedDash8s {
              ncaPartNumber
              ncaSerialNumber
              forecastDescription
              positionCode
              formattedDash8
            }
            optimizedEffectivity
          }
          optimizedVersion
        }
        dash1OptimizedStatusType
        isOptimized
        dash1Status
        uIDash1Status
        isDash1Duplicate
        isPartialActive
      dependentTaskCards {
        dependencyID
        mxMasterId: mxMasterID
        dependencyCardNumber
        dependencyDescription
        dependencyType
        dash1Status
        mxType
        dash8
        workControlNumber
        tailNumber
        serialNumber
        cardId
        carddoctype
        rootId
        positionCode
        station
        masterId
        scheduledDate
        uIDash1Status
        dash1OptimizedStatusType
        isOptimized
        dash1Status
        dupDash8
        isDash1Duplicate
      }
      dependentZonalCards {
        dependencyID
        mxMasterId: mxMasterID
        dependencyCardNumber
        dependencyDescription
        dependencyType
        tailNumber
        flightNumber
        assignedStation
        scheduledArrivalDate
        workControlNumber
        status
        mxType
        dash8
        serialNumber
        isInducted
        showNonRoutines
        zoneCardNumber
        zoneCardId: zoneCardID
        zoneId
        displayZoneId
        actionType
        originalEtaskJobRole
        jobRole
        employeeId
        uIDash1Status
        dash1OptimizedStatusType
        isOptimized
        dash1Status
        isDash1Duplicate
        dupDash8 
      }
      dependentNonRoutines {
        dependencyID
        mxMasterId: mxMasterID
        nonRoutineNumber: dependencyCardNumber
        dependencyDescription
        dependencyType
        enablevs
        tailNumber
        isCompleted
        mxType
      }
      isDependencyIndicator
      isCompleted
      }
      scheduledDate
    }
  }  
`
}

export const getBaseCrewWorkload = () => {
  return `query TemplateManagement($baseCrew: GetCrewWorkloadInputType, $userInfo: UserInformationInputType) {
    getBaseCrewWorkload(baseCrew: $baseCrew, userInfo: $userInfo) {
      assignedWorkload {
        mxItem
        mxItemDescription
        mxMasterId
        serialNumber
        dash8
        workControlNumber
      }
    }
  }`
}

export const assignOrUnassignCrew = () => {
  return `mutation TemplateManagement($crew: AssignOrUnassignCrewInputType, $userInfo: UserInformationInputType) {
    assignOrUnassignCrew(crew: $crew, userInfo: $userInfo) {
      isSuccess
      errors {
        type
        code
        description
      }
    }
  }`
}


export const getAvailableCrew = () => {
  return `query TemplateManagement($crews: CrewsInputType, $userInfo: UserInformationInputType) {
    availableCrews(crews: $crews, userInfo: $userInfo) {
      shiftCrews {
        shiftName
        crewConfigId
        leadCount
        techCount        
        crewTechnicianAttrs {          
          employeeId         
          firstName
          lastName 
          skill         
        }        
        crewLeads {
          firstName
          lastName
          employeeId
          skill
        }
        crewCode
        crewName
        crewDescription
        bidArea        
        station        
      }
      stationSkills {
        stationSkillId
        skillCode      
        skillDescription
        bidArea
        isActive
      }  
    }
  }`
}

export const getGroupsAndWorkAreas = () => {
  return `query TemplateManagement($data: GetMxVisitGroupAndWorkAreaInputType, $userInfo: UserInformationInputType) {
    getMxVisitGroupAndWorkArea(data: $data, userInfo: $userInfo) {
      mxGroups {
        mxVisitGroupId
        groupName
        visitId
        shiftStartNumber
        shiftEndNumber
        modifiedBy
        modifiedDate
        firstName
        lastName
      }
      workAreas {
        workAreaId
        workArea
        isActive
      }
      skills {
        skillId
        skillType
      }  
      workBins
      {
        workBinId
        workBinName
      } 
      zoneTypes
      {
        zoneTypeID
        zoneType
        zoneTypeCode
      }
      taskcardTypes
      {
        taskcardTypeID
        taskcardTypeName
        isActive
      }
      isSuccess
    }
  }
  `
}

export const getGroupsAndWorkAreasWithCounts = () => {
  return `query VisitSchedule($data: SearchWorkloadWithMultiSelectRequestInputType, $userInfo: UserInformationInputType) {
    geFilterCountsForCAWithMultiSelect(data: $data, userInfo: $userInfo) {
      mxGroups {
        mxVisitGroupId
        groupName
        visitId
        shiftStartNumber
        shiftEndNumber
        modifiedBy
        modifiedDate
        firstName
        lastName
        completedWorkloadCount
        totalWorkloadCount
        openNRCount
        openRoutineCount
      }
      workAreas {
        workAreaId
        workArea
        isActive
        completedWorkloadCount
        totalWorkloadCount
        openNRCount
        openRoutineCount
      }
      skills {
        skillId
        skillType
        completedWorkloadCount
        totalWorkloadCount
        openNRCount
        openRoutineCount
      }  
      workBins
      {
        workBinId
        workBinName
        completedWorkloadCount
        totalWorkloadCount
        openNRCount
        openRoutineCount
      } 
      zoneTypes
      {
        zoneTypeID
        zoneType
        zoneTypeCode
        completedWorkloadCount
        totalWorkloadCount
        openNRCount
        openRoutineCount
      }
      taskcardTypes
      {
        taskcardTypeID
        taskcardTypeName
        isActive
        completedWorkloadCount
        totalWorkloadCount
        openNRCount
        openRoutineCount
      }
      isSuccess
    }
  }
`
}

export const getProgressForTaskCards = () => {
  return `query TemplateManagement($progress: TaskcardProgressInputType, $userInfo: UserInformationInputType) {
    taskcardProgress(progress: $progress, userInfo: $userInfo) {
      skills {
        stepsCompleted
        stepsCount
        workControlNumber
        forecastSerialNumber
        cardNumber
        dash8
        isPartialActive
      }
      errors      
    }
  }
  `
}

export const searchTemplateWorkloadWithMultiSelect = () => {
  return `query TemplateManagement($searchTemplateWorkload: GetTemplateWorkloadWithMultiSelectInputType, $userInfo: UserInformationInputType) {
    searchTemplateWorkloadWithMultiSelect(searchTemplateWorkload: $searchTemplateWorkload, userInfo: $userInfo) {
      mxItemDetails {
        skills {
          skillId
          skillType
          skillTypeCode
        }
        dependentTaskCards {
          dependencyID
          mxTemplateMasterId:mxMasterID
          dependencyCardNumber
          dependencyDescription
          dependencyType
          dash1Status
          mxType
          dash8
          workControlNumber
          tailNumber
          serialNumber
          cardId
          carddoctype
          rootId
          positionCode
          station
          masterId
          scheduledDate
        }
        dependentZonalCards {
          dependencyID
          mxTemplateMasterId:mxMasterID
          dependencyCardNumber
          dependencyDescription
          dependencyType
          tailNumber
          flightNumber
          assignedStation
          scheduledArrivalDate
          workControlNumber
          status
          dash8
          mxType
          serialNumber
          isInducted
          showNonRoutines
          zoneCardNumber
          zoneCardID
          zoneId
          displayZoneId
          actionType
          originalEtaskJobRole
          jobRole
          employeeId
        }  
        isDependencyIndicator
        workBins {
          workBinId
          workBinName
        }
        templateId
        mxTemplateMasterId
        mxTemplateGroupId
        mxItem
        mxItemDescription
        mxType
        dash8
        workControlNumber
        serialNumber
        isActive  
        groupID
        groupName
        groupShiftStartNumber
        groupShiftEndNumber
        dash8Description
        positionCode
        modifiedDate
        modifiedBy
        firstName
        lastName
        isPaper
        deferCode
        overrideShiftStartNumber
        overrideShiftEndNumber      
        isActiveOverride
        zoneTypeId
        zoneType
        minSkillId
        cardType
        taskcardTypeId        
      }
      id
      isSuccess
      error
    }
  }
`
}

export const searchTemplateWorkload = () => {
  return `query TemplateManagement($searchWorkload: GetWorkloadForTemplateWorkBinInputType, $userInfo: UserInformationInputType) {
    searchTemplateWorkload(searchWorkload: $searchWorkload, userInfo: $userInfo) {
      mxItems {
        mxMasterId
        cardNumber
        mxType
        description
        shiftEndNumber
        shiftStartNumber
        workArea
        zoneTypeID
        zoneType
        mxGroupId
        mileStone
        notesCount
        isPaper
        dash8
        workControlNumber
        serialNumber
        actionType
        sortOrder
        zoneCardId
        zoneCardNumber
        skills {
          skillId
          skillType
          skillTypeCode
        }
        zoneId 
        positionCode
        masterId
        rootId
        cardDocType
        cardId
        taskCardTypeId
        workStop
        workStopActionType
        workStopActionTypeAbbreviated
        isMissingProperties  
        missingProperties {
          workArea
          taskCardType
          mileStone
          skills
        }
        assignedCrews {
          crewConfigId
          crewName
        }
        workBins
        {
          workBinId
          workBinName
        }        
      }
      scheduledDate
    }
  }
`
}

export const getLastSignedBlockNumber = () => {
  return `query TemplateManagement($blockRequest: TaskcardblockInputType, $userInfo: UserInformationInputType) {
    getLastSignedBlockNumber(blockRequest: $blockRequest, userInfo: $userInfo) {
     blockNumber
    }
  }
  `
}