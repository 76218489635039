import { getConfig } from 'utils/env-utils';
import axios from 'axios';
import moment from 'moment';
import {store} from '../index'
// import { trackPromise } from 'react-promise-tracker';
// import { useSelector } from 'react-redux';


// const userId = sessionStorage.getItem('userId');
// const graphQl_BAM = axios.create({
//     baseURL: `${getConfig('URL_BAM_API_BASE}${config.BAM_GRAPHQL_ENDPT}`,
//     headers: {
//         username: userId //profile.userId
//     }
// });
const graphQl_BAM = (userId) => {
    return axios.create({
        baseURL: `${getConfig('URL_BAM_API_BASE')}${getConfig('BAM_GRAPHQL_ENDPT')}`,
        headers: {
            username: userId,
            AppName: `${getConfig('APP_NAME')}`,
            AppKey: `${getConfig('APP_KEY')}`
        }
    });
}
const graphQl_AMT = axios.create({
    baseURL: `${getConfig('URL_AMT_API_BASE')}${getConfig('BAM_GRAPHQL_ENDPT')}`
});

export const addUserInfo = (variables, state) => {
    // null check
    if (state !== null && state.profileStore.userProfile !== null) {
        const userProfile = state.profileStore.userProfile;
        const userInfo = {
            "userId": userProfile.userId,
            "firstName": userProfile.firstName,
            "station": userProfile.station,
            "lastName": userProfile.lastName
        };
        if (variables !== null) {
            variables.userInfo = userInfo;
        }
        else {
            variables = {
                "userInfo": userInfo
            }
        }
    }
    return variables;
}

export const executeQuery = async (query, variables, state, operationName) => {
    const graphQl = operationName === "" ? graphQl_AMT : graphQl_BAM(state !== null && state.profileStore.userProfile !== null ? state.profileStore.userProfile.userId : null);
    try {
        if (operationName === "Profile")
            variables = null;
        else
            variables = addUserInfo(variables, state);
        return await graphQl.post('', { query: query, "variables": variables, "operationName": operationName })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('Failed to get Aircrafts');
                }
                if (((typeof response.data) === "string") && response.data.startsWith("<!DOCTYPE html>")) {
                    //console.log("session timeout error captured");
                    throw new Error("generic error message to display");

                }
                return response.data;
            })
            .catch(error => {
               return Promise.reject({message:error?.config?.data, status: error?.response?.status})
            //    throw new Error(error);
            });
    }
    catch (err) {
        store.dispatch({
            type: 'SET_GENERIC_ERROR',
            payload: err
        })
       
        return Promise.reject({message:err})
        // throw new Error(err);
    }

}

export const executeWebApi = async () => {

    try {
        const baseURL = `${getConfig('URL_BAM_PROFILE_API')}${getConfig('BAM_PROFILE_ENDPT')}`;
        return await axios.get(baseURL)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Failed to call API");
                }
                return { data: { profile: response.data } };
            })
    }
    catch {
        if (window.location.href.indexOf('localhost') !== -1)
            return { data: { profile: { firstName: 'Harsha', lastName: 'Chinthala', station: 'SMB', userId: 'V795013', isBaseOverride: null, role: 'SUPV' } } };
        else
            throw new Error("generic error message to display");
    }
}
export const toTitleCase = words => words?.split('_')?.map(w => w.substring(0, 1).toUpperCase() + w.substring(1).toLowerCase()).join(' ');

export const formatActorId = actorId => {
    return actorId && actorId.toUpperCase() === "MECH" ? "Tech" : actorId;
}

export const renderIllustrationImage = (illustrationImage) => {
    if (illustrationImage.content && (illustrationImage.name.includes('pdf') || illustrationImage.name.includes('jpg')
      || illustrationImage.name.includes('jpeg') || illustrationImage.name.includes('png'))) {

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
        sessionStorage.setItem(illustrationImage.name, illustrationImage.content);
        const currentUrl = window.location.href;
        const urlToRedirect = currentUrl !== null && currentUrl !== "" ? currentUrl.split("/bam-web")[0] : "https://bam-oqa.ual.com";
        window.open(urlToRedirect + "/bam-web/pdfdisplay.html?fileName=" + illustrationImage.name);
      }
      else { 
        if (illustrationImage.name.includes('jpg')
          || illustrationImage.name.includes('jpeg') || illustrationImage.name.includes('png')) {
          const blob = base64ToBlob(illustrationImage.content, 'image/jpeg');
          const url = URL.createObjectURL(blob);
          const image = new Image();
          image.src = url;
          window.open(url);
        }
        else {
          const blob = base64ToBlob(illustrationImage.content, 'application/pdf');
          const url = URL.createObjectURL(blob);
          const pdfWindow = window.open("");
          pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
        }
      }

    }
}

const base64ToBlob = (base64, type = "application/octet-stream") => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
}

export const underscoreFormat = words => words.split(' ').map(w => w.toUpperCase()).join('_');

export const sort = (rows, field, direction) => {
    switch (field) {
        case ("Card number"):
            return rows.sort((a, b) => {
                return direction ?
                    a.cardNumber.split('-').join('') - b.cardNumber.split('-').join('') :
                    b.cardNumber.split('-').join('') - a.cardNumber.split('-').join('');
            })
        case ("M&E number"):
            return rows.sort((a, b) => {
                return direction ?
                    a.dash8.split('-').join('') - b.dash8.split('-').join('') :
                    b.dash8.split('-').join('') - a.dash8.split('-').join('');
            })
        case ('Aircraft number'):
            return rows.sort((a, b) => {
                return direction ?
                    a.tailNumber - b.tailNumber :
                    b.tailNumber - a.tailNumber;
            });
        case 'Archived status':
            return rows.sort((a, b) => {
                return direction ?
                    (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0) :
                    (a.status > b.status) ? - 1 : ((b.status > a.status) ? 1 : 0)
            })
        case 'Archived date':
            return rows.sort((a, b) => {
                return direction ? (new Date(a.archivalDate) - new Date(b.archivalDate)) : (new Date(b.archivalDate) - new Date(a.archivalDate))
            })
        case 'Card type':
            return rows.sort((a, b) => {
                return direction ?
                    (a.cardType > b.cardType) ? 1 : ((b.cardType > a.cardType) ? -1 : 0) :
                    (a.cardType > b.cardType) ? -1 : ((b.cardType > a.cardType) ? 1 : 0)
            })
        default:
            return rows;
    }
}

export const leadModuleHasWorkload = (workload) => {
    return workload && (workload.nonRoutines?.length > 0 || workload.etaskCards?.length > 0
        || workload.logPages?.length > 0 || workload.logpages?.length > 0 || workload.openLogpages?.length > 0
        || workload.paperTaskCards?.length > 0 || workload.zonalCards?.length > 0);
}

export const getTaskCardPath = (isTemplate, isAccountabilitySearch, isLine, breadcrumbsInfo,isDuplicateTaskcardReport, isZonalCard ) => {
    return isTemplate ? "/dashboard/viewonly/TaskCard" : isAccountabilitySearch ? "/dashboard/Accountability/TaskCard/"
    : isLine ? (breadcrumbsInfo?.find(x => x.label === "Search Workload") ? "/dashboard/line/SearchWorkload/TaskCard"
      : "/dashboard/line/TaskCard")
      : isDuplicateTaskcardReport ? "/dashboard/DuplicateTaskCards/TaskCard"
        : breadcrumbsInfo?.find(x => x.label === "Search Workload") ?
          (isZonalCard ? "/dashboard/SearchWorkload/ZonalCard" : "/dashboard/SearchWorkload/TaskCard")
          : isZonalCard ? "/dashboard/ZonalCard" : "/dashboard/TaskCard";  
}

const getTemplateCardSearch = (dash8,taskCardId,queryStringValues) => {
    return "?mastercardno=" + (dash8?.mxItem || dash8?.cardNumber)
      + "&cardsearchtype=master"
      + "&mxTemplateId=" + (taskCardId || dash8?.mxtemplateid)
      + "&mxMasterId=" + (dash8?.mxMasterId || dash8?.mxmasterid || queryStringValues.mxMasterId)
      + `${dash8?.mxType === 'ZC' ? `&zone=${dash8.cardNumber}` : ''}`;   
    
}
  
const getOptimizedDash8List = (optmizedDash8DetailsList, dash1) => {
    return "&optD8List=" + encodeURIComponent(JSON.stringify(optmizedDash8DetailsList))
      + (dash1.optimizedGroup != null ? "&optGrpId=" + dash1.optimizedGroup : "")
      + "&d1Status=" + dash1.dash1Status
      + ((dash1.optimizedDetails && dash1.optimizedDetails.optimizedReason !== null) ? "&optReason=" + dash1.optimizedDetails.optimizedReason : "")
      + ((dash1.optimizedDetails && dash1.optimizedDetails.optimizedVersion) ? "&optVersion=" + dash1.optimizedDetails.optimizedVersion : "");
  }

export const getTaskCardQueryString = (isTemplate, aircraftInfo, dash8, dash1, isZonalCard, isEnRoute, flifo, optmizedDash8DetailsList, isAccountabilitySearch, queryStringValues, isPaperCard, breadcrumbsInfo, workLoadScheduledDate, isLine) => {
    return isTemplate ? getTemplateCardSearch() :
      "?aircraftNumber=" + aircraftInfo?.aircraftNumber
      + "&Dash8=" + (dash8.dash8Number || dash8.dash8)
      + (isZonalCard ?
        "&ZoneDash1=" + (dash1.zoneDash1 || dash1.zonedash1 || dash1.zoneCardNumber)
        + "&ZoneCardId=" + (dash1.zoneCardId || dash1.zonecardid)
        + "&actionType=" + (dash1.actionType || dash1.actiontype)
        + "&zone=" + (dash1.cardNumber || dash1.cardnumber || dash1.dash1Number || dash8.zone)
        :
        ""
      )
      + "&TaskCard=" + (isZonalCard ? dash1.cardId : (dash1.dash1Number || dash1.cardNumber || dash1.taskcard || dash1.mxItem))
      + "&serialNo=" + (dash8.serialNumber || dash8.serialnumber || dash8.serialno)
      + "&wcno=" + (dash8.workControlNumber || dash8.workcontrolnumber || dash8.wcno)
      + "&cardsearchtype="
      + "&cardId=" + (dash1.cardId || dash1.cardid || dash1.zoneId)
      + "&carddoctype=" + (dash1.carddoctype || dash1.cardDocType)
      + "&rootId=" + (encodeURIComponent(dash8.rootId || dash1.rootId || dash8.rootid))
      + "&dash8Desc=" + (encodeURIComponent((dash8.dash8Description || dash8.workItemDescription || dash8.mxItemDescription)) || dash8.dash8desc)
      + "&positionCode=" + (dash8.positionCode || dash8.positioncode || dash1.positionCode || dash1.pOS)
      //+ "&scheduledDate=" + moment((dash8.schedArrDate || dash8.scheduledDate || dash8.scheduleddate || dash1.scheduledDate || workLoadScheduledDate)).format()
      + "&station=" + aircraftInfo?.station
      + "&isAccountabilitySearch=" + !!((isAccountabilitySearch && !dash1.isDeferOrRemoved) || dash8.isaccountabilitySearch)
      + "&isDash9=" + !!(dash8.isDash9 || dash8.isdash9 || dash8?.dash8?.includes("-9-") || (dash8?.dash8Number || dash8?.dash8)?.includes("-9-"))
      + "&isEnRoute=" + !!(isEnRoute || dash8.isenroute)
      + "&masterId=" + (dash1.taskCardMasterID || dash1.etaskcardMasterId || dash1.masterId || dash8.masterid || dash1.mxMasterId || dash1.mxMasterId || queryStringValues.mxMasterId)
      + "&mxMasterId=" + (dash1.mxMasterId || dash8.mxmasterid || dash1.masterId)
      + (
        isLine ? ("&inflightnumber=" + aircraftInfo?.inFlightNumber
          + "&arrivaldate=" + aircraftInfo?.arrivalDate
          + "&departuredate=" + aircraftInfo?.departureDate
          + "&bestArrivalDate=" + (flifo && flifo.bestArrivalDate)
          + "&scheduledDate=" + (dash8.schedArrDate || dash8.scheduledDate || dash8.scheduleddate || dash1.scheduledDate || workLoadScheduledDate)
        ) :
          ("&acControlID=" + aircraftInfo?.acControlId
            + "&enableVS=" + aircraftInfo?.enableVS
            + "&enableZonal=" + aircraftInfo?.enableZonalCard
            + "&scheduledDate=" + moment((dash8.schedArrDate || dash8.scheduledDate || dash8.scheduleddate || dash1.scheduledDate || workLoadScheduledDate)).format())
      )
      + "&isPaper=" + (isPaperCard || dash1.paperOrElectronic === "P")
      + (
        breadcrumbsInfo?.find(x => x.label === "Search Workload") ? "&sw=searchworkload" : ""
      )
      + "&id=" + aircraftInfo?.visitId
      + "&searchtype=visit"
      + (optmizedDash8DetailsList ?
        getOptimizedDash8List(optmizedDash8DetailsList, dash1) : "")
      + (dash1.isOptimized ? "&isOptimized=" + dash1.isOptimized : "")
      + ((dash1.tcmDetails && dash1.tcmDetails.isTCMEnabled) ? "&isTcm=" + dash1.tcmDetails.isTCMEnabled : "");
}

