import {
    GET_AIRCRAFT_DATA_FOR_VISIT, GET_AIRCRAFT_DATA_FOR_24HRS, GET_AIRCRAFT_DATA_FOR_12HRS, SEARCH_WORKLOAD_AIRCRAFT_STATUS, SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED, UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY, SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED_PROGRESS, VIEW_MILESTONE_GANTT_CHART, VIEW_MILESTONE_GANTT_CHART_SIDE_PANEL, EXPAND_MILESTONE_GANTT_CHART,
    SET_KPI_FILTER
} from '../actions/actionTypes';

const initialState = {
    aircraftStatusData: null,
    isEntireVisit: true,
    isLoadingAircraftStatusData: false,
    selectedDetailsCard:null,
    workloadDetails:{},
    isLoadingworkloadDetails: false,
    isSidePanel: true,
    isChartView: false,
    isGanttChartExpanded:false,
    kpiFilter: null
 }

const aircraftStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${GET_AIRCRAFT_DATA_FOR_VISIT}_PENDING`: {
            return {
              ...state,
              isLoadingAircraftStatusData: true,
              aircraftStatusData: null,
              isEntireVisit: true,
              workloadDetails: {}
            }
          }
          case `${GET_AIRCRAFT_DATA_FOR_VISIT}_FULFILLED`: {
            const aircraftStatusData = JSON.parse(JSON.stringify({...{
              "visitOpenNRCount": 0,
              "visitTotalNRCount": 0,
              "visitCompletedNRCount": 0,
              "visitOpenSWCount": 0,
              "visitTotalSWCount": 0,
              "visitCompletedSWCount": 0,
              "visitRemainingWorkCount": 0,
              "visitAverageWkClosedPerDay": 0,
              "visitTargetedBurnRate": 0,
              "visitCDPOpen": 0,
              "visitCDPClosed": 0,
              "visitCDPCompletionPct": 0,
              "visitCDPDrivenNrs": 0,
              "visitNRsAfterCDP": 0,
              "visitNRGrowthPct": 0,
              "visitCurrentBurnRate": 0,
              "visitProjectedCompletionDate": "",
              "visitCDPTargetDate": "",
              "burnRateDataForVisit": [],
              "skillList": [],
              "zoneTypeList": [],
              "milestoneList": []
            }, ...action.payload.getDashBoardData, milestoneList: action.payload.getDashBoardData?.milestoneList.sort((a,b) => (a.detailViewSortOrder - b.detailViewSortOrder))}))
            return {
              ...state,
              isLoadingAircraftStatusData: false,
              aircraftStatusData
            }
          } 
          case `${GET_AIRCRAFT_DATA_FOR_24HRS}_PENDING`: {
            return {
                ...state,
                isLoadingAircraftStatusData: true,
                isEntireVisit: false
                // aircraftStatusData: null
            }
          }
          case `${GET_AIRCRAFT_DATA_FOR_24HRS}_FULFILLED`: {
            return {
                ...state,
                isLoadingAircraftStatusData: false,
                aircraftStatusData: {
                  ...{
                  "visitProjectedCompletionDate": state.aircraftStatusData.visitProjectedCompletionDate,
                  "visitRemainingWorkCount": state.aircraftStatusData.visitRemainingWorkCount,
                  "visitAverageWkClosedPerDay": state.aircraftStatusData.visitAverageWkClosedPerDay,
                  "visitTargetedBurnRate": state.aircraftStatusData.visitTargetedBurnRate,
                  "visitCurrentBurnRate" : state.aircraftStatusData.visitCurrentBurnRate,
                  "burnRateDataForVisit": [], 
                },
                  ...action.payload.getDailyDashBoardData
                }
            }
          }
          case `${GET_AIRCRAFT_DATA_FOR_12HRS}_PENDING`: {
            return {
              ...state,
              isLoadingAircraftStatusData: true,
              aircraftStatusData: null,
              isEntireVisit: true
            }
          }

        case 'SELECTED_DETAILS_CARD':
          return{
            ...state,
            selectedDetailsCard: state.selectedDetailsCard == action.payload ? null : action.payload
          }
        case `${SEARCH_WORKLOAD_AIRCRAFT_STATUS}_PENDING`: 
          return {
            ...state,
            isLoadingworkloadDetails: true
          }

        case `${SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED}`: {
          const updatedWL = {
            ...state.workloadDetails,
            [action.payload.workloadUUId]:{
              ...(state.workloadDetails[action.payload.workloadUUId] ? state.workloadDetails[action.payload.workloadUUId]:{}),
              [action.payload.selectedTab]: {data: action.payload?.response.map(wl => { return {...wl, scheduledDate: action.payload?.scheduledDate}})}
            }
          }
          return {
            ...state,
            isLoadingworkloadDetails:false,
            workloadDetails:updatedWL,
          }
        }

        case `${SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED_PROGRESS}`: {
          const updatedWL = {
            ...state.workloadDetails,
            [action.payload.workloadUUId]:{
              ...(state.workloadDetails[action.payload.workloadUUId] ? state.workloadDetails[action.payload.workloadUUId]:{}),
              [action.payload.selectedTab]: {
                ...state.workloadDetails[action.payload.workloadUUId][action.payload.selectedTab],
                progress: action.payload?.response
              }
            }
          }
          return {
            ...state,
            isLoadingworkloadDetails:false,
            workloadDetails:updatedWL,
          }
        }

        case `${UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY}`: {
          try {
            const { queryStringValues, taskTypes, isDup, notes } = action.payload;

            const updatedWL = {...state.workloadDetails} 
            Object.keys(updatedWL).forEach(wlItem =>{
              Object.keys(updatedWL[wlItem]).forEach(item =>{
                updatedWL[wlItem][item]['data'] = updatedWL[wlItem][item]['data'].map((wl) => (
                  (!isDup && (wl.dash8 === queryStringValues.dash8))
                  || (isDup && queryStringValues.optimizedDash8s.find(d8 => d8.ncaPartNumber === wl.dash8))
              ) &&
                  ((!isDup && (wl.cardNumber === queryStringValues.taskcard))
                      || (isDup && (wl.cardNumber === queryStringValues.taskcard) && (wl.optimizedGroup === queryStringValues.optimizedGroup))
                      || wl.zoneId === queryStringValues.taskcard) &&
                  wl.serialNumber === queryStringValues.serialno &&
                  wl.workControlNumber === queryStringValues.wcno
                  ? {
                      ...wl, notesCount: wl.notesCount + 1,  lastNoteDetail: {...notes}, workStop: queryStringValues.workStop, ...(queryStringValues.workStop ? {
                          workStopActionType: taskTypes?.find(ttype => ttype.actionTypeId === queryStringValues.actionTypeId)?.actionType,
                          workStopActionTypeAbbreviated: taskTypes?.find(ttype => ttype.actionTypeId === queryStringValues.actionTypeId)?.abbreviatedActionType
                      } : {})
                  }
                  : wl
              )
              })
            })
            return {
              ...state,
              workloadDetails: updatedWL
            }
          } catch (error) {
            //console.log('error check', error)
          }
      }
      case VIEW_MILESTONE_GANTT_CHART:
        const aircraftStatusData = JSON.parse(JSON.stringify(state.aircraftStatusData))
        return{
          ...state,
          // isSidePanel: true,
          isChartView: action.payload,
          aircraftStatusData
        }
      case VIEW_MILESTONE_GANTT_CHART_SIDE_PANEL:
        return{
          ...state,
          isSidePanel: !state.isSidePanel,
        }
      case EXPAND_MILESTONE_GANTT_CHART:
        return{
          ...state,
          isGanttChartExpanded: !state.isGanttChartExpanded,
        }
      case SET_KPI_FILTER:
        return{
          ...state,
          kpiFilter: action.payload,
          selectedDetailsCard: null
        }

  
        default: return state;
    }
}

export default aircraftStatusReducer;