import {
  FETCH_TASKCARD_DATA, UPDATE_SIGNOFF_ARRAY, UPDATE_SIGNOFF_VALIDATIONS, SIGNOFF_BUTTON_CLICK, RESET_TASKCARD_DATA, ADD_NAV_POINTER,
  SET_ACTIVE_NAV_ITEM, SET_NOTIFICATION_MESSAGE, RESET_SIGNOFF_ERROR_MESSAGE, TOGGLE_SIGNOFF_MODAL, FETCH_ILLUSTRATIONS_IMAGES,
  RESET_ILLUSTRATIONS_IMAGES, FETCH_MASTERCARD_DATA, CARD_SEARCH_TYPE, TOGGLE_BULK_SIGNOFF_MODAL,
  RESET_SIGNOFF_ARRAY, FETCH_LINKED_LOGPAGES, RESET_LINKED_LOGPAGE_DATA, GET_DASH1_NOTES, SAVE_DASH1_NOTES, GET_ZONAL_CARD_CONTENT,
  UPDATE_PANEL_SIGNOFFS, ZONAL_CARD_SIGNOFF, FETCH_LINKED_EA, FETCH_FLIFO
} from '../actions/actionTypes';

const initialState = {
  isLoadingTaskCardData: true,
  taskCardData: {},
  signoffupdates: [],
  signoffValidations: [],
  isSignoffPending: false,
  isSignoffSuccess: false,
  signoffFailureMessage: null,
  navPointers: [],
  activeNavItem: { AccordionTitle: "", AccordionItem: "" },
  showSignOffModal: false,
  signoffPasswordFailureMessage: null,
  illustrationImage: {},
  cardSearchType: "",
  masterCardError: null,
  showBulkSignoffModal: false,
  linkedLp: [],
  isLoadingLp: true,
  dash1Notes: null,
  notesCount: 0,
  isLoadingDash1Notes: true,
  isSavingDash1Notes: false,
  isLoadingZonalCardContent: true,
  zonalCardContent: null,
  panelSignoffs: [],
  zonalSignOffPasswordError: null,
  tLWorkStop: false,
  linkedEAs: [],
  isLoadingEAs: true,
  flifo: null
}
const mergeArrays = (x, y) => {
  if (y === null)
    return [];
  let mergedArray
  let set = new Set();
  let unionArray = [];
  mergedArray = [y, ...x];
  unionArray = mergedArray.filter(i => (i.actionName !== i.initialActionName && i.actionName !== "") || (i.actionName !== "" && i.actionName === "PARTIAL")).filter(item => {
    if (item !== null && !set.has(item.blockID) && item.checked !== item.originalChecked) {
      set.add(item.blockID);
      return true;
    }
    return false;
  }, set);
  return unionArray.sort((a, b) => a.index - b.index);


}

const TaskCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_TASKCARD_DATA}_PENDING`: {
      return {
        ...state,
        isLoadingTaskCardData: true,
        navPointers: [],
        taskCardData: {}
      }
    }
    case `${FETCH_TASKCARD_DATA}_FULFILLED`: {
      return {
        ...state,
        isLoadingTaskCardData: false,
        taskCardData: action.payload.routineContent.routineTaskcard,
        actionDictionary: action.payload.routineContent.routineTaskcard && Object.assign(...action.payload.routineContent.routineTaskcard.actionDictionary.map(x => x.replace('[', '').replace(']', '').split(',')).map(([v, k]) => ({ [v]: k.trim() }))),
        actorDictionary: action.payload.routineContent.routineTaskcard && Object.assign(...action.payload.routineContent.routineTaskcard.actorDictionary.map(x => x.replace('[', '').replace(']', '').split(',')).map(([v, k]) => ({ [v]: k.trim() })))
      }
    }
    case `${FETCH_MASTERCARD_DATA}_PENDING`: {
      return {
        ...state,
        isLoadingTaskCardData: true,
        navPointers: [],
        taskCardData: {}
      }
    }
    case `${FETCH_MASTERCARD_DATA}_FULFILLED`: {
      return {
        ...state,
        isLoadingTaskCardData: false,
        taskCardData: action.payload.masterSearch.routineTaskcard !== null ? action.payload.masterSearch.routineTaskcard : {},
        masterCardError: action.payload.masterSearch.error,
        actionDictionary: action.payload.masterSearch.routineTaskcard && Object.assign(...action.payload.masterSearch.routineTaskcard.actionDictionary.map(x => x.replace('[', '').replace(']', '').split(',')).map(([v, k]) => ({ [v]: k.trim() }))),
        actorDictionary: action.payload.masterSearch.routineTaskcard && Object.assign(...action.payload.masterSearch.routineTaskcard.actorDictionary.map(x => x.replace('[', '').replace(']', '').split(',')).map(([v, k]) => ({ [v]: k.trim() })))
      }
    }
    case `${UPDATE_SIGNOFF_ARRAY}`: {
      return {
        ...state,
        isLoadingTaskCardData: false,
        signoffupdates: mergeArrays(state.signoffupdates, action.payload)
      }
    }
    case `${UPDATE_SIGNOFF_VALIDATIONS}`: {
      return {
        ...state,
        signoffValidations: action.payload
      }
    }
    case `${SIGNOFF_BUTTON_CLICK}_PENDING`: {
      return {
        ...state,
        isSignoffPending: true,
        signoffPasswordFailureMessage: null,
        signoffFailureMessage: null
      }
    }
    case `${SIGNOFF_BUTTON_CLICK}_FULFILLED`: {
      return {
        ...state,
        isSignoffPending: false,
        signoffPasswordFailureMessage: action.payload.signOff && action.payload.signOff.passwordErrorMessage !== null ? action.payload.signOff.passwordErrorMessage : null,
        showSignOffModal: action.payload.signOff.passwordErrorMessage !== null ? state.showSignOffModal : false,
        showBulkSignoffModal: action.payload.signOff.passwordErrorMessage !== null ? state.showBulkSignoffModal : false,
        isSignoffSuccess: action.payload.signOff && action.payload.signOff.status !== null ? true : false,
        signoffFailureMessage: action.payload.signOff && action.payload.signOff.errorMessage !== null ? action.payload.signOff.errorMessage : null,
        signoffupdates: action.payload.signOff && action.payload.signOff.passwordErrorMessage !== null ? state.signoffupdates : [],
        signoffValidations: action.payload.signOff && action.payload.signOff.passwordErrorMessage !== null ? state.signoffValidations : [],
        signOffArrayForSubmit: action.payload.signOff && action.payload.signOff.passwordErrorMessage !== null ? state.signOffArrayForSubmit : [],
      }
    }
    case `${ADD_NAV_POINTER}`: {
      let updatedNavPointers = [...state.navPointers, action.payload]
      return {
        ...state,
        navPointers: updatedNavPointers
      }
    }
    case `${SET_ACTIVE_NAV_ITEM}`: {
      return {
        ...state,
        activeNavItem: action.payload
      }
    }
    case `${SET_NOTIFICATION_MESSAGE}`: {
      return {
        ...state,
        showNotification: true,
        notificationMessage: action.payload,
        notificationType: "SUCCESS"
      }
    }
    case `${RESET_TASKCARD_DATA}`: {
      return {
        ...state,
        taskCardData: {},
        signoffupdates: [],
        signoffValidations: [],
        isSignoffPending: false,
        isSignoffSuccess: false,
        signoffFailureMessage: null,
        signoffPasswordFailureMessage: null,
        illustrationImage: {},
        masterCardError: null,
        zonalCardContent: null
      }
    }
    case `${RESET_SIGNOFF_ARRAY}`: {
      return {
        ...state,
        signoffupdates: [],
        panelSignoffs: []
      }
    }
    case `${RESET_SIGNOFF_ERROR_MESSAGE}`: {
      return {
        ...state,
        signoffFailureMessage: null,
        signoffPasswordFailureMessage: null
      }
    }
    case `${TOGGLE_SIGNOFF_MODAL}`: {
      return {
        ...state,
        showSignOffModal: action.payload

      }
    }
    case `${TOGGLE_BULK_SIGNOFF_MODAL}`: {
      return {
        ...state,
        showBulkSignoffModal: action.payload,

      }
    }
    case `${FETCH_ILLUSTRATIONS_IMAGES}_PENDING`: {
      return {
        ...state,
        illustrationImage: {}
      }
    }
    case `${FETCH_ILLUSTRATIONS_IMAGES}_FULFILLED`: {
      return {
        ...state,
        illustrationImage: action.payload.getIllustrations.illustration
      }
    }
    case `${FETCH_LINKED_LOGPAGES}_PENDING`: {
      return {
        ...state,
        linkedLp: [],
        isLoadingLp: true
      }
    }
    case `${FETCH_LINKED_LOGPAGES}_FULFILLED`: {
      return {
        ...state,
        linkedLp: action.payload.getRoutineLinkedLogPages.linkedLogPages,
        isLoadingLp: false
      }
    }
    case `${RESET_ILLUSTRATIONS_IMAGES}`: {
      return {
        ...state,
        illustrationImage: {}
      }
    }
    case `${CARD_SEARCH_TYPE}`: {
      return {
        ...state,
        cardSearchType: action.payload,
      }
    }
    case `${RESET_LINKED_LOGPAGE_DATA}`: {
      return {
        ...state,
        linkedLp: [],
        isLoadingLp: true
      }
    }
    case `${SAVE_DASH1_NOTES}_PENDING`: {
      return {
        ...state,
        isSavingDash1Notes: true
      }
    }
    case `${SAVE_DASH1_NOTES}_FULFILLED`: {
      return {
        ...state,
        isSavingDash1Notes: false
      }
    }
    case `${GET_DASH1_NOTES}_PENDING`: {
      return {
        ...state,
        dash1Notes: null,
        isLoadingDash1Notes: true,
        notesCount: 0
      }
    }
    case `${GET_DASH1_NOTES}_FULFILLED`: {
      return {
        ...state,
        dash1Notes: action.payload.getDash1Notes?.notes,
        dash1NoteTypes: action.payload.getDash1Notes?.actionTypes,
        tLWorkStop: action.payload.getDash1Notes?.tLWorkStop,
        isLoadingDash1Notes: false,
        notesCount: action.payload.getDash1Notes?.notes?.length
      }
    }
    // case `${GET_ZONAL_CARD_CONTENT}`: {
    //   return {
    //     ...state,
    //     isLoadingZonalCardContent: false,
    //     zonalCardContent: action.payload?.zonalCardContent?.zonalCard,
    //   }
    // }
    case `${GET_ZONAL_CARD_CONTENT}_PENDING`: {
      return {
        ...state,
        isLoadingZonalCardContent: true,
        zonalCardContent: null,
        panelSignoffs: [],
        zonalSignOffPasswordError: null
      }
    }
    case `${GET_ZONAL_CARD_CONTENT}_FULFILLED`: {
      return {
        ...state,
        isLoadingZonalCardContent: false,
        zonalCardContent: action.payload?.zonalCardContent?.zonalCard,
      }
    }
    case `${UPDATE_PANEL_SIGNOFFS}`: {
      let updatedSignoffs = state.panelSignoffs.filter(panel => panel.panelId !== action.payload.panelId);
      if (action.payload.isDirty && action.isValid) {
        updatedSignoffs.push(action.payload);
      }
      return {
        ...state,
        panelSignoffs: updatedSignoffs
      }
    }
    case `${ZONAL_CARD_SIGNOFF}_PENDING`: {
      return {
        ...state,
        zonalSignOffPasswordError: null
      }
    }
    case `${ZONAL_CARD_SIGNOFF}_FULFILLED`: {
      return {
        ...state,
        zonalSignOffPasswordError: action.payload?.zonalCardSignOff?.passwordErrorMessage
      }
    }
    case "RESET_DASH1_NOTES": {
      return {
        ...state,
        dash1Notes: null
      }
    }
    case `${FETCH_LINKED_EA}_PENDING`: {
      return {
        ...state,
        linkedEAs: [],
        isLoadingEAs: true
      }
    }
    case `${FETCH_LINKED_EA}_FULFILLED`: {
      return {
        ...state,
        linkedEAs: action.payload?.getTaskCardsByMaintainenceItem?.etaskCards,
        isLoadingEAs: false
      }
    }
    case `${FETCH_FLIFO}_PENDING`: {
      return {
        ...state,
        flifo: null
      }
    }
    case `${FETCH_FLIFO}_FULFILLED`: {
      return {
        ...state,
        flifo: action.payload?.routineRegister?.flifo
      }
    }
    case `${FETCH_FLIFO}`: {
      return {
        ...state,
        flifo: action.payload
      }
    }
    default: return state;
  }

}

export default TaskCardReducer;