export const fetchVisitShiftDetails = (station, actualInductionDate, shiftNumber) => {
  return `query VisitSchedule($userInfo: UserInformationInputType) 
  {
    getShiftInfo(station:"`+ station + `",inductionDate:"` + actualInductionDate + `",shiftNumber:` + shiftNumber + `,userInfo:$userInfo) {
      visitShifts {
        shiftNumber
        shiftType
        shiftDate
        isCurrentShift      
      }
      isCurrentPastETR
    }
  }`
}
export const fetchLoadGroups = () => {
  return `query VisitSchedule($loadGroupsTM:LoadGroupsInputType,$userInfo: UserInformationInputType)
  {
    loadGroupsTM(loadGroupsTM:$loadGroupsTM,userInfo:$userInfo) {
      isSuccess
      error
      scheduledGroupsCount
      unscheduledGroupsCount
      unusedGroupsCount
      overrideGroupsCount
      tailNumber
      station
      flightNumber
      scheduledArrivalDate
      routineTotalCount
      routineScheduledCount
      routineUnScheduledCount
      nonRoutineTotalCount
      nonRoutineScheduledCount
      nonRoutineUnScheduledCount
      nonRoutineOpenCount
      nonRoutineClosedCount
      nonRoutineManHours
      routineManHours
      newlyAddedCardsCount
      groupShiftSpan
      routineOpenCount
      routineClosedCount
      openLogPageCount
      workBinTotalCount
      cDPTotalCount
      cDPOpenCount
      cDPClosedCount
      cDPCompletionPercentage
      cDPDueDate
      cDPLinkedNRCount
      inspectionWorkbinCount 
      inspItemsCount 
      inspPendingItemsCount
      mxGroups {
        mxGroupId
        groupId
        groupName
        shiftStartNumber
        shiftEndNumber
        visitScheduleId
        phaseID
        phaseName
        skill
        completedCount
        totalCount
        openWorkloadCount
        closedWorkloadCount
        dash8s {    
          serialNumber
          totalManHrs
          dash8Number
          workControlNumber
          isDash8Completed
          dash8SignOffDate
          uIDash8SignOffDate
          dash8Description
          positionCode
          dash1s {
            cardDocType
            cardId
            cardType
            instanceId
            rootId
            flightNumber
            cardNumber
            name
            taskTitle
            scheduledDate
            status
            statusDetail
            forcastNumber
            releasedBy            
            manHrs
            mxMasterId
            mxType
            aTA
            etaskcardMasterId
            shiftStartNumber
            shiftEndNumber
            visitScheduleId
            groupOverride
            isDeleted
            isCompleted
            isPartialActive
            isScheduleCleared
            blockingInd
            aTASubChapter
            nRStatus
            isPaper
            deferCode
            dipAction
            dipActionDesc
            rERType
            zoneCardNumber
            zoneCardId
            actionType
            isMissingProperties
            positionCode
            serialNumber
            workStop
            workStopActionType
            workStopActionTypeAbbreviated
            jobNumber 
            stepsCount
			      stepsCompleted
            optimizedGroup
            optimizedDetails {
              optimizedReason
              optimizedReferenceInfo {
                optimizedDash8s {
                  ncaPartNumber
                  ncaSerialNumber
                  forecastDescription
                  positionCode
                  formattedDash8
                }
                optimizedEffectivity
              }
              optimizedVersion
            }
            dash1OptimizedStatusType
            isOptimized
            dash1Status
            uIDash1Status
            isDash1Duplicate 
            tcmDetails {

              isTCMEnabled

              specNumber

            } 
          }
        }
        logPages {
               cardDocType
            cardId
            cardType
            instanceId
            rootId
            flightNumber
            cardNumber
            name
            taskTitle
            scheduledDate
            status
            statusDetail
            forcastNumber
            releasedBy            
            manHrs
            mxMasterId
            mxType
            aTA
            etaskcardMasterId
            shiftStartNumber
            shiftEndNumber
            visitScheduleId
            groupOverride
            isDeleted
            isCompleted
            isScheduleCleared
            blockingInd
            aTASubChapter
            nRStatus
            isPaper
            deferCode
            dipAction
            dipActionDesc
            rERType
        }
        nonRoutines {
               cardDocType
            cardId
            cardType
            instanceId
            rootId
            flightNumber
            cardNumber
            name
            taskTitle
            scheduledDate
            status
            statusDetail
            forcastNumber
            releasedBy            
            manHrs
            mxMasterId
            mxType
            aTA
            etaskcardMasterId
            shiftStartNumber
            shiftEndNumber
            visitScheduleId
            groupOverride
            isDeleted
            isCompleted
            isScheduleCleared
            blockingInd
            aTASubChapter
            nRStatus
            isPaper
            deferCode
            dipAction
            dipActionDesc
            rERType
            workStop
            workStopActionType
            workStopActionTypeAbbreviated
        }        
      }
    }    
  }


`
}
export const saveVisitSchedule = () => {
  return `mutation VisitSchedule($saveVS: VisitScheduleInputType, $userInfo: UserInformationInputType) {
    saveVSandAssociateGroups(saveVS: $saveVS, userInfo: $userInfo) {
      id
      isSuccess
      error
    }
  }
  `
}

export const getVisitScheduleHistory = () => {
  return `query VisitSchedule($visitHistory: VisitHistoryReqType, $userInfo: UserInformationInputType) {
  getVisitScheduleHistory(visitHistory: $visitHistory, userInfo: $userInfo) {
    groupHistory {
      visitId
      tailNumber
      schedArrDate
      baseInductionDate
      aircraftControlID
      createdBy
      createdDate
      mxMasterId
      modifiedBy
      modifiedDateTime
      isActive
      mxGroupId
      mxItem
      mxItemDescription
      mxType
      mxMasterActive
      dash8
      workControlNumber
      serialNumber
      skill
      groupId
      groupDescription
      groupName
      phaseID
      groupActive
      groupOverride
      firstName
      lastName
      isPaper
      deferCode
    }
    overrideScheduleHistory {
      overrideNumber
      visitId
      visitScheduleId
      tailNumber
      schedArrDate
      baseInductionDate
      aircraftControlID
      createdBy
      createdDate
      mxMasterId
      modifiedBy
      modifiedDateTime
      isActive
      mxItem
      mxItemDescription
      mxType
      dash8
      workControlNumber
      serialNumber
      dash8Description
      groupName
      visitWorkloadId
      shiftEndNumber
      shiftStartNumber
      firstName
      lastName
      isPaper
      deferCode
    }
    groupScheduleHistory {
      groupId
      groupName
      groupHistory {
        visitScheduleId
        tailNumber
        schedArrDate
        baseInductionDate
        aircraftControlID
        createdBy
        createdDate
        visitId
        mxMasterId
        modifiedBy
        modifiedDateTime
        mxGroupId
        checkType
        shiftStartNumber
        shiftEndNumber
        groupId
        groupDescription
        groupName
        phaseID
        groupActive
        groupOverride
        firstName
        lastName
        isPaper
        deferCode
        shiftName
      }
    }
  }
}
`
}

export const deleteMxGroup = () => {
  return `mutation TemplateManagement($deleteGroup: DeleteGroupInputType, $userInfo: UserInformationInputType) {
    deleteTemplateOrVisitGroup(deleteGroup: $deleteGroup, userInfo: $userInfo) {
      isSuccess
      errors {
        type
        code
        description
      }
      message
      error
      id
    }
  }`
}
