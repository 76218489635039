import {
    FETCH_NONROUTINES, FETCH_NONROUTINE_DETAILS, UPDATE_NONROUTINE, FETCH_VISIT_INFO, SET_GENERIC_ERROR, SET_LAST_REFRESHED,
    NONROUTINE_OPEN_FILTERS, SET_SEARCH_CARDS_TEXT_NR, CREATE_NONROUTINE, SET_NOTIFICATION_MESSAGE, FETCH_NONROUTINES_REF_DATA, NONROUTINE_HISTORY_FILTERS,
    RESET_CREATE_NONROUTINE_FLAGS, SET_NONROUTINE_ACTIONS_VARIABLE, RESET_NONROUTINE_ACTIONS_VARIABLE, ADD_MXNOTES_NONROUTINES, RESET_NONROUTINE_REGISTER_DATA, FETCH_NONROUTNE_HISTORY,
    SUBTASK_SIGNOFF, FETCH_NONROUTINE_LOGPAGES, TOGGLE_SUBTASK_SIGNOFF_MODAL, FETCH_NONROUTINE_IMAGES, UPDATE_SUBTASK_SORT_ORDER, RESET_VIEW_NR_ERROR_MESSAGE, TOGGLE_ADD_SUBTASK_MODAL,
    HANDLE_NONROUTINE_ACTIONS, IS_APPLY_FILTERS_CLICK_ACTION, RESET_NR_FILTERS, GET_ATTACHED_TASKCARD, RESET_ATTACHED_TASKCARD, GET_GROUPS_FOR_NONROUTINE, GET_ATA_CODES, GET_LINKED_GROUP_FOR_LINKED_NONROUTINE, ADD_ROTABLE_PARTS, RESET_ADD_ROTABLE_PARTS,
    IS_OPENE_LINK_CLICK_ACTION, FETCH_ACTIONTYPES_FOR_NOTES, GET_DASH1_NOTES_FOR_NR, GET_ASSIGNED_CREW, GET_PROGRESS_FOR_TASKCARDS,ADD_PDFIMAGE_NONROUTINES, GET_PDFIMAGE_NRS, DELETE_NR_PDF
} from './actionTypes';
import * as Queries from '../queries/NonRoutinesQueries';
import * as VisitQueries from '../queries/VisitQueries';
import {getProgressForTaskCards} from '../queries/AssignCrewQueries';
import { executeQuery } from '../helper';
import { trackPromise } from 'react-promise-tracker';
import { setNonRoutinesActionVariable } from './nonRoutinesHandlerActions';
import moment from 'moment';


export const fetchNonRoutineRegisterData = (queryStringValues) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        if (visitInfo) {
            const optimizerProps = queryStringValues?.d1status?.toUpperCase() === "DEDUP" ?
                {
                    "dash1Status": queryStringValues?.d1status ? queryStringValues.d1status.toUpperCase() : null,
                    "optimizedGroup": queryStringValues?.optgrpid ? queryStringValues.optgrpid : null,
                    "workControlNumber": queryStringValues?.wcno ? queryStringValues.wcno : null,
                    "isOptimized": queryStringValues?.isoptimized ? queryStringValues.isoptimized === "true" : false,
                    "optimizedDash8s": queryStringValues?.optd8list ? JSON.parse(queryStringValues.optd8list).map(d8 => {
                        return {
                            "forecastDescription": d8.forecastdescription,
                            "ncaPartNumber": d8.dash8,
                            "ncaSerialNumber": queryStringValues.aircraftnumber
                        }
                    }) : null
                }
            :
            {
                "dash1Status": null,
                "optimizedGroup": null,
                "workControlNumber": queryStringValues?.wcno ? queryStringValues.wcno : null,
                "optimizedDash8s": null
            }
            const nonRoutineVariable = {
                "aircraftNonRoutine": {
                    "inFlightNumber": "0000",
                    "baseInductionDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                    "schedArrDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                    "baseStation": visitInfo.station,
                    "tailNumber": visitInfo.aircraftNumber,
                    "refDataReqd": true,
                    "showNRCounts": true,
                    "originatingDocument": queryStringValues?.originatingdoc ? queryStringValues?.originatingdoc : null,
                    "showAssignees": true,
                    "arrivalDate": visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                    "dash1": queryStringValues?.dash1 ? queryStringValues?.dash1 : null,
                    "enableVS": visitInfo.enableVS,
                    "dash8": queryStringValues?.dash8 ? queryStringValues?.dash8 : null,
                    "forecastSerialNumber": queryStringValues?.serialno ? queryStringValues.serialno : null,
                    ...optimizerProps 
                }

            }
            dispatch({
                type: FETCH_NONROUTINES,
                payload: executeQuery(Queries.fetchNonRoutines(), nonRoutineVariable, getState(), 'NonRoutine')
            }).then(resp => {
                if (resp !== null && resp.getAircraftNRs !== null) {
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
                .catch(error => {
                    dispatch({
                        type: SET_GENERIC_ERROR,
                        payload: error?.message
                    })
                });
        }
    }
}
export const fetchNonRoutineRegisterDataNoVisit = (queryStringValues, originatingDoc, visitInfo, dash1) => {
    return (dispatch, getState) => {
        const optimizerProps = queryStringValues?.d1status?.toUpperCase() === "DEDUP" ?
            {
                "dash1Status": queryStringValues?.d1status ? queryStringValues.d1status.toUpperCase() : null,
                "optimizedGroup": queryStringValues?.optgrpid ? queryStringValues.optgrpid : null,
                "workControlNumber": queryStringValues?.wcno ? queryStringValues.wcno : null,
                "isOptimized": queryStringValues?.isoptimized ? queryStringValues.isoptimized === "true" : false,
                "optimizedDash8s": queryStringValues?.optd8list ? JSON.parse(queryStringValues.optd8list).map(d8 => {
                    return {
                        "forecastDescription": d8.forecastdescription,
                        "ncaPartNumber": d8.dash8,
                        "ncaSerialNumber": queryStringValues.aircraftnumber
                    }
                }) : null
            }
            :
            {
                "dash1Status": null,
                "optimizedGroup": null,
                "workControlNumber": queryStringValues?.wcno ? queryStringValues.wcno : null,
                "optimizedDash8s": null,
                "isOptimized": false
            }
        const nonRoutineVariable = {
            "aircraftNonRoutine": {
                "inFlightNumber": "0000",
                "baseInductionDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "schedArrDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "baseStation": visitInfo.station,
                "tailNumber": visitInfo.aircraftNumber,
                "refDataReqd": true,
                "showNRCounts": true,
                "originatingDocument": originatingDoc ? originatingDoc : null,
                "showAssignees": true,
                "arrivalDate": visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "dash1": dash1 ? dash1 : null,
                "enableVS": visitInfo.enableVS,
                "dash8": queryStringValues?.dash8 ? queryStringValues?.dash8 : null,
                "forecastSerialNumber": queryStringValues?.serialno ? queryStringValues.serialno : null,
                ...optimizerProps
            }
        }
        dispatch({
            type: FETCH_NONROUTINES,
            payload: trackPromise(executeQuery(Queries.fetchNonRoutines(), nonRoutineVariable, getState(), 'NonRoutine'))
        }).then(resp => {
            if (resp !== null && resp.value?.getAircraftNRs !== null) {
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const fetchNonRoutineRegisterHistory = (aircraftNumber, acControlID, employeeID, station, jobRole, days) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        if (visitInfo) {
            const nonRoutineVariable = {
                "acNRHistory": {
                    "tailNumber": aircraftNumber,//"0217 ",
                    "noOfDays": days, //"30"
                    "schedArrDate": visitInfo.actualInductionDate,
                    "baseStation": station,
                    "arrivalDate": visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                    "enableVS": visitInfo?.enableVS
                }
            }
            const nonRoutineVariableRefData = {
                "aircraftNonRoutine": {
                    "inFlightNumber": "0000",
                    "baseInductionDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                    "schedArrDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                    "baseStation": visitInfo.station,
                    "tailNumber": visitInfo.aircraftNumber,
                    "refDataReqd": true,
                    "showNRCounts": true
                }
            }
            dispatch({
                type: FETCH_NONROUTINES_REF_DATA,
                payload: executeQuery(Queries.fetchNonRoutinesRefData(), nonRoutineVariableRefData, getState(), 'NonRoutine')
            });
            dispatch({
                type: FETCH_NONROUTNE_HISTORY,
                payload: executeQuery(Queries.fetchNonRoutineHistory(), nonRoutineVariable, getState(), 'NonRoutine')
            }).then(resp => {
                if (resp !== null && resp.value?.getAcNRHistory !== null) {
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
                .catch(error => {
                    dispatch({
                        type: SET_GENERIC_ERROR,
                        payload: error?.message
                    })
                });
        }
    }
}

export const fetchNonRoutineDetails = (tailNumber, nonRoutineID, userId, jobRole, acControlID, station, isActionable, isVisitSchedule, isDependencies = false) => {
    
    return (dispatch, getState) => {
        const visitInfo = getState().visitsStore.visitInfo;
        let variable = {
            "viewNonRoutine": {
                "tailNumber": tailNumber,
                "nonRoutineID": nonRoutineID,
                "employeeId": userId,
                "jobRole": jobRole,
                "refDataReqd": 1,
                "clearRequestRotables": null,
                "isVisitSchedule": isVisitSchedule === "true" ? true : false,
                "station": station,
                "returnDependencies": isDependencies,
                "isHvyChkPackage":visitInfo?.enableZonalCard,
                "actualInductionDate": visitInfo?.actualInductionDate ? visitInfo?.actualInductionDate : visitInfo?.plannedInductionDate,
                "isHistoricalVisit":  visitInfo?.isArchived      
            }
        }
        if(!visitInfo){
            dispatch({
                type: FETCH_VISIT_INFO,
                payload: executeQuery(VisitQueries.fetchVisitInfo(acControlID, station), null, null, 'Visit')
            }).then(visitInfo => {
                variable.viewNonRoutine.isVisitSchedule = visitInfo.value?.viewVisitInfo?.enableVS ;
                variable.viewNonRoutine.isHvyChkPackage = visitInfo.value?.viewVisitInfo?.enableZonalCard;
                variable.viewNonRoutine.actualInductionDate = visitInfo.value?.viewVisitInfo?.actualInductionDate ? visitInfo.value?.viewVisitInfo?.actualInductionDate : visitInfo.value?.viewVisitInfo?.plannedInductionDate ;
                variable.viewNonRoutine.isHistoricalVisit = visitInfo.value?.viewVisitInfo?.isArchived;
                dispatch({
                    type: FETCH_NONROUTINE_DETAILS,
                    payload: executeQuery(Queries.fetchNonRoutineInfo(), variable, getState(), 'NonRoutine')
                }).then(resp => {
                    if (resp !== null && resp.viewNonRoutines !== null) {
                        if(isDependencies){
                            const progressVariable = {
                                "progress": {
                                    "assignedStation": visitInfo.value?.viewVisitInfo?.station,
                                    "cardNumbers": resp.value.viewNonRoutines?.taskCardDependencies?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC")?.map(wl => ({
                                        cardNumber: wl.mxType==="D1" ? wl.dependencyCardNumber : wl.zoneCardNumber,
                                        workControlNumber: wl.workControlNumber,
                                        serialNumber: wl.serialNumber,
                                        jobNumber: wl.dash8,
                                        //adding masterid to get the correct counts for progress, rumiana asked me to add the masterid we are getting from above response
                                        masterId: wl.masterId,
                                        zoneId: wl.mxType === "ZC" ? wl.zoneId : null
                                    })) || [],
                                    "tailNumber": visitInfo.value?.viewVisitInfo?.aircraftNumber,
                                    "flightNumber": "0000",
                                    "scheduledArrivalDate": visitInfo.value?.viewVisitInfo?.actualInductionDate || visitInfo.value?.viewVisitInfo?.plannedInductionDate
                                }
                            };
                            dispatch({
                                type: GET_PROGRESS_FOR_TASKCARDS,
                                payload: executeQuery(getProgressForTaskCards(), progressVariable, getState(), 'TemplateManagement')
                            })
                        }                       
                        if (isActionable) {
                            dispatch({
                                type: SET_NONROUTINE_ACTIONS_VARIABLE,
                                payload: setNonRoutinesActionVariable(resp.value.viewNonRoutines, userId, visitInfo.value.viewVisit)
                            })
                        }
                        else {
                            dispatch({
                                type: SET_LAST_REFRESHED,
                                payload: Date.now()
                            })
                        }
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
        }else{
            variable.viewNonRoutine.isVisitSchedule = visitInfo.value?.viewVisitInfo?.enableVS ?? visitInfo?.enableVS ;
            dispatch({
                type: FETCH_NONROUTINE_DETAILS,
                payload: executeQuery(Queries.fetchNonRoutineInfo(), variable, getState(), 'NonRoutine')
            }).then(resp => {
                if (resp !== null && resp.viewNonRoutines !== null) {
                    if (isActionable) {
                        dispatch({
                            type: SET_NONROUTINE_ACTIONS_VARIABLE,
                            payload: setNonRoutinesActionVariable(resp.value.viewNonRoutines, userId, visitInfo.value.viewVisit)
                        })
                    }
                    else {
                        dispatch({
                            type: SET_LAST_REFRESHED,
                            payload: Date.now()
                        })
                    }
                }
            }) .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
        }
    }
}

export const setNonRoutineSubTaskActionVariable = (nonRoutine, profile, visitInfo, isAddSubTask, actionType) => {
    let nonRoutineActionsVariable = setNonRoutinesActionVariable(nonRoutine, profile, visitInfo, actionType);
    if (isAddSubTask) {
        nonRoutineActionsVariable.subtasks.push({
            "subTaskText": "",
            "inspectionTypeID": null,
            "isDeleted": false,
            "lastModifiedEmployeeID": profile !== null && profile !== undefined ? profile.userId : "",
            "lastModifiedFirstName": profile !== null && profile !== undefined ? profile.firstName : "",
            "lastModifiedLastName": profile !== null && profile !== undefined ? profile.lastName : "",
            "comment": "", //ignore while creating
            "subTaskActivityTypeID": 1, //for create
            "subTaskID": null,
            "nonRoutineID": nonRoutine.nonRoutineID,
        });
    }
    return (dispatch) => {
        dispatch({
            type: SET_NONROUTINE_ACTIONS_VARIABLE,
            payload: nonRoutineActionsVariable
        })
    }

}

export const resetNonRoutinesActionVariable = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_NONROUTINE_ACTIONS_VARIABLE
        }).catch(error => {
            dispatch({
                type: SET_GENERIC_ERROR,
                payload: error?.message
            })
        });
    }
}

export const updateNonRoutine = (nonRoutineInfo, actionType) => {
    nonRoutineInfo.workbinWorkloadData = nonRoutineInfo.workBins.map(wbins =>({
        "workBinId": wbins.value,           
    }));
    if(nonRoutineInfo.hasOwnProperty('groupName')) delete nonRoutineInfo.groupName;
    delete nonRoutineInfo.workBins;
    delete nonRoutineInfo.assignCrewData;
    const variable = {
        "updateOrClearNonRoutine": nonRoutineInfo,
        "nRInputType": {
            "nonRoutineType": actionType
        }
    }
    
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_NONROUTINE,
            payload: trackPromise(executeQuery(Queries.updateOrClearNonRoutine(), variable, getState(), 'NonRoutine'))
        })
            .then(resp => {
                if (resp.value.updateOrClearNonRoutine && resp.value.updateOrClearNonRoutine?.nonRoutineID !== 0) {
                    dispatch({
                        type: SET_NOTIFICATION_MESSAGE,
                        payload: actionType === "UPDATE" ? "Non-Routine has been updated successfully" :
                            actionType === "CLEAR" ? "Non-Routine has been cleared successfully" :
                                ""
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const clearNonRoutine = (nonRoutineInfo) => {
    const variable = {
        "updateOrClearNonRoutine": nonRoutineInfo,
        "nRInputType": {
            "nonRoutineType": "CLEAR"
        }
    }
    delete variable.workBins
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_NONROUTINE,
            payload: trackPromise(executeQuery(Queries.updateOrClearNonRoutine(), variable, getState(), 'NonRoutine'))
        })
    }
}
export const updateNonRoutinesOpenFilters = (filtersObject) => {
    return dispatch => {
        dispatch({
            type: NONROUTINE_OPEN_FILTERS,
            payload: filtersObject
        });
    }
}
export const updateNonRoutinesHistoryFilters = (filtersObject) => {
    return dispatch => {
        dispatch({
            type: NONROUTINE_HISTORY_FILTERS,
            payload: filtersObject
        });
    }
}
export const setSearchCardsTextNR = (searchText) => {
    return dispatch => {
        dispatch({
            type: SET_SEARCH_CARDS_TEXT_NR,
            payload: searchText?.trim()
        });
    }
}
export const createNonRoutine = (nrObject) => {
    return (dispatch, getState) => {
        dispatch({
            type: CREATE_NONROUTINE,
            payload: trackPromise(executeQuery(Queries.createNonRoutine(), nrObject, getState(), 'NonRoutine'))
        }).then(resp => {
            if (resp.value.createNonRoutine && resp.value.createNonRoutine.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Non-Routine has been created successfully."
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchNonRoutineRefData = (acControlID, station) => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_VISIT_INFO,
            payload: executeQuery(VisitQueries.fetchVisitInfo(acControlID, station), null, null, 'Visit')
        }).then(resp => {
            if (resp !== null && resp.value.viewVisitInfo !== null) {
                const visitInfo = resp.value.viewVisitInfo;
                const nonRoutineVariable = {
                    "aircraftNonRoutine": {
                        "inFlightNumber": "0000",
                        "baseInductionDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                        "schedArrDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                        "baseStation": visitInfo.station,
                        "tailNumber": visitInfo.aircraftNumber,
                        "refDataReqd": true,
                        "showNRCounts": true
                    }
                }
                dispatch({
                    type: FETCH_NONROUTINES_REF_DATA,
                    payload: executeQuery(Queries.fetchNonRoutinesRefData(), nonRoutineVariable, getState(), 'NonRoutine')
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const resetCreateNonRoutineFlags = () => {
    return dispatch => {
        dispatch({
            type: RESET_CREATE_NONROUTINE_FLAGS
        })
    }
}
export const addMxnotesForNonRoutines = (nonRoutineID, userId, notes) => {
    const variable = {
        "mxNotes": {
            "mxNotesId": 0,
            "workLoadID": 0,
            "nonRoutineID": nonRoutineID,
            "notes": notes,
            "employeeId": userId,
            "isDelete": false
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: ADD_MXNOTES_NONROUTINES,
            payload: trackPromise(executeQuery(Queries.addMxnotesForNonRoutines(), variable, getState(), 'NonRoutine'))
        }).then(resp => {
            if (resp.value.saveMxNotes?.mxNotes.length > 0) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "A new note has been added."
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const resetNonRoutineRegisterData = () => {
    return dispatch => {
        dispatch({
            type: RESET_NONROUTINE_REGISTER_DATA
        })
    }
}

export const toggleSubTaskSignoffModal = (showSubTaskSignoffModal, type, index) => {
    return dispatch => {
        dispatch({
            type: TOGGLE_SUBTASK_SIGNOFF_MODAL,
            payload: { showSubTaskSignoffModal: showSubTaskSignoffModal, subTaskSignoffModalType: type, index: index }
        })
    }
}
export const toggleAddSubTaskModal = (showAddSubTaskSignModal) => {
    return dispatch => {
        dispatch({
            type: TOGGLE_ADD_SUBTASK_MODAL,
            payload: showAddSubTaskSignModal
        })
    }
}
export const subTaskSignOff = (nonroutine, type, index, password, tailNumber, nonRoutineID, userId, jobRole, profile, manageType, isVisitSchedule, station, mxMasterId) => {
    const payload = {...nonroutine};
    delete payload.groupName
    payload.workbinWorkloadData = payload.workBins.map(wbins =>({
        "workBinId": wbins.value,           
    }));
    delete payload.workBins;
    const variable = {
        nonRoutineInputType: {
            ...payload,
            assignCrewData: payload.assignCrewData?.map(crew=>({...crew, mxMasterId, assign: true})),
             subtasks: [...payload.subtasks.map((subTask, sindex) => sindex === parseInt(index) ?
                {
                    ...subTask,
                    subTaskActivityTypeID: type === "partial" ? 2 : type === "full" ? 5 : type === "accept" ? 3 : type === "reopen" ? 6 : 4,
                    lastModifiedEmployeeID: userId,
                    lastModifiedFirstName: profile.firstName,
                    lastModifiedLastName: profile.lastName,

                } : subTask)],
            token: window.btoa(payload.employeeId + "," + password)
        },
        nRInputType: { nonRoutineType: "MANAGENRSUBTASKS" }
    }
    return (dispatch, getState) => {
        dispatch({
            type: SUBTASK_SIGNOFF,
            payload: trackPromise(executeQuery(Queries.subTaskSignOff(), variable, getState(), 'NonRoutine'))
        })
            .then(resp => {
                if (resp.value.nonRoutineOtherActions?.isSuccess) {
                    const variable = {
                        "viewNonRoutine": {
                            "tailNumber": tailNumber,
                            "nonRoutineID": nonRoutineID,
                            "employeeId": userId,
                            "jobRole": jobRole,
                            "refDataReqd": 1,
                            "clearRequestRotables": null,
                            "isVisitSchedule": isVisitSchedule,
                            "station": station
                        }
                    }
                    dispatch({
                        type: SET_NOTIFICATION_MESSAGE,
                        payload: manageType === "add" ? "SubTask added succesfully" : "Subtask signed off successfully"
                    });
                    dispatch({
                        type: FETCH_NONROUTINE_DETAILS,
                        payload: executeQuery(Queries.fetchNonRoutineInfo(), variable, getState(), 'NonRoutine')
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const fetchNonRoutineLogPages = (tailNumber, nonRoutineID) => {
    const logPageVariable = {
        "linkedLP": {
            "aircraftNumber": tailNumber,
            "nonRoutineId": nonRoutineID
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_NONROUTINE_LOGPAGES,
            payload: executeQuery(Queries.fetchNonRoutineLogPages(), logPageVariable, getState(), 'NonRoutine')
        })
    }
}
export const fetchNonRoutineImages = (employeeID, nonRoutineID) => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_NONROUTINE_IMAGES,
            payload: executeQuery(Queries.fetchNonRoutineImages(employeeID, nonRoutineID), null, getState(), 'NonRoutine')
        })
    }
}
export const updateSubtaskSortOrder = (sortOrderObj, tailNumber, nonRoutineID, userId, jobRole, acControlID, station, isActionable, isVisitSchedule) => {
    const variable = {
        "viewNonRoutine": {
            "tailNumber": tailNumber,
            "nonRoutineID": nonRoutineID,
            "employeeId": userId,
            "jobRole": jobRole,
            "refDataReqd": 1,
            "clearRequestRotables": null,
            "isVisitSchedule": isVisitSchedule === "true" ? true : false,
            "station": station
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_SUBTASK_SORT_ORDER,
            payload: trackPromise(executeQuery(Queries.updateSubtaskSortOrder(), sortOrderObj, getState(), 'NonRoutine'))
        }).then(resp => {
            if (resp.value.updateSubtaskSortOrder?.response?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Sort order for sub tasks has been updated."
                });
                dispatch({
                    type: FETCH_VISIT_INFO,
                    payload: executeQuery(VisitQueries.fetchVisitInfo(acControlID, station), null, null, 'Visit')
                }).then(visitInfo => {
                    dispatch({
                        type: FETCH_NONROUTINE_DETAILS,
                        payload: executeQuery(Queries.fetchNonRoutineInfo(), variable, getState(), 'NonRoutine')
                    }).then(resp => {
                        if (resp !== null && resp.viewNonRoutines !== null) {
                            if (isActionable) {
                                dispatch({
                                    type: SET_NONROUTINE_ACTIONS_VARIABLE,
                                    payload: setNonRoutinesActionVariable(resp.value.viewNonRoutines, userId, visitInfo.value.viewVisit)
                                })
                            }
                            else {
                                dispatch({
                                    type: SET_LAST_REFRESHED,
                                    payload: Date.now()
                                })
                            }
                        }
                    })
                })
                    .catch(error => {
                        dispatch({
                            type: SET_GENERIC_ERROR,
                            payload: error?.message
                        })
                    })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const resetViewNRErrorMessage = () => {
    return dispatch => {
        dispatch({
            type: RESET_VIEW_NR_ERROR_MESSAGE
        })
    }
}

export const handleNonRoutineActions = (nonRoutineInfo, actionType) => {
    const payload = {...nonRoutineInfo};   
    if(payload.hasOwnProperty('groupName')) delete payload.groupName;    
    payload.workbinWorkloadData = payload.workBins.map(wbins =>({
        "workBinId": wbins.value,           
    }));
    delete payload.workBins;
    if(["INSPECT", "INSPECTREJECT", "REOPEN", "VOID"].includes(actionType)) delete payload.assignCrewData;
    const variable = {
        nonRoutineInputType: payload,
        nRInputType: { nonRoutineType: actionType }
    }
    return (dispatch, getState) => {
        dispatch({
            type: HANDLE_NONROUTINE_ACTIONS,
            payload: trackPromise(executeQuery(Queries.subTaskSignOff(), variable, getState(), 'NonRoutine'))
        })
            .then(resp => {
                if (resp.value.nonRoutineOtherActions?.isSuccess) {
                    dispatch({
                        type: SET_NOTIFICATION_MESSAGE,
                        payload: (actionType === "VOID" || actionType === "EVALVOID") ? "Non-Routine has been voided successfully" :
                            actionType === "INSPECT" ? "Non-Routine has been inspected successfully" :
                                actionType === "INSPECTREJECT" ? "Non-Routine has been rejected successfully" :
                                    actionType === "EVALUATE" ? "Non-Routine has been evaluated successfully" :
                                    actionType === "REOPEN" ? "Non-Routine has been reopened successfully" :
                                        ""
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }

}

export const handleManageNonRoutineActions = (nonRoutineInfo, mxMasterId, userId) => {
    const payload = {...nonRoutineInfo};
    let variable = {        
        "saveNRDetailsEhanced": {
            "baseInductionDate": payload.visits[0].baseInductionDate,
            "nonRoutineID": payload.nonRoutine.nonRoutineID,
            "notes": payload.mxNotes,
            "modifiedBy": userId, 
            "actionTypeId":payload.actionTypeId,
            "employeeId": payload.employeeId,
            "firstName": payload.firstName,
            "lastName": payload.lastName,  
            "workStop": false,
            "mileStoneId": payload.mxGroupId,
            "mxMasterId": mxMasterId,
            "tailNumber": payload.visits[0].tailNumber,
            "flightNumber": payload.visits[0].inFlightNumber,
            "station": payload.visits[0].station,
            "scheduledArrivalDate": payload.visits[0].schedArrDate,
            "date": new Date(),
            "workBins": payload.workBins.map(wbins =>({
                "workBinId": wbins.value,
                "mxMasterId": mxMasterId,
                "mxType":"NR"
            })),
            "assignCrewData": payload.assignCrewData?.map(acd =>({
                  "crewConfigId": acd.crewConfigId,
                  "crewName": acd.crewName,
                  "assign": true,
                  "mxMasterId": mxMasterId
            })),
            "taskCardEstimatedHours": payload.estimatedHours.map(eh => ({
                "estimatedHourID": eh.estimatedHourID,
                "skillID": eh.skillID,
                "manHours": eh.estHours,
                "resourcesRequired": eh.resourcesRequired
            })),
            "taskCardDependencies": payload.taskCardDependencies.map(tcd => ({
                "dependencyID": tcd.dependencyID,
                "dependencyMasterID": tcd.dependencyMasterID,
                "dependencyCardNumber": tcd.dependencyCardNumber,
                "dependencyDescription": tcd.dependencyDescription,
                "dependencyType": tcd.dependencyType
            })),
            "zoneTypeId": payload.zones[0].zoneTypeID,
            "aTA": payload.nonRoutine.aTA  
        }
    }
      
    return (dispatch, getState) => {
        dispatch({
            type: HANDLE_NONROUTINE_ACTIONS,
            payload: trackPromise(trackPromise(executeQuery(Queries.saveNonRoutineDetailsEnhanced(), variable, getState(), 'TemplateManagement')))
        })
            .then(resp => {
                if (resp.value.saveNonRoutineDetails?.isSuccess) {
                // if (resp.value.nonRoutineOtherActions?.isSuccess) {
                    dispatch({
                        type: SET_NOTIFICATION_MESSAGE,
                        payload: "Non-Routine saved successfully"
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    } 
}

export const isApplyFiltersClickAction = (val) => {
    return dispatch => {
        dispatch({
            type: IS_APPLY_FILTERS_CLICK_ACTION,
            payload: val
        })
    }
}

export const resetNRFilters = () => {
    return dispatch => {
        dispatch({
            type: RESET_NR_FILTERS
        })
    }
}
export const getAttachedTaskCard = (nonRoutineNumber, lastModifiedDate) => {
    const variable = {
        "attachedNR": {
            "logPageNumber": nonRoutineNumber,
            "createdLPDate": lastModifiedDate
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: GET_ATTACHED_TASKCARD,
            payload: trackPromise(executeQuery(Queries.getAttachedTaskCard(), variable, getState(), 'NonRoutine'))

        }).catch(error => {
            dispatch({
                type: SET_GENERIC_ERROR,
                payload: error?.message
            })
        });
    }
}
export const resetAttachedTaskCard = () => {
    return dispatch => {
        dispatch({
            type: RESET_ATTACHED_TASKCARD
        })
    }
}
export const fetchGroupsForNonroutine = (checkType, station, visitId) => {
    const variable = {
        "groups": {
            "station": station,
            "checkType": checkType,
            "visitId": visitId

        }

    }
    return (dispatch, getState) => {
        dispatch({
            type: GET_GROUPS_FOR_NONROUTINE,
            payload: executeQuery(Queries.fetchGroupsForNonroutine(), variable, getState(), 'VisitSchedule')
        })
    }
}
export const fetchLinkedGroupForLinkedNonroutine = (parentNonRoutine, visitId) => {
    const variable = {
        "request": {
            "visitId": visitId,
            "mxItem": parentNonRoutine.parentType === "ZonalCard" ? parentNonRoutine.dash1: parentNonRoutine.mxItem,
            "mxType": parentNonRoutine.parentType === "TaskCard" ? "D1" : parentNonRoutine.parentType === "ZonalCard" ? "ZC" :"NR",
            "dash8": parentNonRoutine.dash8,
            "workControlNumber": parentNonRoutine.workControlNumber,
            "serialNumber": parentNonRoutine.serialNumber

        }
    }
    // return await executeQuery(Queries.fetchLinkedGroupForLinkedNonroutine(), variable, { profileStore: { userProfile: null } }, 'VisitSchedule')
    //     .then(response => {
    //         return response
    //     })
    //     .catch(ex => {
    //         return null
    //     });
    //return result.data
    return (dispatch, getState) => {
        dispatch({
            type: GET_LINKED_GROUP_FOR_LINKED_NONROUTINE,
            payload: executeQuery(Queries.fetchLinkedGroupForLinkedNonroutine(), variable, getState(), 'VisitSchedule')
        })
    }
}

export const fetchLinkedWorkBinForLinkedNonroutine = async (parentNonRoutine, visitId, mxmasterid) => {
    const variable = {
        "request": {
            "mxMasterId": mxmasterid,
            "mxType": parentNonRoutine.parentType === "TaskCard" ? "D1" : parentNonRoutine.parentType === "ZonalCard" ? "ZC" :"NR",
            "visitId": visitId
        }
    }
    return await executeQuery(Queries.fetchLinkedWorkBinForLinkedNonroutine(), variable, { profileStore: { userProfile: null } }, 'VisitSchedule')
        .then(response => {
            return response
        })
        .catch(ex => {
            return null
        });
}

export const getAtaCodes = (fleet) => {
    const variable = {
        "ata": {
            "fleet": fleet,
            "dateCreated": moment().format()
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: GET_ATA_CODES,
            payload: executeQuery(Queries.getAtaCodes(), variable, getState(), 'NonRoutine')
        })
    }
}
export const addRotableParts = (profile, nonRoutine, token="") => {
    
    return (dispatch, getState) => {       
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "createNRLinkedLp": {
                "aircraftNumber": visitInfo.aircraftNumber,
                 "lTerm": profile.lTerm, //"IAHFT2VH",
                 "token": token, //"VTM3MDI2NCxWZWlsc2lkZVZlaWxzaWRlMTk=",
                 "aTA": nonRoutine.nonroutine.aTA,
                 "employeeId": profile.userId,//"N079848",
                 "nonRoutineID": nonRoutine.nonroutine.nonRoutineID,
                 "discrepancy":  nonRoutine.nonroutine.discrepency   
              }        
        }
      dispatch({
            type: ADD_ROTABLE_PARTS,
            payload: trackPromise(executeQuery(Queries.createNRLinkedLogPages(), variable, getState(), 'NonRoutine'))
        })
    }
}

export const resetAddRotableParts = () => {
    return dispatch => {
        dispatch({
            type: RESET_ADD_ROTABLE_PARTS
        })
    }
}

export const isOpenELinkClick = (val) => {
    return dispatch => {
        dispatch({
            type: IS_OPENE_LINK_CLICK_ACTION,
            payload: val
        })
    }
}

export const fetchActionTypesForNotes = () => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_ACTIONTYPES_FOR_NOTES,
            payload: executeQuery(Queries.fetchActionTypesForNotes(), null, getState(), 'NonRoutine')
        })
    }
}

export const getDash1NotesForNR = (parentNonRoutine, nonRoutineID) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "getd1Notes": {
                "tailNumber":  visitInfo.aircraftNumber,
                "station": visitInfo.station,
                "flightNumber": "0000",
                "dash1": "",
                "dash8": "",
                "dash9": "",
                "scheduledArrivalDate": visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,    
                "serialNumber": parentNonRoutine.serialNumber,
                "workControlNumber": parentNonRoutine.workControlNumber,
                "baseInductionDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "nonRoutineID" : nonRoutineID,
                "returnRefData" : "1"
              } 
        }
        dispatch({
            type: GET_DASH1_NOTES_FOR_NR,
            payload: trackPromise(executeQuery(Queries.getDash1NotesForNR(), variable, getState(), 'RoutineRegister'))
        });
    }
}

export const fetchAssignedCrew = (userId, jobRole, visitInfo) => {
    return(dispatch, getState) => {
        // const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "assignedCrew": {
                "baseStation": ["IAH","IHC"].includes(visitInfo?.station) ? "IHC" : visitInfo?.station,
                "jobRole": jobRole,
                "tailNumber": visitInfo?.aircraftNumber,
                "flightNumber": "0000",
                "scheduledArrivalDate": visitInfo?.actualInductionDate !== "" && visitInfo?.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "station":  ["IAH","IHC"].includes(visitInfo?.station) ? "IAH" : visitInfo?.station,
                "employeeID": userId,
                "date": new Date()
              }
        }
        dispatch({
            type: GET_ASSIGNED_CREW,
            payload: executeQuery(Queries.fetchAssignedCrew(), variable, getState(), 'CrewUpgrade')
        })
    }
}

export const  saveNrPdfImages = (pdfContent, nonRoutine) => (dispatch, getState)=>{
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const profile = getState() && getState().profileStore.userProfile;
        const variable = {
                "saveNrPdf": {
                  pdfContent,
                  "nonRoutine": nonRoutine?.nonRoutineID,
                  "employeeId": profile?.userId,
                  "visitId": visitInfo?.visitId,
                  "station": visitInfo?.station,
                  "tailNumber": visitInfo?.aircraftNumber,
                  "scheduleArrivalDate": visitInfo?.actualInductionDate || visitInfo?.plannedInductionDate
                }
        }
        return dispatch({
            type: ADD_PDFIMAGE_NONROUTINES,
            payload: trackPromise(executeQuery(Queries.saveNrPdfImages(), variable, getState(), 'NonRoutine'))
        }).then(resp => {
            if (resp.value.saveNrPdf?.nrPdfContents) {
              //  dispatch(getPdfs(resp.value.saveNrPdf?.nrPdfContents))
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "A new pdf file has been added."
                });
                return resp.value.saveNrPdf.nrPdfContents;
                
            }
        })
        .catch(error => {
            dispatch({
                type: SET_GENERIC_ERROR,
                payload: error?.message
            })
        });
}

export const getPdfs=(params)=>(dispatch, getState) =>{
    const getPdfParams = {
        "getPdf": {
            "contentIds": params.map(i=> i.contentId)
        }
    }
    return dispatch({
        type: GET_PDFIMAGE_NRS,
        payload: trackPromise(executeQuery(Queries.getPdf(), getPdfParams, getState(), 'NonRoutine'))
    }).then(resp =>{
        return resp?.value.getPdf?.pdfImageUrls[0] || ''
    });
}

export const deleteNrPdf=(params)=>(dispatch, getState) =>{
    const variable = {
        "deletePdfRequest": {...params}        
    }
    return dispatch({
        type: DELETE_NR_PDF,
        payload: trackPromise(executeQuery(Queries.deleteNrPdf(), variable, getState(), 'NonRoutine'))
    }).then(resp =>{
        if (resp.value.deleteNrPdfReference?.isSuccess) {
            dispatch({
                type: `${DELETE_NR_PDF}_FULFILLED`,
                payload: params.contentId
            })
              dispatch({
                  type: SET_NOTIFICATION_MESSAGE,
                  payload: "A pdf file has been deleted."
              });

              
          }
      })
      .catch(error => {
          dispatch({
              type: SET_GENERIC_ERROR,
              payload: error?.message
          })
      });
}