import React, { useEffect, useState } from 'react';

const getViewBox = icon => {
  switch (icon) {
    case 'united-logo':
      return '0 0 160 29';
    case 'sort-icon':
      return '0 0 24 17';
    case 'filters-icon':
      return '0 0 24 24';
    case 'seat-icon':
      return '0 0 24 24';
    case 'location-icon':
      return '0 0 24 24';
    case 'chevron-right-icon':
      return '0 0 10 16';
    case 'calendar-icon':
      return '0 0 40 40';
    case 'info-icon':
    case 'info-transparent-icon':
    case 'close-icon':
      return '0 0 24 24';
    case 'calendar-only-icon':
      return '0 0 20 20';
    case 'clock-icon':
      return '0 0 24 24';
    case 'alert-icon':
      return '0 0 16 14';
    case 'refresh-icon':
      return '0 0 29 29';
    case 'contact-icon':
      return '0 0 24 24';
    case 'insp-required-icon':
      return '0 0 24 16';
    case 'insp-ready-icon':
      return '0 0 20 20';
    case 'tech-required-icon':
      return '0 0 20 16';
    case 'search-icon':
      return '0 0 22 24';
    case 'check-complete-icon':
      return '0 0 16 16';
    case 'success-icon':
      return '0 0 24 24';
    case 'failure-icon':
      return '0 0 24 24';
    case 'indicator-alert-icon':
      return '0 0 26 22';
    case 'thunbsup-icon':
      return '0 0 24 24';
    case 'thunbsdown-icon':
      return '0 0 24 24';
    case 'plane-icon':
      return '0 0 24 24';
    case 'star-icon':
    case 'workload-pending-icon':
      return '0 0 16 16';
    case 'ron-icon':
      return '0 0 14 16';
    case 'etops-icon':
      return '0 0 16 17';
    case 'broken-mrd-icon':
    case 'green-mrd-icon':
      return '0 0 50 14';
    case 'approved-mfd-icon':
    case 'arranged-mfd-icon':
      return '0 0 177 109';
    case 'green-mfd-icon':
      return '0 0 65 26';
    case 'workload-completed-icon':
      return '0 0 26 26';
    case 'arrow-down-icon':
      return '0 0 18 24';
    case 'etr-ac-icon':
    case 'inducted-ac-icon':
    case 'preinducted-ac-icon':
      return '0 0 40 24';
    case 'shift-day-icon':
    case 'shift-swing-icon':
    case 'shift-grave-icon':
      return '0 0 24 24';
    case 'overide-icon':
      return '0 0 42 48';
    case 'paper-icon':
      return '0 0 16 16';
    case 'live-tv-icon':
      return '0 0 24 20';
    case 'wifi-icon':
      return '0 0 20 24';
    case 'plane-in-icon':
      return '0 0 37 25';
    case 'plane-out-icon':
      return '0 0 35 18';
    case 'update-icon':
      return '0 0 30 30';
    case 'taskcard-details-icon':
      return '0 0 16 18';
    case 'feedback-icon':
      return '0 0 23 27';
    case 'notes-icon':
      return '0 0 23 27';
    case 'delete-icon':
      return '0 0 18 20';
    case 'move-icon':
      return '0 0 22 22';
    case 'cancel-icon':
      return '0 0 20 20';
    case 'users-group-icon':
      return '0 0 16 14';
    case 'parts-change-icon':
      return '0 0 24 25';
    case 'stop-icon':
      return ' 0 0 20 20';
    case 'orange-warning-icon':
      return '0 0 16 16';
    case 'planning-call-icon':
      return '0 0 20 20';
    case 'tcm-icon':
      return '0 0 31.5 45.25';
    case 'pdf-icon':
      return '0 0 24 24';
    case 'cross-red-icon':
      return '0 0 16 16';
    case 'risk-icon':
      return '0 0 30 18';
    default:
      return null;
  }
};

const getWidth = icon => {
  switch (icon) {
    case 'united-logo':
      return '160px';
    case 'sort-icon':
      return '24px';
    case 'filters-icon':
    case 'close-icon':
      return '24px';
    case 'seat-icon':
      return '16px';
    case 'location-icon':
      return '16px';
    case 'chevron-right-icon':
      return '10px';
    case 'calendar-icon':
      return '40px';
    case 'info-icon':
      return '16px';
    case 'calendar-only-icon':
      return '20px';
    case 'clock-icon':
      return '20px';
    case 'alert-icon':
      return '16px';
    case 'refresh-icon':
      return '27px';
    case 'contact-icon':
      return '24px';
    case 'insp-required-icon':
      return '24px';
    case 'insp-ready-icon':
      return '20px';
    case 'tech-required-icon':
      return '20px';
    case 'search-icon':
      return '22px';
    case 'check-complete-icon':
      return '16px';
    case 'success-icon':
      return '24px';
    case 'failure-icon':
      return '24px';
    case 'indicator-alert-icon':
      return '26px';
    case 'thumbsup-icon':
      return '24px';
    case 'thumbsdown-icon':
      return '24px';
    case 'plane-icon':
      return '16px';
    case 'star-icon':
    case 'workload-pending-icon':
      return '16px';
    case 'ron-icon':
      return '14px';
    case 'etops-icon':
      return '16px';
    case 'broken-mrd-icon':
    case 'green-mrd-icon':
      return '50px';
    case 'approved-mfd-icon':
    case 'arranged-mfd-icon':
      return '177px';
    case 'green-mfd-icon':
      return '65px';
    case 'workload-completed-icon':
      return '26px';
    case 'arrow-down-icon':
      return '18px';
    case 'etr-ac-icon':
    case 'inducted-ac-icon':
    case 'preinducted-ac-icon':
      return '40px';
    case 'shift-day-icon':
    case 'shift-swing-icon':
    case 'shift-grave-icon':
      return '24px';
    case 'overide-icon':
      return '48px';
    case 'paper-icon':
      return '16px';
    case 'lp-icon':
      return '16px';
    case 'exteranl-link-icon':
      return '16px';
    case 'live-tv-icon':
      return '16px';
    case 'wifi-icon':
      return '16px';
    case 'plane-in-icon':
      return '16px';
    case 'plane-out-icon':
      return '16px';
    case 'update-icon':
      return '30px';
    case 'taskcard-details-icon':
      return '16px';
    case 'notes-icon':
      return '27px';
    case 'delete-icon':
      return '18px';
    case 'move-icon':
      return '22px';
    case 'cancel-icon':
      return '20px';
    case 'users-group-icon':
      return '16px';
    case 'stop-icon':
      return '20px';
    case 'orange-warning-icon':
      return '16px';
    case 'planning-call-icon':
      return '20px';
    case 'tcm-icon':
      return '25px';
    case 'pdf-icon':
      return '24px';
    case 'cross-red-icon':
      return '16px';
    case 'risk-icon':
      return '30px';
    default:
      return null;
  }
}

const Icon = props => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    // fetchIcon();
    (async () => {
      const { default: Component } = await import(
        `../../../assets/images/components/${props.variant}.js`
      );
      setIcon(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={getViewBox(props.variant)}
          width={getWidth(props.variant)}
          {...props}
        >
          <Component {...props} />
        </svg>
      );
    })();

    return () => setIcon(null);
  }, [props]);

  return icon;
};

export default Icon;