import { 
    FETCH_BASELINE_INDUCTION, FETCH_BASELINE_STATS, UPDATE_BASELINE_DATE, FETCH_BASELINE_REPORT_DETAILS, BASELINE_FILTERS, UPDATE_BASELINE_DETAILS
 } from '../actions/actionTypes';

const initialState = {
    baseLineStats: {
        approvedAddedCount:0,
        totalAddedCount:0,
        approvedDefferedCount:0,
        totalDefferedCount:0,
        approvedRemovedCount:0,
        totalRemovedCount: 0        
    },
    baseLineReportDetails: null,
    baselineFilters: { desc: "", workItemStatus: [], workItemType: [], isFilter: false },
    isLoadingBaselineStats: false,    
    inductionData:[]
}

const baselineReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${FETCH_BASELINE_INDUCTION}`: {
           return {
                ...state,
                inductionData: action.payload
            }
        }
        case `${FETCH_BASELINE_STATS}_PENDING`: {
            return {
                ...state,
                isLoadingBaselineStats: true,
                baseLineStats: initialState.baseLineStats        
            }
        }
        case `${FETCH_BASELINE_STATS}_FULFILLED`: {
            return {
                ...state,
                isLoadingBaselineStats: false,
                baseLineStats: action.payload.getBaseLineStats
            }
        }
        case `${UPDATE_BASELINE_DATE}`: {
            return{
                ...state
            }
        }  
        case `${FETCH_BASELINE_REPORT_DETAILS}_PENDING`: {
            return{
                ...state,
                isLoadingBaselineData: true,
                baseLineReportDetails: initialState.baseLineReportDetails
            }
        }
        case `${FETCH_BASELINE_REPORT_DETAILS}_FULFILLED`: {
            return{
                ...state,
                isLoadingBaselineData: false,
                baseLineReportDetails: action.payload.getBaseLineDetails
            }
        }
        case `${BASELINE_FILTERS}`:{
            return {
                ...state,
                baselineFilters: action.payload
            }
        }
        case `${UPDATE_BASELINE_DETAILS}`: {
            return{
                ...state
            }
        }
        default: return state;
    }
}

export default baselineReducer;