import {
    SAVE_CREW_TECH_COMMENT, SHR_FETCH_CREW_WORKLOAD_LEAD_MODULE,
    FETCH_SHR_STATS, FETCH_SHIFTS_AND_CREWS, SET_SELECTED_SHIFT, SHR_GET_CREW_COMMENT,
    SAVE_SHIFT_SUMMAR_NOTES_LOCALLY, SAVE_TEST_HOP_DATE, GET_SHIFT_SUMMARY, SAVE_TECH_CREW_COMMENTS_LOCAL, FINALIZE_REPORT
} from '../actions/actionTypes';
import moment from 'moment';

const initialState = {
    crewTechComments: {},
    isLoading: false,
    isLoadingSHRStats: true,
    shrStats: null,
    crewDetails: {},
    shiftsAndCrews: [],
    selectedShift: null,
    shiftCrews: null,
    isLoadingShiftsAndCrews: false,
    crewComments: {},
    notesMap: {},
    tHopDate: null,
    shiftSummaryNotes: []
}

const shiftHandoverReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${SHR_GET_CREW_COMMENT}_CUSTOM_FULFILLED`: {
            let updatedState = { ...state, crewComments: { ...state.crewComments } };
            if (action?.payload?.value?.getCrewComments?.crewComment) {
                updatedState.crewComments[action.payload.crewConfigId] = action.payload.value;
            }
            return updatedState;
        }
        case `${SAVE_TEST_HOP_DATE}_CUSTOM_FULFILLED`: {
            let updatedState = { ...state };
            //if (action?.payload) {
                updatedState.tHopDate = action.payload;
            //}
            return updatedState;
        }
        case `${SHR_FETCH_CREW_WORKLOAD_LEAD_MODULE}_CUSTOM_FULFILLED`: {
            let updatedState = { ...state, crewDetails: { ...state.crewDetails } };
            if (action.payload?.value?.getCrewWorkloadForLeadModule?.crewWorkloadForSHR) {
                updatedState.crewDetails[action.payload.crewConfigId] = {
                    crewWorkloadForSHR: action.payload.value.getCrewWorkloadForLeadModule?.crewWorkloadForSHR,
                    techCrewWorkloadForSHRList: action.payload.value.getCrewWorkloadForLeadModule?.techCrewWorkloadForSHRList
                }
            }
            return updatedState;
        }

        case `${FETCH_SHR_STATS}_PENDING`: {
            return {
                ...state,
                isLoadingSHRStats: true,
                shrStats: null,
                tHopDate: null
            }
        }
        case `${FETCH_SHR_STATS}_FULFILLED`: {
            return {
                ...state,
                isLoadingSHRStats: false,
                shrStats: action.payload?.getHeaderDetails,
                tHopDate: action.payload?.getHeaderDetails?.testHopDateAttributes?.testHopDate,
                lastVisitSummary: action.payload?.getHeaderDetails?.lastestShiftVisitSumarryAttributes?.summary
            }
        }
        case `${GET_SHIFT_SUMMARY}_PENDING`: {
            return {
                ...state,
                shiftSummaryNotes: [],
                isShiftSummaryLoading: true
            }
        }
        case `${GET_SHIFT_SUMMARY}_FULFILLED`: {
            const summaryList = action?.payload?.shiftVisitSummary?.allShiftVisitSummaryAttributesList;
            return {
                ...state,
                shiftSummaryNotes: summaryList,
                isShiftSummaryLoading: false,
                lastVisitSummary: summaryList ? summaryList[0].summary : ''
            }
        }
        case `${FETCH_SHIFTS_AND_CREWS}_PENDING`: {
            return {
                ...state,
                shiftsAndCrews: [],
                selectedShift: null,
                shiftCrews: null,
                shrShifts: null,
                isLoadingShiftsAndCrews: true
            }
        }
        case `${FETCH_SHIFTS_AND_CREWS}_FULFILLED`: {
            const shiftsl = action.payload?.shiftAndCrewDetails?.shifts?.map(shift => {
                return { shiftNumber: shift.shiftNumber, shiftType: shift.shiftType, shiftDate: shift.shiftStartDate }
            });
            const selectedShift = action.payload?.shiftAndCrewDetails?.shifts?.find(shift => shift.isCurrentShift)
            return {
                ...state,
                shiftsAndCrews: action.payload?.shiftAndCrewDetails?.shifts,
                selectedShift: selectedShift,
                shiftCrews: selectedShift?.shiftCrews,
                shrShifts: shiftsl,
                isLoadingShiftsAndCrews: false
            }
        }
        case `${SET_SELECTED_SHIFT}`: {
            let selectedShift = action.payload?.shift;
            if (action.payload?.data?.getSHRSnappedDataForShift?.statusSummaryList?.length > 0) {
                const shiftInfo = action.payload?.data?.getSHRSnappedDataForShift?.statusSummaryList.find(cid => selectedShift.shiftType === cid.shiftType);
                const contentId = shiftInfo?.contentId;
                const isSnappingInProgress = shiftInfo?.isSnappingInProgress;
                selectedShift = { ...selectedShift, contendId: contentId, isSnappingInProgress: isSnappingInProgress, snapDate: shiftInfo?.snapDate, isWithin30MinInterval: shiftInfo?.isWithin30MinInterval };
            }
            return {
                ...state,
                selectedShift: selectedShift,
                shiftCrews: selectedShift?.shiftCrews,
                crewDetails: {}
            }
        }
        case `${FINALIZE_REPORT}`: {
            let selectedShift = { ...state.selectedShift };
            if (selectedShift) {
                selectedShift = { ...selectedShift, isSnappingInProgress: true }
            }
            return {
                ...state,
                selectedShift: selectedShift
            }
        }
        case `${SAVE_TECH_CREW_COMMENTS_LOCAL}`: {
            let updatedState = { ...state, crewDetails: { ...state.crewDetails } };
            if (action.payload?.crewConfigId && updatedState.crewDetails[action.payload.crewConfigId]) {
                updatedState.crewDetails[action.payload.crewConfigId].techCrewWorkloadForSHRList.forEach(member => {
                    let commentObj = action.payload.techComments.find(val => val.techId === member.techId);
                    if (commentObj) {
                        member.crewTechComment = commentObj.comment;
                    }
                });
            }
            return updatedState;
        }
        case `${SAVE_SHIFT_SUMMAR_NOTES_LOCALLY}`: {
            const data = action.noteData;
            const masterId = data.masterId;
            const mxType = data.mxType;
            //This count is the actual count recieved from the api.
            const originalCount = data.originalCount;
            let clonedNotesMap = { ...state.notesMap };
            if (!clonedNotesMap[masterId]) {
                clonedNotesMap[masterId] = {
                    masterId: masterId,
                    note: `${data.note}${data.workbin ? ` - ${mxType === "NR" ? 'Nonroutine' : "Task Card"} has been added to workbin ${data.workbin.workBinName}` : ''}`,
                    noteCount: originalCount ? originalCount + 1 : 1,
                    workStop: data.workStop,
                    workStopActionTypeAbbreviated: data.actionType
                }
            } else {
                clonedNotesMap[masterId] = {
                    masterId: masterId,
                    note: `${data.note}${data.workbin ? ` - ${mxType === "NR" ? 'Nonroutine' : "Task Card"} has been added to workbin ${data.workbin.workBinName}` : ''}`,
                    noteCount: clonedNotesMap[masterId].noteCount + 1,
                    workStop: data.workStop,
                    workStopActionTypeAbbreviated: data.actionType
                }
            }
            return {
                ...state,
                notesMap: clonedNotesMap
            }
        }
        default: return state;
    }
}


export default shiftHandoverReportReducer;