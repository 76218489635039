import {
  FETCH_WORK_BINS, FETCH_WORK_BINS_WORKLOAD, CREATE_WORK_BIN, SET_ACTIVE_WORK_BIN, DELETE_WORK_BIN,
  UPDATE_SELECTED_WORKBIN_WORKLOAD, UPDATE_SELECTED_WORKBINS, ADD_WORKLOAD_TO_WORKBIN, GET_WORKLOAD_WORKBIN,
  SET_SELECTED_WORKLOAD_FOR_WORKBINS, GET_WORKBIN_WORKLOAD_COUNT, RESET_WORKBINS, SET_WORKBIN_WIDGET_FLOW_FLAG,
  GET_TEMPLATE_WORKBIN_WORKLOAD, MOVE_WORKLOAD_TO_WORKBIN,
  DELETE_WORKLOAD_FROM_WORKBIN, UPDATE_SELECT_ALL_WORKBINS, MOVE_WORKLOAD_TO_MULTIPLE_WORKBIN,DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN,
  FETCH_TEMPLATE_DETAILS
  } from '../actions/actionTypes';
  

  const initialState = {
    workBins: null,
    isLoadingWorkBins: false,
    workBinsworkload: [],
    isLoadingWorkBinsWorkload: true,
    isLoadingWorkloadWorkbin: true,
    isCreatingWorkBin: false,
    createWorkBinErrorMessage: null,
    activeWorkBin: null,
    activeWorkBinName: null,
    isDeletingWorkBin: false,
    isAddingWorkBin: false,
    addWorkBinErrorMessage: null,
    deleteWorkBinErrorMessage: null,
    workBinWorkload: null,
    workLoadworkbin: null,
    selectedWorkBinWorkload: [],
    selectedWorkloadForBins: [],
    selectedWorkBins: [],
    isAddWorkloadSuccess: false,
    workBinWidgetFlow: false
  }    
 
  const workBinsReducer = (state = initialState, action) => {
      switch (action.type) {
        case `${FETCH_TEMPLATE_DETAILS}_FULFILLED`:
          return{
            ...state,
            // workBins: null  --> commented as it is disabling the wbins dropdown in templates
          }
          case `${FETCH_WORK_BINS}_PENDING`: {
              return {
                  ...state,
                  isLoadingWorkBins: true,
                  workBins: null,
                  activeWorkBin: state.activeWorkBin ? state.activeWorkBin : null,
                activeWorkBinName: state.activeWorkBinName ? state.activeWorkBinName : null,
                isAddWorkloadSuccess: false
              }
          }
          case `${FETCH_WORK_BINS}_FULFILLED`: {
              const resp = action.payload;
              return {
                  ...state,
                  isLoadingWorkBins: false,
                  workBins: action.payload?.getAllWorkBins?.workBinsList
              }
          }
          case `${RESET_WORKBINS}`: {
            return {
              ...state,
              workBins: null,
              isAddWorkloadSuccess: false,
              activeWorkBin: null,
              activeWorkBinName: null,
            }
          }
          case `${FETCH_WORK_BINS_WORKLOAD}_PENDING`: {
              return {
                  ...state,
                  isLoadingWorkBinsWorkload: true,
                  workBinsworkload: null
              }
          }
          case `${FETCH_WORK_BINS_WORKLOAD}_FULFILLED`: {
              return {
                  ...state,
                  isLoadingWorkBinsWorkload: false,
                  workBinsworkload: action.payload
              }
          }
          case `${CREATE_WORK_BIN}_PENDING`: {
              return {
                  ...state,
                  isCreatingWorkBin: true,
                  createWorkBinErrorMessage: null
              }
          }
          case `${CREATE_WORK_BIN}_FULFILLED`: {
              return {
                  ...state,
                  isCreatingWorkBin: false,
                  createWorkBinErrorMessage: null
              }
          }
          case `${SET_ACTIVE_WORK_BIN}`: {
            return {
              ...state,
              activeWorkBin: action.payload,
              activeWorkBinName: state.workBins?.find(workBin => workBin.workBinId === action.payload)?.workBinName,
              selectedWorkBinWorkload: []
            }
          }
          case `${DELETE_WORK_BIN}_PENDING`: {
            return {
                ...state,
                isDeletingWorkBin: true,
                deleteWorkBinErrorMessage: null
            }
        }
        case `${DELETE_WORK_BIN}_FULFILLED`: {
            return {
                ...state,
                isDeletingWorkBin: false,
                deleteWorkBinErrorMessage: null
            }
        }
        case `${UPDATE_SELECTED_WORKBIN_WORKLOAD}`: {
          return {
            ...state,
            selectedWorkBinWorkload: action.payload.isChecked  ? [...state.selectedWorkBinWorkload, action.payload] : state.selectedWorkBinWorkload.filter(wl =>  wl.mxMasterId !== action.payload.mxMasterId)
          }
          
        }
        case `${UPDATE_SELECTED_WORKBINS}`: {          
          return {
            ...state,
            selectedWorkBins: action.payload.isChecked ? [...state.selectedWorkBins, action.payload] : state.selectedWorkBins.filter(wl => wl.workBinId !== action.payload.workBinId)
          }
        }
        case `${UPDATE_SELECT_ALL_WORKBINS}`: {          
          return {
            ...state,
            selectedWorkBins: action.payload
          }
        }
        case `${ADD_WORKLOAD_TO_WORKBIN}_PENDING`: {
          return {
            ...state,
            isAddingWorkBin: true,
            addWorkBinErrorMessage: null,
            isAddWorkloadSuccess: false
          }
        }
        case `${ADD_WORKLOAD_TO_WORKBIN}_FULFILLED`: {
          return {
            ...state,
            isAddingWorkBin: false,
            addWorkBinErrorMessage: null,
            selectedWorkBins: [],
            selectedWorkloadForBins: [],
            isAddWorkloadSuccess: action.payload.addWorkLoadToWorkBin?.isSuccess || action.payload.addWorkLoadToTemplateWorkBin?.isSuccess
          }
        }
        case `${GET_WORKLOAD_WORKBIN}_PENDING`: {
          return {
            ...state,
            isLoadingWorkloadWorkbin: true,
            workLoadworkbin: null
          }
        }
        case `${GET_WORKLOAD_WORKBIN}_FULFILLED`: {
          return {
            ...state,
            isLoadingWorkloadWorkbin: false,
            workLoadworkbin: action.payload?.getWorkloadWorkbin
          }
        }
        case `${SET_SELECTED_WORKLOAD_FOR_WORKBINS}`: {
          return {
            ...state,
            selectedWorkloadForBins: action.payload
          }
        }
        case `${GET_WORKBIN_WORKLOAD_COUNT}_PENDING`: {
          return {
            ...state,
            isLoadingWorkBinCounts: true,
            workBinCounts: null
          }
        }
        case `${GET_WORKBIN_WORKLOAD_COUNT}_FULFILLED`: {
          return {
            ...state,
            isLoadingWorkBinCounts: false,
            workBinCounts: action.payload?.getWorkBinsWorkLoadCount?.workLoadCountList || action.payload?.getTemplateWorkBinsWorkLoadCount.workLoadCountList
          }
        }
        case `${SET_WORKBIN_WIDGET_FLOW_FLAG}`: {
          return {
            ...state,
            workBinWidgetFlow: action.payload
          }
        }
        case `${GET_TEMPLATE_WORKBIN_WORKLOAD}_PENDING`: {
          return {
            ...state,
            isLoadingWorkloadWorkbin: true,
            workLoadworkbin: null
          }
        }
        case `${GET_TEMPLATE_WORKBIN_WORKLOAD}_FULFILLED`: {
          return {
            ...state,
            isLoadingWorkloadWorkbin: false,
            workLoadworkbin: action.payload?.getMxMasterDetailsForTemplateWorkBins
          }
        }
        case `${MOVE_WORKLOAD_TO_WORKBIN}_FULFILLED`: {
          return {
            ...state,
            selectedWorkBinWorkload: action.payload?.moveWorkLoadToWorkBin?.isSuccess || action.payload?.moveWorkLoadToTemplateWorkBin?.isSuccess ? [] : { ...state.selectedWorkBinWorkload }
          }
        }
        case `${DELETE_WORKLOAD_FROM_WORKBIN}_FULFILLED`: {
          return {
            ...state,
            selectedWorkBinWorkload: action.payload?.deleteWorkLoadFromWorkBin?.isSuccess || action.payload?.deleteWorkLoadFromTemplateWorkBin?.isSuccess ? [] : { ...state.selectedWorkBinWorkload }
          }
        }
        case `${MOVE_WORKLOAD_TO_MULTIPLE_WORKBIN}_FULFILLED`: {
          return {
            ...state,
            selectedWorkBins:[],
            selectedWorkBinWorkload: action.payload?.moveWorkLoadToWorkBin?.isSuccess || action.payload?.moveMultipleWorkLoadToWorkBinsInTemplate?.isSuccess ? [] : { ...state.selectedWorkBinWorkload }
          }
        }
        case `${DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN}_FULFILLED`: {
          return {
            ...state,
            selectedWorkBins:[],
            selectedWorkBinWorkload: action.payload?.deleteWorkLoadFromWorkBin?.isSuccess || action.payload?.deleteMultipleWorkLoadFromTemplateWorkbins?.isSuccess ? [] : { ...state.selectedWorkBinWorkload }
          }
        }
          default: return state;
      }
  }
  
  export default workBinsReducer;