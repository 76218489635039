import {
    FETCH_VISITS, CLICK_ADD_VISIT, CLICK_ACCORDION_FILTER_VISIT, CLICK_VIEW_HISTORY, CLOSE_ACCORDION_FILTER_VISIT,
    CLICK_CANCEL_VISIT, FETCH_TAIL_NUMBER_LOOKUP, FETCH_VISIT_INFO, CLICK_SAVE_VISIT, UPDATE_VISIT, UPDATE_VISIT_INFO_LOCALLY,
    SET_NOTIFICATION_MESSAGE, RESET_NOTIFICATION_MESSAGE, RESET_UPDATE_FLAGS, RTS_AIRCRAFT, RESET_RTS_FLAGS,
    UPDATE_ETR_INFO_LOCALLY, CLOSE_VISIT, SET_AIRCRAFT_INFO, RESET_VISIT_INFO, APPLY_VISIT_FILTERS, GET_BASE_DASHBOARD_COUNTS_ACTIVE,
    GET_BASE_DASHBOARD_COUNTS_FUTURE, SET_FAILED_NOTIFICATION_MESSAGE, UPDATE_VISITINFO_BASELINE, GET_SCHEDULEDWORK_COUNT, 
    GET_NONROUTINES_COUNT, GET_LOGPAGES_COUNT, TOGGLE_OPEN_CLOSE, GET_FLEET_CODES, FETCH_VISIT_INFO_LITE, SET_VISITS_DATA, SET_VIEWING_STATION
} from '../actions/actionTypes';
import moment from 'moment';

const initialState = {
    isLoading: true,
    isLoadingVisitInfo: true,
    isAddVisitModalOpen: false,
    isFilterMenuOpen: false,
    isViewHistoryButtonClick: false,
    visits: null,
    tailNumbers: null,
    isSaveVisitPending: false,
    isSaveVisitSuccess: false,
    isSaveVisitError: false,
    saveVisitErrorMessage: null,
    visitInfo: null,
    etrInfo: null,
    isUpdateVisitPending: null,
    isUpdateVisitSuccess: false,
    isUpdateVisitError: false,
    showNotification: false,
    notificationMessage: "",
    notificationType: "",
    isRTSAircraftPending: false,
    isRTSAircraftSuccess: false,
    rtsAircraftError: "",
    isCloseVisitPending: false,
    isCloseVisitSuccess: false,
    closeVisitError: "",
    aircraftInfo: null,
    filteredVisits: null,
    isLoadingCounts: true,
    isLoadingActiveCounts: true,
    isLoadingFutureCounts: true,
    baseDashboardCounts: null,
    isLoadingSWCount: false,
    scheduledWorkCounts: null,
    isLoadingLPCount: false,
    logPagesCounts: null,
    isLoadingNRCount: false,
    nonRoutinesCounts: null,
    isExecutionPhase: false,
    pendingWorkloadsForRTS: null,
    rtsWorkItems: null,
    rtsDash8s: null,
    rtsOverrideInfo: null,
    fleets: [
        "A319-100",
        "A320-200",
        "B737-700",
        "B737-800",
        "B737-900",
        "B737-MAX",
        "B757-200",
        "B757-300",
        "B767-300",
        "B767-400",
        "B777-200",
        "B777-300ER",
        "B787-800",
        "B787-900",
        "B787-10"
    ],
    activeVisit:'active',
}

const getVisitSession = (acControlID) => {    
    const sessionVisitInfo = sessionStorage.getItem(acControlID);    
    const visitInfo = JSON.parse(sessionVisitInfo); 
    return visitInfo?.viewVisitInfo;
}

const getAircraftInfo = (visitInfo) => {
    if(visitInfo === null) return null;
    return (({ aircraftNumber, plannedInductionDate, aircraftControlId, actualInductionDate, plannedETRDate, isArchived, showNonRoutines, station, enableVS, enableZonalCard, visitId, isTemplateApplied }) =>
    ({
        aircraftNumber: aircraftNumber,
        arrivalDate: actualInductionDate || plannedInductionDate,
        departureDate: plannedETRDate,
        acControlId: aircraftControlId,
        inFlightNumber: "0000",
        isPastOrFutureVisit: (actualInductionDate === null || isArchived),
        isArchived: isArchived,
        isFuture: actualInductionDate === null,
        showNonRoutines: showNonRoutines,
        station: station,
        enableVS: enableVS,
        enableZonalCard: enableZonalCard,
        visitId: visitId,
        isTemplateApplied: isTemplateApplied,
        actualInductionDate: actualInductionDate
    })
    )(visitInfo)
}

const visitsReducer = (state = initialState, action) => {
    const filterVisits = (visitType, filters, visits) => {
        let filteredVisits = visits;
        switch (visitType) {
            case "active":
                filteredVisits = filteredVisits.filter(visit => visit.actualInductionDate !== null && !visit.isArchived);
                break;
            case "future":
                filteredVisits = filteredVisits.filter(visit => visit.actualInductionDate === null && !visit.isArchived);
                break;
            case "history":
                filteredVisits = filteredVisits.filter(visit => visit.isArchived);
                break;
            default:
                filteredVisits = null;
                break;
        }
        if (filters && filteredVisits && filteredVisits.length > 0) {
            filteredVisits = filteredVisits.sort(function (a, b) {
                return filters.sortBy === "Tail number ascending" ? a.aircraftNumber - b.aircraftNumber :
                        filters.sortBy === "Tail number descending" ? b.aircraftNumber - a.aircraftNumber :
                        filters.sortBy === "Check type ascending" ? a.packageType.localeCompare(b.packageType) :
                        filters.sortBy === "Check type descending" ? b.packageType.localeCompare(a.packageType) :
                        filters.sortBy === "Planned Induction Date ascending" ? (moment(b.plannedInductionDate).isBefore(a.plannedInductionDate) ? 1 : -1) :
                        filters.sortBy === "Actual Induction Date ascending" ? (moment(b.actualInductionDate).isBefore(a.actualInductionDate) ? 1 : -1) :
                        filters.sortBy === "Actual Induction Date descending" ? (moment(a.actualInductionDate).isBefore(b.actualInductionDate) ? 1 : -1) :
                        filters.sortBy === "Rts Date ascending" ? (moment(b.actualETRDate).isBefore(a.actualETRDate) ? 1 : -1) :
                        filters.sortBy === "Rts Date descending" ? (moment(a.actualETRDate).isBefore(b.actualETRDate) ? 1 : -1) :
                        (moment(a.plannedInductionDate).isBefore(b.plannedInductionDate) ? 1 : -1);
            }).filter(ac =>
                (!filters.checkTypes || (filters.checkTypes && filters.checkTypes.length === 0) || (filters.checkTypes && filters.checkTypes.length > 0 && filters.checkTypes.includes(ac.packageType))) &&
                (!filters.inductionDate || (filters.inductionDate && (visitType !== "future" && moment(filters.inductionDate).format("YYYY/MM/DD") === moment(ac.actualInductionDate).format("YYYY/MM/DD")) || (visitType === "future" && moment(filters.inductionDate).format("YYYY/MM/DD") === moment(ac.plannedInductionDate).format("YYYY/MM/DD")))) &&
                (!filters.etrDate || (filters.etrDate && (visitType !== "history" && moment(filters.etrDate).format("YYYY/MM/DD") === moment(ac.plannedETRDate).format("YYYY/MM/DD"))) || (filters.etrDate && (visitType === "history" && moment(filters.etrDate).format("YYYY/MM/DD") === moment(ac.actualETRDate).format("YYYY/MM/DD")))/*|| (visitType === "history" && moment(filters.etrDate).format("YYYY/MM/DD") === moment(ac.actualETRDate).format("YYYY/MM/DD"))) 
                || ((filters.actualInductionStartDate && filters.actualInductionEndDate) &&  moment(ac.actualETRDate).format("YYYY/MM/DD").isBetween(moment(filters.actualInductionStartDate), moment(filters.indDateRangeTo), undefined,'[]'))*/)
            );
        }
        return filteredVisits;
    }

    switch (action.type) {
        case `${FETCH_VISITS}_PENDING`: {
            return {
                ...state,
                isLoading: true,
                visits: state.visits?.length ? state.visits : null,
            }
        }
        case `${FETCH_VISITS}_FULFILLED`: {
            const visits = action.payload.openVisits;            
            return {
                ...state,
                visits/*: state.visits?.length ? [...state.visits, ...visits] : visits*/,//commented out to remove duplication of visits in base dashboard
                isAddVisitModalOpen: true,
                isSaveVisitSuccess: false,
                isSaveVisitError: false,
                saveVisitErrorMessage: null,
                isRTSAircraftSuccess: false,
                rtsAircraftError: "",
                isCloseVisitPending: false,
                isCloseVisitSuccess: false,
                closeVisitError: "",
                isLoading:false,
                lastRefreshed: moment().format("MM/DD/YYYY HH:mm"),
            }
        }
        case `${SET_VISITS_DATA}`: {
            if(action.payload && action.payload.visitsType) {                  
                let filteredVisitslOCAL = { ...state.filteredVisits, [action.payload.visitsType] : {visits: action.payload.visits, filters: { sortBy: "Tail number ascending", checkTypes: [], inductionDate: "", etrDate: "" } }}
                return {
                    ...state,
                    isLoading: false,
                    filteredVisits: filteredVisitslOCAL,
                    activeVisit: action.payload.visitsType
                }
            }
            return {...state}
        }
        case `${APPLY_VISIT_FILTERS}`: {            
            if (action.payload && action.payload.visitType && action.payload.filters) {
                const _visitType = action.payload.visitType
                let visitsObj = { ...(state?.filteredVisits || []) }
                // let visitsObj = { ...state.filteredVisits }
                // visitsObj[action.payload.visitType] = { visits: filterVisits(action.payload.visitType.toLowerCase(), action.payload.filters, [...state.visits]), filters: action.payload.filters };
                visitsObj[_visitType] = { visits: filterVisits(_visitType.toLowerCase(), action.payload.filters, [...(_visitType.toLowerCase() === 'history' ? state.filteredVisits[_visitType]?.visits : state?.visits || [])]), filters: action.payload.filters };
                return {
                    ...state,
                    filteredVisits: visitsObj
                }
            }
            return {
                ...state
            }
        }
        case `${CLICK_ADD_VISIT}`: {
            return {
                ...state,
                isAddVisitModalOpen: true,
                isSaveVisitSuccess: false,
                isSaveVisitError: false,
                saveVisitErrorMessage: null
            }
        }
        case `${CLICK_ACCORDION_FILTER_VISIT}`: {
            return {
                ...state,
                isFilterMenuOpen: true
            }
        }
        case `${CLICK_VIEW_HISTORY}`: {
            return {
                ...state,
                isViewHistoryButtonClick: !state.isViewHistoryButtonClick
            }
        }
        case `${CLOSE_ACCORDION_FILTER_VISIT}`: {
            return {
                ...state,
                isFilterMenuOpen: false
            }
        }
        case `${CLICK_CANCEL_VISIT}`: {
            return {
                ...state,
                isAddVisitModalOpen: false
            }
        }
        case `${FETCH_TAIL_NUMBER_LOOKUP}_PENDING`: {
            return {
                ...state,
                isLoading: true,
                tailNumbers: null
            }
        }
        case `${FETCH_TAIL_NUMBER_LOOKUP}_FULFILLED`: {
            return {
                ...state,
                isLoading: false,
                tailNumbers: action.payload.tailLookUp
            }
        }
        case `${GET_FLEET_CODES}_FULFILLED`: {
            if (action.payload.listFleetTypes && action.payload.listFleetTypes.length > 0) {
                return {
                    ...state,
                    fleets: action.payload.listFleetTypes.map(val => val.fleetCode)
                }
            } else {
                return {
                    ...state
                }
            }
        }
        case `${CLICK_SAVE_VISIT}_PENDING`: {
            return {
                ...state,
                isSaveVisitPending: true

            }
        }
        case `${CLICK_SAVE_VISIT}_FULFILLED`: {
            return {
                ...state,
                isSaveVisitPending: false,
                isSaveVisitSuccess: action.payload.addVisit.isSuccess,
                isSaveVisitError: action.payload.addVisit.error !== null ? true : false,
                saveVisitErrorMessage: action.payload.addVisit.error,
            }
        }
        case `${FETCH_VISIT_INFO}_PENDING`: {
            return {
                ...state,
                isLoadingVisitInfo: true,
                visitInfo: null,
                aircraftInfo: null
            }
        }
        case `${FETCH_VISIT_INFO}_FULFILLED`: {
            let aircraftInfo = null;
            if (action.payload.viewVisitInfo) {
                aircraftInfo = getAircraftInfo(action.payload.viewVisitInfo)                    
            }
            return {
                ...state,
                isLoadingVisitInfo: false,
                visitInfo: action.payload.viewVisitInfo,
                etrInfo: action.payload.getETRHistory?.eTRattributes,
                aircraftInfo: aircraftInfo,
                rtsOverrideInfo: action.payload.getETRHistory?.rTSOverride
            }
        }
        case `${FETCH_VISIT_INFO_LITE}_PENDING`: {
            return {
                ...state,
                isLoadingVisitInfo: true,
                visitInfo: null,
                aircraftInfo: null
            }
        }
        case `${FETCH_VISIT_INFO_LITE}_FULFILLED`: {            
            let aircraftInfo = null;
            let updatedVisitInfo = null;
            const visitInfoDelta = action.payload.viewVisitInfoDelta; 
            if (visitInfoDelta && visitInfoDelta.aircraftControlId) {                             
                const localVisitInfo = getVisitSession(visitInfoDelta.aircraftControlId);                
                updatedVisitInfo = {...localVisitInfo, aircraftControlId: visitInfoDelta.aircraftControlId,
                    aircraftNumber: visitInfoDelta.aircraftNumber, 
                    shiftCount: visitInfoDelta.shiftCount,
                    plannedETRDate: visitInfoDelta.plannedETRDate,
                    actualETRDate: visitInfoDelta.actualETRDate, 
                    plannedInductionDate: visitInfoDelta.plannedInductionDate,  
                    isArchived: visitInfoDelta.isArchived,
                    visitDuration: visitInfoDelta.visitDuration,
                    visitDayNumber: visitInfoDelta.visitDayNumber,
                    baseLineDate: visitInfoDelta.baseLineDate,
                    baselineDateModifiedDate: visitInfoDelta.baselineDateModifiedDate,
                    baselineDateModifiedBy: visitInfoDelta.baselineDateModifiedBy,
                    isTemplateApplied: visitInfoDelta.isTemplateApplied}                    
                aircraftInfo = getAircraftInfo(updatedVisitInfo);                   
                sessionStorage.setItem(visitInfoDelta.acControlID, JSON.stringify(updatedVisitInfo));
            }            

            return {
                ...state,
                isLoadingVisitInfo: false,
                visitInfo: updatedVisitInfo,                
                aircraftInfo: aircraftInfo,
            }
        }
        case `${FETCH_VISIT_INFO}_REJECTED`: {
            return {
                ...state,
                isLoadingVisitInfo: false
            }
        }
        case `${UPDATE_VISIT}_PENDING`: {
            return {
                ...state,
                isUpdateVisitPending: true

            }
        }
        case `${UPDATE_VISIT}_FULFILLED`: {
            return {
                ...state,
                isUpdateVisitPending: false,
                isUpdateVisitSuccess: action.payload.updateVisit.isSuccess,
                isUpdateVisitError: action.payload.updateVisit.error !== null ? true : false
            }
        }
        case `${UPDATE_VISIT_INFO_LOCALLY}`: {
            return {
                ...state,
                visitInfo: { ...action.payload }
            }
        }
        case `${UPDATE_ETR_INFO_LOCALLY}`: {
            return {
                ...state,
                etrInfo: state.etrInfo !== null ? [...state.etrInfo, action.payload] : [action.payload]
            }
        }
        case `${SET_NOTIFICATION_MESSAGE}`: {
            return {
                ...state,
                showNotification: true,
                notificationMessage: action.payload,
                notificationType: "success"
            }
        }
        case `${SET_FAILED_NOTIFICATION_MESSAGE}`: {
            return {
                ...state,
                showNotification: true,
                notificationMessage: action.payload,
                notificationType: "failure"
            }
        }
        case `${RESET_NOTIFICATION_MESSAGE}`: {
            return {
                ...state,
                showNotification: false,
                notificationMessage: "",
                notificationType: ""
            }
        }
        case `${RESET_UPDATE_FLAGS}`: {
            return {
                ...state,
                isUpdateVisitPending: false,
                isUpdateVisitSuccess: false,
                isUpdateVisitError: false,
            }
        }
        case `${RTS_AIRCRAFT}_PENDING`: {
            return {
                ...state,
                isRTSAircraftSuccess: false,
                rtsAircraftError: "",
                isRTSAircraftPending: true,
                rtsWorkItems: null,
                rtsDash8s: null
            }
        }
        case `${RTS_AIRCRAFT}_FULFILLED`: {
            return {
                ...state,
                isRTSAircraftPending: true,
                isRTSAircraftSuccess: action.payload.archiveVisit.isSuccess,
                rtsAircraftError: !action.payload.archiveVisit.isSuccess ? action.payload.archiveVisit.error : "",
                rtsWorkItems: action.payload.archiveVisit.workItems ? action.payload.archiveVisit.workItems : null,
                rtsDash8s: action.payload.archiveVisit.dash8s ? action.payload.archiveVisit.dash8s : null
            }
        }
        case `${RESET_RTS_FLAGS}`: {
            return {
                ...state,
                isRTSAircraftPending: false,
                isRTSAircraftSuccess: false,
                rtsAircraftError: "",
                isSaveVisitError: false,
                saveVisitErrorMessage: null,
            }
        }
        case `${CLOSE_VISIT}_PENDING`: {
            return {
                ...state,
                isCloseVisitPending: true
            }
        }
        case `${CLOSE_VISIT}_FULFILLED`: {
            return {
                ...state,
                isCloseVisitPending: false,
                isCloseVisitSuccess: action.payload.closeVisit.isSuccess,
                closeVisitError: !action.payload.closeVisit.isSuccess ? action.payload.closeVisit.error : ""
            }
        }
        case `${SET_AIRCRAFT_INFO}`: {
            return {
                ...state,
                aircraftInfo: action.payload
            }
        }
        case `${RESET_VISIT_INFO}`: {
            return {
                ...state,
                isLoadingVisitInfo: true,
                visitInfo: null,
                etrInfo: null,
                aircraftInfo: null,
                rtsOverrideInfo: null
            }
        }
        case `${GET_BASE_DASHBOARD_COUNTS_ACTIVE}_PENDING`: {
            return {
                ...state,
                isLoadingActiveCounts: true,
            }
        }
        case `${GET_BASE_DASHBOARD_COUNTS_FUTURE}_PENDING`: {
            return {
                ...state,
                isLoadingFutureCounts: true,
            }
        }
        case `${GET_BASE_DASHBOARD_COUNTS_ACTIVE}_FULFILLED`: {
                const replacementMap =  [...(action.payload.counts ?? [])].reduce((a,c ) => ({...a, [c.aircraftNumber]: c}),{});
                const updatedCounts = [...(state.baseDashboardCounts ?? action.payload.counts)].map(count => replacementMap[count.aircraftNumber] ? replacementMap[count.aircraftNumber] : count);
                action.payload?.counts?.forEach(countItem =>{
                    if(!updatedCounts.some(existingItem => existingItem.aircraftNumber === countItem.aircraftNumber)){
                        updatedCounts.push(countItem)
                    }
                })
                return {
                    ...state,
                    isLoadingActiveCounts: false,
                    baseDashboardCounts: updatedCounts,
                    // baseDashboardCounts: [...(state.baseDashboardCounts ?? []), ...action.payload.counts],
                }
        }
        case `${GET_BASE_DASHBOARD_COUNTS_FUTURE}_FULFILLED`: {
            return {
                ...state,
                isLoadingFutureCounts: false,
                baseDashboardCounts: [...(state.baseDashboardCounts ?? []), ...action.payload.counts],
            }
        }

        case UPDATE_VISITINFO_BASELINE: {
            const _visitInfo = { ...state.visitInfo }
            _visitInfo.baseLineDate = action.payload.baseLineDate
            _visitInfo.baseLineDateModifiedBy = action.payload.baseLineDateModifiedBy
            return {
                ...state,
                visitInfo: _visitInfo
            }
        }

        case `${GET_SCHEDULEDWORK_COUNT}_PENDING`: {
            return {
                ...state,
                isLoadingSWCount: true,
                scheduledWorkCounts: null
            }

        }
        case `${GET_SCHEDULEDWORK_COUNT}_FULFILLED`: {
            return {
                ...state,
                isLoadingSWCount: false,
                scheduledWorkCounts: action.payload.loadGroupsTMScheduledWork
            }
        }
        case `${GET_LOGPAGES_COUNT}_PENDING`: {
            return {
                ...state,
                isLoadingLPCount: true,
                logPagesCounts: null
            }
        }
        case `${GET_LOGPAGES_COUNT}_FULFILLED`: {
            return {
                ...state,
                isLoadingLPCount: false,
                logPagesCounts: action.payload.loadGroupsTMOpenLogPage
            }
        }
        case `${GET_NONROUTINES_COUNT}_PENDING`: {
            return {
                ...state,
                isLoadingNRCount: true,
                nonRoutinesCounts: null
            }
        }
        case `${GET_NONROUTINES_COUNT}_FULFILLED`: {
            return {
                ...state,
                isLoadingNRCount: false,
                nonRoutinesCounts: action.payload.loadGroupsTMNonRoutine
            }
        }
        case `${TOGGLE_OPEN_CLOSE}`: {
            return {
                ...state,
                isExecutionPhase: !state.isExecutionPhase
            }
        }
        case SET_VIEWING_STATION:
            return{
                ...state,
                filteredVisits:null
            }
        default: return state;
    }
}

export default visitsReducer;