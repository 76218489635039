export const fetchNonRoutines = () => {
  return `query NonRoutine($aircraftNonRoutine: AircraftNRInputType, $userInfo: UserInformationInputType) {
    getAircraftNRs(aircraftNonRoutine: $aircraftNonRoutine, userInfo: $userInfo) {
      nRTotalCount
      nROpenCount
      nRClosedCount
      aircraftNonRoutines {
        majorRepair
        sDR
        cat
        partsChange
        requireParts
        insp
        zoneType
        workArea
        workControlNumber
        nonRoutineSequence
        specNumber
        tailNumber
        logPageNumber
        originatingDocument
        overallDescription
        nonRoutineNumber
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
          assignmentCreatedBy
        }
        nonRoutineSkillId
        nonRoutineSkillDesc
        schedArrDate
        lBEntryRequired
        lBEntryCompleted
        rotableParts
        lastModifiedDate
        openDate
        uILastModifiedDate
        uIOpenDate
        isCurrVisit
        immInspReq
        sTTotalCount
        sTClearCount
        sTOpenCount
        sDBlocked
        nonRoutineID
        nonRoutineStatus
        nonRoutineStatusDescription
        discrepency
        aTA
        isVisitScheduleEnabled
        inspectorOnly
        mxMasterId
        workStop
        notesActionType
        lastNote
        lastNoteDetail{
          note
          createdBy
          employeeName
          createdDate
          createdDateUTC
        }
        notesActionTypeAbbreviated
        notesCount
        nRSkillTypes
      }
      cumulativeRefData {
        skills {
          skillID
          skillType
          isActive
        }
        workAreas {
          workAreaId
          workArea
          isActive
        }
        nonRoutineStatuses {
          nonRoutineStatusID
          nonRoutineStatus
        }
        subTaskStatuses {
          subTaskStatusID
          subTaskStatus
        }
        zoneTypes {
          zoneTypeId
          zoneType
          isActive
          zoneLevelID
          isSelectable
        }
        inspectionTypes {
          inspectionTypeID
          inspectionType
          isActive
          sortOrder
        }
        updateTypes {
          updateTypeID
          updateType
          isActive
        }
        rotablePartChangeTypes {
          rotablePartChangeTypeID
          rotablePartChangeTypeDesc
          isActive
        }
        attributeTypes {
          attributeTypeCode
          attributeTypeDesc
        }
        subZoneTypes {
          subZoneTypeId
          subZoneTypeLevel
          zoneTypeId
          zoneTypeIdSub
          isActive
          zoneType
          subZoneType
          isSelectable
        }
        ataChapters
      }
    }
  }
  `
}

export const fetchNonRoutineInfo = () => {
  return `query NonRoutine($viewNonRoutine: ViewNonRoutineInputType, $userInfo: UserInformationInputType) {
    viewNonRoutines(viewNonRoutine: $viewNonRoutine, userInfo: $userInfo) {
      nonroutine {
          nonRoutineNumber
          originatingDocument
          tailNumber
          logPageNumber
          nonRoutineID
          nonRoutineStatusID
          nonRoutineStatus
          zoneType
          zoneTypeID
          zoneID
          checkType
          aTA
          workControlNumber
          nonRoutineSequence
          estimatedHours
          specNumber
          attributeID
          updateTypeID
          majorRepair
          sDR
          cat
          partsChange
          requireParts
          insp
          discrepency
          creator
          lastModifiedDate
          uILastModifiedDate
          workAreaID
          workArea
          inspectionTypeID
          updateInspectionTypeID
          inspectionTypeDesc
          updateInspectionTypeDesc
          type
          uIActions {
            isClearAllowed
            isUpdateAllowed
            isDeferAllowed
            isEvaluateAllowed
            isVoidAllowed
            isInspectAllowed
            isCreateAllowed
            isReOpenAllowed
            isManageSubTasksAllowed
          }
          imageAvailable
          imageCount
          nonRoutineSkillId
          nonRoutineSkillDesc
          schedArrDate
          lBEntryRequired
          lBEntryCompleted
          sDBlocked
          linkedLogPagesExist
          visitID
          immInspReq
          pdfs {
            contentId
            contentName
            updatedBy
            errors {
              description
            }
          }    
      }
      subTask {      
        subTaskID
        nonRoutineID
        creatorEmployeeID
        latestActEmployeeID
        subTaskText
        commonTaskID
        insp
        subTaskStatusID
        subTaskStatus
        lastModifiedDate
        uILastModifiedDate
        creatorFirstName
        creatorLastName
        latestActFirstName
        latestActLastName
        sOAdditionalEmployees
        subTaskActivities {
          subTaskID
          subTaskActivityID
          subTaskActivityType
          comment
          createdDate
          signerEmployeeID
          employeeName
          uICreatedDate
          updateInspectionTypeID
          updateInspectionType
          updateInsp
          clearingInspectionType
          clearingInsp
        }
        inspectionTypeID
        subtaskActions {
          showInspect
          showComplete
          showSignInError
          showPartial}
        originalInspectionTypeID
        subTaskActivityTypeID 
        subTaskActivityType
        updateInspectionTypeID
        sortOrder
        sortOrderDesc
      }
      activity {
        activityID
        nonRoutineID
        activityTypeID
        attributeID
        correctiveAction
        employeeID
        lastModifiedDate
        creatorFirstName
        creatorLastName
        activityType
        setInspection
        uILastModifiedDate
        uIEmployeeName
        updateTypeDesc
        updateTypeInspDesc
        clearUpdateInspType
      }
      estimatedHours {
        estimatedHourID
        skillID
        skillType
        estHours
        resourcesRequired
        nonRoutineID
      }
      taskCardDependencies {
        dependencyID
        dependencyMasterID: mxMasterId
        dependencyCardNumber
        zoneCardNumber 
        zoneId
        dependencyDescription
        dependencyType
        mxType
        parentCardNumber
        parentMxMasterId
        nonRoutineStatusId
        uIDash1Status
        dash1Status
        dupDash8
        dash1OptimizedStatusType
        isDash1Duplicate
        isOptimized
        workControlNumber
        serialNumber
        dash8
        masterId
      }  
      requiredParts {
        requiredPartsID
        mEPartNo
        quantity
        partDescription
        nonRoutineID
      }
      rotablePartsChange {
        rotablePartsChangeID
        trackingNbr
        mESerialNbr
        isRotableException
        nonRoutineID
        mEPartNo
        rotablePartChangeTypeId
        token
        lTerm
        aircraftNumber
      }
      workBins{
        workBinId
        workBinName
      }
    assignedCrews{
      crewConfigId
      crewName
    }
      notes {
        notesId
        notes
        workLoadID
        createdBy
        createdDate
        employeeName
        actionTypeId
        actionType
        workStop
        workBinId
        workBinName
        abbreviatedActionType
        uIFormattedName
        uIFormattedDate
      }
      workBinsList{
        workBinId
        workBinName
      }
      actionTypes{
        actionTypeId
        actionType
        abbreviatedActionType
      }
    
      installedCount
      removedCount
      changedCount
      requiredPartsCount
      estimatedHoursTotal
      inspUpgradeRIR
      inspUpgradeIDT
      mxMasterId
      mxType
      mxGroupId
      groupName
      shiftStartNumber
      shiftEndNumber
      overrideShiftStartNumber
      overrideShiftEndNumber
      cumulativeRefData {
        skills {
          skillID
          skillType
          isActive
        }
        workAreas {
          workAreaId
          workArea
          isActive
        }
        nonRoutineStatuses {
          nonRoutineStatusID
          nonRoutineStatus
        }
        subTaskStatuses {
          subTaskStatusID
          subTaskStatus
        }
        zoneTypes {
          zoneTypeId
          zoneType
          isActive
          zoneLevelID
          isSelectable
        }
        inspectionTypes {
          inspectionTypeID
          inspectionType
          isActive
          sortOrder
        }
        updateTypes {
          updateTypeID
          updateType
          isActive
        }
        rotablePartChangeTypes {
          rotablePartChangeTypeID
          rotablePartChangeTypeDesc
          isActive
        }
        attributeTypes {
          attributeTypeCode
          attributeTypeDesc
        }
        subZoneTypes {
          subZoneTypeId
          subZoneTypeLevel
          zoneTypeId
          zoneTypeIdSub
          isActive
          zoneType
          subZoneType
          isSelectable
        }
        ataChapters 
      }
    }
  }
  `
}

export const updateOrClearNonRoutine = () => {
  return `mutation NonRoutine($updateOrClearNonRoutine: NonRoutineInputType, $userInfo: UserInformationInputType, $nRInputType: NRInputType) {
    updateOrClearNonRoutine(
      updateOrClearNonRoutine: $updateOrClearNonRoutine
      userInfo: $userInfo
      nRInputType: $nRInputType
    ) {
      logpageNumber
      nonRoutineID
      logpagePartsTransactionResponse {
        errorCode
        errorMessage
        informationMessages
        partsChangeDiscrepancyResponse {
          partChangeDataResponse {
            hold_TrackingNumber_ON
            hold_MESerialNumber_OFF
            firstLevelInd
            softwareInd
            quarantineInd
            completedInd
            order
            forcedPartChange {
              trackingNumber
              mEPartNumber
              installedRemovedPosition
              comments
              hold_KeywordDescription
              componentBeingInstalledRequest {
                ipcReference
                mESerialNumber
                fullMFGPartNumber
                fullMFGSerialNumber
              }
              componentBeingRemovedRequest {
                mESerialNumber
                fullMFGPartNumber
                fullMFGSerialNumber
              }
              hold_ValidPositions
              validPositions
              infos
              errorMessage
            }
            softwareInstall {
              hold_SPANumericKey
              hold_PageNumber
              hold_SoftwareCount
              reportDate
              hold_Config_MEPartNumber
              hold_Config_MESerialNumber
              hold_EmployeeName
              hold_EmployeeMessage
              hold_LRU_NcaPartNumber
              hold_LRU_NcaSerialNumber
              hold_LRU_Description
              hold_NavMessage
              uI_MESerialNumber
              uI_LRU_Description
              softwareInfos {
                hold_SW_Description
                sW_ME_MFG_Partnumber
                eA_Ind
                hold_SW_Type_New
                hold_Spa_Sub_loc
                hold_Sw_Pn_Error
              }
              errorMessage
            }
            quarantineReq {
              trackingNumber_ON
              mESerialNumber_OFF
              quarantineReq
              mEPartNumber
              description
            }
          }
          partChangeHoldField {
            tranCode
            orgTrancode
            partsChangeEntires
            activityDate
            dateOnLogPageNumber
            aircraftNumber
            oilAddInd
            employeeNumber
            heightestAssemblyId
            installOnlyInd
            workControlNumber
            jobNumber
            taskInd
            typeOfUpdateInd
            partsChangeInd
            nextWorkControlNumber
            nextJobNumber
            purposeCode
            commentCode
            tranSeq
            keyFill
            scelhcSummaryInd
            materialsReqInd
            logPageNumber
            programNameDataTime
            msgInd
            orgLterm
          }
          transitionXib
          persistErrorCode
          persistErrorMessage
        }
      }
      passwordErrorMessage
      errorMessage
    }
  }`
}

export const createNonRoutine = () => {
  return `mutation NonRoutine($createNonRoutine: NonRoutineInputType, $userInfo: UserInformationInputType) {
    createNonRoutine(createNonRoutine: $createNonRoutine, userInfo: $userInfo) {
      nonRoutineID
      isSuccess
      errors {
        type
        code
        description
      }
      errorMessage
      passwordErrorMessage
    }
  }
  `
}

export const fetchNonRoutinesRefData = () => {
  return `query NonRoutine($aircraftNonRoutine: AircraftNRInputType, $userInfo: UserInformationInputType) {
    getAircraftNRs(aircraftNonRoutine: $aircraftNonRoutine, userInfo: $userInfo) {
      cumulativeRefData {
        skills {
          skillID
          skillType
          isActive
        }        
        zoneTypes {
          zoneTypeId
          zoneType
          isActive
          zoneLevelID
          isSelectable
        }        
        subZoneTypes {
          subZoneTypeId
          subZoneTypeLevel
          zoneTypeId
          zoneTypeIdSub
          isActive
          zoneType
          subZoneType
          isSelectable
        }
      }
    }
  }`
}
export const addMxnotesForNonRoutines = () => {
  return `mutation NonRoutine($mxNotes: SaveNRMxNotesInputType, $userInfo: UserInformationInputType) {
    saveMxNotes(mxNotes: $mxNotes, userInfo: $userInfo) {
      mxNotes {
        mxNotesId
        nonRoutineID
        workLoadID
        notes
        createdBy
        isActive
        createdFirstName
        createdLastName
        modifiedFirstName
        modifiedLastName
        createdDate
        lastModifiedDate
        lastModifiedBy
        station
        uIFormattedName
        uIFormattedDate
      }
    }
  }
  `
}
export const subTaskSignOff = () => { //used for add subtask too
  return `mutation NonRoutine($nonRoutineInputType: NonRoutineInputType, $userInfo: UserInformationInputType, $nRInputType: NRInputType) {
    nonRoutineOtherActions(
      nonRoutineInputType: $nonRoutineInputType
      userInfo: $userInfo
      nRInputType: $nRInputType
    ) {
      nonRoutineID
      isSuccess
      errors {
        type
        code
        description
      }
      errorMessage
      passwordErrorMessage

    }
  }`

}

export const fetchNonRoutineLogPages = () => {
  return `query NonRoutine($linkedLP: LinkedLPInputType, $userInfo: UserInformationInputType) {
    getLinkedLogPages(linkedLP: $linkedLP, userInfo: $userInfo) {
      linkedLogPages {
        logPageID
        logPageCreateDateTime
        logPageNumber
        nonRoutineID
        workLoadID
        logPageTypeId
        logPageTypeName
        visitId
        logPageStaus
        lastModifiedDate
        hasImages
        rERType
        aTA
        hasInspection
        hasLock
        logTypeNumber
        isMEL
        logType
        workItemDescription
        discrepancyStatus
        station
      }
      deferedAndCarriedLogPages {
        logPageID
        logPageCreateDateTime
        logPageNumber
        nonRoutineID
        workLoadID
        logPageTypeId
        logPageTypeName
        visitId
        logPageStaus
        lastModifiedDate
        hasImages
        rERType
        aTA
        hasInspection
        hasLock
        logTypeNumber
        isMEL
        logType
        workItemDescription
        discrepancyStatus
        station
      }
      systemGeneratedLogPages {
        logPageID
        logPageCreateDateTime
        logPageNumber
        nonRoutineID
        workLoadID
        logPageTypeId
        logPageTypeName
        visitId
        logPageStaus
        lastModifiedDate
        hasImages
        rERType
        aTA
        hasInspection
        hasLock
        logTypeNumber
        isMEL
        logType
        workItemDescription
        discrepancyStatus
        station
      }
    }
  }`
}

export const fetchNonRoutineImages = (employeeID, nonRoutineID) => {
  return `query NonRoutine($userInfo: UserInformationInputType) {
    getNRImages(employeeId: "`+ employeeID + `", nonRoutineID: ` + nonRoutineID + `, userInfo: $userInfo) {
      images {
        imageID
        base64Image
        nonRoutineID
      }
    }
  }`
}

export const updateSubtaskSortOrder = () => {
  return `mutation NonRoutine($updateSubtaskSortOrder: UpdateSubTaskInputType, $userInfo: UserInformationInputType) {
    updateSubtaskSortOrder(updateSubtaskSortOrder: $updateSubtaskSortOrder, userInfo: $userInfo) {
    response {
      id
      isSuccess
      error
    }
    passwordErrorMessage
  }
  }`
}
export const fetchNonRoutineHistory = () => {
  return `query NonRoutine($acNRHistory: AcNRHistoryInputType, $userInfo: UserInformationInputType) {
    getAcNRHistory(acNRHistory: $acNRHistory, userInfo: $userInfo) {
      nRTotalCount
      nROpenCount
      nRClosedCount
      aircraftNonRoutines {
        assignees {
          technicianId
          firstName
          lastName
          uIFormattedTechName
        }
        majorRepair
        sDR
        cat
        partsChange
        requireParts
        insp
        zoneType
        workArea
        workControlNumber
        nonRoutineSequence
        specNumber
        tailNumber
        logPageNumber
        originatingDocument
        overallDescription
        nonRoutineNumber
        nonRoutineSkillId
        nonRoutineSkillDesc
        notesActionTypeAbbreviated
        schedArrDate
        lBEntryRequired
        lBEntryCompleted
        rotableParts
        lastModifiedDate
        openDate
        closedDate
        uILastModifiedDate
        uIOpenDate
        isCurrVisit
        immInspReq
        sTTotalCount
        sTClearCount
        sTOpenCount
        sDBlocked
        notesCount
        lastNote
        lastNoteDetail{
          note
          createdBy
          employeeName
          createdDate
          createdDateUTC
        }
        nonRoutineID
        nonRoutineStatus
        nonRoutineStatusDescription
        discrepency
        aTA
        isVisitScheduleEnabled
        inspectorOnly 
        nRSkillTypes
        workStop
      }
    }
  }
  `
}
export const getAttachedTaskCard = () => {
  return `query NonRoutine($attachedNR: AttachedNRInputType, $userInfo: UserInformationInputType) {
    getAttachedNR(attachedNR: $attachedNR, userInfo: $userInfo) {
      getAttachedLPsForViewLPs {
        attachedLogPageId
        visitWorkloadId
        logPageNumber
        isENR
        dash1
        dash8
        specNumber
        serialNumber
        workControlNumber
      }
    }
  }
`
}

export const fetchGroupsForNonroutine = () => {
  return `query VisitSchedule($groups: GroupRequestType, $userInfo: UserInformationInputType) {
    getGroupsList(groups: $groups, userInfo: $userInfo) {
      groups {
        mxGroupId
        groupName
      }
    }
  }  
  `
}
export const getAtaCodes = () => {
  return `query NonRoutine($ata: GetATAType, $userInfo: UserInformationInputType) {
    getATACodes(ata: $ata, userInfo: $userInfo) {
      aTACodes
    }
  }
  `
}
export const fetchLinkedGroupForLinkedNonroutine = () => {
  return `query VisitSchedule($request: MxGroupRequestType, $userInfo: UserInformationInputType) {
    getGroupInfo(request: $request, userInfo: $userInfo) {
      groupId
      mxGroupId
      mxMasterId
      groupName
    }
  }
  `
}

export const createNRLinkedLogPages = () => {
  return `mutation NonRoutine($createNRLinkedLp: CreateNRLinkedLPInputType, $userInfo: UserInformationInputType) {
    createNRLinkedLogPages(createNRLinkedLp: $createNRLinkedLp, userInfo: $userInfo) {
      logpageTransactionResp {
        errorCode
        errorMessage
        informationMessages
      }
      logpageNumber
      nonRoutineID
      attachedLogPageId
      passwordErrorMessage
    }
  }
  `
}

export const fetchLinkedWorkBinForLinkedNonroutine = () => {
  return `query VisitSchedule($request: WorkbinsForMxitemInputType, $userInfo: UserInformationInputType) {
    getWorkbinsForMxitem(request: $request, userInfo: $userInfo) {
      bins{
        workBinId
        workBinName
      }
    }
  }
  `
}

export const fetchActionTypesForNotes = () => {
  return `query NonRoutine($userInfo: UserInformationInputType){
    actionTypesForNotesLookUp(userInfo: $userInfo){
      actionTypesForNotesList {
        actionTypeId,
        actionType,
        abbreviatedActionType      
      }
    }   
  }
`
}

export const getDash1NotesForNR = () => {
  return `query RoutineRegister($getd1Notes: Dash1NotesInputType, $userInfo: UserInformationInputType) {
    getDash1Notes(getd1Notes: $getd1Notes, userInfo: $userInfo) {
      notes
        {
          notesId
          notes
          workLoadID
          employeeName
          createdBy
          createdDate
          actionTypeId
          actionType
          workStop
          workBinId
          workBinName
          workBinType
          abbreviatedActionType
        }
     actionTypes
        {
          actionTypeId
          actionType
          abbreviatedActionType
        }
      workBinsList
        {
        workBinId
        workBinName
        description
        workbinTypeId
        workbinType
        isSystemDefined
        }
        tLWorkStop
    }
  }
  `
}

export const saveNonRoutineDetails = () => {
  return `mutation TemplateManagement($saveNRDetails: SaveTemplateDetailsNRInputType, $userInfo: UserInformationInputType) {
    saveTemplateNonRoutineDetails(
      saveNRDetails: $saveNRDetails
      userInfo: $userInfo
    ) {
      nonRoutineId
      notesCount
      isSuccess
      error
      errorMessage
    }
  }  
`  
}

export const saveNonRoutineDetailsEnhanced = () => {
  return `mutation TemplateManagement($saveNRDetailsEhanced: SaveNRDetailsInputType, $userInfo: UserInformationInputType) {
    saveNonRoutineDetails(
      saveNRDetailsEhanced: $saveNRDetailsEhanced
      userInfo: $userInfo
    ) {
      nonRoutineId
      notesCount
      isSuccess
      error
      errorMessage
    }
  }  
`  
}

export const fetchAssignedCrew = () => {
  return `query CrewUpgrade($assignedCrew: CrewsInputType, $userInfo: UserInformationInputType) {
    assignedCrewForCurrentDay(
      assignedCrew: $assignedCrew
      userInfo: $userInfo
    ) {
     shiftCrews
      {
        shiftName 
          crewConfigId 
          isBase 
          crewCode 
          crewName 
          crewDescription 
          bidArea 
          isAssociated 
          associationId 
          autoAssociationType 
          crewType 
          workAreaId 
          station 
          shiftSeq 
  
      }
    }
  }
`
}

export const saveNrPdfImages = () => {
  return `query NonRoutine($saveNrPdf: SaveNrPdfRequestInputType) {
    saveNrPdf(saveNrPdf: $saveNrPdf) {
      nrPdfContents {
        contentId
        contentName
        isSuccess
        errors {
          description
        }
      }
    }
  }  
`  
}

export const getPdf = () => {
  return `query NonRoutine($getPdf: GetPdfRequestInputType) {
    getPdf(getPdf: $getPdf) {
      pdfImageUrls
      isSuccess
      errors {
        description
      }
    }
  }
`
}

export const deleteNrPdf = () => {
  return `query NonRoutine($deletePdfRequest: NrPdfDeleteRequestInputType) {
    deleteNrPdfReference(deletePdfRequest: $deletePdfRequest) {
      isSuccess
      errors {
        description
      }
    }
  }
`
}


