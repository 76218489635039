import { combineReducers } from 'redux';

import VisitsReducer from './visitsReducer';
import ProfileReducer from './profileReducer';
import FiltersReducer from './filtersReducer';
import RegisterReducer from './registerReducer';
import TaskCardReducer from './taskCardReducer';
import ArchiveCardSearchReducer from './archiveSearchReducer';
import NonRoutinesReducer from './nonRoutinesReducer';
import VisitScheduleReducer from './visitScheduleReducer';
import templateReducer from './TemplateReducer';
import taskAuditReducer from './taskAuditReducer';
import workBinsReducer from './workBinsReducer';
import baselineReducer from './baselineReducer';
import myShiftReducer from './myShiftReducer';
import shiftHandoverReportReducer from './shiftHandoverReportReducer';
import aircraftStatusReducer from './aircraftStatusReducer';
const reducers = {
    visitsStore: VisitsReducer,
    profileStore: ProfileReducer,
    filtersStore: FiltersReducer,
    registerStore: RegisterReducer,
    taskCardStore: TaskCardReducer,
    archiveCardSearchStore: ArchiveCardSearchReducer,
    nonRoutinesStore: NonRoutinesReducer,
    visitScheduleStore: VisitScheduleReducer,
    templateStore: templateReducer,
    taskAuditStore: taskAuditReducer,
    workBinsStore: workBinsReducer,
    baselineStore: baselineReducer,
    myShiftStore: myShiftReducer,
    shiftHandoverReportStore: shiftHandoverReportReducer,
    aircraftStatusStore: aircraftStatusReducer
};

const rootReducer = combineReducers(reducers);
export default rootReducer