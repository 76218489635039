import React, {Component} from 'react';
import ReactDOM from 'react-dom';

export const overlayComponent = (WrappedComponent) => {
    class overlayComp extends Component{
        constructor(props) {
            super(props);
            this.el = document.createElement('div');
            this.el.classList.add("modal-container")
            //document.body.style.overflow = 'hidden';
            if (props.modalClass && props.modalClass !== "") {
                this.el.classList.add(props.modalClass)
            }
            this.modalRoot = document.getElementById('modal-root');
            this.modalRoot.appendChild(this.el);
            this.state = {
               
            }
        }
        componentWillUnmount() {            
            this.modalRoot.removeChild(this.el);
            //document.body.style.overflow = 'auto'
        }
        render() {
            return ReactDOM.createPortal(<WrappedComponent {...this.props}/>, this.el)            
        }
    }
    return overlayComp;
};