import * as templateQueries from '../queries/TemplateQueries';
import {getProgressForTaskCards} from '../queries/AssignCrewQueries';
import {
    SEARCH_TEMPLATES, SEARCH_TASKCARDS_ENHANCED, SET_GENERIC_ERROR, RESET_TEMPLATE_DATA,
    SET_TEMPLATE_SEARCH_CRITERIA, FETCH_TEMPLATE_DETAILS, RESET_TEMPLATE_DETAILS,
    SET_SELECTED_TEMPLATE_GROUP, GET_TEMPLATE_TASKCARD_DETAILS,
    SEARCH_TASKCARDS, SAVE_TEMPLATE_TASKCARD_DETAILS, GET_TEMPLATE_MANAGEMENT_REF_DATA, GET_TEMPLATE_MANAGEMENT_GROUPS_DATA, SAVE_UPDATE_MILESTONE,
    SET_NOTIFICATION_MESSAGE, RESET_TASK_CARD_DETAILS_ERROR_MESSAGE, SET_TASKCARD_SEARCH_CRITERIA,
    RESET_TASKCARD_SEARCH_DATA, RESET_SAVE_UPDATE_MILESTONE, SET_LAST_REFRESHED,
    APPLY_TEMPLATE, SAVE_TEMPLATE, RESET_SAVE_TEMPLATE_ERROR_MESSAGE, RESET_APPLY_TEMPLATE, SET_UPDATE_TEMPLATE_FLAG, UPDATE_TEMPLATE, UPDATE_TEMPLATE_DETAILS, TOGGLE_OPEN_CLOSE, DELETE_TEMPLATE,
    GET_FLEET_CODES, FETCH_TEMPLATE_MILESTONES, GET_PROGRESS_FOR_TASKCARDS
} from './actionTypes';
import { executeQuery } from '../helper';
import { trackPromise } from 'react-promise-tracker';

export const searchTemplates = (searchParams) => {
    return (dispatch, getState) => {
        const variable = {
            "template": {
                "templateName": searchParams.templateName.value,
                "station": searchParams.station.value,
                "startDate": searchParams.startDate.value,
                "endDate": searchParams.endDate.value,
                "checkType": searchParams.checkType.value,
                "searchType": "Default",
                "fleetType": searchParams.fleetCode.value,
                "duration": searchParams.duration.value === "" ? null : searchParams.duration.value,
                "aircraftNumber": searchParams.tailNumber.value
            }
        }
        return dispatch({
            type: SEARCH_TEMPLATES,
            payload: executeQuery(templateQueries.searchTemplates(), variable, getState(), 'TemplateManagement')
            //payload: sampleTemplateData
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const resetTemplateData = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_TEMPLATE_DATA,
            payload: null
        });
    }
}

export const setTemplateSearchCriteria = (searchReq) => {
    return dispatch => {
        return dispatch({
            type: SET_TEMPLATE_SEARCH_CRITERIA,
            payload: searchReq
        });
    }
}

export const fetchTemplateDetails = (mxTemplateId) => {
    return (dispatch, getState) => {
        const variable = {
            "templateDetails": {
                "mxTemplateId": parseInt(mxTemplateId)
            }
        }
        return dispatch({
            type: FETCH_TEMPLATE_DETAILS,
            payload: executeQuery(templateQueries.fetchTemplateDetails(), variable, getState(), 'TemplateManagement')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const getTaskcardProgress=(progressVariable)=>(dispatch, getState)=>{
    dispatch({
        type: GET_PROGRESS_FOR_TASKCARDS,
        payload: executeQuery(getProgressForTaskCards(), progressVariable, getState(), 'TemplateManagement')
    })
}
export const getTemplateTaskCardDetails = (mxMasterId, searchType, station) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "taskcardDetails": {
                "mxMasterId": mxMasterId,
                "searchType": searchType,
                "station": station,
                "isHistoricalVisit": visitInfo?.isArchived,
                ...(searchType ==="visit" && 
                {"isHvyChkPackage": visitInfo?.enableZonalCard,
                "tailNumber": visitInfo?.aircraftNumber,
                "actualInductionDate":visitInfo?.actualInductionDate || visitInfo?.plannedInductionDate})
            }
        }
        return dispatch({
            type: GET_TEMPLATE_TASKCARD_DETAILS,
            payload: executeQuery(templateQueries.getTemplateTaskCardDetails(), variable, getState(), 'TemplateManagement')
        }).then(resp => {
            if (resp !== null && resp.value.getTemplateTaskCardDetails !== null) {
                if(searchType ==="visit"){
                        const progressVariable = {
                            "progress": {
                                "assignedStation": visitInfo?.station,
                                "cardNumbers": resp.value.getTemplateTaskCardDetails?.taskCardDependencies?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC")?.map(wl => ({
                                    cardNumber: wl.mxType==="D1" ? wl.dependencyCardNumber : wl.zoneCardNumber,
                                    workControlNumber: wl.workControlNumber,
                                    serialNumber: wl.serialNumber,
                                    jobNumber: wl.dash8,
                                   //adding masterid to get the correct counts for progress, rumiana asked me to add the masterid we are getting from above response
                                    masterId: wl.masterId,
                                    zoneId: wl.mxType === "ZC" ? wl.zoneId : null
                                })),
                                "tailNumber": visitInfo?.aircraftNumber,
                                "flightNumber": "0000",
                                "scheduledArrivalDate": visitInfo?.actualInductionDate || visitInfo?.plannedInductionDate
                            }
                        };
                       dispatch(getTaskcardProgress(progressVariable))
                }
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const resetTemplateDetails = () => {
    return dispatch => {
        return dispatch({
            type: RESET_TEMPLATE_DETAILS,
            payload: null
        })
    }
}

// export const saveAsTemplate = (templateDetails) => {
//     return dispatch => {
//         return dispatch({
//             type: SAVE_AS_TEMPLATE,
//             payload: {isSuccess:true}
//         })
//     }
// }

export const setSelectedTemplateGroup = (mxTemplateGroupID) => {
    return dispatch => {
        return dispatch({
            type: SET_SELECTED_TEMPLATE_GROUP,
            payload: mxTemplateGroupID
        })
        // .catch(error => {
        //     dispatch({
        //         type: SET_GENERIC_ERROR,
        //         payload: error?.message
        //     })
        // });
    }
}
// export const searchTaskcards = (id, searchType, taskCardNumber, taskCardDesc, zoneTypeId, taskCardType, skillId = 0, workBinId, searchTypeSelected, selectedMissingSearch) => {
//     return (dispatch, getState) => {
//         const visitInfo = getState() && getState().visitsStore.visitInfo;
//         const variable = {
//             "request": {
//                 "id": id,
//                 "searchType": searchType,
//                 "taskcardNumber": taskCardNumber,
//                 "taskcardDescription": taskCardDesc,
//                 "skillId": skillId,
//                 "workBinId": workBinId,
//                 "zoneTypeID": zoneTypeId,
//                 "taskCardType": taskCardType === "" ? "None" : taskCardType.replace(' ', '_'),
//                 ...(searchType === 'visit' && {
//                     "tailNumber": visitInfo.aircraftNumber,
//                     "station": visitInfo.station,
//                     "inFlightNumber": null,
//                     "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
//                     "departureDate": visitInfo.actualETRDate || visitInfo.plannedETRDate,
//                     "isHvyChkPackage": visitInfo.enableZonalCard
//                 }
//                 )

//             }
//         }

//         return dispatch({
//             type: SEARCH_TASKCARDS,
//             payload: executeQuery(templateQueries.searchTaskcards(), variable, getState(), 'TemplateManagement')
//         }).then(resp => {
//             const update = { ...resp.action };
//             if (searchTypeSelected && searchTypeSelected !== 'defaultSearch') {
//                 update.payload.searchTaskcards.searchTaskCards = update.payload.searchTaskcards.searchTaskCards.filter(item => (item.isMissingProperties && item.missingProperties[selectedMissingSearch]))
//             }
//             dispatch(update);
//         })
//             .catch(error => {
//                 dispatch({
//                     type: SET_GENERIC_ERROR,
//                     payload: error?.message
//                 })
//             });
//     }
// }
export const searchTaskcardsMultiSelect = ({ searchType, selectedMissingSearch, searchTypeSelected, ...rest }) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "request": {
                ...rest,
                searchType,
                ...(searchType === 'visit' && {
                    "tailNumber": visitInfo.aircraftNumber,
                    "station": visitInfo.station,
                    "inFlightNumber": null,
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.actualETRDate || visitInfo.plannedETRDate,
                    "isHvyChkPackage": visitInfo.enableZonalCard,
                    "isHistoricalVisit": visitInfo.isArchived
                }
                )

            }
        }

        return dispatch({
            type: SEARCH_TASKCARDS,
            payload: executeQuery(templateQueries.searchTaskcardsMultiSelect(), variable, getState(), 'TemplateManagement')
            //payload: executeQuery(templateQueries.searchTaskcardsMultiSelectEnhanced(), variable, getState(), 'TemplateManagement')
        }).then(resp => {
            const update = { ...resp.action };
            if (searchTypeSelected && searchTypeSelected !== 'defaultSearch' && update.payload.searchTaskcardsMultiSelect) {
                update.payload.searchTaskcardsMultiSelect.searchTaskCards = update.payload.searchTaskcardsMultiSelect.searchTaskCards.filter(item => (item.isMissingProperties && item.missingProperties[selectedMissingSearch]))
            }
            dispatch(update);
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const searchTaskcardsMultiSelectEnhanced = ({ searchType, selectedMissingSearch, searchTypeSelected,isModalPopup, ...rest }) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "request": {
                ...rest,
                searchType,
                ...(searchType === 'visit' && {
                    "tailNumber": visitInfo.aircraftNumber,
                    "station": visitInfo.station,
                    "inFlightNumber": null,
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.actualETRDate || visitInfo.plannedETRDate,
                    "isHvyChkPackage": visitInfo.enableZonalCard,
                    "isHistoricalVisit": visitInfo.isArchived,
                    "forModalPopup": isModalPopup==="true" ? true : false
                }
                )

            }
        }

        return dispatch({
            type: SEARCH_TASKCARDS_ENHANCED,
            payload: executeQuery(templateQueries.searchTaskcardsMultiSelectEnhanced(), variable, getState(), 'TemplateManagement')
        }).then(resp => {
            const update = { ...resp.action };
            if (searchTypeSelected && searchTypeSelected !== 'defaultSearch' && update.payload.searchTaskcardsMultiSelectEnhanced) {
                update.payload.searchTaskcardsMultiSelectEnhanced.searchTaskCards = update.payload.searchTaskcardsMultiSelectEnhanced.searchTaskCards.filter(item => (item.isMissingProperties && item.missingProperties[selectedMissingSearch]))
            }
            dispatch(update);
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}


export const saveTemplateTaskCardDetails = (saveObj, mxMasterId, searchType) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "saveTaskcardDetails": {
                ...saveObj,
                "modifiedBy": profile.userId,
                "tailNumber": visitInfo?.tailNumber,
                "station": visitInfo?.station,
                "scheduledArrivalDate": visitInfo?.actualInductionDate || visitInfo?.plannedInductionDate
            },
            "userInfo": {
                "userId": profile.userId,
                "firstName": profile.firstName,
                "station": profile.station,
                "lastName": profile.lastName
            }
        }
        return dispatch({
            type: SAVE_TEMPLATE_TASKCARD_DETAILS,
            payload: executeQuery(templateQueries.saveTemplateTaskCardDetails(), variable, getState(), 'TemplateManagement')
        }).then(resp => {
            if (resp.value.saveTemplateTaskCardDetails && !resp.value.saveTemplateTaskCardDetails.error) {
                const variable = {
                    "taskcardDetails": {
                        "mxMasterId": mxMasterId,
                        "searchType": searchType,
                        "station": visitInfo?.station,
                        ...(searchType ==="visit" && 
                        {"isHvyChkPackage": visitInfo?.enableZonalCard,
                        "tailNumber": visitInfo?.aircraftNumber,
                        "actualInductionDate":visitInfo?.actualInductionDate || visitInfo?.plannedInductionDate})
                    }
                }

                dispatch({
                    type: GET_TEMPLATE_TASKCARD_DETAILS,
                    payload: executeQuery(templateQueries.getTemplateTaskCardDetails(), variable, getState(), 'TemplateManagement')
                })
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Task card details has been saved successfully!"
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}


export const fetchTemplateManagementRefData = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: GET_TEMPLATE_MANAGEMENT_REF_DATA,
            payload: executeQuery(templateQueries.fetchTemplateManagementRefData(), null, getState(), 'TemplateManagement')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchTemplateMilestones = (id) => {
    return (dispatch, getState) => {
        const variable = {
            "request": {
                "mxTemplateId": id
              }            
        }

        return dispatch({
            type: FETCH_TEMPLATE_MILESTONES,
            payload: executeQuery(templateQueries.fetchTemplateMilestones(), variable, getState(), 'TemplateManagement')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const saveUpdateMilestone = (groupInfo, id, isTemplate) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        let variable = {
            saveTemplateGroup:
            {
                "mxTemplateId": isTemplate ? id : 0,
                "visitId": !isTemplate ? id : 0,
                "groupName": groupInfo.groupName,
                "shiftStartNumber": groupInfo.startShiftNumber,
                "shiftEndNumber": groupInfo.endShiftNumber,
                "modifiedBy": profile.userId,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "groupId": groupInfo.groupId ? groupInfo.groupId : 0
            }
        }
        return dispatch({
            type: SAVE_UPDATE_MILESTONE,
            payload: executeQuery(templateQueries.saveUpdateMilestone(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                if (resp.value.saveGroup && resp.value.saveGroup.isSuccess) {
                    dispatch({
                        type: SET_NOTIFICATION_MESSAGE,
                        payload: groupInfo.groupId ? "Milestone has been updated successfully" :
                            "Milestone has been added successfully"
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchTemplateManagementGroups = (id, searchType) => {
    return (dispatch, getState) => {
        const variable = {
            "request": {
                "id": id,
                "searchType": searchType
            }
        }

        return dispatch({
            type: GET_TEMPLATE_MANAGEMENT_GROUPS_DATA,
            payload: executeQuery(templateQueries.fetchTemplateManagementGroups(), variable, getState(), 'TemplateManagement')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const resetTaskCardDetailsErrorMessage = () => {
    return dispatch => {
        dispatch({
            type: RESET_TASK_CARD_DETAILS_ERROR_MESSAGE
        })
    }
}

export const resetTaskCardSearchData = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_TASKCARD_SEARCH_DATA,
            payload: null
        });
    }
}

export const setTaskCardSearchCriteria = (searchReq) => {
    return (dispatch, getState) => {
        return dispatch({
            type: SET_TASKCARD_SEARCH_CRITERIA,
            payload: searchReq
        });
    }
}

export const resetSaveUpdateMilestoneResp = () => {
    return dispatch => {
        return dispatch({
            type: RESET_SAVE_UPDATE_MILESTONE,
            payload: null
        })
    }
}

export const applyTemplate = (mxTemplateId) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        let variable = {
            applyTemplate:
            {
                "visitId": visitInfo.visitId,
                "templateId": mxTemplateId,
                "tailNumber": visitInfo.aircraftNumber,
                "modifiedBy": profile.userId,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "station": visitInfo.station,
                "inFlightNumber": null,
                "outFlightNumber": null,
                "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                "departureDate": visitInfo.actualETRDate || visitInfo.plannedETRDate,
                "estimatedArrivalDate": visitInfo.actualInductionDate,
                "estimatedDepartureDate": visitInfo.plannedETRDate,
                "packageType": visitInfo.checkType,
                "userId": profile.userId,
                "mxMasters": null,
                "isHvyChkPackage": visitInfo.enableZonalCard
            }
        }
        return dispatch({
            type: APPLY_TEMPLATE,
            payload: executeQuery(templateQueries.applyTemplate(), variable, getState(), 'TemplateManagement')
        })
    }
}
export const saveTemplate = (id, isTemplate, templateDetails, duration, isHvyChkPackage) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        let variable = {
            saveTemplate:
            {
                "templateName": templateDetails.templateName.value,
                "mxTemplateId": isTemplate ? id : null,
                "visitId": !isTemplate ? id : null,
                "modifiedBy": profile.userId,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "station": templateDetails.station.value,
                "checkType": templateDetails.checkType.value,
                "fleetType": templateDetails.fleetType.value,
                "duration": duration,
                "isHvyChkPackage": isHvyChkPackage

            }
        }
        return dispatch({
            type: SAVE_TEMPLATE,
            payload: executeQuery(templateQueries.saveTemplate(), variable, getState(), 'TemplateManagement')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

// {
//     "updateDetails": {
//       "mxTemplateId": 45,
//       "mxTemplateName": " Updatetemplatedetailstest",
//       "fleetType": "test",
//       "station": "IHC",
//       "checkType": "C Check",
//       "duration": 10,
//       "modifiedBy": "v934834",
//       "firstName": "Sandeep",
//       "lastName": "Venkata"
//     },
//     "userInfo": {
//       "userId": "v934834",
//       "firstName": "Sandeep",
//       "station": "IHC",
//       "lastName": "Venkata"
//     }
//   }

export const _updateTemplate = (id, isTemplate, templateDetails, duration, isHvyChkPackage) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        let variable = {
            updateDetails:
            {
                "mxTemplateName": templateDetails.templateName.value,
                "mxTemplateId": isTemplate ? id : null,
                "modifiedBy": profile.userId,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "station": templateDetails.station.value,
                "checkType": templateDetails.checkType.value,
                "fleetType": templateDetails.fleetType.value,
                "duration": templateDetails.duration.value,
            },
            "userInfo": {
                "userId": profile.userId,
                "firstName": profile.firstName,
                "station": templateDetails.station.value,
                "lastName": profile.lastName,
            }
        }
        return dispatch({
            type: UPDATE_TEMPLATE_DETAILS,
            payload: executeQuery(templateQueries.updateDetails(), variable, getState(), 'TemplateManagement')
        }).catch(error => {
            dispatch({
                type: SET_GENERIC_ERROR,
                payload: error.message
            })
        });
    }
}

export const resetSaveTemplateErrorMessage = () => {
    return dispatch => {
        dispatch({
            type: RESET_SAVE_TEMPLATE_ERROR_MESSAGE
        })
    }
}

export const resetApplyTemplateData = () => {
    return dispatch => {
        dispatch({
            type: RESET_APPLY_TEMPLATE
        })
    }
}

export const setUpdateTemplateModalFlag = (showModal) => {
    return dispatch => {
        dispatch({
            type: SET_UPDATE_TEMPLATE_FLAG,
            payload: showModal
        })
    }
}

export const updateTemplate = (mxTemplateId) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        let variable = {
            "updateTemplate": {
                "mxTemplateId": mxTemplateId,
                "visitId": visitInfo?.visitId,
                "modifiedBy": profile.userId,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "isHvyChkPackage": visitInfo?.enableZonalCard
            }
        }
        return dispatch({
            type: UPDATE_TEMPLATE,
            payload: trackPromise(executeQuery(templateQueries.updateTemplate(), variable, getState(), 'TemplateManagement'))
        })
            .then(resp => {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: resp.value.updateTemplateFromVisit && resp.value.updateTemplateFromVisit.isSuccess ? "Template updated successfully." : "Error in updating template."
                });
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const toggleExecutionPhase = () => dispatch => {
    return dispatch({
        type: TOGGLE_OPEN_CLOSE
    })
}

export const deleteTemplate = (mxTemplateId, token) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        let variable = {
            "deleteTemplate": {
                "templateId": mxTemplateId,
                "modifiedBy": profile.userId,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                token
            }
        }
        return dispatch({
            type: DELETE_TEMPLATE,
            payload: trackPromise(executeQuery(templateQueries.deleteTemplate(), variable, getState(), 'TemplateManagement'))
        })
            .then(resp => {
                if (resp.value.deleteTemplate.isSuccess && !resp.value.deleteTemplate.passwordErrorMessage) {
                    dispatch({
                        type: SET_NOTIFICATION_MESSAGE,
                        payload: "Template deleted successfully"
                    });
                    dispatch({
                        type: `${DELETE_TEMPLATE}_FULFILLED`,
                        payload: mxTemplateId
                    });
                }
                return resp.value.deleteTemplate;
            })
            .catch(err => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: err?.message
                })
            })
    }
}