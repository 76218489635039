import {
    FETCH_REGISTER_DATA, HANDLE_DASH8_SELECTION, SET_SEARCH_CARDS_TEXT, UPDATE_DASH1_SELECTION, SIGNOFF_PAPERDASH8, MARK_PAPERDASH1_STATUS,
    RESET_PAPERDASH1_ERROR_MESSAGE, TOGGLE_PAPER_SIGNOFF_MODAL, RESET_PAPERDASH8_ERRORS, FETCH_WORKLOAD_ACCOUNTABILITY_DATA, ACCOUNTABILITY_FILTERS,
    RESET_ACCOUNTABILITY_DATA, RESET_ROUTINE_REGISTER, GET_MRD_STATUS, SET_SHOW_CHECK_MRD_MODAL, CHECK_MRD, SET_SHOW_ISSUE_MRD_CONFIRMATION_MODAL,
    SET_SHOW_ISSUE_MRD_ERROR_MODAL, ISSUE_MRD, GET_STATION_LOCAL_TIME, RESET_MRD_ERROR_MESSAGES, SET_SHOW_VIEW_MRD_MODAL, VIEW_MRD, FETCH_CARRIED_DEFFERED_LOGPAGE_DATA,
    FETCH_INDUCTED_AIRCRAFT_INFO, RESET_INDUCTED_AIRCRAFT_INFO, VIEW_MFD, FETCH_PARTS_AND_TOOLS_DATA, SET_SHOW_PARTS_AND_TOOLS_SUMMARY_LINK, PARTS_AND_TOOLS_FILTERS,
    SEARCH_HARMONIZED_WORKLOAD, RESET_SEARCH_HARMONIZED_WORKLOAD, FETCH_EXTENDED_ROUTING, UPDATE_DASH1_NOTES_COUNT, SET_INSP_WIDGET_FLOW_FLAG, FETCH_ADVANCE_WORKLOAD_SEARCH,
    SELF_ASSOCIATE_WL, FETCH_ASSIGNED_WORK, HANDLE_BASE_ASSIGNWORK_TOGGLE, SET_ASSIGN_TOGGLE, UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY, GET_OPTMIZED_STATUSES
} from '../actions/actionTypes';
import moment from 'moment';
import ActionButton from 'components/Common/ActionButton/ActionButton';

const initialState = {
    isLoadingRegisterData: true,
    registerData: null,
    selectedDash1: null,
    searchCardsText: "",
    paperDash1CompleteErrorMessage: "",
    paperSignoffPasswordFailureMessage: null,
    isPaperSignoffSuccess: false,
    paperSignoffFailureMessage: null,
    showPaperSignOffModal: false,
    isLoadingAccountabilityData: false,
    accountabilityData: null,
    accountabilityFilters: { desc: "", workItemStatus: [], workItemType: [] },
    flifo: null,
    selectedWorkloadInfo: null,
    isLoadingMRDInfo: true,
    isLoadingModalMRDInfo: true,
    mRDInfo: null,
    checkMRDInfo: null,
    showCheckMrdModal: false,
    showIssueMrdConfirmationModal: false,
    passwordencrypted: null,
    showIssueMrdErrorModal: false,
    isLoadingCarriedDeferredLPData: false,
    carriedAndDeferredLogPages: null,
    isTCMActive: false,
    fRMCodesForTCM: ["UA3245MG01","UA3245MG02", "UA3245MG03" , "UA3245MG04" , "UA3245MG05", "UA3245MG06" , "UA3245MG07" , "UA3245MG08", "UA3245MG09" , "UA3245MG10" , "UA3245MG11", "UA3245MG12" , "UA3245NG05" , "UA3245NG06", "UA3245NG09" , "UA3245NG10" , "UA3245NG13" , "UA3245NG14"],
    stationLocalTime: moment(),
    checkMRDInfoErrorMessage: null,
    showViewMrdModal: false,
    viewMRDInfo: null,
    viewMRDInfoErrorMessage: null,
    isInductedLineAircraft: false,
    inductedAircraftInfo: null,
    isLoadingPartsAndToolsData: false,
    partsAndToolsData: null,
    partsAndToolsFilters: { mepn_mfg: "", keywords: "", hardwareType: "both" },
    showPartsAndToolsSummaryLink: false,
    searchHarmonizedSearchCriteria: {
        // card_or_desc: "",
        // filterIn: [],
        // assignedToMe: false
    },
    inspectionWidgetFlow: false,
    advancedWorkloadSearch: null,
    isLoadingAssignedWork: false,
    assignedWorkload: null,
    filteredWorkload: null,
    baseAssignedWorkToggleOn: false,
    assignedWorkToggleOn : null,
    optimizedStatuses: null
    //advanceSearchData: null;
}

const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${TOGGLE_PAPER_SIGNOFF_MODAL}`: {
            return {
                ...state,
                showPaperSignOffModal: action.payload
            }
        }
        case `${FETCH_REGISTER_DATA}_PENDING`: {
            return {
                ...state,
                isLoadingRegisterData: true,
                filteredWorkload: null,
                registerData: null,
                baseAssignedWorkToggleOn: false
            }
        }
        case `${FETCH_REGISTER_DATA}_FULFILLED`: {
            return {
                ...state,
                isLoadingRegisterData: false,
                registerData: action.payload.routineRegister,
                filteredWorkload: action.payload.routineRegister,
                flifo: { aircraftNumber: "0212", arrivalDate: "2020-04-08T21:50:00", departureDate: "2021-02-07T08:20:00", inFlightNumber: "1666" },
            }
        }
        case UPDATE_DASH1_NOTES_COUNT:{
            const searchWL = (window.location.pathname.includes('SearchWorkload') && state.advancedWorkloadSearch)
            const registerData = searchWL ? { ...state.advancedWorkloadSearch } : { ...state.filteredWorkload };
            let _workStop = false;
            const updatedRegisterData = registerData.dash8s.map(item =>{
                if ((!action.payload.isDup && (item.dash8Number === action.payload.dash8 && item.serialNumber === action.payload.serialNumber))
                    ||
                    (action.payload.isDup && action.payload.optimizedDash8s.find(d8 => d8.ncaPartNumber === item.dash8Number))) {
                    item.dash1s.map(items =>{ 
                        _workStop = items.workStop;
                        if ((!action.payload.isDup && (items.dash1Number == action.payload.dash1))
                            ||
                            (action.payload.isDup && items.dash1Number === action.payload.dash1 && items.optimizedGroup === action.payload.optimizedGroup)
                            ||
                            items.cardId == action.payload.dash1)
                        {
                            items.notesCount += 1;
                            items.lastNote = `${action.payload.notes}${(action.payload.actionType?.abbreviatedActionType && action.payload.workbin?.workBinName) ? ` - Task Card has been added to workbin ${action.payload.workbin?.workBinName}` : ''}`;
                            //items.notesActionTypeAbbreviated = action.payload.actionType?.abbreviatedActionType ? action.payload.actionType?.abbreviatedActionType : items.notesActionTypeAbbreviated;
                            items.notesActionTypeAbbreviated = action.payload.actionType?.abbreviatedActionType;
                            items.lastNoteDetail = action.payload.lastNoteDetail
                            // items.lastNoteDetail = `${action.payload.employeeName} (${action.payload.createdBy}) | ${action.payload.createdDate}`
                            //    if(items.workStop === false) {
                            _workStop = action.payload.workStop
                            items.workStop = action.payload.workStop
                            //    }                           
                        }
                        return items;
                        })
                        item.workStop = _workStop;
                }
                return item;
            })
            return{
                ...state,
                ...(searchWL ? {advancedWorkloadSearch:{...state.advancedWorkloadSearch, dash8s: updatedRegisterData}} :{filteredWorkload: {...state.filteredWorkload, dash8s: updatedRegisterData}})                
            }
        }
        case `${HANDLE_DASH8_SELECTION}`: {
            return {
                ...state,
                selectedWorkloadInfo: action.payload,
                // selectedDash8: action.payload.selectedDash8,
                // selectionType: action.payload.selectionType,
                // accordionTitle: action.payload.accordionTitle
            }
        }
        case `${UPDATE_DASH1_SELECTION}`: {
            return {
                ...state,
                selectedDash1: action.payload
            }
        }
        case `${SET_SEARCH_CARDS_TEXT}`: {
            return {
                ...state,
                searchCardsText: action.payload
            }
        }
        case `${MARK_PAPERDASH1_STATUS}_PENDING`: {
            return {
                ...state,
                isLoadingRegisterData: true,
                paperDash1CompleteErrorMessage: null
            }
        }
        case `${MARK_PAPERDASH1_STATUS}_FULFILLED`: {
            return {
                ...state,
                isLoadingRegisterData: false,
                paperDash1CompleteErrorMessage: action.payload.markPaperCardComplete.errorMessage ? action.payload.markPaperCardComplete.errorMessage[0] : "",
                isSuccess: action.payload.markPaperCardComplete.isSuccess
                // lastRefreshed: moment().format("MM/DD/YYYY HH:mm")
            }
        }
        case `${RESET_PAPERDASH1_ERROR_MESSAGE}`: {
            return {
                ...state,
                paperDash1CompleteErrorMessage: ""
                // lastRefreshed: moment().format("MM/DD/YYYY HH:mm")
            }
        }
        case `${SIGNOFF_PAPERDASH8}_PENDING`: {
            return {
                ...state,
                isLoadingRegisterData: true,
                paperSignoffPasswordFailureMessage: null,
                paperSignoffFailureMessage: null
            }
        }
        case `${SIGNOFF_PAPERDASH8}_FULFILLED`: {
            return {
                ...state,
                isLoadingRegisterData: false,
                paperSignoffPasswordFailureMessage: action.payload.dash8SignOff && action.payload.dash8SignOff.passwordErrorMessage !== null ? action.payload.dash8SignOff.passwordErrorMessage : null,
                showPaperSignOffModal: action.payload.dash8SignOff && action.payload.dash8SignOff.passwordErrorMessage !== null ? true : false,
                isPaperSignoffSuccess: action.payload.dash8SignOff && action.payload.dash8SignOff.errorMessage === null ? true : false,
                paperSignoffFailureMessage: action.payload.dash8SignOff && action.payload.dash8SignOff.errorMessage !== null ? action.payload.dash8SignOff.errorMessage[0] : null,
                // lastRefreshed: moment().format("MM/DD/YYYY HH:mm")
            }
        }
        case `${RESET_PAPERDASH8_ERRORS}`: {
            return {
                ...state,
                paperSignoffPasswordFailureMessage: null,
                isPaperSignoffSuccess: null,
                paperSignoffFailureMessage: null
            }
        }
        case `${FETCH_WORKLOAD_ACCOUNTABILITY_DATA}_PENDING`: {
            return {
                ...state,
                isLoadingAccountabilityData: true,
                accountabilityData: null
            }
        }
        case `${FETCH_WORKLOAD_ACCOUNTABILITY_DATA}_FULFILLED`: {
            return {
                ...state,
                isLoadingAccountabilityData: false,
                // accountabilityData: tmp
                accountabilityData: action.payload.getWorkloadAccountability
                // lastRefreshed: moment().format("MM/DD/YYYY HH:mm")
            }
        }
        case `${ACCOUNTABILITY_FILTERS}`: {
            return {
                ...state,
                accountabilityFilters: action.payload
            }
        }
        case `${RESET_ACCOUNTABILITY_DATA}`: {
            return {
                ...state,
                accountabilityData: null,
                isLoadingAccountabilityData: true
            }
        }
        case `${RESET_ROUTINE_REGISTER}`: {
            return {
                ...state,
                isLoadingRegisterData: true,
                registerData: null,
                filteredWorkload: null
            }
        }
        case `${GET_MRD_STATUS}_PENDING`: {
            return {
                ...state,
                isLoadingMRDInfo: true,
                mRDInfo: null
            }
        }
        case `${GET_MRD_STATUS}_FULFILLED`: {
            return {
                ...state,
                isLoadingMRDInfo: false,
                mRDInfo: action.payload.getMRDStatus,
            }
        }
        case `${CHECK_MRD}_PENDING`: {
            return {
                ...state,
                //isLoadingMRDInfo: true,
                isLoadingModalMRDInfo: true,
                checkMRDInfo: null,
                checkMRDInfoErrorMessage: null
            }
        }
        case `${CHECK_MRD}_FULFILLED`: {
            return {
                ...state,
                //isLoadingMRDInfo: false,
                isLoadingModalMRDInfo: false,
                checkMRDInfo: action.payload.checkMrd,
                checkMRDInfoErrorMessage: action.payload.checkMrd.checkMrdInfo.errorMessage
            }
        }
        case `${VIEW_MRD}_PENDING`: {
            return {
                ...state,
                // isLoadingMRDInfo: true,
                viewMRDInfo: null,
                viewMRDInfoErrorMessage: null
            }
        }
        case `${VIEW_MRD}_FULFILLED`: {
            return {
                ...state,
                // isLoadingMRDInfo: false,
                viewMRDInfo: action.payload.viewMrd,
                viewMRDInfoErrorMessage: action.payload.viewMrd.errorMessage
            }
        }
        case `${VIEW_MFD}_PENDING`: {
            return {
                ...state,
                // isLoadingMRDInfo: true,
                viewMRDInfo: null,
                viewMRDInfoErrorMessage: null
            }
        }
        case `${VIEW_MFD}_FULFILLED`: {
            return {
                ...state,
                // isLoadingMRDInfo: false,
                viewMRDInfo: action.payload.viewMfd,
                viewMRDInfoErrorMessage: action.payload.viewMfd.errorMessage
            }
        }
        case `${SET_SHOW_VIEW_MRD_MODAL}`: {
            return {
                ...state,
                showViewMrdModal: action.payload.showViewMrdModal,
                passwordencrypted: action.payload.password
            }
        }
        case `${SET_SHOW_CHECK_MRD_MODAL}`: {
            return {
                ...state,
                showCheckMrdModal: action.payload
            }
        }
        case `${SET_SHOW_ISSUE_MRD_CONFIRMATION_MODAL}`: {
            return {
                ...state,
                showIssueMrdConfirmationModal: action.payload.showIssueMrdConfirmationModal,
                passwordencrypted: action.payload.password
            }
        }
        case `${ISSUE_MRD}_PENDING`: {
            return {
                ...state,
                // isLoadingMRDInfo: true,
                issueMRDInfoErrors: null
            }
        }
        case `${ISSUE_MRD}_FULFILLED`: {
            return {
                ...state,
                // isLoadingMRDInfo: false,
                issueMRDInfoErrors: action.payload.issueMrd.errorMessage,
            }
        }
        case `${SET_SHOW_ISSUE_MRD_ERROR_MODAL}`: {
            return {
                ...state,
                showIssueMrdErrorModal: action.payload,
            }
        }
        case `${RESET_MRD_ERROR_MESSAGES}`: {
            return {
                ...state,
                issueMRDInfoErrors: null,
                mRDInfo: null,
                checkMRDInfoErrorMessage: null,
                viewMRDInfoErrorMessage: null
            }
        }
        case `${GET_STATION_LOCAL_TIME}_FULFILLED`: {
            return {
                ...state,
                stationLocalTime: action.payload.getLocalTime ? moment(action.payload.getLocalTime.localDateTime) : moment()
            }
        }
        case `${FETCH_CARRIED_DEFFERED_LOGPAGE_DATA}_PENDING`: {
            return {
                ...state,
                isLoadingCarriedDeferredLPData: true,
                carriedAndDeferredLogPages: null,
                isTCMActive: false
            }
        }
        case `${FETCH_CARRIED_DEFFERED_LOGPAGE_DATA}_FULFILLED`: {
            const lPages = action.payload?.getDeferredAndCarriedLPs;
            const tcmEnabled = lPages.filter(lp => lp.logpageStatus === "CARRY" && [...state.fRMCodesForTCM]?.includes(lp.fRM)).length > 0;            
            return {
                ...state,
                isLoadingCarriedDeferredLPData: false,
                carriedAndDeferredLogPages: action.payload.getDeferredAndCarriedLPs,
                isTCMActive: tcmEnabled
            }
        }
        case `${FETCH_INDUCTED_AIRCRAFT_INFO}_PENDING`: {
            return {
                ...state,
                isLoadingRegisterData: true
            }
        }
        case `${FETCH_INDUCTED_AIRCRAFT_INFO}_FULFILLED`: {
            return {
                ...state,
                inductedAircraftInfo: action.payload.getInductedACData,
                isInductedLineAircraft: action.payload.getInductedACData && action.payload.getInductedACData.isInducted
            }
        }
        case `${RESET_INDUCTED_AIRCRAFT_INFO}`: {
            return {
                ...state,
                isInductedLineAircraft: false
            }
        }
        case `${FETCH_PARTS_AND_TOOLS_DATA}_PENDING`: {
            return {
                ...state,
                isLoadingPartsAndToolsData: true,
                partsAndToolsData: null
            }
        }
        case `${FETCH_PARTS_AND_TOOLS_DATA}_FULFILLED`: {
            return {
                ...state,
                isLoadingPartsAndToolsData: false,
                partsAndToolsData: action.payload.getPartsAndTools
            }
        }
        case `${SET_SHOW_PARTS_AND_TOOLS_SUMMARY_LINK}`: {
            return {
                ...state,
                showPartsAndToolsSummaryLink: action.payload,
            }
        }
        case `${PARTS_AND_TOOLS_FILTERS}`: {
            return {
                ...state,
                partsAndToolsFilters: action.payload
            }
        }
        case `${SEARCH_HARMONIZED_WORKLOAD}`: {
            return {
                ...state,
                searchHarmonizedSearchCriteria: action.payload,
                advancedWorkloadSearch: action.payload.advancedSearch ? state.advancedWorkloadSearch : null
            }
        }
        case UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY:{
            const _advancedWorkloadSearch = {...state.advancedWorkloadSearch};
            if(_advancedWorkloadSearch && _advancedWorkloadSearch.aircraftNonRoutines){
                _advancedWorkloadSearch.aircraftNonRoutines = _advancedWorkloadSearch.aircraftNonRoutines.map(nr=>{
                    if(nr.nonRoutineID === action.payload.nonroutineid){
                        nr.notesCount +=1;
                        nr['workStop'] = action.payload.workStop ?? false;
                        nr.lastNote = `${action.payload.notes}${(action.payload.abbreviatedActionType && action.payload.workbin?.workBinName) ? ` - Nonroutine has been added to workbin ${action.payload.workbin?.workBinName}` : ''}` ;
                        nr['notesActionTypeAbbreviated'] = action.payload?.abbreviatedActionType ?? '';
                        nr.lastNoteDetail = action.payload.lastNoteDetail
                    }
                    return nr;
                })
            }
            return{
                ...state, 
                advancedWorkloadSearch: _advancedWorkloadSearch
            }
        }
        case `${RESET_SEARCH_HARMONIZED_WORKLOAD}`: {
            return {
                ...state,
                searchHarmonizedSearchCriteria: {}
            }
        }
        case `${FETCH_EXTENDED_ROUTING}_PENDING`: {
            return {
                ...state,
                extendedRoutingLoading: true,
                extendedRoutingInfo: null,
            }
        }
        case `${FETCH_EXTENDED_ROUTING}_FULFILLED`: {
            return {
                ...state,
                extendedRoutingLoading: false,
                extendedRoutingInfo: action.payload ? action.payload.getAircraftAndRoutingInfo?.aircraftandRoutingInfo :  null,
            }
        }
        case `${SET_INSP_WIDGET_FLOW_FLAG}`: {
            return {
                ...state,
                inspectionWidgetFlow: action.payload
            }
        }
        case `${FETCH_ADVANCE_WORKLOAD_SEARCH}_PENDING`:{
            return {
                ...state,
                isLoadingRegisterData: true,
                advancedWorkloadSearch: null
            }
        }
        case `${FETCH_ADVANCE_WORKLOAD_SEARCH}_FULFILLED`:{
            return {
                ...state,
                isLoadingRegisterData: false,
                // searchHarmonizedSearchCriteria:{...state.searchHarmonizedSearchCriteria, advancedSearch: true},
                advancedWorkloadSearch:action.payload?.advWorkloadSearchMultiSelect
                // advancedWorkloadSearch:action.payload?.advWorkloadSearch
            }
        }

        case `SELF_ASSOCIATE_WL_SUCCESS`: {
            const selectedWorkloadInfo = { ...state.selectedWorkloadInfo };
            const selectedDash8 = selectedWorkloadInfo?.selectedDash8;
            const dash8Number =  action.payload.tailWorkItems[0].mxWorkItem;
            const dash1Number = action.payload.tailWorkItems[0].mxSubWorkItem;
            const tailWorkItem = action.payload.tailWorkItems[0];
            const assignedDash8s = action.payload.tailWorkItems.map(wi => wi.mxWorkItem);
            const {techAssigned, isAssign} = action.payload;
            // Assuming itemType == LP, then scheduled Log page
            const itemType = action.payload.type;
            // in order to show unassign button once the  workload is self-assigned without reloading page and getting register api resp to show we are using below
            const user = action.payload.technicianAssigned?.map(user => {
                return{
                    assigneeFullName: `${user.firstName} ${user.lastName}`,
                    assigneeName: user.empId?.toUpperCase(),
                    eTaskItemId: 0,
                    hasTagged: false,
                    assignmentCreatedBy: user.empId?.toUpperCase()
                }
            }) || [];
            const _registerData = {...state.filteredWorkload};
            let updatedRegData = [];
            let updatedSLP=[];
            let updatedLP=[];
            if (itemType === "D1ZC") {
                //writing if condition to seperate out local assignee updates for dup cards
                if (action.payload.isDup) {
                    updatedRegData = _registerData.dash8s.map(dash8 => {
                        if (assignedDash8s.find(d8 => d8 === dash8.dash8Number.replace(/\-/g, '') && dash8.workControlNumber === tailWorkItem.workControlNumber)) {
                            dash8.dash1s = dash8.dash1s.map(dash1 => {
                                if (dash1.dash1Number.replace(/\-/g, '') === dash1Number && dash1.optimizedGroup === tailWorkItem.optimizedGroup) {
                                    if (!isAssign) {
                                        dash1.assignees = dash1.assignees.filter(assignee => assignee.assigneeName?.toUpperCase() !== techAssigned.empId?.toUpperCase())
                                    } else {
                                        dash1.assignees = [...((dash1?.assignees) ? dash1.assignees : []), ...user]
                                    }
                                }
                                return dash1
                            })
                            dash8.assignees = dash8?.assignees ? [...(isAssign ? [...user, ...dash8.assignees] : dash8.assignees.filter(assignee => assignee.assigneeName !== techAssigned.empId))] : [...user]
                        }
                        return dash8;                        
                    });
                }
                else {
                    updatedRegData = _registerData.dash8s.map(dash8 => {
                        if (dash8.dash8Number.replace(/\-/g, '') === dash8Number && selectedDash8 && dash8.jobNumber === selectedDash8.jobNumber && dash8.positionCode === selectedDash8.positionCode) {
                            dash8.dash1s = dash8.dash1s.map(dash1 => {
                                if (dash1.dash1Number.replace(/\-/g, '') === dash1Number || dash1.cardId === dash1Number) {
                                    if (!isAssign) {
                                        dash1.assignees = dash1.assignees.filter(assignee => assignee.assigneeName !== techAssigned.empId)
                                    } else {
                                        dash1.assignees = [...((dash1?.assignees) ? dash1.assignees : []), ...user]
                                    }
                                }
                                return dash1
                            })
                            dash8.assignees = dash8?.assignees ? [...(isAssign ? [...user, ...dash8.assignees] : dash8.assignees.filter(assignee => assignee.assigneeName !== techAssigned.empId))] : [...user]
                        }
                        return dash8;
                    });
                }

                } else if(itemType === "openLP") {
                    updatedLP = _registerData.openLogpages.map(olp =>{
                        if(olp.logpageNumber === dash8Number){
                            if(!isAssign){
                                olp.assignees = olp.assignees.filter(assignee => assignee.assigneeName!== techAssigned.empId)
                            }else{
                                olp.assignees = [...((olp?.assignees) ? olp.assignees : []), ...user]
                            }
                        }
                        return olp
                    })
                } else if(itemType === "scheduledLP") {
                    updatedSLP = _registerData.scheduledLogPages.map(slp =>{
                        if(slp.logpageNumber === dash8Number){
                            if(!isAssign){
                                slp.assignees = slp.assignees.filter(assignee => assignee.assigneeName!== techAssigned.empId)
                            }else{
                                slp.assignees = [...((slp?.assignees) ? slp.assignees : []), ...user]
                            }
                        }
                        return slp
                    })
                }
            
            return{
                ...state,
                //registerData: { ..._registerData, ...(itemType === "D1ZC" ? { dash8s: updatedRegData } : itemType === "scheduledLP" ? { scheduledLogPages: updatedSLP } : itemType === "openLP" ? { openLogpages: updatedLP } : _registerData) },
                filteredWorkload: {..._registerData, ...(itemType === "D1ZC" ? {dash8s: updatedRegData} : itemType === "scheduledLP" ? {scheduledLogPages: updatedSLP} : itemType === "openLP" ? {openLogpages: updatedLP} : _registerData)}
            }
        }
        case `${FETCH_ASSIGNED_WORK}_PENDING`: {
            return {
                ...state,
                isLoadingRegisterData: true,
                assignedWorkload: null,
                filteredWorkload: null,
                baseAssignedWorkToggleOn: true
            }
        }
        case `${FETCH_ASSIGNED_WORK}_FULFILLED`: {
            return {
                ...state,
                isLoadingRegisterData: false,
                assignedWorkload: action.payload.getHarmonizedWorkloadAssignedWork,
                filteredWorkload: action.payload.getHarmonizedWorkloadAssignedWork,
            }
        }
            
        case `${HANDLE_BASE_ASSIGNWORK_TOGGLE}`: {
            let workload = action.payload ? state.assignedWorkload : state.registerData;
            return {
                ...state,
                baseAssignedWorkToggleOn: action.paylod,
                filteredWorkload: workload
            }
        }
            
        case `${SET_ASSIGN_TOGGLE}`: {
            return {
                ...state,
                assignedWorkToggleOn: action.payload
            }
        }
        case `${GET_OPTMIZED_STATUSES}`: {
            return {
                ...state,
                optimizedStatuses: action.payload
            }
        }
        case `UPDATE_WL_PAPERCARD_ASSIGNEES`:
        case 'UPDATE_WL_TASKCARD_ASSIGNEES':{
            const {techsSelected, unAssignedUsers, workItems, cardType} = action.payload;
            const _registerData = state.assignedWorkToggleOn ? {...state.filteredWorkload} : {...state.registerData}; //added filteredworkload as assignees are not getting updated once after show my assigned work is checked
            // Update dash8s in the register data
            // mxSubWorkItem  => dash1 id
            // mxWorkItem     => dash8 id
            let dash8s= [];
            try {
                let dash8Assignees = []
                 dash8s = _registerData[cardType === 'taskcard' ? 'dash8s' :'paperTaskCards'].map(dash8 =>{
                     if(dash8.dash8Number === workItems.mxWorkItem){
                         const assignees = techsSelected.map(tech =>({
                             assigneeFullName: `${tech.firstName} ${tech.lastName}`,
                             assigneeName: tech.empId,
                             eTaskItemId:0,
                             hasTagged: false
                         }));
                         dash8.dash1s = dash8.dash1s.map(dash1 => {
                             if(dash1['dash1Number'] === workItems.mxSubWorkItem || dash1['cardId'] === workItems.mxSubWorkItem){
                                 const existingtechs = dash1?.assignees ? dash1?.assignees?.filter(assignee => /*assignee.hasOwnProperty('id') ? !unAssignedUsers.includes(assignee.id) :*/ !unAssignedUsers.includes(assignee.assigneeName)) : []
                                 dash1.assignees=[...existingtechs.map(i => ({...i, assigneeName: /*i.id || */i.assigneeName})), ...assignees].filter((item, idx, _array) => _array.findIndex(emp => emp.assigneeName === item.assigneeName ) === idx);
                             }
                             dash8Assignees = [...dash8Assignees, ...(dash1.assignees ? dash1.assignees : [])];
                             return dash1;
                         })
                         dash8.assignees = dash8Assignees?.filter((item, idx, _array) => _array.findIndex(emp => emp.assigneeName === item.assigneeName ) === idx);
                         return dash8;
                     }
                     return dash8
                 })
            } catch (error) {
                console.error(error)
            }
        
            return{
                ...state,
                [state.assignedWorkToggleOn ? 'filteredWorkload' : 'registerData']: {...state.registerData, [cardType === 'taskcard' ? 'dash8s' :'paperTaskCards']:dash8s }
            }
        }
        case 'UPDATE_WL_TASKCARD_ASSIGNEES_DUP':{
            const {techsSelected, unAssignedUsers, workItems, cardType} = action.payload;
            const _registerData = {...state.registerData};
            // Update dash8s in the register data
            // mxSubWorkItem  => dash1 id
            // mxWorkItem     => dash8 id
            let dash8s= [];
            try {                
                dash8s = _registerData[cardType === 'taskcard' ? 'dash8s' : 'paperTaskCards'].map(dash8 => {                     
                    if (workItems.find(wi => wi.mxWorkItem === dash8.dash8Number)) {
                        let dash8Assignees = []
                         const assignees = techsSelected.map(tech =>({
                             assigneeFullName: `${tech.firstName} ${tech.lastName}`,
                             assigneeName: tech.empId,
                             eTaskItemId:0,
                             hasTagged: false
                         }));
                         dash8.dash1s = dash8.dash1s.map(dash1 => {
                             if (workItems.find(wi => wi.mxSubWorkItem === dash1['dash1Number'] && dash1.optimizedGroup === wi.optimizedGroup))
                             {
                                 const existingtechs = dash1?.assignees ? dash1?.assignees?.filter(assignee => !unAssignedUsers.includes(assignee.assigneeName)) : []
                                 dash1.assignees = [...existingtechs, ...assignees].filter((item, idx, _array) => _array.findIndex(emp => emp.assigneeName === item.assigneeName) === idx)
                             }
                             dash8Assignees = [...dash8Assignees, ...(dash1.assignees ? dash1.assignees : [])]                             
                             return dash1;
                         })
                         dash8.assignees = dash8Assignees?.filter((item, idx, _array) => _array.findIndex(emp => emp.assigneeName === item.assigneeName ) === idx)
                         return dash8;
                     }
                     return dash8
                 })
            } catch (error) {
                console.error(error)
            }
        
            return{
                ...state,
                registerData: {...state.registerData, [cardType === 'taskcard' ? 'dash8s' :'paperTaskCards']:dash8s }
            }
        }
        case 'UPDATE_WL_LP_ASSIGNEES':{
            const {techsSelected, unAssignedUsers, workItems} = action.payload;
            const _registerData = state.registerData;
            let slps= [];
            try {                
                 slps = _registerData.scheduledLogPages.map(slp =>{
                     if(slp.logpageNumber === workItems.mxWorkItem){
                         const assignees = techsSelected.map(tech =>({
                             assigneeFullName: `${tech.firstName} ${tech.lastName}`,
                             assigneeName: tech.empId,
                             technicianId: tech.empId
                         }));
                        const existingtechs = slp?.assignees ? slp?.assignees?.filter(assignee => !unAssignedUsers.includes(assignee.technicianId)) : [];
                        slp.assignees = [...existingtechs, ...assignees].filter((item, idx, _array) => _array.findIndex(emp => emp.technicianId === item.technicianId ) === idx)
                     }
                     return slp;
                 })
            } catch (error) {
                console.error(error)
            }
        
            return{
                ...state,
                registerData: {...state.registerData, scheduledLogPages: slps }
            }
        }
        case 'UPDATE_WL_OLP_ASSIGNEES':{
            const {techsSelected, unAssignedUsers, workItems} = action.payload;
            const _registerData = state.registerData;
            let olps= [];
            try {                
                 olps = _registerData.openLogpages.map(olp =>{
                     if(olp.logpageNumber === workItems.mxWorkItem){
                         const assignees = techsSelected.map(tech =>({
                             assigneeFullName: `${tech.firstName} ${tech.lastName}`,
                             assigneeName: tech.empId,
                             technicianId: tech.empId
                         }));
                        const existingtechs = olp?.assignees ? olp?.assignees?.filter(assignee => !unAssignedUsers.includes(assignee.technicianId)) : [];
                        olp.assignees = [...existingtechs, ...assignees].filter((item, idx, _array) => _array.findIndex(emp => emp.technicianId === item.technicianId ) === idx);
                     }
                     return olp;
                 })
            } catch (error) {
                console.error(error)
            }
        
            return{
                ...state,
                registerData: {...state.registerData, openLogPages: olps }
            }
        }
        default: return state;
    }
}

export default registerReducer;