export const fetchProfileQuery = () => {
  return `{          
        profile {
          userId
          firstName
          lastName
          station
        }          
      }`
}

export const fetchExtendedProfile = (userId, station) => {
  return `query Profile {
        getProfile(userId: "`+ userId + `") {
          userId
          firstName
          lastName
          station
          jobRole
          originalEtaskRole
          isBaseOverride
          userActions
          isBaseFlow
          isProductionController
          isVendorStation
          lTerm
        }
      }`
}

export const saveFeedback = () => {
  return `mutation Application($feedbackRequest: FeedbackInputType, $userInfo: UserInformationInputType) {
    submitFeedbackWithAttachments(
      feedbackRequest: $feedbackRequest
      userInfo: $userInfo
    ) {
      feedbackId
      serviceNowHRCaseNumber
      errors
    }
  }`
}