import { FETCH_ARCHIVE_SEARCH_RESULTS, HANDLE_SORT_ARCHIVE, STORE_ARCHIVE_SEARCH_PARAMS, UPDATE_DASH1_SELECTION, SET_LAST_REFRESHED, SET_GENERIC_ERROR, RESET_WARNING_MESSAGE,RESET_SORT } from './actionTypes';
import * as Queries from '../queries/ArchiveSearchQueries';
import { executeQuery } from '../helper';


export const fetchArchiveSearchResults = (cardNumber, station, tailNumber, startDate, endDate,keyword) => {
  const variable = {
    "archivedSearch": {
      "station": station,
      "tailNumber": tailNumber,
      "cardNumber": cardNumber,
      "startDate": startDate,
      "endDate": endDate,
      "keyword": keyword
    }
    // "archivedSearch": {
    //     "station": 'smb',
    //     "tailNumber":'4043',
    //     "cardNumber": '',
    //     "startDate": '2019-12-11 12:36:42.083',
    //     "endDate": '2019-12-11 12:36:42.083' 
    //   }
  }
  // '2500-08151-001', '4043', 'smb', '2019-12-11 12:36:42.083', '2019-12-11 12:36:42.083' -unknown
  // "archivedSearch": {
  //   "station": 'smb',
  //   "tailNumber":'4043',
  //   "cardNumber": '',
  //   "startDate": '2019-12-11 12:36:42.083',
  //   "endDate": '2019-12-11 12:36:42.083' 
  // }
  //0065-EA, task
  // "archivedSearch": {
  //   "station": 'smb',
  //   "tailNumber":'4043',
  //   "cardNumber": '',
  //   "startDate": '2019-12-11 12:36:42.083',
  //   "endDate": '2019-12-11 12:36:42.083' 
  // }
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_ARCHIVE_SEARCH_RESULTS,
      payload: executeQuery(Queries.fetchArchiveSearchResults(), variable, getState(), 'Visit')
    }).then(resp => {
      if (resp !== null && resp.openVisits !== null) {
        dispatch({
          type: SET_LAST_REFRESHED,
          payload: Date.now()
        })
      }
    })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }
}

export const handleSort = (type, order, data) => {

  return dispatch => {
    dispatch({
      type: HANDLE_SORT_ARCHIVE,
      payload: {
        type: type,
        order: order,
        data: data
      }
    })
  }
}
export const storeArchiveSearchParams = (cardNumber, station, tailNumber, startDate, endDate,keyword) => {

  return dispatch => {
    dispatch({
      type: STORE_ARCHIVE_SEARCH_PARAMS,
      payload: {
        cardNumber: cardNumber,
        station: station,
        tailNumber: tailNumber,
        startDate: startDate,
        endDate: endDate,
        keyword: keyword
      }
    })
  }
}
export const updateSelectedArchiveCard = (dash1) => {

  return dispatch => {
    dispatch({
      type: UPDATE_DASH1_SELECTION,
      payload: dash1
    })
  }
}
export const resetSort = () => {

  return dispatch => {
    dispatch({
      type: RESET_SORT,

    })
  }
}
export const resetWarningMessage = () => {
  return dispatch => {
    dispatch({
      type: RESET_WARNING_MESSAGE
    });
  }
}