export const setNonRoutinesActionVariable = (nonRoutine, profile, visitInfo,actionType) => {
    return {
        "token": "",
        "employeeId": profile.userId,
        // "mxNotes": nonRoutine.mxNotes,
        //"workStop": nonRoutine.workStop,
        "actionTypeId": 1,
        ...(actionType.toLowerCase() === 'manage' ?{"taskCardDependencies":nonRoutine?.taskCardDependencies || []} :{}),    
        "groupName":nonRoutine.groupName,
        "nonRoutine":{
            "specNumber": nonRoutine.nonroutine.specNumber,
            "aTA": nonRoutine.nonroutine.aTA,
            "nonRoutineID": nonRoutine.nonroutine.nonRoutineID,
            "lastModifiedDate": nonRoutine.nonroutine.lastModifiedDate,
            "nonRoutineStatusID": nonRoutine.nonroutine.nonRoutineStatus !== "CLEARED" ? nonRoutine.nonroutine.nonRoutineStatus.replace(/-/g, "") : "OPENO"

        },
        "zones": visitInfo?.enableVS ? [{
            "zoneID": nonRoutine.nonroutine.zoneID,
            "zoneTypeID": parseInt(nonRoutine.nonroutine.zoneTypeID, 10),
            "nonRoutineID": nonRoutine.nonroutine.nonRoutineID
        }] : [],
        "estimatedHours": nonRoutine.estimatedHours,
        "nonRoutineActivities": [{
                "activityTypeID": 2,
                "correctiveAction": "",
                "employeeId": profile.userId,
                "employeeFirstName": profile.firstName,
                "employeeLastName": profile.lastName
            }],
        "attributes": [{
            "cat": nonRoutine.nonroutine.cat,
            "majorRepair": nonRoutine.nonroutine.majorRepair,
            "insp": actionType === "CLEAR" ? (nonRoutine.nonroutine.inspectionTypeDesc === "I" || nonRoutine.nonroutine.inspectionTypeDesc === "R" ? true : false) : nonRoutine.nonroutine.insp,
            "partsChange": nonRoutine.nonroutine.partsChange,
            "sDR": nonRoutine.nonroutine.sDR,
            "requireParts": nonRoutine.nonroutine.requireParts,
            "estimatedHours": nonRoutine.estimatedHours?.length > 0,
            "sDBlocked": nonRoutine.nonroutine.sDBlocked,
            "type": nonRoutine.nonroutine.type,
            "selfEvaluated": false,//missing in nonroutine object
            "rotableParts": false,//missing in nonroutine object
            // "inspectionTypeID": nonRoutine.nonroutine.inspectionTypeID,
            // "nonRoutineSkillID": nonRoutine.nonroutine.nonRoutineSkillId,
            "lBEntryRequired": nonRoutine.nonroutine.lBEntryRequired,
            "lBEntryCompleted": nonRoutine.nonroutine.lBEntryCompleted,
            "updateInspectionTypeID": actionType === "UPDATE" && nonRoutine.nonroutine.nonRoutineStatus === "CLEARED" ? 1 : nonRoutine.nonroutine.updateInspectionTypeID,
            "updateTypeID": ((actionType === "REOPEN" || actionType === "UPDATE") && nonRoutine.nonroutine.nonRoutineStatus === "CLEARED") ? 2 : 0,
            "workAreaID": 0,
            "inspectionTypeID": nonRoutine.nonroutine.inspectionTypeDesc
        }],
        "subtasks": nonRoutine.subTask.map(st => {
            return {
                "subTaskText": st.subTaskText,
                "lastModifiedEmployeeID": st.latestActEmployeeID,
                "isDeleted": false,
                "inspectionTypeID": st.inspectionTypeID,
                "lastModifiedFirstName": st.latestActFirstName,
                "lastModifiedLastName": st.latestActLastName,
                "subTaskActivityTypeID": st.subTaskActivityTypeID,
                "updateInspectionTypeID": st.updateInspectionTypeID,
                "comment": "",
                "subTaskStatusID":st.subTaskStatusID,
                "subTaskID" :st.subTaskID,
                "nonRoutineID" :st.nonRoutineID ,
                "commonTaskID": st.commonTaskID  
            }
        }
        ),
        "visits": [{    // fill from visit info 
            "visitId": visitInfo.visitId,
            "tailNumber": visitInfo.aircraftNumber,
            "schedArrDate": visitInfo.actualInductionDate,
            "station": visitInfo.station,
            "baseInductionDate": visitInfo.actualInductionDate,
            "baseStation": visitInfo.station,
            "createdBy": visitInfo.createdBy,
            "inFlightNumber": "0000"
        }],
        "visitWorkLoads":
            [{
                "checkType": visitInfo.checkType,
                "schedDate": visitInfo.actualInductionDate
            }],
        "sOAdditionalEmployees": [],
        "rotablePartsChange": nonRoutine.rotablePartsChange,
        "requiredParts": nonRoutine.requiredParts,
        "mxGroupId":nonRoutine.mxGroupId,
        "isVisitSchedule": visitInfo.enableVS,
        "firstName":profile.firstName,
        "lastName":profile.lastName,
        "workBins":nonRoutine.workBins.map(wBins => ({ label: wBins.workBinName, value: wBins.workBinId})),
        "assignCrewData": nonRoutine.assignedCrews
    };
}


