export const fetchArchiveSearchResults= (userId,station) => {
  return `query Visit($archivedSearch: ArchivedSearchInputType,$userInfo: UserInformationInputType) {
    archivedCardSearch(archivedSearch: $archivedSearch, userInfo: $userInfo) {
      taskCardSearchItems {
        station
        tailNumber
        flightNumber
        dash8
        scheduledArrivalDate
        archivalDate
        scheduledDateUTC
        instanceId
        masterId
        serialNumber
        workControlNumber
        status
        cardNumber
        cardDescription
        cardType
        signatories
        positionCode
        signedBy
        employee {
          employeeName
          employeeId
        }
      }
      warningMessage
    }
  }  
  `
}