


const taskcardQuery = `{
  routineTaskcard {
    actorDictionary
    actionDictionary
    cardNumber
    cardRecordsType
    jobNumber
    cardHeader {
      ataReference {
        rootId
        system
        subSystem
      }
      cardNumber
      cRNumber
      consumables {
        consumableId
        consumableName
      }
      equipmentTypes {
        rootId
        fleetCode
        types {
          typeId
          name
          subTypeName
          equipmentTypesId
        }
      }
      effectivities {
        effectivityId
        effectivityName
        effCEC
        effTail
        complexEff
        tailNumberCEC
        tailNumberLine
        equipmentCode
      }
      eAEffectivities {
        eAEffectivityId
        mfgFleet
        total
        serialNumberAircraft
        tailNumberCEC
        tailNumberLine
        equipmentCode
      }
      eAAADocumentNumber
      eAAuthor
      eASpecialItems
      eAOperationNumber
      eAReferenceDocuments
      generalInsturctions {
        rootId
        lineItemsCount
        title
        contentSections {
          sectionId
          itemType
          bulletText
          textContent
          textType
          requireResponse
          question
          questionId
          questionType
          generalId
          tableItem {
            rows {
              cells
              contentCells {
                itemText
                itemType
              }
            }
          }
        }
        contentItems
      }
      illustrations {
        graphicID
        name
        fileName
        id
        description
      }
      jDocNumber
      parts {
        partId
        qty
        mfrPartNbr
        airlinePartNbr
        description
      }
      partsBlock {
        partsRows {
          comment
          description
          item
          mfgId
          preDraw
          qty
          units
        }
      }
      partsToolsMaterials {
        pTMId
        heading
        item
        description
        mfgPN
        qty
        comments
        pTMDesc
        preDraw
      }
      references {
        refId
        isRequired
        documentType
        documentReference
      }
      revDate
      sources {
        sourceId
        documentType
        documentReference
      }
      skills {
        skillId
        skillName
      }
      technicalElectricalWeight {
        tEWId
        heading
        techDocumentNumber
        techDescription
        elecFleetComponent
        elecCurrent
        elecBus
        elecChange
        elecPhaseOfFlight
        elecPowerFactor
        wghtFleetComponent
        wghtWeight
        wghtArm
        wghtMoment
        rev
        dCN
        date
        descLine
        techShow
        descShow
        type
      }
      title
      tools {
        toolId
        toolName
      }
      operationOverview {
        operationID
        heading
        contentItems {
          sectionId
          itemType
          bulletText
          textContent
          textType
          requireResponse
          question
          questionId
          questionType
          tableItem {
            rows {
              cells
              contentCells {
                itemText
                itemType
              }
            }
          }
          operationID
          operation {
            operationID
            heading
            title
          }
        }
      }
      zones {
        zoneId
        zoneName
      }
    }
    cardTasks {
      id
      cardTaskId
      instanceId
      taskBullet
      title
      specNumber
      taskBlocks {
        id
        topics {
          id
          topicBullet
          title
          contentItems {
            contentItemId
            itemType
            bulletText
            textContent
            textType
            requireResponse
            question
            questionId
            questionType
            answer
            label
            tableItem {
              rows {
                cells
                contentCells {
                  itemText
                  itemType
                }
              }
            }
            eTaskBlockId
          }
          eTaskBlockId
          eTaskBlock {
            eTaskBlockId
            blockId
            id
            mechEnabled
            mech
            mechSelectedBy
            inspector
            inspEnabled
            requiresInspection
            requiresRII
            requiresMVT
            mVT
            mVTSelectedBy
            mVTEnabled
            nAInsp
            inspSelectedBy
            nAInspSelectedBy
            nAInspEnabled
            rii
            riiEnabled
            nARii
            riiSelectedBy
            nARiiSelectedBy
            nARiiEnabled
            nA
            nAInformation {
              header
              comments
              mechEcraComment
              inspEcraComment
              ecraNumber
              employeeName
              userId
            }
            naSelectedBy
            nAEnabled
            lineItemsCount
            completedBy
            cardTaskId
          }
          htmlPContent
        }
        lineItemsCount
        contentItems {
          contentItemId
          itemType
          bulletText
          textContent
          textType
          requireResponse
          question
          questionId
          questionType
          answer
          label
          tableItem {
            rows {
              cells
              contentCells {
                itemText
                itemType
              }
            }
          }
          eTaskBlockId
        }
        completedBy
        signOffHistory {
          blockSignOff {
            effCEC
            effTail
            effText
            isBlockEffective
            isSystemNaBlock
            complexEff
            currentSignoffId
            instanceId
            primaryEmployeeId
            primaryEmployeeRoleId
            primaryEmployeeJobRole
            primaryEmployeeName
            primaryECRANumber
            primaryActorId
            primaryActionId
            primaryComment
            primaryTimestamp
            primaryZuluTimestamp
            secondaryEmployeeId
            secondaryEmployeeRoleId
            secondaryEmployeeJobRole
            secondaryEmployeeName
            secondaryECRANumber
            secondaryActorId
            secondaryActionId
            secondaryComment
            secondaryTimestamp
            secondaryZuluTimestamp
            lastActionID
            lastSignoffId
            signoffBlockId
            blockId
            appType
            lastModifiedTime
            uIPrimaryComment
            uISecondaryComment
            actorType
            uIFormattedPrimaryEmployeeName
            uIFormattedSecondaryEmployeeName
            systemNaBy
          }
          userInputInfo {
            signOffId
            userInputId
            idAttr
            commentVal
            commentType
            commentAttr
          }
        }
        currentSignOff {
          blockSignOff {
            effCEC
            effTail
            effText
            isBlockEffective
            isSystemNaBlock
            complexEff
            systemNaBy
            currentSignoffId
            instanceId
            primaryEmployeeId
            primaryEmployeeRoleId
            primaryEmployeeJobRole
            primaryEmployeeName
            primaryECRANumber
            primaryActorId
            primaryActionId
            primaryComment
            primaryTimestamp
            primaryZuluTimestamp
            secondaryEmployeeId
            secondaryEmployeeRoleId
            secondaryEmployeeJobRole
            secondaryEmployeeName
            secondaryECRANumber
            secondaryActorId
            secondaryActionId
            secondaryComment
            secondaryTimestamp
            secondaryZuluTimestamp
            lastActionID
            lastSignoffId
            signoffBlockId
            blockId
            appType
            lastModifiedTime
            uIPrimaryComment
            uISecondaryComment
            actorType
            uIFormattedPrimaryEmployeeName
            uIFormattedSecondaryEmployeeName
          }
          userInputInfo {
            signOffId
            userInputId
            idAttr
            commentVal
            commentType
            commentAttr
          }
        }
        isPartial
        mVTEnabled
        isPrimaryEnabled
        isSecondaryEnabled
        isPrimarySelected
        isSecondarySelected
        showPrimary
        showSecondary
        showNoActivity
        isUnsigned
        possiblePrimaryActions
        possibleSecondaryActions
        isBlockCompleted
        isMixedBlock
        isInspActive            
        isInspRequired
        possibleMechActions
        possibleNAActions
        possibleOtherActions
        openLpNrCount
        closedLpNrCount
        isBTBSelectAll
      }
      mechCount
      riiCount
      mechCountCompleted
      inspectorCountCompleted
      riiCountCompleted
      mVTCountCompleted
      isInspActive
      isPartialActive
    }
    cardFooter
    signedHistory 
    masterId
    cardDescription
    tailNumber
    serialNumber
    positionCode
    dash8
    inspRequired
    mechRequired
    stepsCount
    stepsCompleted
    archivalDate
    scheduledArrivalDate
    scheduledDate
    cardStatus
    isBulkPrimaryEnable
    isBulkSecondaryEnable
    isBulkUnsignEnable
    isExistBlockingNR
    optimizedGroupID
    optimizedDash8sList{
      dash8
      forecastDescription
    }
  }
  error
}`
export const fetchTaskCardData = () => {
  return `query RoutineContent($routinesContent: RoutineContentInputType, $userInfo: UserInformationInputType) {
    routineContent(routinesContent: $routinesContent, userInfo: $userInfo) ` + taskcardQuery + `
  }`
}
export const updateSignOffInfo = () => {

  return `mutation SignOff($signOff: SignOffInputType, $userInfo: UserInformationInputType) {
    signOff(signOff: $signOff,  userInfo: $userInfo) 
      {
      statusDetail
      rootId
      instanceId
      status
      passwordErrorMessage
      errorMessage
    }
  }`
}

/// error messages
// pswd in correct - property adding - error message.
// invalid signoff - error message 
// concurrent msg - 
// no mvt quals for user -  
// partial sigfoff error handling - 
export const fetchIllustrationImage = () => {
  return `query RoutineContent($illustrations: IllustrationInputType,$userInfo: UserInformationInputType) {
    getIllustrations(illustrations: $illustrations, userInfo: $userInfo) {
      
     illustration
      {
        id
        illustrationId
        name
        mimeType
        content
      }
    }
  }`

}

export const fetchMasterCardData = (masterCardId, isDraft) => {
  return `query Visit($userInfo: UserInformationInputType) {
    masterSearch(cardNumber: "`+ masterCardId + `",userInfo:$userInfo, isDraft:` + isDraft + `)` + taskcardQuery + `
  }`
}
export const fetchLinkedLogPages = () => {
  return `query RoutineRegister($routineLinkedLP: RoutineLinkedLPInputType, $userInfo: UserInformationInputType) {
    getRoutineLinkedLogPages(routineLinkedLP: $routineLinkedLP, userInfo: $userInfo) {
      linkedLogPages {
        logPageID
        logPageCreateDateTime
        logPageNumber
        nonRoutineID
        workLoadID
        logPageTypeId
        logPageTypeName
        visitId
        logPageStaus
        lastModifiedDate
        hasImages
        rERType
        aTA
        hasInspection
        hasLock
        logTypeNumber
        isMEL
        logType
        workItemDescription
        discrepancyStatus
        station
      }
    }
  }
  `
}
export const getDash1Notes = () => {
  return `query RoutineRegister($getd1Notes: Dash1NotesInputType, $userInfo: UserInformationInputType) {
    getDash1Notes(getd1Notes: $getd1Notes, userInfo: $userInfo) {
      notes
        {
          notesId
          notes
          workLoadID
          employeeName
          createdBy
          createdDate
          actionTypeId
          actionType
          workStop
          workBinId
          workBinName
          workBinType
          abbreviatedActionType
        }
      actionTypes
        {
          actionTypeId
          actionType
          abbreviatedActionType
        }
      workBinsList
        {
        workBinId
        workBinName
        description
        workbinTypeId
        workbinType
        isSystemDefined
        }
        tLWorkStop
    }
  }
  `
}
export const saveDash1Notes = () => {
  return `query RoutineRegister($d1Notes: Dash1NotesInputType, $userInfo: UserInformationInputType) {
    saveDash1Notes(d1Notes: $d1Notes, userInfo: $userInfo) {
     workLoadID
     notesCount
      code
      description
      type
    }
  }
  `
}

export const getTaskCardsByMaintainenceItem = () => {
  return `query RoutineContent($taskCards: TaskCardsByMaintainenceInputType, $userInfo: UserInformationInputType) {
    getTaskCardsByMaintainenceItem(taskCards: $taskCards, userInfo: $userInfo) {
      errorMessage
      mELCount
      dIPCount
      isModernized
      notYetEnRoute
      isNewModernized
      etaskCards {
        cardDocType
        cardId
        cardType
        dash1Number : cardNumber
        rootId
        cardTitle: name
        aircraftNumber
        station
        aTASystem
        aTASubSystem
        taskTitle
        forcastDescription
        packageType
        pOS
        scheduledDate
        assignees {
          assigneeName
          assigneeFullName
          id
          hasTagged
          eTaskItemId
        }
        status
        isTaskCardCompleted
        statusDetail
        inspRequired
        mechRequired
        mvtRequired      
        forcastSerialNumber
        releasedBy
        isDash8Completed
        workControlNumber
        uIDash8SignOffDate
        dash8SignOffDate
        dash8Number
        instanceId
        masterId
        blocksCount
        blocksCompleted 
        trueDash1
        taskCardCountStr
        totalTaskCardsNum
        totalTaskCardsDen
        isInspActive
        isPartialActive
        timeRemaining
        dash1NotesCount
        zoneCardId
        mxMasterId
        actionType
        sortOrder
        isEAComponent
        workStop
        notesActionType
        notesActionTypeAbbreviated
        inspectorOnly
      }
      
      
    }
  }
  
  `
}

