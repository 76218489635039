import React, { useState, useEffect, Suspense } from 'react';
import '../assets/styles/scss/app.scss';
import {Route, withRouter, useLocation} from 'react-router-dom/'
import { usePromiseTracker } from "react-promise-tracker";
import Spinner from '../components/Common/Spinner/Spinner';
import GenericError from '../components/Common/GenericError/GenericError';
import {useDispatch, useSelector} from 'react-redux';
import Notification from '../components/Common/Notification/Notification';
import { useAuth } from '../utils/oauth2-pkce';
import { fetchProfile, setGenericError } from '../store/actions/profileActions';
import axios from 'axios';
import { getConfig } from 'utils/env-utils';
import queryString from 'query-string';

const Dashboard = React.lazy(() => import('./MyBase/MyBase'))
const CardSearch = React.lazy(() => import('./CardSearch/CardSearch'))
// import CardSearch from './CardSearch/CardSearch';
// import Dashboard from './MyBase/MyBase';


function App() {
  const { promiseInProgress } = usePromiseTracker();
  const showGenericError = useSelector(state => state.profileStore.showGenericError);
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();
  const isLocal = window.location.hostname.match(/localhost/);
  let currentUrl = window.location.search.toLowerCase();
  const queryStringValues = queryString.parse(currentUrl);  
  useEffect(() => {			
    var _window$location$href = window.location.href.split('?');

    if (!isLocal && !(_window$location$href != null && _window$location$href != undefined && _window$location$href.length > 1 && _window$location$href[1].indexOf("code=") > -1)) {
      window.location.href = window.location.search ? `/api/Auth/Load${window.location.search}&actualPath=${window.location.pathname}` :
        (window.location.pathname && window.location.pathname !== "/") ? `/api/Auth/Load?actualPath=${window.location.pathname}` : '/api/Auth/Load';
    }
    else {
      if (!isLocal) {
        axios
          .get(getConfig("URL_BAM_API_BASE") + "/Profile/GetUserInformation")
          .then(user => {
            setUserId(user?.data?.userId || "");
          })
          .catch((err) => {
            dispatch(setGenericError());
          });
      }
    }
  }, []);
  
  useEffect(() => {
    if ((!isLocal && userId) || isLocal || queryStringValues.isext)
      dispatch(fetchProfile(userId, queryStringValues.isext));
  }, [userId]);

  return ( 
      <div className="App">
        <Suspense fallback={<div>Loading....</div>}>
          <Route path="/" exact component= {Dashboard} />
          <Route path="/dashboard" component= {Dashboard} /> 
          <Route path="/cardSearch" component= {CardSearch} />  
        </Suspense>
            {promiseInProgress && !showGenericError? 
            <div className="spinner-container-holder">
              <div className="spinner-container"><Spinner/></div>
              </div> 
              : null 
              }
            
            {showGenericError ? 
            <GenericError modalClass="higher-z" /> : null}
            <Notification />

          
      </div>
  );
}

export default withRouter(App);