export const fetchVisitsQuery = (station, type = "open") => {
  return `query Visit
      {
      openVisits(station:"`+ station + `", visitType:"`+ type +`") {
          aircraftControlId ,
          aircraftNumber,
          plannedInductionDate ,
          actualInductionDate,
          actualETRDate,
          plannedETRDate,
          isArchived,
          isTemplateApplied,
          station,
          packageType,
          fleetType,
          licenseNumber,
          visitStatus,
          showRoutines,
          location,
          shiftCount,
          enableVS,
          visitId,
          storageInd,
          estTimeofReturn
          enableZonalCard
        }
      }`
}

export const fetchArchiveVisitsQuery = (station, filters) => {
  const {actualInductionStartDate, actualInductionEndDate, rtsStartDate, rtsEndDate, indDate, etrDate, checkTypes} = filters;
  console.log('checkTypes', checkTypes)
  return `query Visit
      {
        archivedVisits(station:"${station}", actualInductionStartDate:"${actualInductionStartDate || '0001-01-01'}", actualInductionEndDate:"${actualInductionEndDate || '0001-01-01'}"
        , rtsStartDate:"${rtsStartDate || '0001-01-01'}", rtsEndDate:"${rtsEndDate || '0001-01-01'}", actualInductionDate:"${indDate || '0001-01-01'}", actualETRDate:"${etrDate || '0001-01-01'}",
          packageTypeList: ${checkTypes  ? (checkTypes instanceof Array && checkTypes?.length) ? "["+checkTypes.map((a) => `"${a}"`).join(',')+"]" : checkTypes?.length ? "["+checkTypes+"]" :null : null})
          {
            aircraftControlId ,
          aircraftNumber,
          plannedInductionDate ,
          actualInductionDate,
          actualETRDate,
          plannedETRDate,
          isArchived,
          isTemplateApplied,
          station,
          packageType,
          fleetType,
          licenseNumber,
          visitStatus,
          showRoutines,
          location,
          shiftCount,
          enableVS,
          visitId,
          storageInd,
          estTimeofReturn,
          enableZonalCard
          }
      }`
}

export const fetchTailNumbersQuery = (params) => {
  return `query Visit 
{
  tailLookUp
  {
      tailNumber
  }   
}
`
}


export const fleetCodes = () => {
  return `query Visit 
  {
    listFleetTypes
    {
        fleetCode
    }   
  }
  `
}

export const fetchFiltersQuery = () => {
  return `query Visit
    {
      packageLookUp
      {
        packageType,
        isActive,
        isNREnable
      }   
    }
    `
}

export const saveVisitQuery = (params) => {
  return `mutation Visit($visitInfo:AircraftControlInputType!)
  {
    addVisit(visitInfo:$visitInfo)
    {
      id
      isSuccess
      error
    }
  }`
}

// export const fetchVisitInfo = (acControlID,station) => {
//   return `query Visit
//   {
//     viewVisit(station:"`+station+`",aircraftControlId:`+acControlID+`) {      
//       aircraftControlId,
//       aircraftNumber,     
//       seatingConfig,
//       shiftCount,
//       fleet,
//       subFleet,
//       plannedETRDate,
//       plannedInductionDate,
//       actualInductionDate,
//       actualETRDate,
//       station,
//       enableVS,
//       checkType,
//       location,
//       showRoutines,
//       showNonRoutines,  
//       isArchived,
//       createdBy,
//       packageType,
//       nonRoutinesClosedCount,
//       nonRoutinesTotalCount,
//       nonRoutinesOpenCount,
//       routinesTotalCount,
//       routinesClosedCount,
//       routinesOpenCount,
//       visitId
//       openLPCount
//     }
//     getETRHistory(aircraftcontrolid: `+acControlID+`) {
//       aircraftControlId
//       plannedETRDate
//       eTRChangeReason
//       firstName
//       lastName  
//       createdDate  
//     }  
//   }`
// }

export const fetchVisitInfo = (acControlID, station) => {
  return `
  query Visit
  {
    viewVisitInfo(station:"`+ station + `",aircraftControlId:` + acControlID + `) {      
      aircraftControlId,
      aircraftNumber,     
      seatingConfig,
      shiftCount,
      fleet,
      subFleet,
      plannedETRDate,
      plannedInductionDate,
      actualInductionDate,
      actualETRDate,
      station,
      enableVS,
      checkType,
      location,
      showRoutines,
      showNonRoutines,  
      isArchived,
      createdBy,
      createdDate,
      packageType,
      isTemplateApplied,
      visitId
      enableZonalCard
      visitDuration
      visitDayNumber
      baseLineDate
      baselineDateModifiedDate
      baselineDateModifiedBy
    }
    getETRHistory(aircraftcontrolid: `+ acControlID + `) {
      eTRattributes
      {
      aircraftControlId
      plannedETRDate
      eTRChangeReason
      firstName
      lastName  
      createdDate
      }
      rTSOverride {
        rTSOverrideReason
        rTSOverrideCreatedBy
        rTSOverrideCreatedDate
        rTSOverrideModifiedBy
        rTSOverrideModifiedDate         
        rTSOverrideFirstName
        rTSOverrideLastName          
      } 
    }  
  }
  `
}

export const fetchVisitInfoLite = (acControlID, station) => {
  return `
  query Visit
  {
    viewVisitInfoDelta(station:"`+ station + `",aircraftControlId:` + acControlID + `) {      
      aircraftControlId,
      aircraftNumber, 
      shiftCount,
      plannedETRDate,
      actualETRDate, 
      plannedInductionDate,  
      isArchived,
      visitDuration
      visitDayNumber
      baseLineDate
      baselineDateModifiedDate
      baselineDateModifiedBy
      isTemplateApplied
    }    
  }
  `
}

export const updateVisitQuery = () => {
  return `mutation Visit($visitInfo: AircraftControlInputType!) {
    updateVisit(visitInfo: $visitInfo) {
      id
      isSuccess
      error
    }
  }`
}

// export const aircraftRTSQuery = () => {
//   return `mutation Visit($visitInfo : AircraftControlInputType!)
//   {    
//     archiveVisit(visitInfo : $visitInfo)
//     {
//       id
//       isSuccess
//       error
//     }    
//   }`
// }

export const aircraftRTSQuery = () => {
  return `mutation Visit($visitInfo: AircraftControlInputType!, $userInfo: UserInformationInputType) {
    archiveVisit(visitInfo: $visitInfo, userInfo: $userInfo) {
      isSuccess
      workItems {
        code
        description
        logPageNumber
        type
        cardNumber
        jobNumber
        workControlNumber
        serialNumber
        errorHeader
      }
      dash8s
      error
    }
  }`
}

export const closeVisit = () => {
  return `mutation Visit($visitInfo : AircraftControlInputType!)
  {    
    closeVisit(visitInfo : $visitInfo)
    {
      id
      isSuccess
      error
    }    
  }`
}

export const fetchBaseDashboardCountsQuery = () => {
  return `query Visit($counts: CountsInputType, $userInfo: UserInformationInputType) {
    counts(counts: $counts, userInfo: $userInfo) {
      aircraftNumber
      nonRoutineTotalCount
      nonRoutineOpenCount
      scheduledWorkTotalCount
      scheduledWorkOpenCount
      logpageOpenCount
      inductionDate
    }
  }`
}


// optimization queries
export const getLogPagesCount = () => {
  return `query VisitSchedule($tailnumber:LoadGroupsOpenLogPageInputType,$userInfo: UserInformationInputType)
  {
    loadGroupsTMOpenLogPage(tailnumber:$tailnumber,userInfo:$userInfo) {
      openLogPageCount
  }
  }
 `
}

export const getNonRoutinesCount = () => {
  return `query VisitSchedule($loadGroupsTMNonRoutine:LoadGroupsNonRoutineInputType,$userInfo: UserInformationInputType)
  {
    loadGroupsTMNonRoutine(loadGroupsTMNonRoutine:$loadGroupsTMNonRoutine,userInfo:$userInfo) {
      nonRoutineOpenCount
      nonRoutineOpenECount
      nonRoutineClosedCount
      nonRoutineTotalCount
      nonRoutineManHours
  }
  }
`
}

export const getScheduledWorkCount = () => {
  return `query VisitSchedule($loadGroupsTMScheduledWork:LoadGroupsInputType,$userInfo: UserInformationInputType)
  {
    loadGroupsTMScheduledWork(loadGroupsTMScheduledWork:$loadGroupsTMScheduledWork,userInfo:$userInfo) {
      routineTotalCount
      routineManHours
      routineOpenCount
      routineClosedCount
                    newlyAddedCardsCount
                    tailNumber
                    station
                    flightNumber
                    scheduledArrivalDate
  }
  }
`
}
