export const fetchLeadModuleCrews = () => {
  return `query TemplateManagement($crews: CrewsInputType, $userInfo: UserInformationInputType) {
        crewDetailsForLeadModule(crews: $crews, userInfo: $userInfo) {
          shiftCrews {
            shiftName
            shiftStartDate
            shiftEndDate
            shiftStartDateUTC
            shiftEndDateUTC
            crewConfigId
            leadCount
            techCount
            isMyCrew   
            crewTechnicianAttrs {          
              employeeId         
              firstName
              lastName          
            }        
            crewLeads {
              firstName
              lastName
              employeeId
            }
            crewCode
            crewName
            crewDescription
            bidArea        
            station        
          }
        }
      }    
    `
}

export const assignAssociateTechs = () => {
  return `query CrewUpgrade($leadAssociateTechs: LeadAssociateTechsToWorkInputType, $userInfo: UserInformationInputType) {
      leadAssociateTechsToWork(leadAssociateTechs: $leadAssociateTechs, userInfo: $userInfo) {
        isAssign
        tailWorkItems {
          tailNumber
          station
          scheduledArrival
          scheduledDeparture
          mxWorkItem
          mxWorkItemType
          mxSubWorkItem
          mxSubWorkItemType
          serialNumber
          isInductedAC
          dash1Status
          workControlNumber
          optimizedGroup
        }
        technicianAssigned {
          empId
          firstName
          lastName
          jobRole
        }
        isSuccess
        errors {
          code
          description
          type
        }
        errorMessage
      }
    }  
  `
}

const workload = `
nonRoutines {
  shiftStartNumber
  shiftEndNumber
  mxMasterId
  nRStatusDescription
  assignees {
    technicianId
    firstName
    lastName
    uIFormattedTechName
    assignmentCreatedBy
  }
  majorRepair
  sDR
  cat
  partsChange
  requireParts
  insp
  zoneType
  workArea
  workControlNumber
  nonRoutineSequence
  specNumber
  originatingDocument
  logPageNumber
  tailNumber
  overallDescription
  nonRoutineNumber
  nonRoutineSkillId
  nonRoutineSkillDesc
  schedArrDate
  lBEntryRequired
  lBEntryCompleted
  rotableParts
  lastModifiedDate
  openDate
  uILastModifiedDate
  uIOpenDate
  isCurrVisit
  immInspReq
  sTTotalCount
  sTClearCount
  sTOpenCount
  sDBlocked
  inspectorOnly
  notesCount
  lastNote
  lastNoteDetail{
    note
    createdBy
    employeeName
    createdDate
    createdDateUTC
    }
  nonRoutineID
  nonRoutineStatus
  nonRoutineStatusDescription
  discrepency
  workStop
  notesActionType
  notesActionTypeAbbreviated
  aTA
  nonRoutineNo
  isVisitScheduleEnabled
  isAssignedToCrew
  nRSkillTypes
}      
openNRCount
totalNRCount
mrdInfo {
  tailNumber
  hasMrd
  isMFD
  mrdMfdStatus
}
etaskCards {
  cardDocType
  isADCard
  cardType
  cardId
  dash1Number: cardNumber
  trueDash1
  rootId
  name
  aircraftNumber
  station
  aTASystem
  aTASubSystem
  cardTitle: taskTitle
  forcastDescription
  packageType
  pOS
  scheduledDate
  status
  assignees {
    assigneeName
    assigneeFullName
    id
    hasTagged
    eTaskItemId
    assignmentCreatedBy
  }
  isTaskCardCompleted
  statusDetail
  inspRequired
  mechRequired
  mvtRequired
  forcastSerialNumber
  serialNumber: forcastSerialNumber
  releasedBy
  workControlNumber
  uIDash8SignOffDate
  isDash8Completed
  dash8SignOffDate
  dash8Number
  masterId
  instanceId
  blocksCount
  blocksCompleted
  taskCardCountStr
  totalTaskCardsNum
  totalTaskCardsDen
  groupName
  forcastNumber
  isPartialActive
  isInspActive
  dash8WorkStop
  isDash9
  isTagged
  isPartsRequired
  timeRemaining
  actionType
  lastNote
  lastNoteDetail{
    note
    createdBy
    employeeName
    createdDate
    createdDateUTC
    }
  forcastDescriptionFromJobName
  zoneCardId
  mxGroupId
  visitId
  groupID
  shiftStartNumber
  shiftEndNumber
  sortOrder
  isEAComponent
  workStop
  showTagCards
  inspectorOnly
  notesActionType
  notesActionTypeAbbreviated
  mxMasterId
  dash1NotesCount
  notesCount: dash1NotesCount
  jobNumber
  assignedGroupsByTech
  totalUntagged
  isAssignedToCrew
  assignedTechs {
    assigneeName
    assigneeID
    hasTagged
  }
  optimizedGroup
  optimizedDetails {
    optimizedReason
    optimizedReferenceInfo {
      optimizedDash8s {
        ncaPartNumber
        ncaSerialNumber
        forecastDescription
        positionCode
        formattedDash8
      }
      optimizedEffectivity
    }
    optimizedVersion
  }
  dash1OptimizedStatusType
  isOptimized
  dash1Status
  uIDash1Status
  isDash1Duplicate
  tcmDetails {
    isTCMEnabled
    specNumber
  }
}
paperTaskCards {
  assignedDate
  dash1Number: cardNumber
  aircraftNumber
  station
  cardTitle: taskTitle
  pOS
  uIDash8SignOffDate
  isDash8Completed
  isCompleted
  isScepterSignOffDisabled
  dash8Number
  dash8Description
  workControlNumber
  serialNumber
  signedBy
  signedDate
  firstName
  lastName
  paperTaskCardId
  assignees {
    assigneeName
    assigneeFullName
    hasTagged
  }
  isAssignedToCrew
  assignedTechs {
    assigneeFullName: assigneeName
    assigneeID
    hasTagged
  }
  mxMasterId
  jobNumber
}
dash9WithoutCards {
  dash8Number
  workControlNumber
  serialNumber
  tailNumber
  dash8Description
  positionCode
  isDash8Completed
  dash8SignOffDate
  assignees {
    assigneeName
    assigneeFullName
    id
    hasTagged
    eTaskItemId
    assignmentCreatedBy
  }
  workUpdatedDateTime
  workItemType
  workItemStatus
  scheduledDate
  isDash9
  jobNumber
  isAssignedToCrew
}
zonalCards {
  actionType
  sortOrder
  dash8Number
  dash8Description
  status
  statusDetail
  uIDash8SignOffDate
  isDash8Completed
  assignedDate
  assignees {
    id
    assigneeName
    assigneeFullName
  }
  panelsCount
  panelsCompleted
  cardId: zoneId
  zoneName
  dash1Number: zoneIdUI
  cardTitle: zoneDescription
  zoneDash1: zoneCardNumber
  masterId
  serialNumber
  workControlNumber
  shiftStartNumber
  shiftEndNumber
  visitId
  mxGroupId
  dash1NotesCount
  notesCount: dash1NotesCount
  lastNote
  lastNoteDetail{
    note
    createdBy
    employeeName
    createdDate
    createdDateUTC
    }
  workStop
  notesActionType
  aircraftNumber
  positionCode: pOS
  timeRemaining
  mxMasterId
  instanceId
  zoneCardId
  isInspActive
  oKRequired
  inspRequired
  mechRequired
  isTaskCardCompleted
  rootId
  scheduledDate
  jobNumber
  taskCardCountStr
  blocksCompleted: totalTaskCardsNum
  blocksCount: totalTaskCardsDen
  notesActionTypeAbbreviated
  dash8WorkStop
  inspectorOnly
  isAssignedToCrew
  isPartialActive
}

logpages {
  timeRemaining
  isMEL
  assignees {
    technicianId
    firstName
    lastName
    uIFormattedTechName
    assignmentCreatedBy
  }
  manHrs          
  forecastedDash8s
  schdDate          
  isCompleted
  isDetachedD8
  jobNumber
  logpageNumber
  openedDate
  openTime
  station
  flightNumber
  workItemDescription
  logType
  hasLock
  hasInspection
  closedDateFormatted
  logpageStatus
  rERType
  hasImages
  isAssignedToCrew
  mxMasterId
}
openLogpages {
  timeRemaining
  isMEL
  assignees {
    technicianId
    firstName
    lastName
    uIFormattedTechName
    assignmentCreatedBy
  }
  manHrs          
  forecastedDash8s
  schdDate          
  isCompleted
  isDetachedD8
  jobNumber
  logpageNumber
  openedDate
  openTime
  station
  flightNumber
  workItemDescription
  logType
  hasLock
  hasInspection
  closedDateFormatted
  logpageStatus
  rERType
  hasImages
  isAssignedToCrew
  mxMasterId
}
assignments {
  employeeId
  employeeRole
  employeeName
}
flifo {
  tailNumber
  flightNumber
}
mELCount
dIPCount
isModernized
notYetEnRoute
isNewModernized
`
const crewWorkloadForSHR = `
mxItemList {
  mxMasterId
  cardNumber
  mxType
  description
  shiftEndNumber
  shiftStartNumber
  workArea
  zoneTypeID
  zoneType
  mxGroupId
  mileStone
  notesCount
  isPaper
  dash8
  workControlNumber
  serialNumber
  actionType
  sortOrder
  zoneCardId
  zoneCardNumber
  notesCount
  lastNote
  lastNoteDetail{
    note
    createdBy
    employeeName
    createdDate
    createdDateUTC
    }
  skills {
    skillId
    skillType
    skillTypeCode
  }
  zoneId 
  positionCode
  masterId
  rootId
  cardDocType
  cardId
  taskCardTypeId
  workStop
  workStopActionType
  workStopActionTypeAbbreviated
  isMissingProperties
  completedCount
  totalCount
  nRStatusDescription 
  missingProperties {
    workArea
    taskCardType
    mileStone
    skills
  }
  assignedCrews {
    crewConfigId
    crewName
  }
  workBins
  {
    workBinId
    workBinName
  }        
}
`
const techCrewWorkloadForSHRList = `
techId
firstName
lastName
displayName
crewTechComment
crewWorkloadshiftHandoverReport {
  mxItemList {
    mxMasterId
    cardNumber
    mxType
    description
    shiftEndNumber
    shiftStartNumber
    workArea
    zoneTypeID
    zoneType
    mxGroupId
    mileStone
    notesCount
    isPaper
    dash8
    workControlNumber
    serialNumber
    actionType
    sortOrder
    zoneCardId
    zoneCardNumber
    skills {
      skillId
      skillType
      skillTypeCode
    }
    zoneId 
    positionCode
    masterId
    rootId
    cardDocType
    cardId
    taskCardTypeId
    workStop
    workStopActionType
    workStopActionTypeAbbreviated
    isMissingProperties  
    missingProperties {
      workArea
      taskCardType
      mileStone
      skills
    }
    assignedCrews {
      crewConfigId
      crewName
    }
    workBins {
      workBinId
      workBinName
    }        
  }
}
`

export const fetchCrewWorkloadForLeadModule = (isFromSHR = false) => {
  if (!isFromSHR) {
    return `query CrewUpgrade($crewWorkload: HarmonizedWorkloadInputType, $userInfo: UserInformationInputType) {
        getCrewWorkloadForLeadModule(crewWorkload: $crewWorkload, userInfo: $userInfo) {
          crewWorkload {`+ workload + `}
          unAssignedWorkload {`+ workload + `}
          futureWorkload {`+ workload + `}
          techWorkloadList {
            techId
            firstName
            lastName
            displayName
            openTechWorkload {`+ workload + `}
            completedTechWorkload {`+ workload + `}
          }      
      }
    }`
  } else {
    return `query CrewUpgrade($crewWorkload: HarmonizedWorkloadInputType, $userInfo: UserInformationInputType) {
        getCrewWorkloadForLeadModule(crewWorkload: $crewWorkload, userInfo: $userInfo) {          
          crewWorkloadForSHR {`+ crewWorkloadForSHR + `}
          techCrewWorkloadForSHRList {`+ techCrewWorkloadForSHRList + `}   
      }
    }`
  }
}

export const fetchOpenENonRoutinesForLeadModule = () => {
  return `query CrewUpgrade($openE: OpenENRInputType, $userInfo: UserInformationInputType) {
    getOpenENonRoutines(openE: $openE, userInfo: $userInfo) {
      aircraftNonRoutines {
        nonRoutineNo
        nonRoutineNumber
        majorRepair
        sDR
        cat
        partsChange
        requireParts
        insp
        zoneType
        workArea
        workControlNumber
        nonRoutineSequence
        specNumber
        tailNumber
        logPageNumber
        originatingDocument
        overallDescription
        shiftStartNumber
        shiftEndNumber
        mxMasterId
        nRStatusDescription
        assignees {
          technicianId
          firstName
          lastName
          assignmentCreatedBy
        }
        nonRoutineSkillId
        nonRoutineSkillDesc
        schedArrDate
        lBEntryRequired
        lBEntryCompleted
        rotableParts
        lastModifiedDate
        openDate
        uILastModifiedDate
        uIOpenDate
        isCurrVisit
        immInspReq
        sTTotalCount
        sTClearCount
        sTOpenCount
        sDBlocked
        nonRoutineID
        nonRoutineStatus
        nonRoutineStatusDescription
        discrepency
        aTA
        isVisitScheduleEnabled
        inspectorOnly
       isCreatedByThisCrew
       isNRAssignedToCrew: isAssignedToCrew
       nRSkillTypes
       notesCount
       notesActionTypeAbbreviated
       workStop
       lastNote
       lastNoteDetail{
        note
        createdBy
        employeeName
        createdDate
        createdDateUTC
        }
      }
    }
  }
  `
}