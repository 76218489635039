import { FETCH_PROFILE, FETCH_EXTENDED_PROFILE, SET_CURRENT_PATH, SET_GENERIC_ERROR, SET_VIEWING_STATION, SAVE_FEEDBACK, SET_NOTIFICATION_MESSAGE, SET_FAILED_NOTIFICATION_MESSAGE } from './actionTypes';
import * as Queries11 from '../queries/ProfileQueries';
import { executeQuery, executeWebApi } from '../helper';
import { localProfile } from './localProfile';
//Queries.fetchVisitsQuery

export const fetchProfile = (userId, isExt) => {
    return dispatch => {
        userId = (window.location.href.indexOf('localhost') !== -1) ? localProfile : userId;
        const profile_data = sessionStorage.getItem('profile_data');
        if(profile_data && JSON.parse(profile_data)?.userId === userId && !isExt){
            dispatch({type:`${FETCH_EXTENDED_PROFILE}_FULFILLED`, payload:{getProfile: JSON.parse(profile_data)}});
            return false
        }
        dispatch({
            type: FETCH_EXTENDED_PROFILE,
            payload: executeQuery(Queries11.fetchExtendedProfile(userId, ""), null, null, "Profile")
        }).then(response =>{
            sessionStorage.setItem('profile_data', JSON.stringify(response.value.getProfile));
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const setGenericError = () => {
    return dispatch => {
        dispatch({
            type: SET_GENERIC_ERROR,
            payload: ""
        })
    }
}

export const setCurrentPath = (currentPath, nonRoutineAction = "") => {
    return dispatch => {
        dispatch({
            type: SET_CURRENT_PATH,
            payload: { currentPath: currentPath, nonRoutineAction: nonRoutineAction }
        });
    }
}

export const setViewingStation = (station) => {
    return dispatch => {
        dispatch({
            type: SET_VIEWING_STATION,
            payload: station
        });
    }
}

// export cosnt getBreadcrumbsPath = (currentPath) => {
//     return dispatch => {
//         dispatch({
//             type: SET_BREADCRUMBS
//         })
//     }
// }

export const saveFeedback = (profile, feedbackReq) => {
    return (dispatch, getState) => {
        let request = {
            "feedbackRequest": {
                "employeeStation": profile.station,
                "station": feedbackReq.viewingStation || profile.station,
                "lastname": profile.lastName,
                "tailNumber": feedbackReq.taskCardData.tailNumber || "",
                "feedback": feedbackReq.comment,
                "fleet": feedbackReq.taskCardData.fleet || '',
                "email": profile.userId + "@global.ual.com",
                "sourceId": feedbackReq.isTaskCardFeedback ? null : 1,
                "employeeId": profile.userId,
                "appId": feedbackReq.isTaskCardFeedback ? "BaseWebTaskcard" :"BamWeb",
                "firstname": profile.firstName,
                "appVersion": "1.0.2339",
                "createServiceNowCase": feedbackReq.isTaskCardFeedback ? true : false,
                "attachments": null,
                "taskCardNumber": feedbackReq.taskCardData.cardNumber || "",
                "feedbackAttachments": feedbackReq.feedbackAttachments
            }
        };
        return dispatch({
            type: SAVE_FEEDBACK,
            payload: executeQuery(Queries11.saveFeedback(), request, getState(), 'Application')
        }).then(resp => {
            if (resp !== null && resp?.value?.submitFeedbackWithAttachments?.feedbackId) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Feedback submitted successfully!"
                });
                return Promise.resolve('success');
            } else {
                throw { message: "Failed to save feedback." }
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_FAILED_NOTIFICATION_MESSAGE,
                    payload: error?.message
                })
            });
    }
}