import React from 'react';

const Spinner = ({isMini, loadingText}) => {
    return (
        // <div className="spinner my-auto">
        //     <div className="bounce1"></div>
        //     <div className="bounce2"></div>
        //     <div className="bounce3"></div>
        // </div>
        <div className="text-align-center">
            <div className={isMini ? "lds-ring-mini" : "lds-ring"}><div></div><div></div><div></div><div></div></div>
            <div>{!isMini ? (loadingText && loadingText !== "") ? loadingText : "Loading" : ""}</div>
        </div>
    );
}

export default Spinner;