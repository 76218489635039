export const BAM_CONFIG = {
    'local': {
        ENV: 'local',
        URL_SIGN_OUT: 'https://signon-oqa.ual.com/oam/server/logout?end_url=https://signon-oqa.ual.com/oamsso/global/logout.html',
        URL_AMT_API_BASE: 'http://amtapi-dev.ual.com/svc/station-api',
        URL_BAM_API_BASE: 'https://bam-api.qa.dvx.aws.ual.com/api',
        URL_BAM_PROFILE_API: 'http://bamf5-dev.ual.com/bam-profile',
        BAM_GRAPHQL_ENDPT: '/graphql/',
        BAM_PROFILE_ENDPT: '/api/profile',
        AMTS_LOGBOOK_URL: 'https://logbook-stg.ual.com/Home/LogPage',
        AMTS_LOGBOOK_CREATE_URL: 'https://logbook-stg.ual.com/Home/CreateStandalone',
        HOME_PATH: '/bam-web',
        AMTS_MYWORK_URL: 'https://amts-oqa.ual.com/mx/my-work/Mechanic',
        AMTS_ACCOUNTABILITY_URL: 'http://vcld15gqabjsr01-fe/Reports/Pages/Report.aspx?ItemPath=%2fetaskcard%2fAccountabilityreport',
        AMTS_WEBTECH_LOGBOOK_URL: 'https://webtech-stg.ual.com',
        AMTS_URL: 'https://amts-oqa.ual.com/mx',
        APP_NAME: 'bamtech',
        APP_KEY: '0EE5ADDB-A6EF-468E-8D05-52D67CD5C54B',
        AMTS_PLANNING_URL: 'https://amts-oqa.ual.com/mxData/MxDash8Dash9/',
        NHA_REPORT_URL: 'https://techopsreportsqa.ual.com/reports/report/AMT/NHAReport',
        PO_URL: 'https://partsordering-oqa.ual.com',
        UDOCS_URL: 'https://udocs-oqa.ual.com/udocs',
        TECHOPS_URL: 'https://techops-oqa.ual.com/TisPortal/Home.aspx',
        MAPS_URL: 'https://amts-oqa.ual.com/maps',
        ELOTTO_URL: 'https://eloto.qa.dqk.aws.ual.com/ViewModel/BaseDashBoard',
        WEBTECH_URL: 'https://webtech-stg.ual.com',
        FEEDBACK_URL: 'https://amts-oqa.ual.com/app-feedback',
        STATIONPLAN_URL: 'https://mx.dev.dwz.aws.ual.com/stationplan',
        TCM_URL: 'https://amts-oqa.ual.com/TCM'
    },
    'dev': {
        ENV: 'development',
        // sign out url
        URL_SIGN_OUT: 'https://signon-oqa.ual.com/oam/server/logout?end_url=https://signon-oqa.ual.com/oamsso/global/logout.html',
        URL_BKR_SAVE_FEEDBACK: '/hangar/SaveAppFeedback',
        URL_WEB_LOGPAGE: 'https://logbook.qa.dsq.aws.ual.com',
        URL_AMT_API_BASE: 'http://amtapi-dev.ual.com/svc/station-api',
        URL_BAM_API_BASE: 'https://bam.dev.dvx.aws.ual.com/api',
        URL_BAM_PROFILE_API: 'https://bam-odev.ual.com/bam-profile',
        BAM_GRAPHQL_ENDPT: '/graphql/',
        BAM_PROFILE_ENDPT: '/api/profile',
        AMTS_LOGBOOK_URL: 'https://logbook.qa.dsq.aws.ual.com/Home/LogPage',
        AMTS_LOGBOOK_CREATE_URL: 'https://logbook.qa.dsq.aws.ual.com/Home/CreateStandalone',
        HOME_PATH: '/bam-web',
        AMTS_MYWORK_URL: 'https://amts-oqa.ual.com/mx/my-work/Mechanic',
        AMTS_ACCOUNTABILITY_URL: 'http://vcld15gqabjsr01-fe/Reports/Pages/Report.aspx?ItemPath=%2fetaskcard%2fAccountabilityreport',
        AMTS_URL: 'https://amts-oqa.ual.com/mx',
        AMTS_WEBTECH_LOGBOOK_URL: 'https://webtech.qa.dsq.aws.ual.com/',
        APP_NAME: 'bamtech',
        APP_KEY: '0EE5ADDB-A6EF-468E-8D05-52D67CD5C54B',
        AMTS_PLANNING_URL: 'https://amts-oqa.ual.com/mxData/MxDash8Dash9/',
        NHA_REPORT_URL: 'https://techopsreportsqa.ual.com/reports/report/AMT/NHAReport',
        PO_URL: 'https://partsordering-oqa.ual.com',
        UDOCS_URL: 'https://udocs-oqa.ual.com/udocs',
        TECHOPS_URL: 'https://techops-oqa.ual.com/TisPortal/Home.aspx',
        MAPS_URL: 'https://amts-oqa.ual.com/maps',
        ELOTTO_URL: 'https://eloto.qa.dqk.aws.ual.com/ViewModel/BaseDashBoard',
        WEBTECH_URL: 'https://webtech.qa.dsq.aws.ual.com/',
        TOOLBOX_URL: 'http://toolboxremote.ual.com:8081/toolboxremote.html',
        FEEDBACK_URL: 'https://amts-oqa.ual.com/app-feedback',
        STATIONPLAN_URL: 'https://mx.dev.dwz.aws.ual.com/stationplan',
        TCM_URL: 'https://amts-oqa.ual.com/TCM'
    },
    'qa': {
        ENV: 'qa',
        // sign out url
        URL_SIGN_OUT: 'https://signon-oqa.ual.com/oam/server/logout?end_url=https://signon-oqa.ual.com/oamsso/global/logout.html',
        URL_BKR_SAVE_FEEDBACK: '/hangar/SaveAppFeedback',
        URL_WEB_LOGPAGE: 'https://logbook.qa.dsq.aws.ual.com/Home/LogPage',
        URL_AMT_API_BASE: 'https://amts-oqa.ual.com/svc/station-api',
        URL_BAM_API_BASE: 'https://bam.qa.dvx.aws.ual.com/api',
        URL_BAM_PROFILE_API: 'https://bam-oqa.ual.com/bam-profile',
        BAM_GRAPHQL_ENDPT: '/graphql/',
        BAM_PROFILE_ENDPT: '/api/profile',
        AMTS_LOGBOOK_URL: 'https://logbook.qa.dsq.aws.ual.com/Home/LogPage',
        AMTS_LOGBOOK_CREATE_URL: 'https://logbook.qa.dsq.aws.ual.com/Home/CreateStandalone',
        HOME_PATH: '/bam-web',
        AMTS_MYWORK_URL: 'https://amts-oqa.ual.com/mx/my-work/Mechanic',
        AMTS_ACCOUNTABILITY_URL: 'http://vcld15gqabjsr01-fe/Reports/Pages/Report.aspx?ItemPath=%2fetaskcard%2fAccountabilityreport',
        AMTS_URL: 'https://amts-oqa.ual.com/mx',
        AMTS_WEBTECH_LOGBOOK_URL: 'https://webtech.qa.dsq.aws.ual.com/',
        APP_NAME: 'bamtech',
        APP_KEY: '0EE5ADDB-A6EF-468E-8D05-52D67CD5C54B',
        AMTS_PLANNING_URL: 'https://amts-oqa.ual.com/mxData/MxDash8Dash9/',
        NHA_REPORT_URL: 'https://techopsreportsqa.ual.com/reports/report/AMT/NHAReport',
        PO_URL: 'https://partsordering-oqa.ual.com',
        UDOCS_URL: 'https://udocs-oqa.ual.com/udocs',
        TECHOPS_URL: 'https://techops-oqa.ual.com/TisPortal/Home.aspx',
        MAPS_URL: 'https://amts-oqa.ual.com/maps',
        ELOTTO_URL: 'https://eloto.qa.dqk.aws.ual.com/ViewModel/BaseDashBoard',
        WEBTECH_URL: 'https://webtech.qa.dsq.aws.ual.com/',
        TOOLBOX_URL: 'http://toolboxremote.ual.com:8081/toolboxremote.html',
        FEEDBACK_URL: 'https://amts-oqa.ual.com/app-feedback',
        STATIONPLAN_URL: 'https://mx.qa.dwz.aws.ual.com/stationplan',
        TCM_URL: 'https://amts-oqa.ual.com/TCM'
    },
    'stage': {
        ENV: 'stage',
        // sign out url
        URL_SIGN_OUT: 'https://signon.ual.com/oam/server/logout?end_url=https://signon.ual.com/oamsso/global/logout.html',
        URL_BKR_SAVE_FEEDBACK: '/hangar/SaveAppFeedback',
        URL_WEB_LOGPAGE: 'https://logbook-stg.ual.com/Home/LogPage',
        URL_AMT_API_BASE: 'https://amts-oqa.ual.com/svc/station-api',
        URL_BAM_API_BASE: 'https://bam-preprod.ual.com/api',
        URL_BAM_PROFILE_API: 'https://bam-oqa.ual.com/bam-profile',
        BAM_GRAPHQL_ENDPT: '/graphql/',
        BAM_PROFILE_ENDPT: '/api/profile',
        AMTS_LOGBOOK_URL: 'https://logbook-stg.ual.com/Home/LogPage',
        AMTS_LOGBOOK_CREATE_URL: 'https://logbook-stg.ual.com/Home/CreateStandalone',
        HOME_PATH: '/bam-web-stage',
        AMTS_MYWORK_URL: 'https://amts-oqa.ual.com/mx/my-work/Mechanic',
        AMTS_ACCOUNTABILITY_URL: 'http://vcld15gqabjsr01-fe/Reports/Pages/Report.aspx?ItemPath=%2fetaskcard%2fAccountabilityreport',
        AMTS_URL: 'https://amts-oqa.ual.com/mx',
        AMTS_WEBTECH_LOGBOOK_URL: 'https://webtech-stg.ual.com',
        APP_NAME: 'bamtech',
        APP_KEY: '0EE5ADDB-A6EF-468E-8D05-52D67CD5C54B',
        AMTS_PLANNING_URL: 'https://amts-oqa.ual.com/mxData/MxDash8Dash9/',
        NHA_REPORT_URL: 'https://techopsreportsqa.ual.com/reports/report/AMT/NHAReport',
        PO_URL: 'https://partsordering-oqa.ual.com',
        UDOCS_URL: 'https://udocs-oqa.ual.com/udocs',
        TECHOPS_URL: 'https://techops-oqa.ual.com/TisPortal/Home.aspx',
        MAPS_URL: 'https://amts-oqa.ual.com/maps',
        ELOTTO_URL: 'https://eloto.qa.dqk.aws.ual.com/ViewModel/BaseDashBoard',
        WEBTECH_URL: 'https://webtech-stg.ual.com',
        TOOLBOX_URL: 'http://toolboxremote.ual.com:8081/toolboxremote.html',
        FEEDBACK_URL: 'https://amts-oqa.ual.com/app-feedback',
        STATIONPLAN_URL: 'https://mx.qa.dwz.aws.ual.com/stationplan',
        TCM_URL: 'https://amts-oqa.ual.com/TCM'
    },
    'preprod': {
        ENV: 'stage',
        // sign out url
        URL_SIGN_OUT: 'https://signon.ual.com/oam/server/logout?end_url=https://signon.ual.com/oamsso/global/logout.html',
        URL_BKR_SAVE_FEEDBACK: '/hangar/SaveAppFeedback',
        URL_WEB_LOGPAGE: 'https://logbook-stg.ual.com/Home/LogPage',
        URL_AMT_API_BASE: 'https://amts-oqa.ual.com/svc/station-api',
        URL_BAM_API_BASE: 'https://bam-preprod.ual.com/api',
        URL_BAM_PROFILE_API: 'https://bam-oqa.ual.com/bam-profile',
        BAM_GRAPHQL_ENDPT: '/graphql/',
        BAM_PROFILE_ENDPT: '/api/profile',
        AMTS_LOGBOOK_URL: 'https://logbook-stg.ual.com/Home/LogPage',
        AMTS_LOGBOOK_CREATE_URL: 'https://logbook-stg.ual.com/Home/CreateStandalone',
        HOME_PATH: '/bam-web-stage',
        AMTS_MYWORK_URL: 'https://amts-oqa.ual.com/mx/my-work/Mechanic',
        AMTS_ACCOUNTABILITY_URL: 'http://vcld15gqabjsr01-fe/Reports/Pages/Report.aspx?ItemPath=%2fetaskcard%2fAccountabilityreport',
        AMTS_URL: 'https://amts-oqa.ual.com/mx',
        AMTS_WEBTECH_LOGBOOK_URL: 'https://webtech-stg.ual.com',
        APP_NAME: 'bamtech',
        APP_KEY: '0EE5ADDB-A6EF-468E-8D05-52D67CD5C54B',
        AMTS_PLANNING_URL: 'https://amts-oqa.ual.com/mxData/MxDash8Dash9/',
        NHA_REPORT_URL: 'https://techopsreportsqa.ual.com/reports/report/AMT/NHAReport',
        PO_URL: 'https://partsordering-oqa.ual.com',
        UDOCS_URL: 'https://udocs-oqa.ual.com/udocs',
        TECHOPS_URL: 'https://techops-oqa.ual.com/TisPortal/Home.aspx',
        MAPS_URL: 'https://amts-oqa.ual.com/maps',
        ELOTTO_URL: 'https://eloto.qa.dqk.aws.ual.com/ViewModel/BaseDashBoard',
        WEBTECH_URL: 'https://webtech-stg.ual.com',
        TOOLBOX_URL: 'http://toolboxremote.ual.com:8081/toolboxremote.html',
        FEEDBACK_URL: 'https://amts-oqa.ual.com/app-feedback',
        STATIONPLAN_URL: 'https://mx.qa.dwz.aws.ual.com/stationplan',
        TCM_URL: 'https://amts-oqa.ual.com/TCM'
    },
    'prod': {
        ENV: 'prod',
        // sign out url
        URL_SIGN_OUT: 'https://signon.ual.com/oam/server/logout?end_url=https://signon.ual.com/oamsso/global/logout.html',
        URL_BKR_SAVE_FEEDBACK: '/hangar/SaveAppFeedback',
        URL_AMT_API_BASE: 'https://amts-oqa.ual.com/svc/station-api',
        URL_BAM_API_BASE: 'https://bam.ual.com/api',
        URL_BAM_PROFILE_API: 'https://bam.ual.com/bam-profile',
        BAM_GRAPHQL_ENDPT: '/graphql/',
        BAM_PROFILE_ENDPT: '/api/profile',
        AMTS_LOGBOOK_URL: 'https://logbook.ual.com/Home/LogPage',
        AMTS_LOGBOOK_CREATE_URL: 'https://logbook.ual.com/Home/CreateStandalone',
        HOME_PATH: '/bam-web',
        AMTS_MYWORK_URL: 'https://amts.ual.com/mx/my-work/Mechanic',
        AMTS_ACCOUNTABILITY_URL: 'http://vcld14gpabjsr01/Reports/Pages/Report.aspx?ItemPath=%2fetaskcard%2fAccountabilityreport',
        AMTS_URL: 'https://amts.ual.com/mx',
        AMTS_WEBTECH_LOGBOOK_URL: 'https://webtech.ual.com',
        APP_NAME: 'bamtech',
        APP_KEY: 'AB0ECB0E-F8E0-406D-8586-04E7FAA60F64',
        AMTS_PLANNING_URL: 'https://amts.ual.com/mxData/MxDash8Dash9/',
        NHA_REPORT_URL: 'https://techopsreportsprd.ual.com/reports/report/AMT/NHAReport',
        PO_URL: 'https://partsordering.ual.com',
        UDOCS_URL: 'https://udocs.ual.com/udocs',
        TECHOPS_URL: 'https://techops.ual.com/TisPortal/Home.aspx',
        MAPS_URL: 'https://amts.ual.com/maps',
        ELOTTO_URL: 'https://eloto.ual.com/',
        WEBTECH_URL: 'https://webtech.ual.com',
        TOOLBOX_URL: 'http://toolboxremote.ual.com:8081/toolboxremote.html',
        FEEDBACK_URL: 'https://amts.ual.com/app-feedback',
        STATIONPLAN_URL: 'https://mx.ual.com/stationplan',
        TCM_URL: 'https://amts.ual.com/TCM'
    }
}