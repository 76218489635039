

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';




const ActionButton = props => {
  const viewingStation = useSelector(state => state.profileStore.viewingStation);
  const profile = useSelector(state => state.profileStore.userProfile);
  const actionFlag = profile && (profile.isBaseFlow && profile.isProductionController) && 
    viewingStation !== null && (viewingStation.toUpperCase() === profile.station.toUpperCase() || viewingStation === ""
    )
  const extendedMxSupv = props.isExtendedMxSupv ? (profile && (profile.jobRole === "MXSUPV")) : false
  const extendedCondition = props.isExtended ? (profile && (profile.jobRole === "LEAD" || profile.jobRole === "MXSUPV")) : false;
  const stationChangeExempt = props.stationChangeExempt ? profile && (profile.isBaseFlow && profile.isProductionController) : false;
  const extendedAboveLead = props.isExtendedAboveLead ? (profile && (profile.jobRole === "LEAD" || profile.jobRole === "MXSUPV" || profile.jobRole === "INSUPV")) : false;
  return (
    <>
      {
        (actionFlag || extendedCondition ||stationChangeExempt || extendedMxSupv || extendedAboveLead) &&
        <>
          {props.children}
        </>
      }
    </>
  )
};

export default ActionButton;