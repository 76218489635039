import React, { useState, useEffect } from 'react';
import Icon from '../Icon/Icon';
import { resetNotificationMessage } from '../../../store/actions/visitsActions';
import { useSelector, useDispatch } from 'react-redux';

const Notification = () => {
    const dispatch = useDispatch();
    const showNotification = useSelector(state => state.visitsStore.showNotification);
    const notificationMessage = useSelector(state => state.visitsStore.notificationMessage);
    const notificationType = useSelector(state => state.visitsStore.notificationType);

    useEffect(() => {
            setTimeout(() => dispatch(resetNotificationMessage()), 5000);
    }, [showNotification]);

    const closeNotification = () => {
            dispatch(resetNotificationMessage());
    }
    return (
        showNotification ?
            <div className={`notification ${notificationType}`}>               
                    {/* <img src={SuccessImg} alt="SuccessImg"></img> */}                    
                    <Icon variant={notificationType === "success" ? "success-icon" : "failure-icon"} primarycolor="#FFFFFF" />
                    <span>{notificationMessage}</span>
                    <Icon variant="close-icon" className="close-icon" primarycolor="#FFFFFF" onClick={closeNotification.bind(this)} />                 
            </div>            
        : null
    );
}

export default Notification