import {
  FETCH_TASK_AUDIT, SET_TASK_AUDIT_SIGNOFF_OBJECT,
  RESET_TASK_AUDIT_SIGNOFF_OBJECT, SIGNOFF_TASK_AUDIT_BLOCKS, SET_ACTIVE_DASH1, APPROVE_ALL_BLOCKS, REOPEN_ALL_BLOCKS, RESET_FETCHTASKCARD_ERROR_MESSAGE, FETCH_AUDIT_ACTIVITY_DETAILS
} from '../actions/actionTypes';

const initialState = {
  taskAuditDetails: null,
  isLoadingTaskAuditDetails: true,
  signOffObjects: [],
  signOffTaskAuditBlocksResponse: null,
  isSigningOffTaskAuditBlocks: false,
  activeDash1: null,
  isApproveAll: false,
  isReopenAll: false,
  reopenAllBlocksDash1: null,
  approveAllBlocksDash1: null,
  fetchTaskAuditErrorMessage: null,
  taskAuditActivityLog:null
}

const resp1 = {
    "getAuditDetails": {
      "auditResponseList": [
        {
          "auditDetailsList": [
            {
              "auditDetailId": 40,
              "blockId": "B737-05-3-0003-29-20-00-002-002",
              "blockNumber": "2",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 1,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "test comments",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640              
            },
            {
              "auditDetailId": 41,
              "blockId": "B737-05-3-0003-29-20-00-002-003",
              "blockNumber": "3",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            },
            {
              "auditDetailId": 42,
              "blockId": "B737-05-3-0003-29-20-00-002-004",
              "blockNumber": "4",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            },
            {
              "auditDetailId": 43,
              "blockId": "B737-05-3-0003-29-20-00-002-005",
              "blockNumber": "5",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            },
            {
              "auditDetailId": 44,
              "blockId": "B737-05-3-0003-29-20-00-002-006",
              "blockNumber": "6",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            }
          ],
          "visitId": 31727,
          "dash8": "29-0505-8-0004",
          "cardNumber": "29-0505-1-0002",
          "dash1Description": "B737-700/800/900/900ER STAR PRE-DEPARTURE CHECKLIST",
          "pendingReview": 5,
          "totalNABlocks": 5,
          "masterId": 304987,
          "workControlNumber": "5625218",
          "assignedStation": "IAH",
          "serialNumber": "0441",
          "tailNumber": "0441",
          "flightNumber": "1730",
          "forecastPosition": "x",
          "forecastDescription": "star check",
          "scheduledArrivalDate": "2021-12-14T20:28:00",
          "scheduledDateUTC": "2021-12-14T00:00:00",
          "createdBy": "v758611"
          // "cardId"
          // "cardDocType"
          // "rootId"
          // "mxMasterId"
          // "assignees"

        },
        {
          "auditDetailsList": [
            {
              "auditDetailId": 40,
              "blockId": "B737-05-3-0003-29-20-00-002-002",
              "blockNumber": "2",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            },
            {
              "auditDetailId": 41,
              "blockId": "B737-05-3-0003-29-20-00-002-003",
              "blockNumber": "3",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            },
            {
              "auditDetailId": 42,
              "blockId": "B737-05-3-0003-29-20-00-002-004",
              "blockNumber": "4",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            },
            {
              "auditDetailId": 43,
              "blockId": "B737-05-3-0003-29-20-00-002-005",
              "blockNumber": "5",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            },
            {
              "auditDetailId": 44,
              "blockId": "B737-05-3-0003-29-20-00-002-006",
              "blockNumber": "6",
              "instanceId": 259520,
              "signoffBlockId": 92382022,
              "auditActionId": 0,
              "auditReasonId": 0,
              "auditStatus": "No action",
              "reason": "N/A Steps",
              "comments": "",
              "employeeId": "",
              "modifiedDate": "2021-12-27T11:05:48.317",
              "firstName": "",
              "lastName": "",
              "currentSignoffId": 1404640,
              "primaryActionId": 2,
              "primaryActorId": 1,
              "primaryECRANumber": "",
              "primaryTimestamp": "2021-12-14T23:58:58.75",
              "primaryComment": "",
              "primaryEmployeeName": "Tejas Kollu",
              "primaryEmployeeId": "v758611",
              "primaryEmployeeRoleId": 2,
              "primaryEmployeeJobRole": "Lead",
              "secondaryActionId": 0,
              "secondaryActorId": 0,
              "secondaryEmployeeId": "",
              "secondaryEmployeeName": "",
              "secondaryEmployeeRoleId": 0,
              "secondaryTimestamp": "1753-01-01T00:00:00",
              "secondaryComment": "",
              "secondaryECRANumber": "",
              "lastModifiedTime": "2021-12-14T23:58:58.75"
            }
          ],
          "visitId": 31727,
          "dash8": "29-0505-8-0004",
          "cardNumber": "29-0505-1-0002",
          "dash1Description": "B737-700/800/900/900ER STAR PRE-DEPARTURE CHECKLIST",
          "pendingReview": 5,
          "totalNABlocks": 5,
          "masterId": 304987,
          "workControlNumber": "5625218",
          "assignedStation": "IAH",
          "serialNumber": "0441",
          "tailNumber": "0441",
          "flightNumber": "1730",
          "forecastPosition": "x",
          "forecastDescription": "star check",
          "scheduledArrivalDate": "2021-12-14T20:28:00",
          "scheduledDateUTC": "2021-12-14T00:00:00",
          "createdBy": "v758611"
        }
      ]
    }
  }
  

const taskAuditReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_TASK_AUDIT}_PENDING`: {
      return {
        ...state,
        taskAuditDetails: null,
        isLoadingTaskAuditDetails: true,
        fetchTaskAuditErrorMessage: null
      }
    }
    case `${FETCH_TASK_AUDIT}_FULFILLED`: {
      const resp = action.payload;
      return {
        ...state,
        taskAuditDetails: resp.getAuditDetails?.auditResponseList,
        fetchTaskAuditErrorMessage: resp.getAuditDetails?.isSuccess === false && resp.getAuditDetails?.errors !== null && resp.getAuditDetails?.errors.length > 0 ? "An error occurred. Please try again later":"",
        isLoadingTaskAuditDetails: false
      }
    }
    case `${SET_TASK_AUDIT_SIGNOFF_OBJECT}`: {
      let sObjects = state.signOffObjects.filter(sign => sign.blockId !== action.payload.blockId);
      sObjects.push(action.payload)
      return {
        ...state,
        signOffObjects: sObjects,
        isApproveAll: false,
        isReopenAll: false
      }
    }
    case `${RESET_TASK_AUDIT_SIGNOFF_OBJECT}`: {
      return {
        ...state,
        signOffObjects: [],
        isApproveAll: false,
        isReopenAll: false,
        approveAllBlocksDash1: null,
        reopenAllBlocksDash1: null
      }
    }
    case `${SIGNOFF_TASK_AUDIT_BLOCKS}_PENDING`: {
      return {
        ...state,
        signOffTaskAuditBlocksResponse: null,
        signOffTaskAuditErrors: null,
        isSigningOffTaskAuditBlocks: true
      }
    }
    case `${SIGNOFF_TASK_AUDIT_BLOCKS}_FULFILLED`: {
      const resp = action.payload;
      return {
        ...state,
        signOffTaskAuditBlocksResponse: resp?.updateNASignoffBlocks?.isSuccess,
        signOffTaskAuditErrors: resp?.updateNASignoffBlocks?.errors,
        isSigningOffTaskAuditBlocks: false,
        approveAllBlocksDash1: null,
        reopenAllBlocksDash1: null,
        signOffObjects: []
      }
    }
    case `${SET_ACTIVE_DASH1}`: {
      return {
        ...state,
        activeDash1: state.activeDash1 !== action.payload ? action.payload : null,
        signOffObjects: [],
        isApproveAll: false,
        isReopenAll: false
      }
    }
    case `${APPROVE_ALL_BLOCKS}`: {
      return {
        ...state,
        isApproveAll: action.payload.signoffObjects && action.payload.signoffObjects.length > 0 ? true : false,
        isReopenAll: false,
        signOffObjects: action.payload.signoffObjects,
        approveAllBlocksDash1: action.payload.mxMasterId,
        reopenAllBlocksDash1: null
      }
    }
    case `${REOPEN_ALL_BLOCKS}`: {
      return {
        ...state,
        isReopenAll: action.payload.signoffObjects && action.payload.signoffObjects.length > 0 ? true : false,
        isApproveAll: false,
        signOffObjects: action.payload.signoffObjects,
        reopenAllBlocksDash1: action.payload.mxMasterId,
        approveAllBlocksDash1: null
      }
    }  
    case `${RESET_FETCHTASKCARD_ERROR_MESSAGE}`: {
      return {
          ...state,
          fetchTaskAuditErrorMessage: null
      }
  }
  case `${FETCH_AUDIT_ACTIVITY_DETAILS}_PENDING`:
    return{
      ...state,
      isLoadingTaskAuditDetails: true
    }
 
  case `${FETCH_AUDIT_ACTIVITY_DETAILS}_FULFILLED`:
    return{
      ...state,
      taskAuditActivityLog: action.payload?.getAuditActivityDetails?.auditActivityResponseList,
      isLoadingTaskAuditDetails: false
    }
  case "SET_AUDIT_FILTERS":
    return {
      ...state,
      taskAuditFilters: action.payload
    }
  default: return state;
  }
}

export default taskAuditReducer;