import { trackPromise } from 'react-promise-tracker';
import * as Queries from '../queries/VisitScheduleQueries';
import * as VisitQueries from '../queries/VisitQueries';
import * as TemplateQueries from '../queries/TemplateQueries';
import { fetchTemplateDetails, fetchTemplateManagementGroups } from './templateActions';

import {
    SET_LAST_REFRESHED, FETCH_VISIT_INFO, FETCH_VISIT_SCHEDULE_SHIFT_INFO,
    SET_GENERIC_ERROR, FETCH_LOAD_GROUPS, SET_SELECTED_GROUP, SAVE_VISIT_SCHEDULE,FETCH_GROUPS_HISTORY,
    RESET_LOADGROUPS, SET_NOTIFICATION_MESSAGE, SET_GROUP_SCHEDULE_UPDATE_FLAG,
    SET_SAVE_MXGROUP_REQUEST, SET_INFO_MODAL, SET_MXGROUP_OVERRIDE,
    FETCH_TEMPLATE_DETAILS, SET_FAILED_NOTIFICATION_MESSAGE, DELETE_MXGROUP
} from './actionTypes';
import { executeQuery } from '../helper';

export const fetchVisitShiftDetails = (station, actualInductionDate, shiftNumber) => {
    return (dispatch, getState) => {
        return dispatch({
            type: FETCH_VISIT_SCHEDULE_SHIFT_INFO,
            payload: executeQuery(Queries.fetchVisitShiftDetails(station, actualInductionDate, shiftNumber), null, getState(), 'VisitSchedule')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const fetchLoadGroups = (visitInfo) => {
    return (dispatch, getState) => {
        //const visitInfo = getState() && getState().visitsStore.visitInfo;
        const profile = getState() && getState().profileStore.userProfile
        const variable = {
            "loadGroupsTM": {
                "groupName": "",
                "checkType": visitInfo.checkType,
                "visitId": parseInt(visitInfo.visitId, 10),
                "lastWorkLoadRequestDate": null,
                "tailNumber": visitInfo.aircraftNumber,
                "station": visitInfo.station,
                "inFlightNumber": "",
                "outFlightNumber": "",
                "arrivalDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "departureDate": visitInfo.actualETRDate ? visitInfo.actualETRDate : visitInfo.plannedETRDate,
                "estimatedArrivalDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "estimatedDepartureDate": visitInfo.actualETRDate ? visitInfo.actualETRDate : visitInfo.plannedETRDate,
                //package type is defaulted to R for all loadgroups calls.
                "packageType": "R",
                "userId": profile.userId,
                "isHvyChkPackage": visitInfo.enableZonalCard,
                "isHistoricalVisit": visitInfo.isArchived,
                "enableVS": visitInfo.enableVS
            }
        }
        return dispatch({
            type: FETCH_LOAD_GROUPS,
            payload: executeQuery(Queries.fetchLoadGroups(), variable, getState(), 'VisitSchedule')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchVisitScheduleInfo = (visitInfo, userId) => {
    return (dispatch, getState) => {
        const selectedGroupId = getState() && getState().visitScheduleStore.selectedGroup?.groupId;
        return dispatch(fetchTemplateManagementGroups(visitInfo.visitId, "visit"))
        //return dispatch(fetchLoadGroups(visitInfo))
            .then((response) => {
                let maxShift = response?.value?.getGroups !== null && Math.max.apply(Math, response.value?.getGroups?.groups?.map(function (grp) { return grp.shiftEndNumber; }));
                //let maxShift = response?.value?.loadGroups !== null && Math.max.apply(Math, response.value?.loadGroups?.mxGroups?.map(function (grp) { return grp.shiftEndNumber; }));
                maxShift = maxShift > visitInfo.shiftCount + 9 ? (maxShift - 9) : visitInfo.shiftCount
                dispatch({
                    type: FETCH_VISIT_SCHEDULE_SHIFT_INFO,
                    payload: executeQuery(Queries.fetchVisitShiftDetails(visitInfo.station, visitInfo.actualInductionDate || visitInfo.plannedInductionDate, maxShift), null, getState(), 'VisitSchedule')
                })
                .then(() => {
                    if (selectedGroupId) {
                        dispatch(setSelectedGroup(selectedGroupId));
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchLoadGroupsSummaryData = (acControlID, station, visitId, userId, forceRefresh = false, isNotVisitDashboard = true) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        //if (visitInfo && !forceRefresh) return;
        return dispatch({
            type: FETCH_VISIT_INFO,
            payload: executeQuery(VisitQueries.fetchVisitInfo(acControlID, station), null, getState(), 'Visit')
        })
            .then(resp => {
                if (resp !== null && resp.value.viewVisitInfo !== null) {
                    const visitInfo = resp.value.viewVisitInfo;
                    dispatch(resetLoadGroups());
                    dispatch(fetchVisitScheduleInfo(visitInfo, visitInfo.visitId, userId))
                    if(isNotVisitDashboard) dispatch(fetchLoadGroups(visitInfo))
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const setSelectedGroup = (mxGroupId) => {
    return dispatch => {
        return dispatch({
            type: SET_SELECTED_GROUP,
            payload: mxGroupId
        });
    }
}

export const saveVisitSchedule = (group, saveMXGroup, isTemplate, mxTemplateId) => {    
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const selectedGroup = isTemplate ?
            getState() && getState().templateStore.selectedTemplateGroup
            :
            getState() && getState().visitScheduleStore.selectedGroup;
        const selectedGroupId = selectedGroup?.groupId
        let variable = {};
        let isOverride = saveMXGroup && group && group.saveVS?.visitScheduleList?.length > 0 && !group.saveVS?.associateGroups?.find(assocGroup => assocGroup.mxmasterIds?.length > 0);
        variable = { ...group, saveVS: { ...group.saveVS, associateGroups: [...group.saveVS.associateGroups.filter(assocGroup => assocGroup.mxmasterIds?.length > 0)] } } ;
               
        return dispatch({
            type: SAVE_VISIT_SCHEDULE,
            payload: executeQuery(Queries.saveVisitSchedule(), variable, getState(), 'VisitSchedule')
        })
            .then(resp => {
                if (resp !== null && resp.value.saveVSandAssociateGroups !== null) {
                    if (resp.value.saveVSandAssociateGroups.isSuccess) {
                        dispatch({
                            type: SET_NOTIFICATION_MESSAGE,
                            payload: saveMXGroup ? isOverride ? "Scheduled items successfully" : "Groups associated successfully" :  "Scheduled group successfully"
                        });
                        const getTemplateVariable = {
                            "templateDetails": {
                                "mxTemplateId": parseInt(mxTemplateId)
                            }
                        }
                        if (isTemplate) {
                            dispatch({
                                type: FETCH_TEMPLATE_DETAILS,
                                payload: executeQuery(TemplateQueries.fetchTemplateDetails(), getTemplateVariable, getState(), 'TemplateManagement')
                            });                            
                        }
                        else {
                            dispatch(fetchLoadGroups(visitInfo))
                                .then(() => {
                                    if (selectedGroupId) {
                                        dispatch(setSelectedGroup(selectedGroupId));
                                    }
                                })
                        }
                    }
                    else {
                        dispatch({
                            type: SET_FAILED_NOTIFICATION_MESSAGE,
                            payload: "Error occured"
                        });
                    }
                 }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const resetLoadGroups = () => {
    return dispatch => {
        return dispatch({
            type: RESET_LOADGROUPS
        });
    }
}

export const setGroupScheduleUpdateFlag = (isUpdated) => {
    return dispatch => {
        return dispatch({
            type: SET_GROUP_SCHEDULE_UPDATE_FLAG,
            payload: isUpdated
        })
    }
}

export const setSaveMXGroupsReq = (request) => {
    return dispatch => {
        return dispatch({
            type: SET_SAVE_MXGROUP_REQUEST,
            payload: request
        })
    }
}

export const setMxGroupOverride = (overrideShift, mxMasterId, isRemove, isTemplate) => {
    return (dispatch, getState) => {
        const selectedGroup = getState() && getState().visitScheduleStore.selectedGroup?.groupInfo;
        const selectedTemplateGroup = getState() && getState().templateStore.selectedTemplateGroup?.groupInfo;
        const group = isTemplate ? selectedTemplateGroup : selectedGroup;
        const saveMXGroupsReq = getState() && getState().visitScheduleStore.saveMXGroupsReq;
        const profile = getState() && getState().profileStore.userProfile
        const visitSchedule = {
            "visitScheduleId": 0,
            "visitWorkLoadId": 0,
            "mxMasterId": parseInt(mxMasterId),
            "shiftStartNumber": overrideShift ? parseInt(overrideShift.shiftNumber) : 0,
            "shiftEndNumber": overrideShift ? parseInt(overrideShift.shiftNumber) : 0,
            "mxGroupId": group.groupId,
            "modifiedBy": profile.userId,
            "firstName": profile.firstName,
            "lastName": profile.lastName,
            "isActive": true
        }
        const updatedSaveMXGroupsReq = isRemove ?
            { ...saveMXGroupsReq, saveVS: { ...saveMXGroupsReq.saveVS, visitScheduleList: [...saveMXGroupsReq.saveVS.visitScheduleList.filter(vs => vs.mxMasterId !== mxMasterId)] } }
            :
                saveMXGroupsReq?.saveVS?.visitScheduleList?.find(vs => vs.mxMasterId === mxMasterId) === undefined ?
                { ...saveMXGroupsReq, saveVS: { ...saveMXGroupsReq.saveVS, visitScheduleList: [...saveMXGroupsReq.saveVS.visitScheduleList, visitSchedule] } }
                : { ...saveMXGroupsReq, saveVS: { ...saveMXGroupsReq.saveVS, visitScheduleList: [...saveMXGroupsReq.saveVS.visitScheduleList.map(vsl => vsl.mxMasterId === mxMasterId ? { ...vsl, shiftStartNumber: parseInt(overrideShift.shiftNumber), shiftEndNumber: parseInt(overrideShift.shiftNumber) } : { ...vsl })] } }
        return dispatch({
            type: SET_SAVE_MXGROUP_REQUEST,
            payload: updatedSaveMXGroupsReq
        })
    }
}
export const getVisitScheduleHistory = (acControlID,station, forceRefresh) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        if (visitInfo && !forceRefresh) return;
        return dispatch({
            type: FETCH_VISIT_INFO,
            payload: executeQuery(VisitQueries.fetchVisitInfo(acControlID, station), null, getState(), 'Visit')
        }).then(resp => {
            if (resp !== null && resp.value.viewVisitInfo !== null) {
                const visitInfo = resp.value.viewVisitInfo;
                // const variable = {
                //     "visitHistory": {
                //         "tailNumber": "0204",
                //         "visitId": 32750,
                //         "station": "MCO",
                //         "schedArrDate": "2021-04-29T09:32:00",
                //         "baseStation": "",
                //         "inFlightNumber": "",
                //         "aicraftControlId": 0,
                //         "createdBy": "",
                //         "baseInductionDate": "2021-04-29T09:32:00"
                //     }
                // }
                 const variable = {
                    "visitHistory": {
                        "tailNumber": visitInfo.aircraftNumber,
                        "visitId": visitInfo.visitId,
                        "station": visitInfo.station,
                        "schedArrDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                        "baseStation": "",
                        "inFlightNumber": "",
                        "aicraftControlId": visitInfo.aircraftControlId,
                        "createdBy": "",
                        "baseInductionDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate
                    }
                }
                

                return dispatch({
                    type: FETCH_GROUPS_HISTORY,
                    payload: executeQuery(Queries.getVisitScheduleHistory(), variable, getState(), 'VisitSchedule')
                })
            }
        })
            .then(resp => {
                if (resp?.value?.getVisitScheduleHistory) {
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const setInfoModal = (infoMessage) => {
    return dispatch => {
        return dispatch({
            type: SET_INFO_MODAL,
            payload: infoMessage
        })
    }
}
export const deleteMxGroup = (mileStone, isTemplate, id) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile
        const variable = {
            "deleteGroup": {
                "groupId": mileStone.groupId,
                "modifiedBy": profile.userId,
                "firstName": profile.firstName,
                "lastName": profile.lastName
            }
        }
        
        return dispatch({
            type: DELETE_MXGROUP,
            payload: trackPromise(executeQuery(Queries.deleteMxGroup(), variable, getState(), 'TemplateManagement'))
        })
            .then(resp => {
                if (resp.value.deleteTemplateOrVisitGroup.isSuccess) {
                    dispatch({
                        type: SET_NOTIFICATION_MESSAGE,
                        payload: "Milestone " + mileStone.groupName + " has been deleted successfully."
                    });
                    dispatch(fetchTemplateDetails(id));
                }
                else{
                    dispatch({
                        type: SET_FAILED_NOTIFICATION_MESSAGE,
                        payload: "Milestone delete failed.Please try later."
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

