//export const localProfile = "V744337"; // Vishnu
//export const localProfile = "N079848";
//export const localProfile = "N079848";
//export const localProfile = "v795013"; //Harsha
//export const localProfile = "v795013";
// export const localProfile = "U372356";
//export const localProfile = "N079848";
//export const localProfile = "v788339";
//export const localProfile = "v768276"; //Sai
// export const localProfile ="U347196"
//export const localProfile = "V859218";
//export const localProfile = "V801493"; //Anusha
//export const localProfile = "v855128"; // Mubaraq
export const localProfile = "V860033"; // Revathi
//export const localProfile = "N079848"; // Nikhil
//export const localProfile = "u370653"; // Rumiana
//export const localProfile = "V868712"; //Torres Jose
//export const localProfile = "v101294"; //Hemanth Bogala


