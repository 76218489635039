import { FETCH_ARCHIVE_SEARCH_RESULTS, HANDLE_SORT_ARCHIVE, STORE_ARCHIVE_SEARCH_PARAMS, IS_ARCHIVE, IS_MASTER, RESET_WARNING_MESSAGE, RESET_SORT } from '../actions/actionTypes';
import { sort } from '../helper';

const initialState = {
  sortedArchiveSearchResults: [],
  sortType: "Archived date",
  sortOrder: false,//desc
  archiveSearchParams: {},
  isLoadingResults: false,
  notificationMessage: "",
  showNotification: false,
}

const archiveSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ARCHIVE_SEARCH_RESULTS}_PENDING`: {
      return {
        ...state,
        sortedArchiveSearchResults: [],
        notificationMessage: "",
        showNotification: false,
        isLoadingResults: true

      }
    }
    case `${FETCH_ARCHIVE_SEARCH_RESULTS}_FULFILLED`: {
      return {
        ...state,
        // archiveSearchResults: action.payload && action.payload.archivedCardSearch.taskCardSearchItems,
        sortedArchiveSearchResults: action.payload.archivedCardSearch.taskCardSearchItems !== null ? sort(action.payload && action.payload.archivedCardSearch.taskCardSearchItems, state.sortType, state.sortOrder): [],
        notificationMessage: action.payload.archivedCardSearch.warningMessage,
        showNotification: action.payload.archivedCardSearch.warningMessage ? true : false,
        // sortType: "Archived date",
        // sortOrder: false,
        isLoadingResults: false

      }
    }
    case `${HANDLE_SORT_ARCHIVE}`: {
      return {
        ...state,
        sortType: action.payload.type,
        sortOrder: action.payload.order,
        sortedArchiveSearchResults: action.payload.data,

      }
    }
    case `${STORE_ARCHIVE_SEARCH_PARAMS}`: {
      return {
        ...state,
        archiveSearchParams: action.payload,
      }
    }
 
    case `${RESET_WARNING_MESSAGE}`: {
      return {
        ...state,
        showNotification: false
      }
    }
    case `${RESET_SORT}`: {
      return {
        ...state,
        sortType: "Archived date",
        sortOrder: false,
      }
    }
    default: return state;
  }
}

export default archiveSearchReducer;