import {
    FETCH_REGISTER_DATA, HANDLE_DASH8_SELECTION, SET_LAST_REFRESHED, SET_SEARCH_CARDS_TEXT, SET_GENERIC_ERROR, UPDATE_DASH1_SELECTION, FETCH_VISIT_INFO, ACCOUNTABILITY_FILTERS,
    MARK_PAPERDASH1_STATUS, SIGNOFF_PAPERDASH8, SET_NOTIFICATION_MESSAGE, RESET_PAPERDASH1_ERROR_MESSAGE, TOGGLE_PAPER_SIGNOFF_MODAL, RESET_PAPERDASH8_ERRORS,
    FETCH_WORKLOAD_ACCOUNTABILITY_DATA, RESET_ACCOUNTABILITY_DATA, SET_AIRCRAFT_INFO, GET_MRD_STATUS, CHECK_MRD, SET_SHOW_CHECK_MRD_MODAL, SET_SHOW_ISSUE_MRD_CONFIRMATION_MODAL,
    SET_SHOW_ISSUE_MRD_ERROR_MODAL, ISSUE_MRD, GET_STATION_LOCAL_TIME, RESET_MRD_ERROR_MESSAGES, SET_SHOW_VIEW_MRD_MODAL, VIEW_MRD, FETCH_CARRIED_DEFFERED_LOGPAGE_DATA, VIEW_MFD,
    FETCH_INDUCTED_AIRCRAFT_INFO, RESET_INDUCTED_AIRCRAFT_INFO, FETCH_PARTS_AND_TOOLS_DATA, SET_SHOW_PARTS_AND_TOOLS_SUMMARY_LINK, PARTS_AND_TOOLS_FILTERS, SEARCH_HARMONIZED_WORKLOAD, RESET_SEARCH_HARMONIZED_WORKLOAD,
    FETCH_EXTENDED_ROUTING, SET_INSP_WIDGET_FLOW_FLAG, FETCH_ADVANCE_WORKLOAD_SEARCH, SELF_ASSOCIATE_WL, SET_FAILED_NOTIFICATION_MESSAGE,
    FETCH_ASSIGNED_WORK, HANDLE_BASE_ASSIGNWORK_TOGGLE, SET_ASSIGNED_ROUTINES, SET_ASSIGN_TOGGLE, GET_OPTMIZED_STATUSES
} from './actionTypes';
import { trackPromise } from 'react-promise-tracker';
import * as Queries from '../queries/RegisterQueries';
import * as VisitQueries from '../queries/VisitQueries';
import { executeQuery } from '../helper';
import moment from 'moment'


export const fetchRegisterData = (aircraftNumber, employeeID, station, departureDate, arrivalDate, jobRole, isLine, inFlightNumber, isHeavyChk, isArchived, enableVS) => {
    
    const variable = {
        "routinesWorkload": {
            "inFlightNumber": inFlightNumber,
            "arrivalDate": arrivalDate,
            "departureDate": departureDate
        }
    }
    const optmizedStatus = {
        ACTIVE: 0,
        DEDUP: 1,
        EXCLUDE: 2
      };
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_REGISTER_DATA,
            payload: executeQuery(Queries.fetchRegisterData(aircraftNumber, employeeID, station, jobRole, isLine, isLine ? false : isHeavyChk, isArchived, enableVS), variable, getState(), 'RoutineRegister')
        }).then(resp => {
            if (resp !== null && resp.value.routineRegister !== null) {
                // set aircraftinfo when response has flifo data SET_AIRCRAFT_INFO
                if (isLine) {
                    const aircraftInfo =
                    {
                        aircraftNumber: aircraftNumber,
                        arrivalDate: arrivalDate,
                        departureDate: departureDate,
                        acControlId: null,
                        inFlightNumber: inFlightNumber,
                        isPastOrFutureVisit: false,
                        isArchived: false,
                        isFuture: false,
                        showNonRoutines: false,
                        station: station
                    };
                    dispatch({
                        type: SET_AIRCRAFT_INFO,
                        payload: aircraftInfo
                    })
                    const mRDVariable = {
                        "getMrd": {
                            "tailNumber": aircraftNumber
                        }
                    }
                    dispatch({
                        type: GET_MRD_STATUS,
                        payload: executeQuery(Queries.getMRDStatus(), mRDVariable, getState(), 'Workload')
                    })
                }
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })    
                dispatch({
                    type: GET_OPTMIZED_STATUSES,
                    payload: optmizedStatus
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchLineRegisterData = (aircraftNumber, employeeID, station, departureDate, arrivalDate, jobRole, isLine, inFlightNumber, isHeavyChk) => {
    return (dispatch, getState) => {
       //always false for line
        const isArchived = false;
        const enableVS = false;      
        dispatch({
            type: FETCH_INDUCTED_AIRCRAFT_INFO,
            payload: executeQuery(Queries.GetInductedAircraftData(station, aircraftNumber, arrivalDate), null, getState(), 'Visit')
        }).then(resp => {
            if (resp.value.getInductedACData && !resp.value.getInductedACData.isInducted) {
                dispatch(fetchRegisterData(aircraftNumber, employeeID, station, departureDate, arrivalDate, jobRole, isLine, inFlightNumber, isHeavyChk, isArchived, enableVS));
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const getInductedAircraftData = (station, aircraftNumber, arrivalDate) => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_INDUCTED_AIRCRAFT_INFO,
            payload: executeQuery(Queries.GetInductedAircraftData(station, aircraftNumber, arrivalDate), null, getState(), 'Visit')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchRoutineRegisterData = (aircraftNumber, acControlID, employeeID, station, jobRole, isLine) => {
    const optmizedStatus = {
        ACTIVE: 0,
        DEDUP: 1,
        EXCLUDE: 2
      };
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        dispatch({
            type: RESET_INDUCTED_AIRCRAFT_INFO
        });
       
        if(visitInfo)
        {
        const routineVariable = {
            "routinesWorkload": {
                "inFlightNumber": "0000",
                "arrivalDate": visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                "departureDate": visitInfo.plannedETRDate,
                //"isHistoricalVisit": visitInfo.isArchived
            }
        }
        
        dispatch({
            type: FETCH_REGISTER_DATA,
            payload: executeQuery(Queries.fetchRegisterData(aircraftNumber, employeeID, station, jobRole, isLine, visitInfo.enableZonalCard, visitInfo.isArchived, visitInfo.enableVS), routineVariable, getState(), 'RoutineRegister')
        }).then(resp => {
            if (resp !== null && resp.value.routineRegister !== null) {
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
                dispatch({
                    type: GET_OPTMIZED_STATUSES,
                    payload: optmizedStatus
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
        }
    }

}

export const handleDash8Selection = (dash8Info, type, accordionTitle) => {
    return dispatch => {
        dispatch({
            type: HANDLE_DASH8_SELECTION,
            payload: { selectedDash8: dash8Info, selectionType: type, accordionTitle: accordionTitle }
        });
    }
}

export const setSearchCardsText = (searchText) => {
    return dispatch => {
        dispatch({
            type: SET_SEARCH_CARDS_TEXT,
            payload: searchText?.trim()
        });
    }
}
export const updateDash1Selection = (dash1Info) => {
    return dispatch => {
        dispatch({
            type: UPDATE_DASH1_SELECTION,
            payload: dash1Info
        });
    }
}
export const signoffPaperDash8 = (profile, visitInfo, selectedDash8, encryptedToken, isFlifo) => {
    const dash1Array = selectedDash8 && selectedDash8.dash1s.map((dash1) => {
        return Object.assign({},
            {
                "optimizedReason": dash1.optimizedReason,
                "optimizedVersion": dash1.optimizedVersion,
                "masterId": dash1.mxMasterId,
                "cardNumber": dash1.dash1Number,
                "dash1Status": dash1.dash1Status,
                "optimizedGroup": dash1.optimizedGroup
            });
    });
    const variable = {
        "d8signOff": {
            "lTerm": profile.lTerm, //"SFOFT2VQ",
            "employeeID": profile.userId, //"V744337",
            "inspectedAircraftNumber": visitInfo.aircraftNumber,
            "assignedStation": visitInfo.station,
            "flightNumber": visitInfo.inFlightNumber ,
            "scheduledArrivalDate": visitInfo.arrivalDate,
            "token": encryptedToken,
            "dash8s": [{
                "inspectionPartNumber": selectedDash8.dash8Number,
                "serialNumber": selectedDash8.serialNumber,
                "workControlNumber": selectedDash8.workControlNumber,
                "forecastPosition": selectedDash8.positionCode,
                "forecastDescription": selectedDash8.dash8Description,
                "cardDetails": dash1Array
            }]
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: SIGNOFF_PAPERDASH8,
            payload: executeQuery(Queries.signoffPaperDash8(), variable, getState(), 'SignOff')
        }).then(resp => {
            if (resp.value.dash8SignOff && !resp.value.dash8SignOff.errorMessage && !resp.value.dash8SignOff.passwordErrorMessage) {
                if (selectedDash8.dash1s
                    && selectedDash8.dash1s.filter(dash1 => dash1.dash1Number !== null).length > 0) {
                    dispatch(markPaperDash1Status(selectedDash8.dash1s, selectedDash8, profile, visitInfo, true, isFlifo));
                }
                else {
                    dispatch(fetchRegisterData(selectedDash8.tailNumber, profile.userId, profile.station, visitInfo.departureDate, visitInfo.arrivalDate, profile.jobRole, isFlifo, visitInfo.inFlightNumber, visitInfo.enableZonalCard, visitInfo.isArchived, visitInfo.enableVS));
                }
                const paperCardNotification = "Sceptre signoff has been successful.";
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: paperCardNotification
                });                
            }
            else if (!resp.value.dash8SignOff || (resp.value.dash8SignOff && resp.value.dash8SignOff.errorMessage)) {
                dispatch({
                    type: SET_FAILED_NOTIFICATION_MESSAGE,
                    payload: "Signoff failed due to an issue."
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const markPaperDash1Status = (dash1s, dash8, profile, visitInfo, sceptreSignoff, isFlifo, isSearchWL = false) => {

    const dash1Array = dash1s.map((dash1) => {
        return Object.assign({},
            {
                "forecastPosition": dash8.positionCode,
                "isCompleted": sceptreSignoff ? true : !dash1.isDash1Completed,
                "cardnumber": dash1.dash1Number,
                "workControlNumber": dash1.workControlNumber,
                "assignedDate": dash1.assignedDate,
                "tailNumber": dash8.tailNumber || visitInfo?.aircraftNumber,
                "scheduledArrivalDate": visitInfo.arrivalDate,
                "paperTaskCardId": dash1.paperTaskCardId,
                "assignedStation": visitInfo.station,
                "flightNumber": visitInfo.inFlightNumber,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "serialNumber": dash1.serialNumber,
                "dash8": dash8.dash8Number
            });
    });

    const variable = {
        "paperSignOff": {
            "employeeId": profile.userId,
            "lastUpdatedDateTime": moment(),
            "firstName": profile.firstName,
            "lastName": profile.lastName,
            "savePaperTaskCards": dash1Array
        }
    }
    const paperCardNotification = sceptreSignoff ? "Sceptre signoff has been successful." : sceptreSignoff === false && dash1Array[0].isCompleted !== true ? "Paper card has been marked as incomplete!" :
        "Paper card has been marked as complete!";

    return (dispatch, getState) => {
        dispatch({
            type: MARK_PAPERDASH1_STATUS,
            payload: trackPromise(executeQuery(Queries.markPaperDash1Status(), variable, getState(), 'SignOff'))
        }).then(resp => {
            if (resp !== null && resp.value.markPaperCardComplete && resp.value.markPaperCardComplete.isSuccess !== "False") {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: paperCardNotification
                });
                if(visitInfo?.enableVS && visitInfo.isTemplateApplied && isSearchWL){
                    dispatch(fetchAdvanceWorkloadSearch(getState().registerStore.searchHarmonizedSearchCriteria.advancedSearchParams))
                }
                else {
                    dispatch(fetchRegisterData((dash8.tailNumber || visitInfo?.aircraftNumber), profile.userId, visitInfo.station, visitInfo.departureDate, visitInfo.arrivalDate, profile.jobRole, isFlifo, visitInfo.inFlightNumber, visitInfo.enableZonalCard, visitInfo.isArchived, visitInfo.enableVS));
                }               
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const resetPaperDash1completeError = () => {
    return dispatch => {
        dispatch({
            type: RESET_PAPERDASH1_ERROR_MESSAGE
        })
    }
}

export const togglePaperSignoffModal = (showPaperSignoffModal) => {
    return dispatch => {
        dispatch({
            type: TOGGLE_PAPER_SIGNOFF_MODAL,
            payload: showPaperSignoffModal
        })
    }
}

export const resetPaperDash8Errors = () => {
    return dispatch => {
        dispatch({
            type: RESET_PAPERDASH8_ERRORS
        })
    }
}

export const fetchWorkloadAccountabilityData = (acControlID, station) => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_VISIT_INFO,
            payload: executeQuery(VisitQueries.fetchVisitInfo(acControlID, station), null, null, 'Visit')
        }).then(resp => {
            if (resp !== null && resp.value.viewVisitInfo !== null) {
                const visitInfo = resp.value.viewVisitInfo;
                const workloadObj = {
                    "workloadAccountability": {
                        "tailNumber": visitInfo.aircraftNumber,
                        "lastWorkLoadPullDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                        "station": visitInfo.station,
                        "arrivalDate": visitInfo.actualInductionDate ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
                        "departureDate": visitInfo.plannedETRDate,
                        "actualInductionDate": visitInfo.actualInductionDate,
                        "isHvyChkPackage": visitInfo.enableZonalCard,
                        "visitId": visitInfo.enableVS ? visitInfo.visitId : 0
                    }
                }
                dispatch({
                    type: FETCH_WORKLOAD_ACCOUNTABILITY_DATA,
                    payload: executeQuery(Queries.fetchWorkloadAccountabilityData(), workloadObj, getState(), 'Workload')
                }).then(resp => {
                    if (resp !== null && resp.value?.getWorkloadAccountability !== null) {
                        dispatch({
                            type: SET_LAST_REFRESHED,
                            payload: Date.now()
                        })
                    }
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const updateAccountabilityFilters = (filtersObject) => {
    return dispatch => {
        dispatch({
            type: ACCOUNTABILITY_FILTERS,
            payload: filtersObject
        });
    }
}
export const resetAccountabilityData = () => {
    return dispatch => {
        dispatch({
            type: RESET_ACCOUNTABILITY_DATA
        })
    }
}
export const checkMRD = (profile, tailNo, password) => {
    var tobeEncrypted = profile.userId + "," + password;
    var encryptedToken = window.btoa(tobeEncrypted);
    const checkVariable = {
        "checkMrd": {
            "sendToPrinter": false,
            "token": encryptedToken, //"VTM0NzE5NiwkU2hhc2EyMzY2",//encryptedToken,
            "lTerm": profile.lTerm, //"SFOFT0VM",//profile.lTerm,
            "tailNumber":tailNo,
            "station":sessionStorage.getItem("apiStation").toUpperCase()
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: CHECK_MRD,
            payload: trackPromise(executeQuery(Queries.checkMRD(), checkVariable, getState(), 'Workload'))

        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const setShowCheckMrdModal = (showCheckMrdModal) => {
    return dispatch => {
        dispatch({
            type: SET_SHOW_CHECK_MRD_MODAL,
            payload: showCheckMrdModal

        })
    }

}
export const setShowIssueMrdConfirmationModal = (showIssueMrdConfirmationModal, password) => {
    return dispatch => {
        dispatch({
            type: SET_SHOW_ISSUE_MRD_CONFIRMATION_MODAL,
            payload: { showIssueMrdConfirmationModal: showIssueMrdConfirmationModal, password: password }

        })
    }
}
export const issueMRD = (profile, tailNo, password, station) => {

    const issueVariable = {
        "issueMrd": {
            "station": station,
            "token": password,//"VTM0NzE5NiwkU2hhc2EyMzY2", //password,
            "lTerm": profile.lTerm,//"SFOFT0VM", //profile.lTerm,
            "tailNumber": tailNo,
            "printerAddress": "ACFT"
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: ISSUE_MRD,
            payload: trackPromise(executeQuery(Queries.issueMRD(), issueVariable, getState(), 'Workload'))
        }).then(resp => {
            dispatch({
                type: SET_SHOW_ISSUE_MRD_CONFIRMATION_MODAL,
                payload: { showIssueMrdConfirmationModal: false, password: null }
            })
            if (resp?.value?.issueMrd?.isSuccessful) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "MRD issued Successfully"
                });
                const mRDVariable = {
                    "getMrd": {
                        "tailNumber": tailNo
                    }
                }
                dispatch({
                    type: GET_MRD_STATUS,
                    payload: executeQuery(Queries.getMRDStatus(), mRDVariable, getState(), 'Workload')
                })
            }
            else {

                dispatch({
                    type: SET_SHOW_ISSUE_MRD_ERROR_MODAL,
                    payload: true
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const getMRDStatus = (tailNo) => {
    const mRDVariable = {
        "getMrd": {
            "tailNumber": tailNo
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: GET_MRD_STATUS,
            payload: executeQuery(Queries.getMRDStatus(), mRDVariable, getState(), 'Workload')

        })
    }
}
export const setShowIssueMrdErrorModal = (showIssueMrdErrorModal) => {
    return dispatch => {
        dispatch({
            type: SET_SHOW_ISSUE_MRD_ERROR_MODAL,
            payload: showIssueMrdErrorModal
        })
    }
}
export const setShowViewMrdModal = (showViewMrdModal, password) => {
    return dispatch => {
        dispatch({
            type: SET_SHOW_VIEW_MRD_MODAL,
            payload: { showViewMrdModal: showViewMrdModal, password: password }
        })
    }
}
export const resetErrorMessagesAndData = () => {
    return dispatch => {
        dispatch({
            type: RESET_MRD_ERROR_MESSAGES,
        })
    }
}

export const getStationLocalTime = (station) => {
    return (dispatch, getState) => {
        dispatch({
            type: GET_STATION_LOCAL_TIME,
            payload: executeQuery(Queries.getStationLocalTime(station), null, getState(), "RoutineRegister")
        })
    }
}
export const viewMRD = (profile, tailNo, password, station) => {
    var tobeEncrypted = profile.userId + "," + password;
    var encryptedToken = window.btoa(tobeEncrypted);
    const viewVariable = {
        "viewMrd": {
            "token": encryptedToken, //"VTM0NzE5NiwkU2hhc2EyMzY2",//encryptedToken,
            "lTerm": profile.lTerm, //"SFOFT0VM",//profile.lTerm,
            "tailNumber": tailNo,
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: VIEW_MRD,
            payload: trackPromise(executeQuery(Queries.viewMRD(), viewVariable, getState(), 'Workload'))

        })
            .then(resp => {
                if (resp?.value?.viewMrd?.errorMessage) {
                    dispatch({
                        type: SET_SHOW_VIEW_MRD_MODAL,
                        payload: false
                    })
                    dispatch({
                        type: SET_SHOW_ISSUE_MRD_ERROR_MODAL, // used for view too
                        payload: true
                    })
                }

            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const viewMFD = (profile, tailNo, password, station) => {
    var tobeEncrypted = profile.userId + "," + password;
    var encryptedToken = window.btoa(tobeEncrypted);
    const viewVariable = {
        "viewMfd": {
            "token": encryptedToken, //"VTM0NzE5NiwkU2hhc2EyMzY2",//encryptedToken,
            "lTerm": profile.lTerm, //"SFOFT0VM",//profile.lTerm,
            "tailNumber": tailNo,
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: VIEW_MFD,
            payload: trackPromise(executeQuery(Queries.viewMFD(), viewVariable, getState(), 'Workload'))

        })
            .then(resp => {
                if (resp?.value?.viewMfd?.errorMessage) {
                    dispatch({
                        type: SET_SHOW_VIEW_MRD_MODAL,
                        payload: false
                    })
                    dispatch({
                        type: SET_SHOW_ISSUE_MRD_ERROR_MODAL, // used for view too
                        payload: true
                    })
                }

            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }

}

export const fetchCarriedAndDefferedLPData = (aircraftNumber) => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_CARRIED_DEFFERED_LOGPAGE_DATA,
            payload: executeQuery(Queries.GetDeferredAndCarriedLPs(aircraftNumber), null, getState(), 'RoutineRegister')
        }).then(resp => {
            if (resp !== null && resp.getDeferredAndCarriedLPs !== null) {
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }

}

export const fetchPartsAndToolsData = (dash8Num) => {
    const variable = {
        "nCAPartNumber": {
            "ncaPartNumber": dash8Num
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_PARTS_AND_TOOLS_DATA,
            payload: executeQuery(Queries.GetDash8PartsAndToolsData(), variable, getState(), 'RoutineRegister')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
export const showPartsAndToolsSummaryLink = (showPTSummaryLink) => {
    return dispatch => {
        dispatch({
            type: SET_SHOW_PARTS_AND_TOOLS_SUMMARY_LINK,
            payload: showPTSummaryLink
        })
    }
}
export const updatepartsAndToolsFilters = (filtersObject) => {
    return dispatch => {
        dispatch({
            type: PARTS_AND_TOOLS_FILTERS,
            payload: filtersObject
        });
    }
}
export const updateHarmonizedSearchCriteria = (filters) => {
    return dispatch => {
        dispatch({
            type: SEARCH_HARMONIZED_WORKLOAD,
            payload: filters
        });
    }
}
export const resetHarmonizedSearchCriteria = () => {
    return dispatch => {
        dispatch({
            type: RESET_SEARCH_HARMONIZED_WORKLOAD,
            
        });
    }
}

export const fetchExtendedRouting = (isLine) => {    
    return (dispatch, getState) => {
        const aircraftInfo = getState() && getState().visitsStore.aircraftInfo;
        const profile = getState() && getState().profileStore.userProfile
        const variable = {
            "aircraft": {
                "employeeId": profile.userId,
                "tailNumber": aircraftInfo.aircraftNumber,
                "flightNumber": aircraftInfo.inFlightNumber,
                "station": aircraftInfo.station,
                "baseStation": aircraftInfo.station,
                "fleetType": aircraftInfo.fleet || "",
                "scheduledArrival": aircraftInfo.arrivalDate,
                "isBase": !isLine
              }        
        }
        dispatch({
            type: FETCH_EXTENDED_ROUTING,
            payload: executeQuery(Queries.GetExtendedRouting(), variable, getState(), 'Aircraft')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const setInspectionWidgetFlowFlag = (setFlag) => {
    return dispatch => {
        dispatch({
            type: SET_INSP_WIDGET_FLOW_FLAG,
            payload: setFlag
        })
    }
}

export const resetInspectionWidgetFlowFlag = (setFlag) => {
    return dispatch => {
        dispatch({
            type: SET_INSP_WIDGET_FLOW_FLAG,
            payload: false
        })
    }
}

export const fetchAdvanceWorkloadSearch = ({//workAreaIds,
    //mxVisitGroupIds,
    workBinIds,
    skillIds,
    zoneTypeIds,
    taskcardTypeIds,
    mileStoneIds,
    card_or_desc,
    originatingDocument,
    filterIn, visitInfo, isLine, assignedToMe, isAdvSearch}) => {  
        const optmizedStatus = {
            ACTIVE: 0,
            DEDUP: 1,
            EXCLUDE: 2
          };  
    return (dispatch, getState) => {
        const filterBy = filterIn.map(filter =>{
            switch(filter.value){
                case 'nonRoutines':
                    return 'NR';
                case 'openLogPages':
                    return 'OLP';
                case 'scheduledLogPages':
                    return 'SLP';
                case '-1s':
                    return 'TASKS';
                default:
                   return 'ALL';
            }
        }); 
        const profile = getState() && getState().profileStore.userProfile
        const variable = {
           "routinesWorkload": {
            "inFlightNumber": "0000", //Always 0000 since it is a base aircraft
            "arrivalDate": visitInfo?.actualInductionDate || visitInfo?.plannedInductionDate,
            "departureDate": visitInfo?.plannedETRDate
          },
            "advsearchWorkload": {
            "visitId": visitInfo?.visitId,
            "skillIds": skillIds.value?.map(item => (item.value)) || [],
            "workBinIds": workBinIds.value?.map(item => (item.value)) || [],
            // "workAreaIds": workAreaIds.value?.map(item => (item.value)) || [],
            "zoneTypeIds":zoneTypeIds.value?.map(item => (item.value)) || [],
            "mileStoneIds": mileStoneIds.value?.map(item => (item.value)) || [],
            "taskCardTypeIds": taskcardTypeIds.value?.map(item => (item.value)) || [],
            "searchCard": card_or_desc,
            "isHistoricalVisit": visitInfo.isArchived,
            "filterBy": filterBy,
            "originatingDocument": originatingDocument?.value
            }  
        }
        dispatch(updateHarmonizedSearchCriteria({card_or_desc, filterIn, advancedSearch: !!isAdvSearch, assignedToMe, advancedSearchParams: {workBinIds,
            skillIds,
            zoneTypeIds,
            taskcardTypeIds,
            mileStoneIds,
            card_or_desc,
            filterIn, visitInfo, isLine, assignedToMe, isAdvSearch}}))
        dispatch({
            type: FETCH_ADVANCE_WORKLOAD_SEARCH,
            payload: trackPromise(executeQuery(Queries.fetchAdvanceWorkloadSearch(visitInfo?.aircraftNumber, profile?.userId, visitInfo?.station, profile?.jobRole, isLine, visitInfo?.enableZonalCard, visitInfo?.enableVS), variable, getState(), 'RoutineRegister'))
        }).then(resp=>{if (resp !== null && resp.value.advWorkloadSearchMultiSelect !== null) {
            dispatch({
                type: SET_LAST_REFRESHED,
                payload: Date.now()
            })
            dispatch({
                type: GET_OPTMIZED_STATUSES,
                payload: optmizedStatus
            })
        }})
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const selfAssociateWorkload = ({profile, payload, isAssign, type, isDup=false }) => {
    return(dispatch, getState) => {
        const aircraftInfo = getState() && getState().visitsStore.aircraftInfo;
        const assignedWorkToggleOn = getState && getState().registerStore.assignedWorkToggleOn;
        const techAssigned = {
            "empId": profile.userId,
            "lastName": profile.lastName,
            "firstName": profile.firstName,
            "jobRole": profile.jobRole
        }
        const variable = {
            "workload": {
                "createdBy": profile.userId,
                "tailNumber": aircraftInfo.aircraftNumber,
                "station": aircraftInfo.station,
                "assignedDate": moment().format('MMM DD, YYYY'),
                "isInductedAC": true, //for base AC it is always true
                "isAssign": isAssign,
                "tailWorkItems": payload?.map(workItem => {
                    return {
                        ...workItem,
                        "tailNumber": aircraftInfo.aircraftNumber,
                        "scheduledArrival": aircraftInfo.arrivalDate,
                        "scheduledDeparture": aircraftInfo.departureDate,
                        "station": profile.station,
                        "isInductedAC": true,
                        ...(type !== "D1ZC" && { "serialNumber": aircraftInfo.aircraftNumber })
                    }
                }
                ),
                "techs": [techAssigned]
            }
        };
       return dispatch({
            type: SELF_ASSOCIATE_WL,
            payload: trackPromise(executeQuery(Queries.selfAssociateWorkload(), variable, getState(), 'CrewUpgrade'))
        }).then(response =>{
            dispatch({
                type: `SELF_ASSOCIATE_WL_SUCCESS`,
                payload: {...response.value.selfAssociateWorkload, techAssigned, type, isAssign, isDup }            
            })
            if(response.value.selfAssociateWorkload.isSuccess){
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: response.value.selfAssociateWorkload.isAssign ? "Work item assigned successfully." : "Work item unassigned successfully."
                });
                if (!isAssign && assignedWorkToggleOn) {
                    dispatch(fetchAssignedWork(aircraftInfo.aircraftNumber, aircraftInfo.acControlId, profile.userId, aircraftInfo.station, profile.jobRole, false))
                }
            }else{
                dispatch({
                    type: SET_FAILED_NOTIFICATION_MESSAGE,
                    payload: "Workload Assign/UnAssign failed.Please try later."
                });
            }
            return response.value.selfAssociateWorkload.technicianAssigned;
        })
        .catch(err =>{
            dispatch({
                type: SET_GENERIC_ERROR,
                payload: err.errorMessage
            });
        })
    }
}

export const fetchAssignedWork = (aircraftNumber, acControlID, employeeID, station, jobRole, isLine) => {  
    const optmizedStatus = {
        ACTIVE: 0,
        DEDUP: 1,
        EXCLUDE: 2
      }; 
    
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        dispatch({
            type: RESET_INDUCTED_AIRCRAFT_INFO
        });    
        if(visitInfo) {   
            const harmonizedAssignedWorkVariable = {
                "harmonizedAssignedWork": {
                    "harmonizedWorkloadType": "ALL",
                    "isHvyChkPackage": visitInfo.enableZonalCard,
                    "appVersion": "1.0.2168",
                    "baseInductionDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "employeeId": employeeID,
                    "getEtaskWorkload": {
                        "flightNumber": "0000",
                        "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                        "departureDate": visitInfo.plannedETRDate
                    },
                    "enableVS": visitInfo.enableVS,
                    "tailnumber": visitInfo.aircraftNumber,
                    "baseStation": visitInfo.station,
                    "isLineAircraft": isLine,
                    "isFlifo": isLine,
                    "schedArrDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "station": visitInfo.station,
                    "jobRole": jobRole,
                    "shiftCount": visitInfo.shiftCount
                }
            }
            
            dispatch({
                type: FETCH_ASSIGNED_WORK,
                payload: executeQuery(Queries.fetchAssignedWorkload(), harmonizedAssignedWorkVariable, getState(), 'Workload')
            }).then(resp => {
                if (resp !== null && resp.value.getHarmonizedWorkloadAssignedWork !== null) {
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    });
                    dispatch({
                        type: SET_ASSIGNED_ROUTINES,
                        payload: resp.value.getHarmonizedWorkloadAssignedWork.nonRoutines
                    });
                    dispatch({
                        type: GET_OPTMIZED_STATUSES,
                        payload: optmizedStatus
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
        }
    }
}
    

export const handleBaseAssignedWorkToggle = (isToggleOn, aircraftNumber, acControlID, employeeID, station, jobRole, isLine) => {
    return (dispatch, getState) => {
        const assignedWorkload = getState() && getState().registerStore.assignedWorkload;
        const registerData = getState() && getState().registerStore.registerData;
        if (isToggleOn) {
            if (assignedWorkload) {
                dispatch({
                    type: HANDLE_BASE_ASSIGNWORK_TOGGLE,
                    payload: isToggleOn
                })
            }
            else {
                dispatch(fetchAssignedWork(aircraftNumber, acControlID, employeeID, station, jobRole, isLine))
            }
        }
        else {
            if (registerData) {
                dispatch({
                    type: HANDLE_BASE_ASSIGNWORK_TOGGLE,
                    payload: isToggleOn
                })
            }
            else {
                dispatch(fetchRoutineRegisterData(aircraftNumber, acControlID, employeeID, station, jobRole, isLine))
            }
        }
        
    }
}
export const setAssignedWorkToggleOn = (value) => {
    return (dispatch) => {
        dispatch({
            type: SET_ASSIGN_TOGGLE,
            payload: value
        })
    } 
}