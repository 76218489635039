import {
    FETCH_VISIT_SCHEDULE_SHIFT_INFO, FETCH_LOAD_GROUPS,
    SET_SELECTED_GROUP, SAVE_VISIT_SCHEDULE, RESET_LOADGROUPS,
    SET_GROUP_SCHEDULE_UPDATE_FLAG, SET_SAVE_MXGROUP_REQUEST, FETCH_GROUPS_HISTORY,
    SET_INFO_MODAL, SET_MXGROUP_OVERRIDE, SCROLL_WORKLOAD_FLAG, SEARCH_WORKLOAD, SEARCH_WORKLOAD_ENHANCED,
    GET_BASE_CREW_WORKLOAD, UNASSIGN_CREW, GET_AVAIALABLE_CREW, GET_GROUPS_AND_WORKAREA, GET_GROUPS_AND_WORKAREA_WITH_COUNTS,
    GET_PROGRESS_FOR_TASKCARDS, SET_SELECTED_WORKLOAD, SET_SELECTED_CREWS,
    SET_SELECTED_ASSIGNED_WORKLOAD, CURRENT_CREW_DATE, UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY,
    RESET_SEARCH_WORKLOAD, SET_IS_ITEM_UNCHECKED, DELETE_MXGROUP, UNASSIGN_ASSIGN_WORKLOAD,
    CREW_WORK_LOAD_SEARCH, MOVE_WORKLOAD_TO_MULTIPLE_WORKBIN, ADD_WORKLOAD_TO_WORKBIN, CLEAR_CREW_WORK_LOAD_SEARCH,
    UNASSIGN_ASSIGN_WORKLOAD_LOCAL_UPDATE,
    SAVE_LAST_BLOCK_NUMBER,
    DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN
} from '../actions/actionTypes';

const initialState = {
    isLoadingVisitShift: false,
    visitShifts: null,
    loadGroups: null,
    isLoadingLoadGroups: false,
    currentShift: null,
    selectedGroup: null,
    isSaveVisitScheduleSuccess: false,
    saveVisitScheduleError: null,
    isLoadingOnActionSuccess: false,
    isVisitSchedulingTabActive: false,
    otherGroup: null,
    mxGroups: null,
    groupScheduleUpdatedFlag: false,
    saveMXGroupsReq: {
        "saveVS": {
            "tailNumber": "",
            "scheduledArrivalDate": "",
            "station": "",
            "visitScheduleList": [],
            "checkType": "",
            "modifiedBy": "",
            "firstName": "",
            "lastName": "",
            "associateGroups": []
        }
    },
    loadGroupsTransactionHistory: [],
    loadGroupsScheduleHistory: [],
    loadGroupsOverrideHistory: [],
    isLoadingLoadGroupsHistory: true,
    isSaveGroupDirty: false,
    infoModal: "",
    assignCrewWorkload: null,
    workLoadScheduledDate: null,
    isLoadingAssignCrewWorkload: false,
    assignedWorkload: null,
    isLoadingAssignedWorkload: false,
    isUnAssignCrewPending: false,
    isUnAssignSuccess: false,
    unAssignFailureMessage: null,
    availableCrew: null,
    isLoadingAvailableCrew: false,
    crewGroups: null,
    workAreas: null,
    workBins: null,
    skills: null,
    zoneTypes: null,
    isLoadingGroupsAndWorkAreas: false,
    isLoadingGroupsAndWorkAreasWithCounts: false,
    crewFilters: null,
    taskCardsProgress: null,
    isTaskCardsProgressLoading: false,
    selectedWorkload: [],
    selectedCrews: [],
    isItemUnchecked: false,
    isValidAssign: false,
    selectedAssignedWorkload: [],
    isValidUnAssign: false,
    isCurrentCrew: false,
    stationSkill: null,
    workLoadSearch: null,
    blockNumber: null,
    isBlockNumberLoading: true
}

const visitScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${FETCH_VISIT_SCHEDULE_SHIFT_INFO}_PENDING`: {
            return {
                ...state,
                isLoadingVisitShift: true,
                visitShifts: null
            }
        }
        case `${FETCH_VISIT_SCHEDULE_SHIFT_INFO}_FULFILLED`: {
            const shiftInfo = action.payload.getShiftInfo;
            return {
                ...state,
                isLoadingVisitShift: false,
                currentShift: shiftInfo?.visitShifts?.find(shift => shift.isCurrentShift),
                visitShifts: shiftInfo?.visitShifts,
                isCurrentPastETR: shiftInfo?.isCurrentPastETR
            }
        }
        case `${FETCH_LOAD_GROUPS}_PENDING`: {
            return {
                ...state,
                loadGroups: null,
                mxGroups: null,
                isLoadingLoadGroups: true,
                groupSummary: null,
                selectedGroup: state.selectedGroup ? {
                    groupId: state.selectedGroup.groupId,
                    isScheduled: false,
                    groupInfo: null,
                    startShiftInfo: null,
                    endShiftInfo: null
                } : null,
            }
        }
        case `${FETCH_LOAD_GROUPS}_FULFILLED`: {
            const loadGroups = action.payload.loadGroupsTM;
            const mxGroups = loadGroups.error === null ? loadGroups?.mxGroups?.filter(group => group.groupName !== "OTHERS") : [];
            const unGroupedWorkload = loadGroups.error === null ? loadGroups?.mxGroups?.find(group => group.groupName === "OTHERS") : [];
            return {
                ...state,
                loadGroups: loadGroups,
                mxGroups: mxGroups,
                groupSummary: loadGroups.error === null ? {
                    unScheduledGroups: mxGroups?.filter(group => !group.visitScheduleId && (group.nonRoutines.length > 0 || group.dash8s.length > 0 || group.logPages.length > 0)),
                    unUsedGroups: mxGroups?.filter(group => !group.visitScheduleId && group.nonRoutines.length === 0 && group.dash8s.length === 0 && group.logPages.length == 0),
                    scheduledGroups: mxGroups?.filter(group => group.visitScheduleId)
                } : null,
                otherGroup: unGroupedWorkload,
                isLoadingLoadGroups: false,
                isLoadingOnActionSuccess: false,
                isVisitSchedulingTabActive: true,
            }
        }
        case `${SET_SELECTED_GROUP}`: {
            const selectedGroup = state.loadGroups?.mxGroups?.find(group => group.groupId === action.payload);
            let infoModalText = "";
            if (state.saveMXGroupsReq.saveVS.associateGroups.find(assocGroup => assocGroup.mxmasterIds.length > 0) || state.saveMXGroupsReq.saveVS.visitScheduleList.length > 0) {
                infoModalText = "Please save group changes before changing group.";
            }
            if (infoModalText === "") {
                return {
                    ...state,
                    selectedGroup: action.payload ? {
                        groupId: action.payload,
                        isScheduled: selectedGroup?.shiftStartNumber !== null && selectedGroup?.shiftStartNumber !== 0 && selectedGroup?.shiftEndNumber !== null && selectedGroup?.shiftEndNumber !== 0,
                        groupInfo: selectedGroup,
                        startShiftInfo: selectedGroup?.shiftStartNumber && state.visitShifts?.find(vs => vs.shiftNumber == selectedGroup?.shiftStartNumber),
                        endShiftInfo: selectedGroup?.shiftEndNumber && state.visitShifts?.find(vs => vs.shiftNumber == selectedGroup?.shiftEndNumber)
                    }
                        : null
                }
            }
            else {
                return {
                    ...state,
                    infoModal: infoModalText
                }
            }
        }
        case `${SAVE_VISIT_SCHEDULE}_FULFILLED`: {
            return {
                ...state,
                isSaveVisitScheduleSuccess: action.payload?.saveVSandAssociateGroups?.isSuccess,
                saveVisitScheduleError: action.payload?.saveVSandAssociateGroups?.error,
                isLoadingOnActionSuccess: true,
                saveMXGroupsReq: {
                    ...state.saveMXGroupsReq,
                    saveVS: {
                        ...state.saveMXGroupsReq.saveVS,
                        associateGroups: [...state.saveMXGroupsReq.saveVS.associateGroups
                            .map(assocGroup => assocGroup.groupName !== "OTHERS" ?
                                { ...assocGroup, mxmasterIds: [] } : { ...assocGroup, mxmasterIds: [] })
                        ],
                        visitScheduleList: []
                    }
                }
            }
        }
        case `${RESET_LOADGROUPS}`: {
            return {
                ...state,
                selectedGroup: state.selectedGroup ? {
                    groupId: state.selectedGroup.groupId,
                    groupInfo: null,
                    startShiftInfo: null,
                    endShiftInfo: null
                } : null,
                loadGroups: null,
                mxGroups: null,
                visitShifts: null,
                groupSummary: null,
                otherGroup: null,
                isLoadingLoadGroups: false,
                isLoadingOnActionSuccess: false,
                isVisitSchedulingTabActive: false,
                saveMXGroupsReq: {
                    "saveVS": {
                        "tailNumber": "",
                        "scheduledArrivalDate": "",
                        "station": "",
                        "visitScheduleList": [],
                        "checkType": "",
                        "modifiedBy": "",
                        "firstName": "",
                        "lastName": "",
                        "associateGroups": []
                    }
                },
            }
        }
        case `${SET_GROUP_SCHEDULE_UPDATE_FLAG}`: {
            return {
                ...state,
                groupScheduleUpdatedFlag: action.payload
            }
        }
        case `${SET_SAVE_MXGROUP_REQUEST}`: {
            return {
                ...state,
                saveMXGroupsReq: action.payload,
                isSaveGroupDirty: action.payload?.saveVS?.associateGroups?.find(assocGroup => assocGroup.mxmasterIds.length > 0) !== undefined
            }
        }
        case `${SET_MXGROUP_OVERRIDE}`: {
            return {
                ...state,
                saveMXGroupsReq: action.payload,
                isSaveGroupDirty: action.payload?.saveVS?.associateGroups?.find(assocGroup => assocGroup.mxmasterIds.length > 0) !== undefined
            }
        }
        case `${SET_INFO_MODAL}`: {
            return {
                ...state,
                infoModal: action.payload
            }
        }
        case `${FETCH_GROUPS_HISTORY}_PENDING`: {
            return {
                ...state,
                loadGroupsTransactionHistory: [],
                loadGroupsScheduleHistory: [],
                loadGroupsOverrideHistory: [],
                isLoadingLoadGroupsHistory: true,
            }
        }
        case `${FETCH_GROUPS_HISTORY}_FULFILLED`: {
            const loadGroupsHistory = action.payload.getVisitScheduleHistory
            return {
                ...state,
                loadGroupsTransactionHistory: loadGroupsHistory.groupHistory,
                loadGroupsScheduleHistory: loadGroupsHistory.groupScheduleHistory,
                loadGroupsOverrideHistory: loadGroupsHistory.overrideScheduleHistory,
                isLoadingLoadGroupsHistory: false
            }
        }
        case `${SCROLL_WORKLOAD_FLAG}`: {
            return {
                ...state,
                scrollWorkloadFlag: action.payload
            }
        }
        case `${SEARCH_WORKLOAD}_PENDING`: {
            return {
                ...state,
                assignCrewWorkload: null,
                isLoadingAssignCrewWorkload: true,
                workLoadScheduledDate: null,
                // selectedWorkload: [],
                isValidAssign: false
            }
        }
        case `${SEARCH_WORKLOAD}_FULFILLED`: {
            return {
                ...state,
                assignCrewWorkload: action.payload?.searchWorkload?.mxItems || action.payload?.searchTemplateWorkload?.mxItems || action.payload?.searchWorkloadWithMultiSelect?.mxItems || action?.payload?.searchTemplateWorkloadWithMultiSelect?.mxItemDetails,
                workLoadScheduledDate: action.payload?.searchWorkload?.scheduledDate || action.payload?.searchTemplateWorkload?.scheduledDate || action.payload?.searchWorkloadWithMultiSelect?.scheduledDate || action?.payload?.searchTemplateWorkloadWithMultiSelect?.mxItemDetails,
                isLoadingAssignCrewWorkload: false
            }
        }
        case `${SEARCH_WORKLOAD_ENHANCED}_PENDING`: {
            return {
                ...state,
                assignCrewWorkload: null,
                isLoadingAssignCrewWorkload: true,
                workLoadScheduledDate: null,
                // selectedWorkload: [],
                isValidAssign: false
            }
        }
        case `${SEARCH_WORKLOAD_ENHANCED}_FULFILLED`: {
            return {
                ...state,
                assignCrewWorkload: action.payload?.searchWorkload?.mxItems || action.payload?.searchTemplateWorkload?.mxItems || action.payload?.searchWorkloadWithMultiSelect_Enhanced?.mxItems || action?.payload?.searchTemplateWorkloadWithMultiSelect?.mxItemDetails,
                workLoadScheduledDate: action.payload?.searchWorkload?.scheduledDate || action.payload?.searchTemplateWorkload?.scheduledDate || action.payload?.searchWorkloadWithMultiSelect_Enhanced?.scheduledDate || action?.payload?.searchTemplateWorkloadWithMultiSelect?.mxItemDetails,
                isLoadingAssignCrewWorkload: false
            }
        }
        case `${GET_BASE_CREW_WORKLOAD}_PENDING`: {
            return {
                ...state,
                assignedWorkload: null,
                workLoadScheduledDate: null,
                isLoadingAssignedWorkload: true,
                isUnAssignSuccess: false
            }
        }
        case `${GET_BASE_CREW_WORKLOAD}_FULFILLED`: {
            return {
                ...state,
                assignedWorkload: action.payload?.searchWorkloadWithMultiSelect?.mxItems,
                workLoadScheduledDate: action.payload?.searchWorkloadWithMultiSelect?.scheduledDate,
                isLoadingAssignedWorkload: false
            }
        }
        case `${UNASSIGN_CREW}_PENDING`: {
            return {
                ...state,
                isUnAssignCrewPending: true,
                unAssignFailureMessage: null,
                selectedWorkload: [],
                selectedCrews: [],
                isValidAssign: false
            }
        }
        case `${UNASSIGN_CREW}_FULFILLED`: {
            return {
                ...state,
                isUnAssignCrewPending: false,
                isUnAssignSuccess: action.payload.assignOrUnassignCrew && action.payload.assignOrUnassignCrew?.isSuccess,
                unAssignFailureMessage: action.payload.assignOrUnassignCrew && action.payload.assignOrUnassignCrew.errors?.description,
            }
        }
        case `${GET_AVAIALABLE_CREW}_PENDING`: {
            return {
                ...state,
                availableCrew: null,
                stationSkill: null,
                crewFilters: null,
                isLoadingAvailableCrew: true,
                selectedCrews: [],
                selectedWorkload: [],
                isValidAssign: false,
                isUnAssignSuccess: false
            }
        }
        case `${GET_AVAIALABLE_CREW}_FULFILLED`: {
            return {
                ...state,
                availableCrew: action.payload?.availableCrews?.shiftCrews,
                stationSkill: action.payload?.availableCrews?.stationSkills,
                crewFilters: action.payload?.availableCrews?.shiftCrews?.map(ac => ({ crewConfigId: ac.crewConfigId, crewName: ac.crewName })),
                isLoadingAvailableCrew: false
            }
        }
        case `${GET_GROUPS_AND_WORKAREA}_PENDING`: {
            return {
                ...state,
                workBins: null,
                crewGroups: null,
                workAreas: null,
                skills: null,
                zoneTypes: null,
                taskcardTypes: null,
                isLoadingGroupsAndWorkAreas: true
            }
        }
        case `${GET_GROUPS_AND_WORKAREA}_FULFILLED`: {
            return {
                ...state,
                workBins: action.payload?.getMxVisitGroupAndWorkArea?.workBins,
                crewGroups: action.payload?.getMxVisitGroupAndWorkArea?.mxGroups,
                workAreas: action.payload?.getMxVisitGroupAndWorkArea?.workAreas,
                skills: action.payload?.getMxVisitGroupAndWorkArea?.skills,
                zoneTypes: action.payload?.getMxVisitGroupAndWorkArea?.zoneTypes.map(zt => ({ ...zt, zoneType: `${zt.zoneTypeCode} ${zt.zoneType}` })),
                taskcardTypes: action.payload?.getMxVisitGroupAndWorkArea?.taskcardTypes,
                isLoadingGroupsAndWorkAreas: false
            }
        }

        case `${GET_GROUPS_AND_WORKAREA_WITH_COUNTS}_PENDING`: {
            return {
                ...state,
                workBins: null,
                crewGroups: null,
                workAreas: null,
                skills: null,
                zoneTypes: null,
                taskcardTypes: null,
                isLoadingGroupsAndWorkAreasWithCounts: true
            }
        }
        case `${GET_GROUPS_AND_WORKAREA_WITH_COUNTS}_FULFILLED`: {
            return {
                ...state,
                workBins: action.payload?.geFilterCountsForCAWithMultiSelect?.workBins,
                crewGroups: action.payload?.geFilterCountsForCAWithMultiSelect?.mxGroups,
                workAreas: action.payload?.geFilterCountsForCAWithMultiSelect?.workAreas,
                skills: action.payload?.geFilterCountsForCAWithMultiSelect?.skills,
                zoneTypes: action.payload?.geFilterCountsForCAWithMultiSelect?.zoneTypes.map(zt => ({ ...zt, zoneType: `${zt.zoneTypeCode} ${zt.zoneType}` })),
                taskcardTypes: action.payload?.geFilterCountsForCAWithMultiSelect?.taskcardTypes,
                isLoadingGroupsAndWorkAreasWithCounts: false
            }
        }

        case `${GET_PROGRESS_FOR_TASKCARDS}_PENDING`: {
            return {
                ...state,
                taskCardsProgress: null,
                isTaskCardsProgressLoading: true
            }
        }
        case `${GET_PROGRESS_FOR_TASKCARDS}_FULFILLED`: {
            return {
                ...state,
                taskCardsProgress: action.payload.taskcardProgress.skills,
                isTaskCardsProgressLoading: false
            }
        }
        case `${SET_SELECTED_WORKLOAD}`: {
            return {
                ...state,
                selectedWorkload: action.payload,
                isValidAssign: action.payload && action.payload.length > 0 && state.selectedCrews && state.selectedCrews.length > 0
            }
        }
        case `${SET_SELECTED_CREWS}`: {
            return {
                ...state,
                selectedCrews: action.payload,
                isValidAssign: action.payload && action.payload.length > 0 && state.selectedWorkload && state.selectedWorkload.length > 0
            }
        }
        case `${SET_SELECTED_ASSIGNED_WORKLOAD}`: {
            return {
                ...state,
                selectedAssignedWorkload: action.payload,
                isValidUnAssign: action.payload && action.payload.length > 0
            }
        }
        case `${CURRENT_CREW_DATE}`: {
            return {
                ...state,
                isCurrentCrew: action.payload
            }
        }
        case `${UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY}`: {
            const { queryStringValues, taskTypes, isDup, notes } = action.payload;
            return {
                ...state,
                assignCrewWorkload: state.assignCrewWorkload && [...state.assignCrewWorkload.map((wl) => (
                    (!isDup && (wl.dash8 === queryStringValues.dash8))
                    || (isDup && queryStringValues.optimizedDash8s.find(d8 => d8.ncaPartNumber === wl.dash8))
                ) &&
                    ((!isDup && (wl.cardNumber === queryStringValues.taskcard))
                        || (isDup && (wl.cardNumber === queryStringValues.taskcard) && (wl.optimizedGroup === queryStringValues.optimizedGroup))
                        || wl.zoneId === queryStringValues.taskcard) &&
                    wl.serialNumber === queryStringValues.serialno &&
                    wl.workControlNumber === queryStringValues.wcno
                    ? {
                        ...wl, notesCount: wl.notesCount + 1, lastNoteDetail: {...notes}, workStop: queryStringValues.workStop, ...(queryStringValues.workStop ? {
                            workStopActionType: taskTypes?.find(ttype => ttype.actionTypeId === queryStringValues.actionTypeId)?.actionType,
                            workStopActionTypeAbbreviated: taskTypes?.find(ttype => ttype.actionTypeId === queryStringValues.actionTypeId)?.abbreviatedActionType
                        } : {})
                    }
                    : wl
                )]
            }
        }
        case `${RESET_SEARCH_WORKLOAD}`: {
            return {
                ...state,
                assignCrewWorkload: null,
                workLoadScheduledDate: null,
                selectedWorkload: []
            }
        }
        case `${SET_IS_ITEM_UNCHECKED}`: {
            return {
                ...state,
                isItemUnchecked: action.payload
            }
        }
        case `${DELETE_MXGROUP}_PENDING`: {
            return {
                ...state,
                isDeletingMilestone: true
            }
        }
        case `${DELETE_MXGROUP}_FULFILLED`: {
            return {
                ...state,
                isDeletingMilestone: false
            }
        }
        // Update assigned workload after unassign
        case `${UNASSIGN_ASSIGN_WORKLOAD}`: {
            return {
                ...state,
                assignedWorkload: state.assignedWorkload.filter(aw => !action.payload.includes(aw.mxMasterId))
            }
        }
        case `${UNASSIGN_ASSIGN_WORKLOAD_LOCAL_UPDATE}`: {
            let lclCrews = [...state.availableCrew]?.filter(ac => action.payload.crews.includes(ac.crewConfigId));
            let updatedCrewWorkload =
                [...state.assignCrewWorkload].map(wl => {
                    let assignedCrewsIds = wl.assignedCrews.map(ac => ac.crewConfigId);
                    let crewsTobeAdded = lclCrews.filter(c => !(assignedCrewsIds.includes(c.crewConfigId)));
                    return action.payload.workload.includes(wl.mxMasterId) ?
                        {
                            ...wl,
                            assignedCrews: action.payload.isAssign ?
                                [...wl.assignedCrews, ...crewsTobeAdded]
                                : [...wl.assignedCrews.filter(ac => !action.payload.crews.includes(ac.crewConfigId))]

                        } : wl;
                })


            return {
                ...state,
                assignCrewWorkload: updatedCrewWorkload
            }
        }

        case `${CREW_WORK_LOAD_SEARCH}`:
            return {
                ...state,
                workLoadSearch: { ...(state.workLoadSearch ? state.workLoadSearch : {}), ...action.payload }
            }
        case CLEAR_CREW_WORK_LOAD_SEARCH:
            return {
                ...state,
                workLoadSearch: null,
                assignCrewWorkload: null
            }
        //after incorporating workbins into assign crew if the below case is not in this reducer it is defaulting the moved workload to be checked     
        case `${MOVE_WORKLOAD_TO_MULTIPLE_WORKBIN}_FULFILLED`:
        case `${ADD_WORKLOAD_TO_WORKBIN}_FULFILLED`:
            return {
                ...state,
                selectedWorkload: []
            }
        case `${SAVE_LAST_BLOCK_NUMBER}_PENDING`:
            return {
                ...state,
                isBlockNumberLoading: true
            }
        case `${SAVE_LAST_BLOCK_NUMBER}_FULFILLED`:
            return {
                ...state,
                isBlockNumberLoading: false
            }
            
        case 'UPDATE_WORKBIN_SEARCH':{
            const mxMasterIds = action.payload.workloadObj.map(({mxMasterId}) => mxMasterId)
            const _assignCrewWorkload = [...state.assignCrewWorkload].map(crewWl => {
                if(mxMasterIds.includes(crewWl.mxMasterId)){
                    crewWl.workBins = action.payload.workBins
                }
                return crewWl
            })
            return{
              ...state,
              assignCrewWorkload: _assignCrewWorkload
            }
        }
        case 'REMOVE_WORKBIN_FROM_WORKLOAD':{
            const mxMasterIds = action.payload.workload.map(({mxMasterId}) => mxMasterId)
            const wbins = action.payload.workbin.map(({workBinId}) => workBinId)
            const _assignCrewWorkload = [...state.assignCrewWorkload].map(crewWl => {
                if(mxMasterIds.includes(crewWl.mxMasterId)){
                    crewWl.workBins = crewWl.workBins.filter(wbin => !wbins.includes(wbin.workBinId))
                }
                return crewWl
            })
            return{
              ...state,
              assignCrewWorkload: _assignCrewWorkload
            }
        }
        case `${DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN}_FULFILLED`:
            return{
                ...state,
                selectedWorkload:[]
            }
        default: return state;
    }
}

export default visitScheduleReducer;