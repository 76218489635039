import { FETCH_VISITS, FETCH_VISIT_INFO } from './actionTypes';
import { CLICK_ADD_VISIT } from './actionTypes';
import { CLICK_ACCORDION_FILTER_VISIT } from './actionTypes';
import { CLICK_VIEW_HISTORY } from './actionTypes';
import { CLOSE_ACCORDION_FILTER_VISIT } from './actionTypes';
import { CLICK_CANCEL_VISIT } from './actionTypes';
import { FETCH_TAIL_NUMBER_LOOKUP, SET_AIRCRAFT_INFO } from './actionTypes';
import { trackPromise } from 'react-promise-tracker';
import * as Queries from '../queries/VisitQueries';
import { CLICK_SAVE_VISIT, SET_LAST_REFRESHED } from './actionTypes';
import {
    UPDATE_VISIT, UPDATE_VISIT_INFO_LOCALLY, SET_NOTIFICATION_MESSAGE, RESET_NOTIFICATION_MESSAGE,
    RESET_UPDATE_FLAGS, RTS_AIRCRAFT, RESET_RTS_FLAGS, CLOSE_VISIT, SET_GENERIC_ERROR, RESET_VISIT_INFO,
    GET_BASE_DASHBOARD_COUNTS_ACTIVE, GET_BASE_DASHBOARD_COUNTS_FUTURE, SET_FAILED_NOTIFICATION_MESSAGE, 
    GET_SCHEDULEDWORK_COUNT, GET_NONROUTINES_COUNT, GET_LOGPAGES_COUNT, GET_FLEET_CODES, FETCH_VISIT_INFO_LITE,
    SET_VISITS_DATA
} from './actionTypes';
import { executeQuery } from '../helper';

const getVisitType = (visitType) => {
    switch(visitType) {
    case 'active': return 'open'
    case 'future': return 'future'
    case 'history': return 'archived'
    default: break;
    }
}

export const fetchVisits = (station, _visitsType) => {
    return (dispatch, getState) => {
        const filteredVisits = getState() && getState().visitsStore.filteredVisits;
        const visitsType = _visitsType ?? (getState() && getState().visitsStore.activeVisit);
        if(!(filteredVisits && filteredVisits[_visitsType]))
        {
            return dispatch({
                type: FETCH_VISITS,
                payload: executeQuery(Queries.fetchVisitsQuery(station, getVisitType(visitsType)), null, getState(), 'Visit')
            }).then(resp => {
                let openVisits = resp?.value?.openVisits
                if (openVisits) {
                    dispatch({
                        type: SET_VISITS_DATA,
                        payload: {visits: resp.value.openVisits, visitsType: visitsType}
                    })
                    if(visitsType === "active" || visitsType === "future") 
                    {                        
                        const visitsCounts = {
                            "counts": {
                                "station": station,
                                "tailNumbers": openVisits.map(v => {                          
                                    return {
                                        "showRoutines": true,
                                        "isHvyChkPackage": v.enableZonalCard,
                                        "tailNumber": v.aircraftNumber,
                                        "inductionDate": v.actualInductionDate || v.plannedInductionDate ,
                                        "estTimeofReturn": v.estTimeofReturn,
                                        "actualInductionDate": v.actualInductionDate
                                        } 
                                    }
                                )
                            }
                        }                        
                        
                        if(visitsType === "active")
                            dispatch({
                                type: GET_BASE_DASHBOARD_COUNTS_ACTIVE,
                                payload: executeQuery(Queries.fetchBaseDashboardCountsQuery(), visitsCounts, getState(), 'Visit')
                            })

                        if(visitsType === "future")
                            dispatch({
                                type: GET_BASE_DASHBOARD_COUNTS_FUTURE,
                                payload: executeQuery(Queries.fetchBaseDashboardCountsQuery(), visitsCounts, getState(), 'Visit')
                            })
                    }
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
        }
    }
}

export const fetchHistoricVisits = (filters)=> (dispatch, getState) =>{
    // try {
    const station = sessionStorage.getItem("apiStation")
    return dispatch({
        type: FETCH_VISITS,
        payload: executeQuery(Queries.fetchArchiveVisitsQuery(station, filters), null, getState(), 'Visit')
    }).then(resp => {
        let archivedVisits = resp?.value?.archivedVisits;
        if (archivedVisits) {
            dispatch({
                type: SET_VISITS_DATA,
                payload: {visits:archivedVisits, visitsType: 'history'}
            })
            dispatch({
                type: SET_LAST_REFRESHED,
                payload: Date.now()
            })
            return archivedVisits
        }
    })
    
    // } catch (error) {
    //     console.log(error)
    // }
}

export const fetchFleetCodes = (templateDetails) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        let variable = {
            "userInfo": {
                "userId": profile.userId,
                "firstName": profile.firstName,
                "station": templateDetails.station.value,
                "lastName": profile.lastName,
            }
        }
        return dispatch({
            type: GET_FLEET_CODES,
            payload: executeQuery(Queries.fleetCodes(), variable, getState(), 'Visit')
        }).catch(error => {
            dispatch({
                type: SET_GENERIC_ERROR,
                payload: error.message
            })
        });
    }
}

export const handleAddVisitClick = () => {
    return dispatch => {
        dispatch({
            type: CLICK_ADD_VISIT
        });
    }
}
export const handleAccordionFilterClick = () => {
    return dispatch => {
        dispatch({
            type: CLICK_ACCORDION_FILTER_VISIT
        });
    }
}
export const handleViewHistoryClick = () => {
    return dispatch => {
        dispatch({
            type: CLICK_VIEW_HISTORY
        });
    }
}
export const handleAccordionFilterClose = () => {
    return dispatch => {
        dispatch({
            type: CLOSE_ACCORDION_FILTER_VISIT
        });
    }
}
export const handleCancelVisitClick = () => {
    return dispatch => {
        dispatch({
            type: CLICK_CANCEL_VISIT
        });
    }
}
export const fetchTailNumbers = () => {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_TAIL_NUMBER_LOOKUP,
            payload: executeQuery(Queries.fetchTailNumbersQuery(), null, getState(), 'Visit')
        });
    }
}
export const fetchVisitInfo = (acControlID, station, isExt) => {
    const localVisitInfo = sessionStorage.getItem(acControlID);    
    return (dispatch, getState) => {
        if(localVisitInfo && JSON.parse(localVisitInfo) && !isExt){
            dispatch({
            type: FETCH_VISIT_INFO_LITE,
            payload: executeQuery(Queries.fetchVisitInfoLite(acControlID, station), null, getState(), 'Visit')
        })
            return false
        }
        return dispatch({
            type: FETCH_VISIT_INFO,
            payload: executeQuery(Queries.fetchVisitInfo(acControlID, station), null, getState(), 'Visit')
        }).then(resp => {
            if (resp !== null && resp.value.viewVisitInfo !== null) {
                sessionStorage.setItem(acControlID, JSON.stringify(resp.value));
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const fetchVisitInfoLite = (acControlID, station) => {
    return (dispatch, getState) => {
        return dispatch({
            type: FETCH_VISIT_INFO_LITE,
            payload: executeQuery(Queries.fetchVisitInfo(acControlID, station), null, getState(), 'Visit')
        })
    }
}


export const handleSaveVisitClick = (visitInfo) => {
    return (dispatch, getState) => {
        dispatch({
            type: CLICK_SAVE_VISIT,
            payload: trackPromise(executeQuery(Queries.saveVisitQuery(), visitInfo, getState(), 'Visit'))
        }).then(resp => {
            if (resp.value.addVisit.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "A visit record has been created successfully for A/C " + visitInfo.visitInfo.aircraftNumber + " in the visit panel."
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}


export const resetNotificationMessage = () => {
    return dispatch => {
        dispatch({
            type: RESET_NOTIFICATION_MESSAGE
        });
    }
}

export const resetUpdateFlags = () => {
    return dispatch => {
        dispatch({
            type: RESET_UPDATE_FLAGS
        });
    }
}

export const setVisitInfoLocally = (visitInfo) => {
    return dispatch => {
        dispatch({
            type: UPDATE_VISIT_INFO_LOCALLY,
            payload: visitInfo
        });
    }
}

export const handleUpdateVisitClick = (updateVisitInfo, profile, revisedETRDate = null, etrChangeReason = "", updateType = "") => {
    const visitInfo = {
        "visitInfo": {
            "aircraftControlId": updateVisitInfo.aircraftControlId,
            "aircraftNumber": updateVisitInfo.aircraftNumber,
            "plannedInductionDate": updateVisitInfo.plannedInductionDate,
            "location": updateVisitInfo.location,
            "plannedETRDate": updateVisitInfo.plannedETRDate,
            "actualInductionDate": updateVisitInfo.actualInductionDate,
            "createdBy": updateVisitInfo.createdBy,
            "isArchived": updateVisitInfo.isArchived,
            "station": updateVisitInfo.station,
            "packageType": updateVisitInfo.packageType,
            "showRoutines": updateVisitInfo.showRoutines,
            "showNonRoutines": updateVisitInfo.showNonRoutines,
            "enableVS": updateVisitInfo.enableVS,
            "modifiedBy": profile.userId,
            "shiftCount": updateVisitInfo.shiftCount,
            "revisedPlannedETRDate": revisedETRDate,
            "eTRChangeReason": etrChangeReason,
            "firstName": profile.firstName,
            "lastName": profile.lastName,
            "isStartVisit": updateType.toUpperCase() === "STARTVISIT"
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_VISIT,
            payload: trackPromise(executeQuery(Queries.updateVisitQuery(), visitInfo, getState(), 'Visit'))
        }).then(resp => {
            if (resp.value.updateVisit.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: updateType === "StartVisit" ? "Visit for A/C " + updateVisitInfo.aircraftNumber + " has been successfully inducted" : "Visit update successful"
                });                
                dispatch({
                    type: FETCH_VISIT_INFO,
                    payload: executeQuery(Queries.fetchVisitInfo(updateVisitInfo.aircraftControlId, updateVisitInfo.station), null, null, 'Visit')
                })
                .then(resp => {
                    if (resp !== null && resp.value.viewVisitInfo !== null) {
                        sessionStorage.setItem(updateVisitInfo.aircraftControlId, JSON.stringify(resp.value));
                        dispatch({
                            type: SET_LAST_REFRESHED,
                            payload: Date.now()
                        })
                    }
                })
                    .catch(error => {
                        dispatch({
                            type: SET_GENERIC_ERROR,
                            payload: error?.message
                        })
                    });             
            }
            else {
                dispatch({
                    type: SET_FAILED_NOTIFICATION_MESSAGE,
                    payload: updateType === "StartVisit" ? resp.value.updateVisit.error : "Visit update failed"
                });
                dispatch(fetchVisitInfo(updateVisitInfo.aircraftControlId, updateVisitInfo.station));
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const handleRTSAircraft = (rtsVisitInfo, userId, lastModifiedDate, baseOverideFlag, rtsOverrideReason) => {
    const visitInfo = {
        "visitInfo": {
            "aircraftControlId": rtsVisitInfo.aircraftControlId,
            "aircraftNumber": rtsVisitInfo.aircraftNumber,
            "station": rtsVisitInfo.station,
            "actualInductionDate": rtsVisitInfo.actualInductionDate,
            "lastModifiedDate": lastModifiedDate,
            "modifiedBy": userId,
            "isBaseOverride": baseOverideFlag,
            "rtsOverrideReason": rtsOverrideReason,
            "enableVS": rtsVisitInfo.enableVS
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: RTS_AIRCRAFT,
            payload: trackPromise(executeQuery(Queries.aircraftRTSQuery(), visitInfo, getState(), 'Visit'))
        }).then(resp => {
            if (resp.value.archiveVisit.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "A/C " + rtsVisitInfo.aircraftNumber + " has been successfully returned to service."
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const resetRTSFlags = () => {
    return dispatch => {
        dispatch({
            type: RESET_RTS_FLAGS
        })
    }
}

export const closeVisit = (aircraftControlId, lastModifiedDate, modifiedBy) => {
    const closeVisitInfo = {
        "visitInfo": {
            "aircraftControlId": aircraftControlId,
            "lastModifiedDate": lastModifiedDate,
            "modifiedBy": modifiedBy
        }
    }
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_VISIT,
            payload: trackPromise(executeQuery(Queries.closeVisit(), closeVisitInfo, getState(), 'Visit'))
        }).then(resp => {
            if (resp.value.closeVisit.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Visit has been successfully closed"
                });
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const setAircraftInfo = (aircraftInfo) => {
    return dispatch => {
        dispatch({
            type: SET_AIRCRAFT_INFO,
            payload: aircraftInfo
        });
    }
}

export const resetVisitInfo = () => {
    return dispatch => {
        dispatch({
            type: RESET_VISIT_INFO
        });
    }
}

export const getLogPagesCount = (visit) => {
    let variable = {
        "tailnumber": {
            "tailNumber": visit.aircraftNumber,
            "isHistoricalVisit": visit.isArchived
        }
    };

    return (dispatch, getState) => {
        dispatch({
            type: GET_LOGPAGES_COUNT,
            payload: executeQuery(Queries.getLogPagesCount(), variable, getState(), 'VisitSchedule')
        })
    }
}

export const getNonRoutinesCount = (visit) => {
    let variable = {
        "loadGroupsTMNonRoutine": {
            "tailNumber": visit.aircraftNumber,
            "station": visit.station,
            "arrivalDate": visit.actualInductionDate ? visit.actualInductionDate : visit.plannedInductionDate,
            "isCalledByWeb": true,
            "visitId": visit.visitId,
            "isHistoricalVisit": visit.isArchived,
            "enableVS": visit.enableVS

        }
    };

    return (dispatch, getState) => {
        dispatch({
            type: GET_NONROUTINES_COUNT,
            payload: executeQuery(Queries.getNonRoutinesCount(), variable, getState(), 'VisitSchedule')
        })
    }
}

export const getScheduledWorkCount = (visit) => {
    let variable = {
        "loadGroupsTMScheduledWork": {
            "groupName": "",
            "checkType": visit.checkType,
            "visitId": visit.visitId,
            "lastWorkLoadRequestDate": null,
            "tailNumber": visit.aircraftNumber,
            "station": visit.station,
            "inFlightNumber": "0000",
            "outFlightNumber": "",
            "arrivalDate": visit.actualInductionDate ? visit.actualInductionDate : visit.plannedInductionDate,
            "departureDate": visit.actualETRDate ? visit.actualETRDate : visit.plannedETRDate,
            "estimatedArrivalDate": visit.actualInductionDate ? visit.actualInductionDate : visit.plannedInductionDate,
            "estimatedDepartureDate": visit.plannedETRDate || visit.actualETRDate,
            "packageType": visit.packageType,
            "userId": "u354214",
            "isHvyChkPackage": visit.enableZonalCard,
            "isHistoricalVisit": visit.isArchived,
            "enableVS": visit.enableVS
        }
    }

    return (dispatch, getState) => {
        dispatch({
            type: GET_SCHEDULEDWORK_COUNT,
            payload: executeQuery(Queries.getScheduledWorkCount(), variable, getState(), 'VisitSchedule')
        })
    }
}

