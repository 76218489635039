import {
    FETCH_WORK_BINS, FETCH_WORK_BINS_WORKLOAD, CREATE_WORK_BIN, SET_NOTIFICATION_MESSAGE, SET_LAST_REFRESHED,
    SET_GENERIC_ERROR, SET_ACTIVE_WORK_BIN, DELETE_WORK_BIN, UPDATE_SELECTED_WORKBIN_WORKLOAD, MOVE_WORKLOAD_TO_WORKBIN,
    DELETE_WORKLOAD_FROM_WORKBIN, UPDATE_SELECTED_WORKBINS, ADD_WORKLOAD_TO_WORKBIN, GET_WORKLOAD_WORKBIN,
    GET_WORKBIN_WORKLOAD_COUNT, SET_SELECTED_WORKLOAD, SET_FAILED_NOTIFICATION_MESSAGE, RESET_WORKBINS,
    SET_WORKBIN_WIDGET_FLOW_FLAG, GET_TEMPLATE_WORKBIN_WORKLOAD, UPDATE_SELECT_ALL_WORKBINS, MOVE_WORKLOAD_TO_MULTIPLE_WORKBIN, DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN
} from './actionTypes';
import * as workBinsQueries from '../queries/WorkBinsQueries';
import { executeQuery } from '../helper';
import { trackPromise } from 'react-promise-tracker';
import { searchWorkloadWithMultiSelect } from './assignCrewActions';

const getSearchRequest = (workbinId) => {
    return {
        taskcardNumber: { value: "", isValid: true },
        nRNumber: { value: "", isValid: true },
        description: { value: "", isValid: true },
        workAreaId: { value: "", isValid: true },
        workBinIds: { value: [{ value: workbinId }], isValid: true },
        mxVisitGroupId: { value: "", isValid: true },
        //crewConfigId: { value: "", isValid: true },
        //shiftNumber: { value: "", isValid: true },
        crews: { value: [], isValid: true },
        shiftNumbers: { value: [], isValid: true },
        skillIds: { value: [], isValid: true },
        zoneTypeIds: { value: [], isValid: true },
        taskcardTypeIds: { value: [], isValid: true },
        mileStoneIds: { value: [], isValid: true },
        isSearchValid: false,
        workloadForWorkBins: true,
        returnDependencies: false,
    }
}

export const fetchWorkBins = (id, searchType, isRefData) => {    
    return (dispatch, getState) => {
        const visitInfo = searchType === "visit" ? getState() && getState().visitsStore.visitInfo : null;
        const variable = {
            "workBin": {
                "id": id,
                "searchType": searchType,
                "isHistoricalVisit": (searchType === "visit" && visitInfo) ? visitInfo.isArchived : false
              }        
        }
        dispatch({
            type: FETCH_WORK_BINS,
            payload: executeQuery( workBinsQueries.fetchWorkBins(), variable, getState(), 'WorkBin')
        }).then(resp => {
            if (resp?.value?.getAllWorkBins?.workBinsList) {
                if (!isRefData) {
                    const visitInfo =  getState() && getState().visitsStore.visitInfo;
                    dispatch(getWorkBinsWorkLoadCount(resp.value.getAllWorkBins.workBinsList, id, visitInfo, searchType === "template"));
                }
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const createWorkBin = (binName, description, id, searchType) => {
    
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const variable = {
            "createWorkBin": {
                "workBinName": binName,
                "description": description,
                "id": id,
                "searchType": searchType,
                "createdBy": profile.userId
            }
        }
        dispatch({
            type: CREATE_WORK_BIN,
            payload: trackPromise(executeQuery(workBinsQueries.createWorkBin(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.createWorkBin?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work bin created successfully."
                });
                dispatch(fetchWorkBins(id, searchType));
            }
            else {
                dispatch({
                    type: SET_FAILED_NOTIFICATION_MESSAGE,
                    payload: resp?.value?.createWorkBin?.errors && resp?.value?.createWorkBin?.errors[0].description
                });
            }
        })
    } 
}

export const setActiveWorkBin = (workbinId, isTemplate, id, isNewImplementation) => {
    return dispatch => {
        dispatch({
            type: SET_ACTIVE_WORK_BIN,
            payload: workbinId
        });
        dispatch({
            type: SET_SELECTED_WORKLOAD,
            payload: []
        })
        if (workbinId) {
            if (isNewImplementation) {
                const searchReq = getSearchRequest(workbinId);
                // dispatch(searchWorkload(searchReq, id, isTemplate));
               // dispatch(searchWorkloadWithMultiSelect(searchReq, id, isTemplate));
            }
            else {
                if (isTemplate)
                    dispatch(getTemplateWorkbinWorkload(workbinId, id));
                else
                    dispatch(getWorkloadWorkbin(workbinId));
            }
        }
        //service call to get workload
    }
}

export const searchWorkbinWorkload = (workbinId, isTemplate, id) => {
    return dispatch => {
        const searchReq = getSearchRequest(workbinId);
        dispatch(searchWorkloadWithMultiSelect(searchReq, id, isTemplate));
    }
}

export const deleteWorkBin = (workBinId, id, searchType, isNewImplementation) => {
    return (dispatch, getState) => {
        const variable = {
            "deleteWorkBin": {
                "workBinId":workBinId,
                "id": id,
                "searchType": searchType
              }            
        }
        dispatch({
            type: DELETE_WORK_BIN,
            payload: trackPromise(executeQuery(workBinsQueries.deleteWorkBin(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.deleteWorkBin?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work bin deleted successfully."
                });                
                dispatch(fetchWorkBins(id, searchType));
                if (isNewImplementation) {
                    dispatch(setActiveWorkBin(null, searchType !== "visit", id, isNewImplementation));
                }
            }
        })
    } 
}

export const resetWorkBins = () => {
    return dispatch => {
        dispatch({
            type: RESET_WORKBINS
        })
    }
}

export const updateWorkBin = (binName, description, id, searchType, binId) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const variable = {
            "updateWorkBin": {
                "workBinName": binName,
                "description": description,
                "workBinId":binId,
                "id": id,
                "searchType": searchType,
                "modifiedBy": profile.userId
              }            
        }
        dispatch({
            type: CREATE_WORK_BIN,
            payload: trackPromise(executeQuery(workBinsQueries.updateWorkBin(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.updateWorkBin?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work bin updated successfully."
                });
                dispatch(fetchWorkBins(id, searchType));
            }
        })
    } 
}

export const updateSelectedWorkbinWorkload = (workload) => {
    return dispatch => {
        dispatch({
            type: UPDATE_SELECTED_WORKBIN_WORKLOAD,
            payload: workload
        })
    }
}

export const updateSelectedWorkBins = (workBin) => {
    return dispatch => {
        dispatch({
            type: UPDATE_SELECTED_WORKBINS,
            payload: workBin
        })
    }
}

export const updateSelectAllWorkBins = (workBin) => {
    return dispatch => {
        dispatch({
            type: UPDATE_SELECT_ALL_WORKBINS,
            payload: workBin
        })
    }
}

export const moveWorkLoadToWorkBin = (workload, targetWorkBinId, sourceWorkBinId, id, searchType, isNewImplementation) => {
    const variable = {
        "moveWorkLoad": {
            "mxMasterIdList": workload.map(wl => wl.mxMasterId),
            "targetWorkBinId": targetWorkBinId,
            "sourceWorkBinId": sourceWorkBinId
          }        
    }
    return (dispatch, getState) => {
        dispatch({
            type: MOVE_WORKLOAD_TO_WORKBIN,
            payload: trackPromise(executeQuery(searchType === "visit" ? workBinsQueries.moveWorkloadToWorkBin() : workBinsQueries.moveWorkloadToTemplateWorkbin(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.moveWorkLoadToWorkBin?.isSuccess || resp?.value?.moveWorkLoadToTemplateWorkBin?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work load moved successfully."
                });
                dispatch(fetchWorkBins(id, searchType));
                if (isNewImplementation) {
                    const searchReq = getSearchRequest(sourceWorkBinId);
                    dispatch(searchWorkloadWithMultiSelect(searchReq, id, searchType !== "visit"));
                 }
                else {
                    if (searchType === "visit")
                        dispatch(getWorkloadWorkbin(sourceWorkBinId));
                    else {
                        dispatch(getTemplateWorkbinWorkload(sourceWorkBinId, id));
                    }
                }
            }
        });
    }
}

const moveDeleteWL = (workload, targetWorkBinId, sourceWorkBinId=null)=>{
    return workload.map(wl =>{
        return targetWorkBinId.filter(({workBinId}) => sourceWorkBinId ? workBinId != sourceWorkBinId : true).map(wb =>({
            "mxType": wl.mxType,
            "mxMasterId": wl.mxMasterId || wl.mxTemplateMasterId,
            "workBinId": wb.workBinId
        }))
    }).reduce((acc, cur) => [...acc,...cur],[])
}
export const moveWorkLoadToMultipleWorkBins = (workload, targetWorkBinId, sourceWorkBinId, id, searchType) => {
    const variable = {
        [searchType !== 'template' ? "moveMultipleWorkLoad" : 'moveMultipleWorkLoadInTemplate']: {
            "workBinWorkLoadDetailsList": moveDeleteWL(workload, targetWorkBinId, sourceWorkBinId)
            ,
            "sourceWorkBinId": sourceWorkBinId
          }        
    }
    return (dispatch, getState) => {
        dispatch({
            type: MOVE_WORKLOAD_TO_MULTIPLE_WORKBIN,
            payload: trackPromise(executeQuery(searchType !== 'template' ? workBinsQueries.moveMultipleWorkLoadToWorkBins() : workBinsQueries.moveMultipleWorkLoadToWorkBinsInTemplate(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.moveMultipleWorkLoadToWorkBins?.isSuccess || resp?.value?.moveMultipleWorkLoadToWorkBinsInTemplate?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work load moved successfully."
                });
                dispatch(fetchWorkBins(id, searchType));
                // if (isNewImplementation) {
                    const searchReq = getSearchRequest(sourceWorkBinId);
                    dispatch(searchWorkloadWithMultiSelect(searchReq, id, searchType !== "visit"));
                //  }
                // else {
                //     if (searchType === "visit")
                //         dispatch(getWorkloadWorkbin(sourceWorkBinId));
                //     else {
                //         dispatch(getTemplateWorkbinWorkload(sourceWorkBinId, id));
                //     }
                // }
            }
        });
    }
}

export const deleteWorkLoadFromWorkBin = (workload, sourceWorkBinId, id, searchType, isNewImplementation) => {
    const variable = searchType === "visit" ? {
        "deleteWorkLoad": {
            "mxMasterIdList": workload.map(wl => wl.mxMasterId),
            "workBinId": sourceWorkBinId
        }
    }
        :
        {
            "deleteWorkLoadFromTWorkBin": {
                "mxMasterIdList": workload.map(wl => wl.mxMasterId),
                "workBinId": sourceWorkBinId
            }
        }
    return (dispatch, getState) => {
        dispatch({
            type: DELETE_WORKLOAD_FROM_WORKBIN,
            payload: trackPromise(executeQuery(searchType === "visit" ? workBinsQueries.deleteWorkloadFromWorkBin() : workBinsQueries.deleteWorkloadFromTemplateWorbin(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.deleteWorkLoadFromWorkBin?.isSuccess || resp?.value?.deleteWorkLoadFromTemplateWorkBin?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work load deleted successfully."
                });
                dispatch(fetchWorkBins(id, searchType));
                if (isNewImplementation) {
                    const searchReq = getSearchRequest(sourceWorkBinId);
                    dispatch(searchWorkloadWithMultiSelect(searchReq, id, searchType !== "visit"));
                }
                else {
                    if (searchType === "visit")
                        dispatch(getWorkloadWorkbin(sourceWorkBinId));
                    else {
                        dispatch(getTemplateWorkbinWorkload(sourceWorkBinId, id));
                    }
                }
            }
        })
    }
}

export const deleteWorkLoadFromMutipleWorkBins = (workload, targetWorkBinId, id, sourceWorkBinId, searchType) => {
    const variable = {
        [searchType !== 'template' ? "deleteMultipleWorkLoad" : 'deleteMultipleWorkLoadInTemplate']: {
            "workBinWorkLoadDetailsList": moveDeleteWL(workload, targetWorkBinId)
          }        
    }
    return (dispatch, getState) => {
        dispatch({
            type: DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN,
            payload: trackPromise(executeQuery(searchType !== 'template' ? workBinsQueries.deleteMultipleWorkLoadFromWorkBins() : workBinsQueries.deleteMultipleWorkLoadFromTemplateWorkbins(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.deleteMultipleWorkLoadFromWorkBins?.isSuccess || resp?.value?.deleteMultipleWorkLoadFromTemplateWorkbins?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work load deleted successfully."
                });
                dispatch(fetchWorkBins(id, searchType));
                 if (sourceWorkBinId) {
                    const searchReq = getSearchRequest(sourceWorkBinId);
                    dispatch(searchWorkloadWithMultiSelect(searchReq, id, searchType !== "visit"));
                 }
                else {
                    if (searchType === "visit"){
                        // const searchParams = getState().visitScheduleReducer.workLoadSearch;
                        dispatch({
                            type: 'REMOVE_WORKBIN_FROM_WORKLOAD',
                            payload:{workload, workbin: targetWorkBinId}
                        })
                        // dispatch(searchWorkloadWithMultiSelect(searchParams, id, searchType === "visit"));
                        // dispatch(getWorkloadWorkbin(sourceWorkBinId));
                       } 
                    // else {
                    //     dispatch(getTemplateWorkbinWorkload(sourceWorkBinId, id));
                    // }
                }
            }
        })
    }
}

export const addWorkLoadToWorkBin = (workload, workBins, isTemplate, id, isNewImplementation) => {
    const workloadObj = workload.map(wl => { return { "mxMasterId": wl.mxMasterId ||  wl.mxTemplateMasterId, "mxType": wl.mxType } });
    const workBinObj = workBins.map(workBin => {
        return { "workLoadDetailsList": workloadObj, "workBinId": workBin.workBinId }
    });
    const wbinName = workBins.map(wbins=> wbins.workBinName)
    
    const variable = isTemplate ?
        {
            "addWorkLoadToTWorkBin": {
                "workBinsDetailsList": workBinObj
            }
        }
        :
        {
            "addWorkLoad": {
                "workBinsDetailsList": workBinObj
            }
        }
        
        
    return (dispatch, getState) => {
        dispatch({
            type: ADD_WORKLOAD_TO_WORKBIN,
            payload: trackPromise(executeQuery(isTemplate ? workBinsQueries.addWorkloadToTemplateWorkbin() : workBinsQueries.addWorkLoadToWorkBin(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.addWorkLoadToWorkBin?.isSuccess || resp?.value?.addWorkLoadToTemplateWorkBin?.isSuccess) {
                dispatch({
                    type: SET_NOTIFICATION_MESSAGE,
                    payload: "Work load added successfully."
                });
                dispatch({
                    type: SET_SELECTED_WORKLOAD,
                    payload: []
                })
                dispatch({
                    type: 'UPDATE_WORKBIN_SEARCH',
                    payload: {workloadObj, workBins}
                })
                if (isNewImplementation) {
                    dispatch(fetchWorkBins(id, isTemplate ? "template" : "visit"));
                }
            }
        })
    }
}

export const getWorkloadWorkbin = (workBinId) => {    
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const profile = getState() && getState().profileStore.userProfile;
        const variable = {
            "workloadWb": {
                "visitId": visitInfo.visitId,
                "workBinId": workBinId,
                "isCalledByWeb": true,
                "getEtaskWorkload": {
                    "inFlightNumber": "0000",
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.actualETRDate || visitInfo.plannedETRDate
                },
                "isFlifo": false,
                "jobRole": profile.jobRole,
                "employeeID": profile.userId,
                "station": sessionStorage.getItem('apiStation'),
                "appVersion": "1122",
                "tailNumber": visitInfo.aircraftNumber,
                "isHvyChkPackage": visitInfo.enableZonalCard,
                "isLineAircraft": false,
                "enableVS": visitInfo.enableVS,
                "baseStation": sessionStorage.getItem('apiStation')
            }
        }
        dispatch({
            type: GET_WORKLOAD_WORKBIN,
            payload: trackPromise(executeQuery( workBinsQueries.getWorkloadWorkbin(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.getWorkloadWorkbin?.isSuccess) {
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const getWorkBinsWorkLoadCount = (workBins, id, visitInfo, isTemplate) => {
    
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const workBinsCountReq = workBins.map(wb => { return { "workBinId": wb.workBinId } });
        const variable = isTemplate ?
            {
                "templateWorkBinsWorkLoadCount": {
                    "templateId": id,
                    "templateWorkBinsIdList": workBinsCountReq          
                }
            }
        :
            {
            "workBinsWorkLoadCount": {
                "workBinsIdList": workBinsCountReq,
                "visitId": id,
                "isCalledByWeb": true,
                "getEtaskWorkload": {
                    "inFlightNumber": "0000",
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.plannedETRDate
                },
                "isFlifo": false,
                "jobRole": profile.jobRole,
                "employeeID": profile.userId,
                "station": visitInfo.station,
                "appVersion": "",
                "tailNumber": visitInfo.aircraftNumber,
                "isHvyChkPackage": true,
                "isLineAircraft": false,
                "enableVS": visitInfo.enableVS,
                "baseStation": visitInfo.station,
                "isHistoricalVisit": visitInfo.isArchived
            }
        }
        dispatch({
            type: GET_WORKBIN_WORKLOAD_COUNT,
            payload: trackPromise(executeQuery(isTemplate ? workBinsQueries.getTemplateWorkBinsWorkLoadCount() : workBinsQueries.getWorkBinsWorkLoadCount(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            
        })
        .catch(error => {
            dispatch({
                type: SET_GENERIC_ERROR,
                payload: error?.message
            })
        });
    }
}

export const setWorkBinWidgetFlowFlag = (setFlag) => {
    return dispatch => {
        dispatch({
            type: SET_WORKBIN_WIDGET_FLOW_FLAG,
            payload: setFlag
        })
    }
}

export const resetWorkBinWidgetFlowFlag = () => {
    return dispatch => {
        dispatch({
            type: SET_WORKBIN_WIDGET_FLOW_FLAG,
            payload: false
        })
    }
}

export const getTemplateWorkbinWorkload = (workBinId, id) => {    
    return (dispatch, getState) => {        
        const profile = getState() && getState().profileStore.userProfile;
        const variable = {
            "mxMasterDetails": {
                "templateId": id,
                "isCalledByWeb": true,
                "taskCardNumber": "",
                "nRNumber": "",
                "workArea": "",
                "workBinId": workBinId,
                "taskCardTypeId": 0,
                "mileStone": "",
                "crew": "",
                "shiftNumber": "",
                "taskCardDescription": "",
                "skillId": 0,
                "workloadForWorkBins": true,
                "zoneTypeID": 0
            }
        }
        dispatch({
            type: GET_TEMPLATE_WORKBIN_WORKLOAD,
            payload: trackPromise(executeQuery( workBinsQueries.getTemplateWorkbinWorkload(), variable, getState(), 'WorkBin'))
        }).then(resp => {
            if (resp?.value?.getMxMasterDetailsForTemplateWorkBins?.taskCards) {
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            }
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}